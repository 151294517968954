import { useEffect, useMemo, useRef, useState } from "react";
import apiService from "../services/api.service";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline'
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import { flatten } from "lodash";
import { differenceInMilliseconds, differenceInSeconds } from "date-fns";

export const AudioPlayer = ({ 
    audioBlob = null as any,
    data =  [] as any
 }) => {

    const waveformRef = useRef<any>(null);
    const wavesurfer = useRef<any>(null);
    const [playing, setPlay] = useState(false);
    const [volume, setVolume] = useState(1);
    const [audioLoadComplete, setAudioLoadComplete] = useState(false);
    
    const loadAudio = async () => {
        
        // const res  = await apiService.get(`${process.env.REACT_APP_ServerBaseURL}/results/${url.id}/audio`, {
        //     responseType: 'blob',
        // });
        // const blob = new Blob([res.data], { type: "octet/stream" });
        wavesurfer.current.loadBlob(audioBlob);
    }

    const formWaveSurferOptions = (ref: any) => ({
        // container: ref,
        // waveColor: "#eee",
        // progressColor: "#3c8ebd",
        // cursorColor: "OrangeRed",
        // barWidth: 3,
        // barRadius: 3,
        // barHeight: .02,
        // responsive: true,
        // height: 100,
        // normalize: true,
        // partialRender: true,
        // autoCenter: true,
        container: ref,
        height: 150,
        waveColor: "#eee",
        progressColor: "#3c8ebd",
        cursorColor: "OrangeRed",
        cursorWidth: 2,
        barWidth: 3,
        barGap: 3,
        barRadius: 3,
        barHeight: .6,
        fillParent: true,
        mediaControls: false,
        autoplay: false,
        interact: true,
        dragToSeek: true,
        hideScrollbar: false,
        audioRate: 1,
        autoScroll: true,
        autoCenter: false,
        sampleRate: 14000,
        responsive: true,
        plugins: [
            TimelinePlugin.create({
                container: '#wave-timeline',
            }),
            
        ]
    
    });

    // const downloadFile = useMemo(async () => {
    //     try {
    //         const res = await apiService.get(`${process.env.REACT_APP_ServerBaseURL}/results/${url.id}/audio`, {
    //             responseType: 'blob',
    //         });

    //         const blob = new Blob([res.data], { type: "octet/stream" });
    
    //         return blob;
    
    //     } catch { }
    
    //     return null;
    // }, [url])

    const getTimes = data?.segments?.map((s: any) => s.steps.map((st: any) => ({
        ...st,
        segmentName: s.name,
        segmentId: s.id
    }))).flat();

    const DiffInSeconds = (start: any, end: any) => differenceInSeconds(new Date(start), new Date(end));

    const DiffInMilliSeconds = (start: any, end: any) => Math.abs(differenceInMilliseconds(new Date(start), new Date(end)));

    // Find regions separated by silence
    const extractRegions = (audioData: any, duration: any) => {
        const minValue = 0.01
        const minSilenceDuration = 0.1
        const mergeDuration = 0.2
        const scale = duration / audioData.length
        const silentRegions = []
    
        // Find all silent regions longer than minSilenceDuration
        let start = 0
        let end = 0
        let isSilent = false
        for (let i = 0; i < audioData.length; i++) {
        if (audioData[i] < minValue) {
            if (!isSilent) {
            start = i
            isSilent = true
            }
        } else if (isSilent) {
            end = i
            isSilent = false
            if (scale * (end - start) > minSilenceDuration) {
            silentRegions.push({
                start: scale * start,
                end: scale * end,
            })
            }
        }
        }
    
        // Merge silent regions that are close together
        const mergedRegions = []
        let lastRegion = null
        for (let i = 0; i < silentRegions.length; i++) {
        if (lastRegion && silentRegions[i].start - lastRegion.end < mergeDuration) {
            lastRegion.end = silentRegions[i].end
        } else {
            lastRegion = silentRegions[i]
            mergedRegions.push(lastRegion)
        }
        }
    
        // Find regions that are not silent
        const regions = []
        let lastEnd = 0
        for (let i = 0; i < mergedRegions.length; i++) {
        regions.push({
            start: lastEnd,
            end: mergedRegions[i].start,
        })
        lastEnd = mergedRegions[i].end
        }
    
        return regions
    };

    const getMarker = (stepId: any, segmentId: any, segmentName : any, details: any, isUpper: boolean) => {
        var content = document.createElement('div');

        content.onmouseover = (event: any) => {
            if(event.target.children['tooltiptext']) event.target.children['tooltiptext'].style.visibility="visible";
        }

        content.onmouseout = (event: any) => {
            if(event.target.children['tooltiptext']) event.target.children['tooltiptext'].style.visibility="hidden";
        }

        content.className = 'icon';
        if(isUpper){
            content.innerHTML = `<div>
                <svg viewBox="0 0 40 80" style="width: 11px; height: 22px; min-width: 11px; margin-right: 5px; z-index: 4;">
                    <polygon id="polygon" stroke="#979797" fill="#000" points="20 0 40 30 40 80 0 80 0 30" transform="rotate(180, 20 40)"></polygon>
                </svg>
                ID: ${stepId} 
                <div style="visibility: hidden" id="tooltiptext">
                    <div  style="font-style: italic; color: blue">${segmentId} - ${segmentName}</div>
                    <div>Details: ${details}</div>
                    
                </div>
            </div>`;
        } else {
            content.innerHTML = `<div>
                <div style="visibility: hidden" id="tooltiptext">
                <div style="font-style: italic; color: blue">${segmentId} - ${segmentName}</div>
                <div>Details: ${details}</div>
            </div>
            <svg viewBox="0 0 40 80" style="width: 11px; height: 22px; min-width: 11px; margin-right: 5px; z-index: 4;">
                <polygon id="polygon" stroke="#979797" fill="red" points="20 0 40 30 40 80 0 80 0 30"></polygon>
            </svg>
            ID: ${stepId} 
            
        </div>`;
        }
        

        return content;
    }


    useEffect(()=>{
        setPlay(false);
        const options = formWaveSurferOptions(waveformRef.current);

        if(audioBlob){
            wavesurfer.current = WaveSurfer.create({
                ...options
            });

            
            loadAudio();
              /** When the audio has been decoded */
              wavesurfer.current.on('decode', (duration: any) => {
                // console.log('Decode', duration + 's');
                const decodedData = wavesurfer.current.getDecodedData();
                const wsRegions = wavesurfer.current.registerPlugin(RegionsPlugin.create());
                if(decodedData){
                    const regions = extractRegions(decodedData.getChannelData(0), duration);

                    let preSeconds: number = regions[1].start;

                    getTimes.map((time : any, index: any) => {

                        if(time.stepType === 'Speak' || 
                            time.stepType === 'Listen' || 
                            time.stepType === 'Listen Anything'){                                        
                            
                            preSeconds += index === 0 ? 0: (DiffInMilliSeconds(getTimes[index - 1].testResult?.endTime, time.testResult?.startTime) / 1000);

                            const content = time.prompt || !time.messageToPlay 
                                ? getMarker(time.testStepId, time.segmentId, time.segmentName, time.prompt, true) 
                                : getMarker(time.testStepId, time.segmentId, time.segmentName, time.messageToPlay, false);

                            var region = wsRegions.addRegion({
                                id: time.prompt || !time.messageToPlay ? 'up': 'down',
                                start : preSeconds + (index === 0 ? 0 : ((time.testResult?.voice?.latency ? time.testResult?.voice?.latency: 0)/1000)) - .1,                            
                                content: content,                        
                                drag: false,
                                // color: time.prompt ? '#000' : 'red',
                                style: 'border-left-width: 0px;'
                            });

                            
                            // Listen to the region-update-end event
                            // region.on('region-update-end', function() {

                            //     // Get the region element
                            //     var regionEl = region.element;
                                
                            //     // Create a custom HTML element
                            //     var iconEl = document.createElement('div');
                            //     iconEl.className = 'icon';
                            //     iconEl.innerHTML = '<svg viewBox="0 0 40 80" style="width: 11px; height: 22px; min-width: 11px; margin-right: 5px; z-index: 4;"><polygon id="polygon" stroke="#979797" fill="#000" points="20 0 40 30 40 80 0 80 0 30" transform="rotate(180, 20 40)"></polygon></svg> My region';
                                
                            //     // Append the custom element to the region element
                            //     regionEl.appendChild(iconEl);
                            //     });

                            preSeconds += (time.testResult?.voice?.recordingDuration + (time.testResult?.voice?.latency ? time.testResult?.voice?.latency: 0))/1000;                            
                        }
                    });
                }

                
            });   

            // wavesurfer.current.on('ready', (duration:any) => {
            //     console.log('Ready', duration + 's');
            //     console.log(document.querySelectorAll('marker up'));
                
            //   })
    
            return () => wavesurfer.current.destroy();
        }
        

    } , [ audioBlob ]);
    



    useEffect(() => {
        console.log(wavesurfer.current);
        if(playing){
            wavesurfer.current?.play();
        }else{
            wavesurfer.current?.pause();
        }
        // wavesurfer.current.play(playing);
    }, [ playing ]);

    const handlePlayPause = () => {
        setPlay(!playing);
    };

    const onVolumeChange = (e: any) => {
        const { target } = e;
        const newVolume = +target.value;

        if (newVolume) {
            setVolume(newVolume);
            wavesurfer.current.setVolume(newVolume || 1);
        }
    };

    return (
        <div>
            <div id="waveform" ref={waveformRef} data-id={1} data-player={1} />
            <div id="wave-timeline"></div>
            <div className="controls">
                {audioBlob && (
                    <>
                        {!playing ? (
                            <PlayCircleOutlineIcon style={{fontSize:40}} onClick={handlePlayPause} className="pointer" />
                        ) : (
                            <PauseCircleOutlineIcon  style={{fontSize:40}} onClick={handlePlayPause} className="pointer" />
                        )}
                    </>
                )}
            </div>
        </div>
    );

}