export const HelpCenterCatalog = {
    "Add a Project": {
        "Add a Project": "Add a project into the List.",
    },
    "Project Page": {
        "Select": "Select the item to perform one of the available actions",
        "ID": `System-generated project ID
        Channel: Channel, can be voice (IVR) or web (chat)`,
        "Project Name": `Name given to the project`,
        "Trash can icon": `Delete the selected item (cannot be undone)`,
        "Modified": `Last modified date and time`,
        "Pagination": `First page, last page, jump to page, previous page, next page, page number`,
        "Filter": `Filters the list (enter text which the project name or ID “starts with” or “contains”)`,
        "Column headers": "Click column header to sort in alpha ascending or descending order (by project name)",
    },
    "Navigation Panel": {
        "Project icon": `Projects, Test Cases, Project Library`,
        "Settings icon": `Settings`,
        "Help and Getting Started icon": `Help and Getting Started`,
        "Logout icon": `Logout`,
        "Expand arrow": `Expand navigation panel`,
    },
    "Test Case Navigation Panel": {
        "Breadcrumbs": `Project ID, channel, project name`,
    },
    "Project View page": {
        "Trash can icon": `Delete the selected item (cannot be undone)`,
        "Arrow icon": `Export the selection`,
        "Select": `Select the item to perform one of the available actions`,
        "Status": `Status of the test case’s last run (success or fail)`,
        "Test Case Name": `Name given to the test case, can be up to 50 characters; special characters are not`,
        "allowed": `acceptable characters are: A-Za-z0-9àéêè_-.,()#`,
        "Tel No": `Phone number for the IVR test`,
        "Modified": `Last modified date and time`,
    },
    "New Test Case": {
        "New Test Case": `Create a new test case`,
        "Project Library": `Open the project library`,
    },
    "Project Library": {
        "Segments (tab label)": `One or more test case steps that may be reused within a project. A segment
        "may contain segments, variables, grammars, or audio files. Segments are the larger building blocks,
        "and steps are contained within a segment. A segment can contain multiple steps of different types.`,
        "Variables (tab label)": `Elements that can be used by a “listen type” segment (prompt), “speak type”
        "segment (Send to IVR), or CLID or IVR Telephone Number (any segment). The variable is assigned a
        "value which will be automatically used everywhere in a project the variable is used, but the value
        "can be changed centrally at any time.`,
        "Prompts (tab label)": `Input (voice/text-to-speech or DTMF) the Lens application will listen for from
        "the IVR; used with variables to form a “listen type” segment and can be reused`,
        "Grammars (tab label)": `Elements which specify the value(s) the Lens application will listen for from
        "the IVR; a grammar is used with a Grammar variable as part of a “listen type” segment, and can be
        "reused. Specific syntax must be used when creating grammars.`,
        "Audios (tab label)": `Audio file that contains the input (voice/text-to-speech) the Lens application will
        "listen for from the IVR as part of a “play audio” segment; can be reused`,
        "Close Case Editor": `Close the test case editor`,
        "Close Library": `Close the project library`,
        "Trash can icon": `Delete the selected item (cannot be undone)`,
        "Language selection": `Filter the list by language (only languages available in the project library are
        "shown)`,
        "Search": `Search the project library for a specific entry (enter text of the name you want to search on);
        "Search is based on the currently opened tab`,
        "Pagination": `First page, last page, jump to page, previous page, next page, page number`,
        "Add to Editor": `Add the selected item to the test case (for a segment, alternately drag and drop it
        "into the test case, and drag and drop to rearrange order)`,
        "Create": `Create a new library entry (segment, variable, grammar, prompt, or audio depending on the
        "library tab open)`,
        "Edit icon (grammar, segment, variable, audio, prompt)": `Modify the library entry`,
    },
    "Test Case Editor/Add Segment": {
        "CLID": `The phone number the Lens application will spoof the call is coming from when running the
        "test case; format is area code/prefix + phone number or this field can contain a variable referring to
        "the phone number`,
        "IVR Tel. Number": `The IVR phone number the Lens application will call when running the test case
        "(country code + area code + phone number); this field can contain a variable referring to the phone
        "number`,
        "Add Segment": `Create a new segment for the test case in the project library`,
        "Save": `Save the changes`,
        "Run": `Run the test case to verify its set up`,
        "Detailed Run Result": `View results from the test run`,
        "Trash can icon": `Delete the selected item (cannot be undone)`,
        "Refresh arrows": `Refresh the segment based on the latest segment changes`,
    },
    "Segment Editor": {
        "Segment Name": `Name given to the segment, can be up to 50 characters; special characters are not
        allowed; acceptable characters are: A-Za-z0-9àéêè_-.,()#`,
        "Language": `Language that will be used for the segment (e.g., for a “speak type” segment, if French is
            selected the Send to IVR content must be provided in French; for a “listen type” segment, if French
            is selected the prompt must be provided in French, etc.)`,
        "Step": `Select + to add more steps to the segment; only used for “listen type” segments`,
        "Remove": `Delete the step for the segment (cannot be undone); can be selected only if the segment
            "has multiple steps`,
        "Remark": `Enter a comment for the segment (it will be included in the test run results as a note)`,
        "Type": `The type of segment; can be one of:
            • Hangup = System hangs up
            • Listen = System listens for IVR response (prompt)
            • Listen Anytime = System listens to any IVR response
            • Play Audio = IVR listens for audio file
            • Record Audio = System records audio from IVR (??)
            • Speak = IVR listens for input
            • Transfer Call to Agent = Call routes to agent
            • Screenshot = System captures a screenshot of the Tester’s screen
            • Mouse Action = System simulates the Tester’s mouse actions
            • Get Call Data = System retrieves data from the API call the Agent Desktop makes to the system
            • Get Screen Data = System retrieves Tester’s screen data
            • Enter Screen Data = Input data into the Virtual Agent Desktop`,
        "Branch Condition": `Select the desired action after the selected step is completed (Default goto next =
            go to the next step; Goto step # = go to the specified step; Number of runs equals = go to the step
            based on the number of runs; Goto next script = go to the next test segment; Stop test run = stop
            the test)`,
        "Branch Condition > Go to step # > Go to step #": `Select the step to go to next`,
        "Branch Condition > Number of runs equals > # of runs": `Number of runs for the condition`,
        "Branch Condition > Number of runs equals > Go to step #": `Select the step to go to once the
            specified number of runs is complete`,
        "Branch Condition > Number of runs equals > Else go to step #": `Select the step to go to if the
            specified number of runs is not completed`,
        "Clear": `Cancel the changes`,
        "Segment Editor close icon": `Close the Segment Editor`,
    },
    "Schedule page" : {
        "Trash can icon": `Delete the selected item (cannot be undone)`,
        "Select": `Select the item to perform one of the available actions`,
        "ID": `System-generated schedule ID`,
        "Schedule Name": `Name of the schedule`,
        "Last Run Date": `Date and time of the schedule’s last run`,
        "Last Run Status": `Status of the schedule’s last run (success or fail)`,
        "Result": `Result of the schedule’s last run (success or fail)`,
        "Schedule Type": `Type of schedule (one time or recurring)`,
        "Status": `Status of the schedule (enabled or disabled)`,
        "Filter": `Filters the list (enter text which the schedule name or ID “starts with” or “contains”) `,
        "Add Schedule": `Create a new schedule`,
    },
    "Add Schedule page" : {
        "Close Schedule": `Close the Scheduler window`,
        "Add Test Case": `Add a test case to the schedule`,
        "Schedule Name": `Name of the schedule, can be up to 50 characters; special characters are not allowed; 
            acceptable characters are: A-Za-z0-9àéêè_-.,()#`,
        "Ports": `The number of ports that will be used for the test; determines the number of concurrent calls that 
            will be made when the test case is run (e.g., ports = 3 means three test calls will be made at once) `,
        "Schedule Type": `Type of schedule (one time or recurring)`,
        "Schedule Type = One Time > Status": `Status of the schedule, enabled or disabled`,
        "Schedule Type = Perpetual > Status": `Status of the schedule, enabled or disabled`
    },
    "Test Results" : {
        "Upload icon": `Export the test results`,
        "Trash can icon": `Delete the selected item(s) (cannot be undone)`,
        "Select": `Select the item to perform one of the available actions`,
        "Status": `Status of the test case’s last run (success or fail of the run itself)`,
        "ID": `System-generated test result ID`,
        "Test Case Name": `Name of the test case the results are showing for`,
        "Passed": `Percentage and number of test cases that passed during the run`,
        "Date/Time": `Date and time of the last test case run`,
        "Purged Date": `Relates to the Trash Can icon, where it will delete results of the selected test cases up to and including 
            the date specified here (if no date is specified, it deletes all results of the selected test cases)`,
        "Filter": `Filters the list (enter text which the test case name or test run ID “starts with” or “contains”) `,
    },
    "Schedule Editor": {
    }
} as any;