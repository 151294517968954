import React, { useState, useEffect, useMemo } from 'react';
import { FormikProps } from "formik";
import { InputLabel, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Box, Checkbox, TextField, Radio, RadioGroup, Typography } from "@mui/material"
import { useSelector } from 'react-redux';
import { getBasicActions } from '../../../store/selectors/project.selector';
import { STEP_TYPE } from '../../../types/global.enum';
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}

export const ScreenscrapeData = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange, setFieldValue } = formik;
    const actions = useSelector(getBasicActions);
    const { t } = useTranslation();

    const handleModeChange = (event: any) => {
        if (event.target.value === 'basic') {
            setFieldValue('xPath', '');
        }
        handleChange(event);
    };

    const allActions = useMemo(() => actions?.filter((c: any) => c.action === STEP_TYPE.SCREENSCRAPE_DATA), [actions]);


    return (
        <>
            <Box className="prompt-library-checkbox">
                <div className="mode-typography">
                    <Typography variant="subtitle1">{t("Mode")}</Typography>
                </div>
                <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    id='screenDataActionModeSelector'
                    name="screenDataActionModeSelector"
                    value={values?.screenDataActionModeSelector}
                    onChange={handleModeChange}
                >
                    <FormControl sx={{ width: "50%" }}>
                        <FormControlLabel
                            value={'basic'}
                            control={<Radio />}
                            label={t("Normal")}
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl sx={{ width: "50%" }}>
                        <FormControlLabel
                            value={'advanced'}
                            control={<Radio />}
                            label={t("Advance")}
                            labelPlacement="end"
                        />
                    </FormControl>
                </RadioGroup>
            </Box>
            {values?.screenDataActionModeSelector === 'advanced' ? (
                <Box className='segment-editor-text-area'>
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="case-editor-text-area"></InputLabel>
                        <TextField
                            id="screenDataActionXpath"
                            name='screenDataActionXpath'
                            value={values?.screenDataActionXpath}
                            label={t("xPath")}
                            onChange={handleChange}
                            multiline
                            rows={1}
                        />
                    </FormControl>
                </Box>
            ) : <Box className='branch-condition'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-predefined-action-names">{t("Predefined Action Names")}</InputLabel>
                    <Select
                        labelId="case-editor-predefined-action-names"
                        id="screenDataActionElementName"
                        name='screenDataActionElementName'
                        value={values?.screenDataActionElementName}
                        label={t("Predefined Action Names")}
                        onChange={handleChange}
                    >

                        {allActions?.map((c: any) => (
                            <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>

                        ))}
                    </Select>
                </FormControl>
            </Box>}

            <Box className="prompt-library-checkbox">
                <FormControl sx={{ width: "50%" }}>
                    <FormControlLabel
                        name="case-select-screenshot-option"
                        control={
                            <Checkbox
                                defaultChecked={formik.values?.screenDataActionScreenshot}
                                id='screenDataActionScreenshot'
                                name='screenDataActionScreenshot'
                                value={formik.values?.screenDataActionScreenshot}
                                onChange={formik.handleChange} />
                        }
                        label={t("Screenshot Option")}
                        labelPlacement="end"
                    />
                </FormControl>
            </Box>

            <Box className='segment-editor-text-area'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-text-area"></InputLabel>
                    <TextField
                        id="screenDataActionExpectedValue"
                        name='screenDataActionExpectedValue'
                        value={values?.screenDataActionExpectedValue}
                        label={t("Expected Value")}
                        onChange={formik.handleChange}
                    />
                </FormControl>
            </Box>
        </>
    );
};
