
import { STEP_TYPE } from "../../types/global.enum"
import { Button, MenuItem, TextField } from "@mui/material"
import { findGrammrts } from "../../services/grammar.service";
import { useSelector } from "react-redux";
import { getGrammars } from "../../store/selectors/grammar.selector";
import { FormikProps } from "formik";
import { IGrammarMapping } from "../../types/interfaces/segment.interface";
import { getProjectGrammarOptions } from "../../store/selectors/app.selector";
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    type: STEP_TYPE;
    prompt: string;
}

export const GrammarMapper = ({
    type = STEP_TYPE.NONE,
    prompt = '',
    formik,
}: IProps) => {
    const grammars = useSelector(getProjectGrammarOptions);
    const { t } = useTranslation();

    const startScan = () => {
        const match = findGrammrts(prompt);
        formik.setFieldValue('grammarMapping', match?.map((k: string) => ({
            grammarType: '',
            variable: k.replace(/%/g, ''),
        })))
    }

    const setGrammar = (item: IGrammarMapping) => (e: any) => {
        const g = [...formik.values?.grammarMapping];
        formik.setFieldValue('grammarMapping',
            g.reduce((r: IGrammarMapping[], curr: IGrammarMapping) => {
                if (item.variable.replace(/%/g, '') === curr.variable.replace(/%/g, '')) {
                    r.push({
                        ...curr,
                        grammarType: e.target.value,
                    });
                } else {
                    r.push(curr);
                }
                return r;
            }, [])
        );
    }

    if (type !== STEP_TYPE.LISTEN) return null;

    return (
        <div className="grammar-detail">
            <div className="grammar-header">{t('Grammar Type')}</div>
            <div className="w-100 text-center">
                <Button variant={'contained'}
                    onClick={startScan}
                    color="primary"
                    className='max-h-30 btn-scan'>{t('Scan For Grammar')}</Button>
            </div>

            {formik.values?.grammarMapping?.map((item: IGrammarMapping) => (
                <div className="db mt-16 w-100">
                    <div className="dib w-50">
                        <TextField
                            value={item.variable}
                            label="Grammar Variables"
                            read-only={true}
                        />
                    </div>
                    <div className="dib w-50 pl-20">
                        <TextField
                            select
                            fullWidth
                            label={t("Grammar Type")}
                            value={item.grammarType.replace(/%/g, '')}
                            onChange={setGrammar(item)}
                        >
                            {grammars?.map((g: any) => (
                                <MenuItem key={`grammar_${g.key}`} value={g.key}>{g.displayName}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
            ))}
        </div>
    )
}