import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton, Typography,
} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

export const Modal = ({
    title = '',
    ButtonDelete = null as any,
    isOpen = '',
    openHash = '',
    statement = '',
    body = null as any,
    overflow = false,
    small = false,
    big = false,
    onClosed = null as any,
    primaryButton = '',
    closeOnSubmit = true,
    deleteConfirmation = false,
    callOnClosed = true,
}) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(() => isOpen);

    const handleClose = () => {
        setOpen('');
        onClosed && onClosed();
    }

    const handleCloseOnConfirmation = () => {
        setOpen('');
        callOnClosed && onClosed && onClosed();
    }

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen, openHash])

    return (
        <Dialog open={open !== ''} onClose={handleClose} fullWidth={body && !small ? true : false} maxWidth={body && !small ? (big ? 'xl' : 'lg') : 'sm'}
            disableAutoFocus={true}>
            {title && (
                <>
                    <DialogTitle id="responsive-dialog-title"
                        sx={{
                            backgroundColor: "#E8F3F4",
                            textAlign: "center",
                            fontSize: "18px"
                        }}
                    >{title}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#FFFFFF',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </>
            )}
            <DialogContent>
                {(!body && deleteConfirmation) ? (
                    <DialogContentText sx={{
                        fontSize: "16px",
                        color: '#000000',
                        textAlign: "center",
                        fontFamily: 'Roboto',
                        mt: 5,
                        mb: 5,
                    }}>
                        <div>
                            <ErrorOutlineIcon style={{ fontSize: '50px', color: '#FD5900', paddingBottom: '33px' }} />
                        </div>
                        {t('You are about to delete the items you selected')}.<br />
                        {t('Are you sure')}?
                    </DialogContentText>

                ) : (
                    <>
                        {body}
                    </>
                )}

            </DialogContent>

            {ButtonDelete && (
                <DialogActions
                    sx={{
                        backgroundColor: "#E8F3F4"
                    }}
                >
                    <Button variant='outlined' onClick={handleClose}>
                        <Typography style={{ textTransform: 'none', color: "grey" }}>{t('Cancel')}</Typography>
                    </Button>

                    <Button variant='contained'
                        onClick={() => {
                            ButtonDelete();
                            if (closeOnSubmit) handleCloseOnConfirmation();
                        }}
                    >

                        <span style={{ color: '#FFF' }}>{t(primaryButton || 'Confirm')}</span>
                    </Button>

                </DialogActions>
            )}
        </Dialog>
    )
}

