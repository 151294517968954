import * as React from 'react';
import { InputLabel, FormLabel, FormControl, Select, SelectChangeEvent, MenuItem, Box, TextField } from "@mui/material"
import { FormikProps } from "formik";
import { useTranslation } from 'react-i18next';


export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    steps: number[];
    language?: string;
}


export const GoToStep = ({ formik, steps = [] }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const { t } = useTranslation();


    return (
        <>
            <Box className='go-to-step'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-go-to-step">{t('Go to Step')} #</InputLabel>
                    <Select
                        labelId="case-editor-go-to-step"
                        id="gotoStepNo"
                        name='gotoStepNo'
                        value={values?.gotoStepNo}
                        label={t("Go to Step #")}
                        onChange={handleChange}
                    >
                        <MenuItem value={"First"}>{t('First')}</MenuItem>
                        <MenuItem value={"Last"}>{t('Last')}</MenuItem>
                        <MenuItem value={"Previous"}>{t('Previous')}</MenuItem>
                        {steps.map(i => (
                            <MenuItem value={i} key={`step_${i}`}>{i}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </>
    );
};
