import { useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link } from '@mui/material';
import { deleteIcon, scheduledBatchCompareIcon, scheduledBatchExportIcon } from '../assets';
import { Modal} from '../ui';
import { useSelector } from 'react-redux';
import { getCurrentProject, getCurrentTestResultDetails } from '../store/selectors/project.selector';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useAppDispatch } from '../store/hooks';
import { setCurrentProjectId, setCurrentProject } from '../store/resuders/project.reducer';
import { SearchInput } from '../ui';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from "react-router-dom";
import { getProjectById } from '../services/project.service';
import { deleteSelectedBatches, getBatchList, getScheduleList } from '../services/scheduler.service';
import { SchedulerGrid } from '../ui/schedulerGrid.ui';
import dayjs from 'dayjs';
import { formatTimestamp } from '../helpers/date.helper';
import { toggleAlert } from '../store/resuders/app.reducer';
import { useTranslation } from 'react-i18next';
import { CHANNELS } from "../types/global.enum";
import { BatchDetails } from '../ui/scheduledTestResult/batchDetails';
import { CompareBatches } from '../ui/scheduledTestResult/compareBatches';
import { uniqueId } from '../helpers/form.helper';
import { TestResultDetails } from './testResultsDetails';


export const ScheduledTestResults = () => {
    const { projectId, scheduledId, testResultId } = useParams();
    const { t } = useTranslation();
    const project = useSelector(getCurrentProject);  // Store Project
    const [schedulerPage, setSchedulerPage] = useState(true);
    const [scheduledBatchPage, setScheduledBatchPage] = useState(false);
    const [scheduledBatchDetailsPage, setScheduledBatchDetailsPage] = useState(false);
    const [scheduledTestResultDetailsPage, setScheduledTestResultDetailsPage] = useState(false);
    const [openCompareBatch, setOpenCompareBatch] = useState('');
    const currentDate: any = dayjs();
    const oneWeekAgoDate: any = currentDate.subtract(7,'Day');
    const [startDateFilter, setStartDateFilter] = useState<any>(oneWeekAgoDate);
    const [stopDateFilter, setStopDateFilter] = useState<any>(currentDate);
    const [selectedScheduler, setSelectedScheduler] = useState<any>();
    const testResultDetails = useSelector(getCurrentTestResultDetails);


    const dispatch = useAppDispatch();
    const history = useNavigate();
    const [data, setData] = useState<any>([]);
    const [batchList, setBatchList] = useState<any>([]);
    const [selectedBatch, setSelectedBatch] = useState<any>();
    const [selectedBatchIds, setSelectedBatchIds] = useState<any>([]);
    const [criteria, setCriteria] = useState<any>({
        sort: '-lastModifiedTime',
        pageNo: 0,
        pageSize: 10,
        filter: '',
        projectId: project ? project?.testProjectID : projectId
    });

    const pageSizeChanged = (size: number) => {
        setCriteria({
            ...criteria,
            pageSize: size,
        })
    }

    const onStartDateChanged = (startDate: any) => {
        if(!startDate){
            setStartDateFilter(null);
            return;
        }
        if (typeof startDate === 'object') setStartDateFilter(startDate);
    }

    const onStopDateChanged = (stopDate: any) => {
        if(!stopDate){
            setStopDateFilter(null);
            return;
        }

        if (stopDate) setStopDateFilter(stopDate);
    }

    const schedulerColumns: any[] = [
        {
            fieldName: 'testScheduleId', caption: t('ID'), width: '5%', sortable: true,
        },
        {
            fieldName: 'name', caption: t('SCHEDULE NAME'), width: '35%', sortable: true,
            redner: (params: any) => (
                <div className="flex-center">
                    <Link className="a-link-black" onClick = {ScheduleBatchPage(params)}>
                        {params.name}
                    </Link>
                </div>
            )
        },
        {
            fieldName: 'lastRunTime', caption: t('LAST RUN DATE'),width: scheduledBatchDetailsPage ? '45%' : '15%', sortable: true,
            redner: (params: any) => (
                <div className="flex-center">
                    {params.lastRunTime ? formatTimestamp(params.lastRunTime) : t('No Last Run')}
                </div>

            )
        },
        {
            fieldName: 'lastRunStatus', caption: t('LAST RUN STATUS'), width: scheduledBatchDetailsPage ? '45%' : '20%',
            redner: (params: any) => {
                // const _totalRun = (params.lastRunStatus?.success || 0)+(params.lastRunStatus?.warning|| 0)+(params.lastRunStatus?.fail || 0);
                const _totalRun = (params.lastRunStatus?.total || 0);
                const s = ((params.lastRunStatus?.success || 0)/_totalRun) * 100;
                const r = ((params.lastRunStatus?.running || 0)/_totalRun) * 100;
                const f = ((params.lastRunStatus?.fail || 0)/_totalRun) * 100;
                
                const progress = (params.lastRunStatus?.success || 0)+(params.lastRunStatus?.warning|| 0)+(params.lastRunStatus?.fail || 0);

                return (
                <div className="flex-center">
                    {params.lastRunStatus ?
                        (progress === _totalRun ?
                            <>
                            <div className='bar-container'>
                            <div className='bar-success' title={`${s.toFixed(0)}%`} style={{width:`${s}%`}}></div>
                            <div className='bar-running' title={`${r.toFixed(0)}%`}  style={{width:`${r}%`}}></div>
                            <div className='bar-fail' title={`${f.toFixed(0)}%`}  style={{width:`${f}%`}}></div>
                            </div>
                            <div>Total: {_totalRun}</div>
                            </>
                            :
                            <>
                            <div className='flex-center w-100' style={{justifyContent: 'space-around'}}>
                                <div>Running</div> 
                                <div><Typography fontWeight={600}>Progress: {progress} / {_totalRun}</Typography></div>
                            </div>
                            </>)
                    :
                        <div>
                         No Run status
                        </div>
                    }                    
                </div>
            )}
        },
        !scheduledBatchDetailsPage &&{
            fieldName: 'type', caption: t('SCHEDULE TYPE'), sortable: true, width: '20%'
        }
    ];

    // Columns for Test Results Status Page
    const schedulerBatchColumns: any[] = [
        !selectedBatch && {
            fieldName: 'archiveId', caption: t('ID'), width: '10%', key: true, sortable: true,
        },
        !selectedBatch && {
            fieldName: 'scheduleName', caption: t('SCHEDULE NAME'), width: '30%',
            redner: (params: any) => (
                <div className="flex-center">
                    <Link className="a-link-black" onClick = {openBatchDetailsView(params)}>
                        {params.scheduleName}
                    </Link>
                </div>
            )
        },
        {
            fieldName: 'runStartDate', caption: t('RUN DATE'),width: selectedBatch ? '45%' : '15%', sortable: true,
            redner: (params: any) => (
                <div className="flex-center">
                    {params.runStartDate ? formatTimestamp(params.runStartDate) : t('No Run Date')}
                </div>

            )
        },
        {
            fieldName: 'lastRunStatus', caption: t('LAST RUN STATUS'), width: selectedBatch ? '45%' : '20%',
            redner: (params: any) => {
                // const _totalRun = (params?.success || 0)+(params?.running || 0)+(params?.failed || 0);
                const _totalRun = (params?.total || 0);
                const s = ((params?.success || 0)/_totalRun) * 100;
                const r = ((params?.warning || 0)/_totalRun) * 100;
                const f = ((params?.failed || 0)/_totalRun) * 100;
                
                const progress = (params?.success || 0)+(params?.warning|| 0)+(params?.failed || 0);

                return (
                <div className="flex-center">
                    
                    {progress === _totalRun ?
                    <>
                    <div className='bar-container'>
                    <div className='bar-success' title={`${s.toFixed(0)}%`} style={{width:`${s}%`}}></div>
                    <div className='bar-running' title={`${r.toFixed(0)}%`}  style={{width:`${r}%`}}></div>
                    <div className='bar-fail' title={`${f.toFixed(0)}%`}  style={{width:`${f}%`}}></div>
                    </div>
                    <div>Total: {_totalRun}</div>
                    </>
                    :
                    <>
                    <div className='flex-center w-100' style={{justifyContent: 'space-around'}}>
                        <div>{t("Running")}</div> 
                        <div><Typography fontWeight={600}>Progress: {progress} / {_totalRun}</Typography></div>
                    </div>
                    </>
                    }
                    
                </div>
            )}
        },
        !selectedBatch &&{
            fieldName: 'scheduleType', caption: t('SCHEDULE TYPE'), sortable: true, width: '20%'
        }
    ];

    const schedulerBatchColumnsSm: any[] = [
        {
            fieldName: 'archiveId', caption: t('ID'), width: '10%', key: true, sortable: true,
        },
        {
            fieldName: 'runStartDate', caption: t('RUN DATE'),width: '45%', sortable: true,
            redner: (params: any) => (
                <div className="flex-center">
                    {params.runStartDate ? formatTimestamp(params.runStartDate) : t('No Run Date')}
                </div>

            )
        },
        {
            fieldName: 'lastRunStatus', caption: t('LAST RUN STATUS'), width: '45%',
            redner: (params: any) => {
                // const _totalRun = (params?.success || 0)+(params?.running || 0)+(params?.failed || 0);
                const _totalRun = (params?.total || 0);
                const s = ((params?.success || 0)/_totalRun) * 100;
                const r = ((params?.running || 0)/_totalRun) * 100;
                const f = ((params?.failed || 0)/_totalRun) * 100;
                
                const progress = (params?.success || 0)+(params?.warning|| 0)+(params?.failed || 0);

                return (
                <div className="flex-center">
                    {progress === _totalRun ?
                    <>
                    <div className='bar-container'>
                    <div className='bar-success' title={`${s.toFixed(0)}%`} style={{width:`${s}%`}}></div>
                    <div className='bar-running' title={`${r.toFixed(0)}%`}  style={{width:`${r}%`}}></div>
                    <div className='bar-fail' title={`${f.toFixed(0)}%`}  style={{width:`${f}%`}}></div>
                    </div>
                    <div>Total: {_totalRun}</div>
                    </>
                    :
                    <>
                    <div className='flex-center w-100' style={{justifyContent: 'space-around'}}>
                        <div>{t("Running")}</div> 
                        <div><Typography fontWeight={600}>Progress: {progress} / {_totalRun}</Typography></div>
                    </div>
                    </>
                    }
                </div>
            )}
        }
    ];

    // Columns for Test Results Status Page
    const testDetailsColumns: any[] = [
        { caption: t('Test Case ID'), width: '55px', fieldName: 'id' },
        { caption: t('Test Case Name'), width: '55px', fieldName: 'name' },
        {
            caption: t('MOS Score'), width: '55px', fieldName: 'mos',
            redner: (params: any) => {
                return `${params.data.testResult.voice.mos}`;
            }
        },
        {
            caption: t('Test Run ID'), width: '55px', fieldName: 'testRunId',
            redner: (params: any) => {
                return `${params.data.testResult.id}`;
            }
        },
        {
            caption: t('From'), width: '55px', fieldName: 'creationTime',
            redner: (params: any) => {
                // return `${formatDate(params.data.testResult?.startTime)} to ${formatDate(params.data.testResult?.endTime)}`;
                return `${formatTimestamp(params.data.testResult?.startTime)} to ${formatTimestamp(params.data.testResult?.endTime)}`;
            }
        },
        {
            caption: t('Duration'), width: '55px', fieldName: 'duration',
            redner: (params: any) => {
                const startTime = new Date(params.data.testResult.startTime);
                const endTime = new Date(params.data.testResult.endTime);
                const durationMilliseconds = endTime.getTime() - startTime.getTime();
                const durationInSeconds = durationMilliseconds / 1000;
                if (durationInSeconds < 0) {
                    return `0 secs`;
                } else {
                    return `${durationInSeconds.toFixed(2)} secs`;
                }
            }
        }
    ];

    const ScheduleBatchPage = (scheduler: any) => async ()=> {
        await gotoScheduleBatchPage(scheduler);
    }

    const gotoScheduleBatchPage = async (scheduler: any) => {
        setSelectedScheduler(scheduler);
        await fetchBatchList(scheduler?.projectId, scheduler?.testScheduleId);
        history(`/projects/${scheduler.projectId}/scheduled/testresults/${scheduler.testScheduleId}`);
        setSchedulerPage(false);
        setScheduledBatchPage(true);
        
    }

    const fetchBatchList = async (projectId : number, scheduleId: number) =>{
        const batchListData = await getBatchList(projectId, scheduleId, project?.data?.channelType);
        setBatchList(batchListData.data);
    }

    const openBatchDetailsView = (batch: any) => async () => {
        setSelectedBatch(batch);
        setScheduledBatchDetailsPage(true);
    }

    const loadData = async () => {        
        const project = await getProjectById(Number(projectId));
        dispatch(setCurrentProject(project));
        dispatch(setCurrentProjectId(projectId));
        const { data } = await getScheduleList(criteria.projectId, criteria.pageSize, criteria.pageNo, criteria.filter, project?.data?.channelType);
        if(scheduledId) {
            const scheduleData = project?.data?.channelType === CHANNELS.DIGITAL ? data?.content || [] : data?.content || [];
            const findScheduler = scheduleData.find((d: any) => d.testScheduleId === Number(scheduledId));
            await gotoScheduleBatchPage(findScheduler);
        }
        setData(data);
        
    };

    const sortChanged = (column: string, direction: string) => {
        if (column === 'startTime') {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}startTime`,
            });
        } else {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
            });
        }

        setData((previousData: any) => (previousData.content || [])
        .sort((a: any, b: any) => {
            if (direction && criteria.sort) {
                const aValue = a[criteria.sort] || ''; 
                const bValue = b[criteria.sort] || ''; 
                if (direction === 'ASC') {
                    return aValue.localeCompare(bValue);
                } else {
                    return bValue.localeCompare(aValue);
                }
            }
            return 0;
        }));
    };

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    const selectionChanged = (id: any) => {
        setSelectedBatchIds(id);
    }

    const onFilterChanged = (filter: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            filter,
        })
    }

    useEffect(()=> {
       loadData();
    },[criteria]);


    const closeBatchDetails = () => {
        setSelectedBatch(null);
        setScheduledBatchDetailsPage(false);
    }

    const openCompareBatchModal = () => {
        if(selectedBatchIds.length !== 2) {
            dispatch(toggleAlert(t("Select 2 Batches to compare")));
            return;
        }

        const firstBatch = batchList.find((batch : any) => +batch.archiveId === +selectedBatchIds[0]);
        const secondBatch = batchList.find((batch : any) => +batch.archiveId === +selectedBatchIds[1]);
        if(firstBatch?.running !== 0 || secondBatch?.running !== 0) {
            dispatch(toggleAlert(t("Running batch can't be compared")));
            return;
        }

        setOpenCompareBatch(uniqueId());
    }

    const closeCompareBatchModal = () => {
        setOpenCompareBatch('');
    }

    const openSchedulerPage = () => {
        // setScheduledBatchPage(false);
        // setScheduledBatchDetailsPage(false);
        // setScheduledTestResultDetailsPage(false);
        // setSchedulerPage(true);
        // loadData();
        // history(`/projects/${project?.testProjectID || projectId}/scheduled/testresults`);
        window.location.replace(`/projects/${project?.testProjectID || projectId}/scheduled/testresults`);

    }

    const openScheduleBatchPage = () => {
        setSchedulerPage(false);
        setScheduledTestResultDetailsPage(false);
        // setScheduledBatchDetailsPage(false);
        setScheduledBatchPage(true);
        setScheduledBatchDetailsPage(true);
        history(`/projects/${selectedScheduler.projectId || projectId}/scheduled/testresults/${selectedScheduler.testScheduleId || scheduledId ? scheduledId : 0}`);
    }

    const openTestResultsDetailsView = () => {
        setScheduledBatchPage(false);
        setScheduledBatchDetailsPage(false);
        setSchedulerPage(false);
        setOpenCompareBatch('');
        setScheduledTestResultDetailsPage(true);
    }

    const deleteBatches = async () => {
        // await deleteTestResults(project.testProjectID, selectedBatchIds);
        await deleteSelectedBatches(project.testProjectID, selectedScheduler?.testScheduleId, selectedBatchIds);
        window.location.reload();
        // await fetchBatchList(selectedScheduler?.projectId, selectedScheduler?.testScheduleId);
        // setSelectedBatchIds([]);
    }

    const reloadBatchList = async () => {
        await fetchBatchList(selectedScheduler?.projectId, selectedScheduler?.testScheduleId);
    }

    const formatDate = (dateTimeString: any) => {
        if (!dateTimeString) return '';

        const date = new Date(dateTimeString);
        const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    };

    useEffect(() => {
        loadData();
    }, [])

    let gridItemSize = (scheduledBatchDetailsPage && selectedBatch) ? 6 : 12;

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container>
                {/* Header */}
                <Grid item xs={12} paddingTop={'16px'} paddingBottom={'10px'}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                        <Typography variant="subtitle2">{t('Project')} :</Typography>
                        <Typography variant="subtitle2">{project ? project?.testProjectID : projectId}</Typography>
                        {/* {project?.data?.channelType === CHANNELS.VOICE ? (<PhoneEnabledOutlinedIcon style={{ fontSize: '17px' }} />) : (<img src={digital} alt="" width={17}/>)} */}
                        {project ?
                            <>
                                <Typography variant="subtitle2">
                                    <Link
                                        onClick={openSchedulerPage}
                                        className="a-link-black"
                                    >
                                        {project?.data?.name}
                                    </Link>
                                </Typography>
                            </>
                            : null}
                            <Typography variant="subtitle2">{'>'}</Typography>
                            <Typography variant="subtitle2">
                                <Link
                                    onClick={openSchedulerPage}
                                    className="a-link-black"
                                >
                                    {t('Scheduled Test Runs')}
                                </Link>
                            </Typography>
                        {(scheduledBatchPage) && (<>
                            <Typography variant="subtitle2">{'>'}</Typography>
                            <Typography variant="subtitle2">
                                {selectedScheduler?.name}
                            </Typography>
                            </>
                        )}

                        {scheduledTestResultDetailsPage && (
                            <>
                            <Typography variant="subtitle2">{'>'}</Typography>
                            <Typography variant="subtitle2">
                                <Link
                                    onClick={openScheduleBatchPage}
                                    className="a-link-black">
                                    {selectedScheduler?.name}
                                </Link>                            
                            </Typography>
                            <Typography variant="subtitle2">{'>'}</Typography>
                            <Typography variant="subtitle2">
                                {testResultDetails.name}
                            </Typography>
                            </>
                        )}      
                    </div>
                </Grid>

                {/* Case Editor or DataGrid */}
                {schedulerPage && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <div>
                            <Card>
                                <CardContent>
                                <div className='test-result-header'>
                                        <div className="action-header">            
                                        </div>
                                        <div className="test-results-filters">
                                            {/* <DatePicker
                                                value={startDateFilter}
                                                format='YYYY/MM/DD'
                                                label={t("Start Date")}
                                                className='start-date-field'
                                                onChange={onStartDateChanged}
                                                
                                                slotProps={{ textField: { size: 'medium' }}}
                                            />
                                            <DatePicker
                                                value={stopDateFilter}
                                                format='YYYY/MM/DD'
                                                label={t("Stop Date")}
                                                className='stop-date-field'
                                                onChange={onStopDateChanged}
                                                slotProps={{ textField: { size: 'medium' }}}
                                            /> */}
                                            <SearchInput onFilter={onFilterChanged} />
                                        </div>
                                    </div>
                                    <SchedulerGrid                                    
                                        totalPages={-1}
                                        sortChanged={sortChanged}                                        
                                        columns={schedulerColumns}
                                        data={project?.data?.channelType === CHANNELS.DIGITAL ? data?.content || [] : data?.content || []}
                                        sortBy={criteria.sort}
                                        selectionChanged={selectionChanged} 
                                        pageSizeChanged={pageSizeChanged}/>
                                </CardContent>
                            </Card>
                        </div>
                        
                    </Grid>
                )}
                {scheduledBatchPage && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <div>
                            <Card>
                                <CardContent>
                                    <div className='test-result-header'>
                                        <div className="action-header">
                                            <Link onClick={deleteBatches}>
                                                <img src={deleteIcon} alt={t('Remove')} />
                                            </Link>                                                                                        
                                            <img src={scheduledBatchExportIcon} alt={t('Export')} />
                                            <Link onClick={openCompareBatchModal}>
                                                <img src={scheduledBatchCompareIcon} alt={t('Compare')} />
                                            </Link>
                                            
                                        </div>                                    
                                    </div>
                                    <SchedulerGrid
                                        // pageChanged={pageChanged}
                                        totalPages={-1}
                                        sortChanged={sortChanged}
                                        columns={(scheduledBatchDetailsPage && selectedBatch) ? schedulerBatchColumnsSm : schedulerBatchColumns}
                                        data={batchList}
                                        selection={true}
                                        sortBy={criteria.sort}
                                        selectionChanged={selectionChanged} 
                                        pageSizeChanged={pageSizeChanged}/>
                                </CardContent>
                            </Card>
                        </div>
                        
                    </Grid>
                )}
                {(scheduledBatchDetailsPage && selectedBatch) && (
                    <Grid item xs={6} paddingRight={'9px'} style={{}}>
                        <BatchDetails 
                        showCloseButton={true} 
                        closeBatchDetails={closeBatchDetails} 
                        projectId={projectId} 
                        testcaseId={1551} 
                        selectedBatch={selectedBatch}
                        openTestResultsDetailsView={openTestResultsDetailsView}
                        reloadBatchList={reloadBatchList} />
                    </Grid>
                    
                )}

                {scheduledTestResultDetailsPage && (
                    <Grid item xs={12}>
                        <TestResultDetails
                            pageChanged={pageChanged}
                            totalPages={data?.totalPages || 0}
                            sortChanged={sortChanged}
                            columns={testDetailsColumns}
                            data={
                                testResultDetails || []
                            }
                            selection={true}
                            sortBy={criteria.sort}
                            selectionChanged={selectionChanged}
                            formatDate={formatDate}
                            onFilterChanged={onFilterChanged}
                            criteria={criteria}
                        />
                    </Grid>
                )}

                <Modal 
                    isOpen={openCompareBatch}
                    big={true}
                    body={
                    <CompareBatches
                        projectId={projectId}
                        closeCompareBatch={closeCompareBatchModal} 
                        firstbatch={batchList.find((batch : any) => +batch.archiveId === +selectedBatchIds[0])} 
                        secondBatch={batchList.find((batch : any) => +batch.archiveId === +selectedBatchIds[1])}
                        openTestResultsDetailsView={openTestResultsDetailsView}
                        reloadBatchList={reloadBatchList}
                    />
                    }                    
                />
            </Grid>
        </DndProvider>

    );
};