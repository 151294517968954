import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, CardContent, MenuItem, Select, TextField, Typography } from "@mui/material";
import { deleteIcon, editFilledIcon } from '../assets';
import { useFormik } from "formik";
import { LibraryDataGrid, PromptLibraryDataGrid, AudioLibraryDataGrid, VariableLibraryDataGrid, GrammarLibraryDataGrid, Modal } from '../ui';
import { uniqueId } from '../helpers/form.helper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import SearchIcon from '@mui/icons-material/Search';
import { exportDataTypes, getSegments } from '../services/project.service';
import { useAppDispatch } from '../store/hooks';
import { addNewSegment, setEditSegment, upsertLibrary } from '../store/resuders/segment.reducer';
import { useSelector } from 'react-redux';
import { getCurrentProject, getCurrentProjectSegments } from '../store/selectors/project.selector';
import { addProjectSegments, setprojectSegments } from '../store/resuders/project.reducer';
import { orderBy } from "lodash";
import { ISegment } from '../types/interfaces/segment.interface';
import { deleteLibraryItems } from '../services/segment.service';
import { toggleAlert } from '../store/resuders/app.reducer';
// Variable Library Interface Imports
import { getVariables } from '../store/selectors/variable.selector';
import { setCurrentVariable, addVariables, upsertVariable } from '../store/resuders/variable.reducer';
import { getVariableList } from '../services/variable.service';
// Grammar Library Interface Imports
import { getGrammars } from '../store/selectors/grammar.selector';
import { setCurrentGrammar, addGrammars, upsertGrammar } from '../store/resuders/grammar.reducer';
import { getGrammarList } from '../services/grammar.service';
// Prompt Library Interface Imports
import { getPrompts } from '../store/selectors/prompt.selector';
import { setCurrentPrompt, addPrompts, upsertPrompt } from '../store/resuders/prompt.reducer';
import { getPromptList } from '../services/prompt.service';
// Audio Library Interface Imports
import { getAudios } from '../store/selectors/audio.selector';
import { setCurrentAudio, addAudios, upsertAudio } from '../store/resuders/audio.reducer';
import { getAudioList } from '../services/audio.service';
import { getHelpCenter, getReloadLibraries, getReloadSegments } from '../store/selectors/app.selector';
import { closeLibraryEditor } from '../helpers/form.helper';
import { useTranslation } from 'react-i18next';
import { ImportData } from '../ui/importData';
import InfoIcon from '@mui/icons-material/Info';
import { CHANNELS } from '../types/global.enum';
import { populateDigitalSteps } from '../services/testcase.service';

export const ProjectLibrary = ({
    toggleSegmentEditor,
    gridItemSize,
    editorOpen,
    setEditorOpen,
    selectedData,
    setSelectedData,
    editorState,
    setEditorState,
    caseEditorOpen,
    checkIsAnyEditorOpen,
    discardChangeConfirmation,
    setDiscardChangeConfirmation,
    setOpenDiscardConfirmation,
}: {
    toggleSegmentEditor: (close?: boolean, open?: boolean) => void;
    gridItemSize: number;
    editorOpen: boolean;
    setEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedData: any[];
    setSelectedData: React.Dispatch<React.SetStateAction<any[]>>;
    editorState: string;
    setEditorState: React.Dispatch<React.SetStateAction<string>>;
    caseEditorOpen: boolean;
    checkIsAnyEditorOpen: () => boolean;
    discardChangeConfirmation: any;
    setDiscardChangeConfirmation: React.Dispatch<React.SetStateAction<any>>;
    setOpenDiscardConfirmation: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const { t } = useTranslation();

    const [addButton, setAddButton] = useState(false);
    const [openImport, setOpenImport] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [importButton, setImportButton] = useState(false);
    const [edit, setEdit] = useState<any>(null);
    const [value, setValue] = React.useState('Segments');
    const [currentPage, setCurrentPage] = useState(1); // Track the current page state of Pagination
    const [itemsPerPage, setItemsPerPage] = useState(10); // Track the items per page state of Pagination
    const [inputFocused, setInputFocused] = useState(false); // Track the focus state of the TextField
    const [segmentsTab, setSegmentsTab] = useState(true); // Track Segments Tab State
    const [variablesTab, setVariablesTab] = useState(false); // Track Variables Tab State
    const [grammarsTab, setGrammarsTab] = useState(false); // Track Grammars Tab State
    const [promptsTab, setPromptsTab] = useState(false); // Track Prompts Tab State
    const [audiosTab, setAudiosTab] = useState(false); // Track Audios Tab State
    const [tabColor, setTabColor] = useState("#006A68");
    const [backgroundColor, setBackgroundColor] = useState("#CCE8E6");
    const [selected, setSelected] = useState<string[]>([]);
    const project = useSelector(getCurrentProject);
    const reloadLib = useSelector(getReloadLibraries);
    // Get Library Data
    const getAllData = useSelector(getCurrentProjectSegments);  // Segment Data
    const getAllPrompts = useSelector(getPrompts);  // Prompt Data
    const getAllAudios = useSelector(getAudios);  // Audio Data
    const getAllVariables = useSelector(getVariables);  // Variable Data
    const getAllGrammars = useSelector(getGrammars);  // Grammar Data
    const helpCenter = useSelector(getHelpCenter);
    // Library Data States
    const [data, setData] = useState(useSelector(getCurrentProjectSegments));  // Segment Data
    const [prompts, setPrompts] = useState(useSelector(getPrompts));  // Prompt Data
    const [audios, setAudios] = useState(useSelector(getAudios));  // Audio Data
    const [variables, setVariables] = useState(useSelector(getVariables));  // Variable Data
    const [grammars, setGrammars] = useState(useSelector(getGrammars));  // Grammar Data

    const setReloadSegments = useSelector(getReloadSegments);  // Grammar Data
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState('');  // Search value in search box

    const onSegmentEdit = (params: any) => {
        if(checkIsAnyEditorOpen()){
            setDiscardChangeConfirmation({...discardChangeConfirmation,
                callAfter: () => {
                    dispatch(upsertLibrary(uniqueId()));
                    dispatch(setEditSegment({ segment: params, }));
                },
            });
            setOpenDiscardConfirmation(`${new Date().getTime()}`);
        } else {
            dispatch(upsertLibrary(uniqueId()));
            dispatch(setEditSegment({ segment: params, }));
        }
    }

    const onVariableEdit = (params: any) => {
        if(checkIsAnyEditorOpen()){
            setDiscardChangeConfirmation({...discardChangeConfirmation, 
                callAfter: () => {
                    dispatch(upsertVariable(uniqueId()));
                    setSelectedData(params);
                    setEditorOpen(true);
                    setEditorState("edit");
                }});
            setOpenDiscardConfirmation(`${new Date().getTime()}`);
        } else {
            dispatch(upsertVariable(uniqueId()));
            // dispatch(setEditVariable({ variable: params, }))
            setSelectedData(params);
            setEditorOpen(true);
            setEditorState("edit");
        }
    }

    const onPromptsEdit = (params: any) => {
        if(checkIsAnyEditorOpen()){
            setDiscardChangeConfirmation({...discardChangeConfirmation, 
                callAfter: () => {
                    dispatch(upsertPrompt(uniqueId()))
                    setSelectedData(params);
                    setEditorOpen(true)
                    setEditorState("edit")
                }});
            setOpenDiscardConfirmation(`${new Date().getTime()}`);
        } else {
            dispatch(upsertPrompt(uniqueId()))
            // dispatch(setEditPrompt({ prompt: params, }))
            setSelectedData(params);
            setEditorOpen(true)
            setEditorState("edit")
        }
    }

    const onGrammarEdit = (params: any) => {
        if(checkIsAnyEditorOpen()){
            setDiscardChangeConfirmation({...discardChangeConfirmation, 
                callAfter: () => {
                    dispatch(upsertGrammar(uniqueId()));
                    setSelectedData(params);
                    setEditorOpen(true);
                    setEditorState("edit");
                }});
            setOpenDiscardConfirmation(`${new Date().getTime()}`);
        } else {
            dispatch(upsertGrammar(uniqueId()));
            setSelectedData(params);
            setEditorOpen(true);
            setEditorState("edit");
        }
    }

    const onAudioEdit = (params: any) => {
        if(checkIsAnyEditorOpen()){
            setDiscardChangeConfirmation({...discardChangeConfirmation, 
                callAfter: () => {
                    dispatch(upsertAudio(uniqueId()));
                    // dispatch(setEditAudio({ audio: params, }))
                    setSelectedData(params);
                    setEditorOpen(true);
                    setEditorState("edit");
                }});
            setOpenDiscardConfirmation(`${new Date().getTime()}`);
        } else {
            dispatch(upsertAudio(uniqueId()));
            // dispatch(setEditAudio({ audio: params, }))
            setSelectedData(params);
            setEditorOpen(true);
            setEditorState("edit");
        }
    }


    const columns = useMemo(()=>[
        { fieldName: project?.data?.channelType === CHANNELS.DIGITAL ? 'id':'testSegmentVoiceId', width: '10%', sortable: true },
        { fieldName: 'name', width: '70%' },
        {
            fieldName: 'edit',
            redner: (params: any) => (
                <div className="flex-center">
                    <img alt='edit' src={editFilledIcon} onClick={() => {onSegmentEdit(params);}} style={{ fontSize: '17px', cursor: 'pointer' }} />
                </div>
            )
        }
    ]as  any[],[project])


    // Variable Library Columns
    const variableColumns: any[] = [
        { fieldName: 'libraryID', width: '10%', sortable: true },
        { fieldName: 'name', width: '70%' },
        {
            fieldName: 'edit',
            redner: (params: any) => (
                <div className="flex-center">
                    <img alt='edit' src={editFilledIcon} onClick={() => {onVariableEdit(params)}} style={{ fontSize: '17px', cursor: 'pointer' }} />
                </div>
            ),
        },
    ];

    // Grammar Library Columns
    const grammarColumns: any[] = [
        { fieldName: 'libraryID', width: '10%', sortable: true },
        { fieldName: 'name', width: '70%' },
        {
            fieldName: 'edit',
            redner: (params: any) => (
                <div className="flex-center">
                    <img src={editFilledIcon} alt='edit' onClick={() => {onGrammarEdit(params)}} style={{ fontSize: '17px', cursor: 'pointer' }} />
                </div>
            ),
        },
    ];

    // Prompt Library Columns
    const promptColumns: any[] = [
        { fieldName: 'libraryID', width: '10%', sortable: true },
        { fieldName: 'name', width: '70%' },
        {
            fieldName: 'edit',
            redner: (params: any) => (
                <div className="flex-center" >
                    <img src={editFilledIcon} alt='edit' onClick={() => {onPromptsEdit(params)}} style={{ fontSize: '17px', cursor: 'pointer' }} />
                </div>
            ),
        },
    ];

    // Audio Library Columns
    const audioColumns: any[] = [
        { fieldName: 'libraryID', width: '10%', sortable: true },
        { fieldName: 'name', width: '70%' },
        {
            fieldName: 'edit',
            redner: (params: any) => (
                <div className="flex-center">
                    <img alt='edit' src={editFilledIcon} onClick={() => {onAudioEdit(params)}} style={{ fontSize: '17px', cursor: 'pointer' }} />

                    {/* Play/Pause Audio Button */}

                    <audio id="library-audio" controls>
                        <source src={`data:audio/wav;base64,${params?.binaryContent}`} type="audio/wav" />
                    </audio>

                </div>
            ),
        },
    ];


    const formik = useFormik({

        initialValues: {
            channelType: 'voice',
            language: 'english',
        },

        onSubmit: async (values: any) => {
            console.log('submit', values);
            // TODO: Add onSubmit actions here
        }

    })


    const editProject = (id: string) => {
        setEdit(data.find((d: any) => +d.id === +id));
    };

    const toggleAddButton = () => {
        closeLibraryEditor(dispatch);
        dispatch(setEditSegment(null))
        if (segmentsTab) {
            dispatch(upsertLibrary(uniqueId()));
            setAddButton(!addButton);
            setEdit(null);
        } else { // If any tab is open other than segments tab
            setEditorOpen(true)
            setEditorState("create");
            if (variablesTab) {
                dispatch(upsertVariable(uniqueId()));
            } else if (promptsTab) {
                dispatch(upsertPrompt(uniqueId()));
            } else if (audiosTab) {
                dispatch(upsertAudio(uniqueId()));
            } else if (grammarsTab) {
                dispatch(upsertGrammar(uniqueId()));

            }

        }
    };

    const toggleImportButton = () => {
        setImportButton(!importButton);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
        const value = parseInt(event.target.value as string, 10); // Convert string to number
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const toggleSegmentsTab = () => {
        setSegmentsTab(true)
        setVariablesTab(false)
        setGrammarsTab(false)
        setPromptsTab(false)
        setAudiosTab(false)
        setTabColor("#006A68")
        setBackgroundColor("#CCE8E6");
        setCurrentPage(1);
    };

    const toggleVariablesTab = () => {
        setVariablesTab(true)
        setSegmentsTab(false)
        setGrammarsTab(false)
        setPromptsTab(false)
        setAudiosTab(false)
        setTabColor("#3818D9")
        if (variablesTab) {
            setBackgroundColor("#F7F5FF")
        } else {
            setBackgroundColor("#E3DEF8")
        }
        setCurrentPage(1);
    };

    const toggleGrammarsTab = () => {
        setGrammarsTab(true)
        setVariablesTab(false)
        setSegmentsTab(false)
        setPromptsTab(false)
        setAudiosTab(false)
        setTabColor("#FFA800")
        if (grammarsTab) {
            setBackgroundColor("#FCFAF1")
        } else {
            setBackgroundColor("#EDE9D6")
        }
        setCurrentPage(1);
    };

    const togglePromptsTab = () => {
        setPromptsTab(true)
        setVariablesTab(false)
        setSegmentsTab(false)
        setGrammarsTab(false)
        setAudiosTab(false)
        setTabColor("#006A68")
        if (promptsTab) {
            setBackgroundColor("#F0F9F2")
        } else {
            setBackgroundColor("#D3E7D7")
        }
        setCurrentPage(1);
    };

    const toggleAudiosTab = () => {
        setAudiosTab(true)
        setVariablesTab(false)
        setSegmentsTab(false)
        setGrammarsTab(false)
        setPromptsTab(false)
        setTabColor("#2F80ED")
        if (audiosTab) {
            setBackgroundColor("#F0F8FF")
        } else {
            setBackgroundColor("#D4E6F7")
        }
        setCurrentPage(1);
    };



    const loadSegments = async () => {
        const { data } = await getSegments(project?.testProjectID || 1, project?.data?.channelType, itemsPerPage, currentPage, formik?.values?.language, searchValue);

        setTotalElements(data.totalPages || data?.length);

        // Filter the content array based on the languageCodeId
        const filteredContent = project?.data?.channelType === CHANNELS.DIGITAL ? data?.content: data?.content?.filter((item: any) => {
            return item?.segmentLangs.filter((s:any)=>s.languageCode?.id === formik?.values?.language);
        });

        // Map and set the filtered content
        const content = (filteredContent?.map((t: any) => ({
            ...t,
            id: `${t.testSegmentVoiceId || t.testSegmentId}`,
        })));

        if(project?.data?.channelType === CHANNELS.DIGITAL) {
            setData(content.map((c:any)=>({
                ...c,
                testSegmentVoiceId: c.testSegmentId,
                segmentLangs:[
                    {
                        languageCode: {
                            id:'english',
                        },
                        steps:populateDigitalSteps(c.steps),
                    }
                ]
            })));
        } else {
            setData(content);
        }
        

        if (currentPage === 1) {
            dispatch(setprojectSegments(content))
        } else {
            dispatch(addProjectSegments(content?.map((t: any) => ({
                ...t,
                id: `${t.testSegmentVoiceId || t.testSegmentId}`,
            }))))
        }
    }


    const loadData = async (loadFunction: any, setContentFunction: any, setCurrentFunction: any, addDataFunction: any) => {
        const { data } = await loadFunction(project?.testProjectID || 1, itemsPerPage, currentPage, '', searchValue);

        setTotalElements(data.totalPages);

        // Filter the content array based on the languageCodeId
        const filteredContent = data?.content?.filter((item: any) => {
            return item.languageCodeId === formik.values.language;
        });

        // Map and set the filtered content
        const content = (filteredContent?.map((t: any) => ({
            ...t,
            id: `${t.libraryID}`,
        })));

        setContentFunction(content);

        if (currentPage === 1) {
            dispatch(setCurrentFunction(content))
        } else {
            dispatch(addDataFunction(filteredContent?.map((t: any) => ({
                ...t,
                id: `${t.libraryID}`,
            }))))
        }
    }

    const loadVariables = () => {
        loadData(getVariableList, setVariables, setCurrentVariable, addVariables);
    }

    const loadGrammars = () => {
        loadData(getGrammarList, setGrammars, setCurrentGrammar, addGrammars);
    }

    const loadPrompts = () => {
        loadData(getPromptList, setPrompts, setCurrentPrompt, addPrompts);
    }

    const loadAudios = () => {
        loadData(getAudioList, setAudios, setCurrentAudio, addAudios);
    }


    const reloadLibraryData = () => {
        segmentsTab && loadSegments();
        promptsTab && loadPrompts();
        audiosTab && loadAudios();
        variablesTab && loadVariables();
        grammarsTab && loadGrammars();
    };

    const importClosed = ()=>{
        setOpenImport('');
        reloadLibraryData();
    }

    useEffect(() => {
        if (reloadLib) {
            reloadLibraryData();
        }
    }, [reloadLib, formik?.values?.language]);

    useEffect(() => {
        reloadLibraryData();
    }, [itemsPerPage, currentPage, segmentsTab, promptsTab, audiosTab, variablesTab, grammarsTab, formik.values.language, searchValue]);

    useEffect(() => {
        if (setReloadSegments) {
            loadSegments();
        }
    }, [setReloadSegments])


    useEffect(() => {
        // Function to filter the data based on the searchValue
        // const filterData = (dataToFilter: any) => {
        //     return dataToFilter.filter((item: any) =>
        //         item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        //     );
        // };
        const filterData = (dataToFilter: any) => {

            return dataToFilter.filter((item: any) => {
                const nameMatch = item?.name?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
                let idMatch;
                if (segmentsTab) {
                    idMatch = (item?.testSegmentVoiceId || item?.testSegmentId)?.toString()?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
                } else {
                    idMatch = item?.libraryID?.toString()?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
                }
                // Return true if either name or libraryID matches the searchValue
                return nameMatch || idMatch;
            });
        };


        // Variables to store the library data
        let filteredData;
        let libraryData;
        let useFilterData = true;
        // Determine which library data to use based on the selected tab
        if (segmentsTab) {
            libraryData = getAllData;
            useFilterData = false;
        } else if (variablesTab) {
            libraryData = getAllVariables;
        } else if (grammarsTab) {
            libraryData = getAllGrammars;
        } else if (promptsTab) {
            libraryData = getAllPrompts;
        } else if (audiosTab) {
            libraryData = getAllAudios;
        }

        if(useFilterData) {
            filteredData = filterData(libraryData);
        }

        // Update the state based on the selected tab
        if (segmentsTab) {
            setData(filteredData);
        } else if (variablesTab) {
            setVariables(filteredData);
        } else if (grammarsTab) {
            setGrammars(filteredData);
        } else if (promptsTab) {
            setPrompts(filteredData);
        } else if (audiosTab) {
            setAudios(filteredData);
        }
    }, [variablesTab, grammarsTab, promptsTab, audiosTab]);

    const selectionChanged = (id: any) => {
        setSelected(id);
    }

    const getLibraryItem = () => promptsTab ? 'prompts' : variablesTab ? 'variables' : grammarsTab ? 'grammars' : audiosTab ? 'audios' : 'segments';

    const removeSelected = async () => {
        const result: any = await deleteLibraryItems(project?.testProjectID || 1, getLibraryItem(), selected.map((c: string) => +c), project?.data?.channelType);
        if (getLibraryItem() === 'segments') {
            dispatch(setEditSegment(null));
        }

        setCurrentPage(1);
        reloadLibraryData();
        setSelected([]);

        if (result.some((r:any)=>["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(r.code))) {
            dispatch(toggleAlert(result.filter((r:any)=>["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(r.code))?.[0]?.response?.data?.data?.messages?.join(',')))
        }
    }

    const addSelectedSegments = () => {
        selected.forEach(id => {
            const seg = data.find((c: any) => c.id === id) as ISegment;
            dispatch(addNewSegment({
                ...seg,
                defaultLng: seg.segmentLangs?.[0]?.languageCode?.id,
            }))
        })
    }


    const paginatedData = useMemo(() => orderBy(data || [], 'lastModifiedTime', 'desc')/*.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)*/, [data, currentPage])

    const variableLibraryData = useMemo(
        () => orderBy(variables || [], 'lastModifiedTime', 'desc')/*.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)*/,
        [variables, currentPage]
    );

    const grammarLibraryData = useMemo(
        () => orderBy(grammars || [], 'lastModifiedTime', 'desc')/*.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)*/,
        [grammars, currentPage]
    );

    const promptLibraryData = useMemo(
        () => orderBy(prompts || [], 'lastModifiedTime', 'desc')/*.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)*/,
        [prompts, currentPage]
    );

    const audioLibraryData = useMemo(
        () => orderBy(audios || [], 'lastModifiedTime', 'desc')/*.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)*/,
        [audios, currentPage]
    );

    const importData = () => {
        setOpenImport(uniqueId());
    }

    const exportData = async () => {
        const csv = await exportDataTypes(project?.testProjectID || 1, getLibraryItem());
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${getLibraryItem()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const keyPress = (e: any) => {
        if (e.keyCode == 13) {
            setSearchValue(e.target.value);
        }
    }

    return (
        <>
            <Card className="h-100 sticky-top">
               
                <CardContent>
                    <div className="form-header">
                        <Typography variant="subtitle1">{t('Project Library')}</Typography>
                    </div>

                    <div className="library-searchbox">
                        <div className="delete-icon">
                            <img src={deleteIcon} alt="" className='pointer' onClick={removeSelected} />
                        </div>
                        <div className="search-input-lib">
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label={
                                    <React.Fragment>
                                        <SearchIcon sx={{ marginRight: '10px' }} />
                                        <span>{t('Search')}</span>
                                    </React.Fragment>
                                }
                                variant="outlined"
                                sx={{
                                    borderRadius: 32.5,
                                    backgroundColor: "#f7f7f7",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: 32.5,
                                        "& fieldset": {
                                            borderColor: "#f7f7f7",
                                            borderWidth: 2,
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#f7f7f7",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#f7f7f7",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '0px',
                                        // marginLeft: inputFocused ? '0px' : '35px',
                                    },
                                }}
                                onFocus={() => setInputFocused(true)}
                                onKeyDown={keyPress}
                                onBlur={(e) => {
                                    setInputFocused(false);
                                    setSearchValue(e.target.value);
                                }}
                            />

                        </div>
                    </div>

                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            // textColor="secondary"
                            indicatorColor="secondary"
                            sx={{
                                ".Mui-selected": {
                                    color: tabColor, // Tab font color
                                },
                            }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: tabColor // Line color underneath tab
                                }
                            }}
                            aria-label="secondary tabs example"
                            variant="fullWidth"
                        >
                            <Tab value="Segments" label={t("Segments")} sx={{ color: "#006A68", backgroundColor: (segmentsTab ? "#CCE8E6" : "#F1FFFE") }} onClick={toggleSegmentsTab} />
                            <Tab value="Variables" label={t("Variables")} sx={{ color: "#3818D9", backgroundColor: (variablesTab ? "#E3DEF8" : "#F7F5FF") }} onClick={toggleVariablesTab} />
                            {project?.data?.channelType === CHANNELS.VOICE && <Tab value="Prompts" label={t("Prompts")} sx={{ color: "#006A68", backgroundColor: (promptsTab ? "#D3E7D7" : "#F0F9F2") }} onClick={togglePromptsTab} />}
                            {project?.data?.channelType === CHANNELS.VOICE && <Tab value="Grammars" label={t("Grammars")} sx={{ color: "#FFA800", backgroundColor: (grammarsTab ? "#EDE9D6" : "#FCFAF1") }} onClick={toggleGrammarsTab} />}
                            {project?.data?.channelType === CHANNELS.VOICE && <Tab value="Audios" label={t("Audios")} sx={{ color: "#2F80ED", backgroundColor: (audiosTab ? "#D4E6F7" : "#F0F8FF") }} onClick={toggleAudiosTab} />}
                        </Tabs>
                    </Box>


                    <div className="library-container" style={{ backgroundColor: backgroundColor }}>
                        <div className="datagrid-wrapper">
                            {/* Conditionally Render Library Tabs */}
                            {segmentsTab && (<LibraryDataGrid showLanguages={project?.data?.channelType === CHANNELS.VOICE} formik={formik} selectionChanged={selectionChanged} columns={columns} data={paginatedData} selection={true} sortBy={'id'} />)}
                            {variablesTab && (<VariableLibraryDataGrid showLanguages={project?.data?.channelType === CHANNELS.VOICE} formik={formik} selectionChanged={selectionChanged} columns={variableColumns} data={variableLibraryData} selection={true} sortBy={'id'} />)}
                            {grammarsTab && (<GrammarLibraryDataGrid formik={formik} selectionChanged={selectionChanged} columns={grammarColumns} data={grammarLibraryData} selection={true} sortBy={'id'} />)}
                            {promptsTab && (<PromptLibraryDataGrid formik={formik} selectionChanged={selectionChanged} columns={promptColumns} data={promptLibraryData} selection={true} sortBy={'id'} />)}
                            {audiosTab && (<AudioLibraryDataGrid formik={formik} selectionChanged={selectionChanged} columns={audioColumns} data={audioLibraryData} selection={true} sortBy={'id'} />)}
                        </div>
                        <div className="pagination-wrapper">
                            <div className="items-per-page">
                                <FormHelperText sx={{ color: '#2196F3' }}>{t("items per page")}</FormHelperText>
                                <Select
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    variant="standard"
                                    style={{ width: '82px' }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                </Select>
                            </div>
                            <div className="pagination">
                                <Pagination
                                    count={totalElements || 0}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    showFirstButton
                                    showLastButton
                                    color="primary"
                                />
                            </div>
                        </div>
                        <div className='segment-editor-save-buttons' style={{ display: 'flex', justifyContent: 'center', gap: '48px', paddingTop: '10px' }}>
                            {caseEditorOpen && segmentsTab && selected.length > 0 && (
                                <Button
                                    variant={'contained'}
                                    color="primary"
                                    className='max-h-30'
                                    onClick={addSelectedSegments}
                                >
                                    {t("Add to Editor")}
                                </Button>
                            )}

                            <Button
                                variant={'contained'}
                                color="primary"
                                className='max-h-30'
                                onClick={toggleAddButton}
                            >
                                {t("Create")}
                            </Button>

                            {!segmentsTab && !audiosTab && (
                                <>
                                    <Button
                                        variant={'contained'}
                                        color="primary"
                                        className='max-h-30'
                                        onClick={importData}
                                    >
                                        {t("Import")}
                                    </Button>


                                    <Button
                                        variant={'contained'}
                                        color="primary"
                                        className='max-h-30'
                                        onClick={exportData}
                                    >
                                        {t("Export")}
                                    </Button>
                                </>
                            )}

                        </div>
                    </div>
                </CardContent>
            </Card>

            <Modal
                isOpen={openImport}
                title={t("Importing Files into Library")}
                small={true}
                body={<ImportData id={project?.testProjectID || 1} language={formik.values?.language} close={importClosed} tab={value} />}
                deleteConfirmation={false}
            />
        </>
    );
};
