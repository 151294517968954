import { FormikProps } from "formik";
import { InputLabel, FormControl, Box, Checkbox, FormControlLabel, TextField, Radio, RadioGroup, MenuItem } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { getVAUserList } from "../../../services/user.service";

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}

export const TransferCallToAgent = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    // const { values, handleChange } = formik;
    const [agents, setAgents] = useState<any[]>([]);

    const { t } = useTranslation();

    const loadAgents  =async()=> {
        const {data } = await getVAUserList(0, 200);
        console.log(data?.data?.content)
        setAgents(data?.data?.content?.map((a:any)=>({
            id:a.id,
            title:a.agentName,
        })))
    }

    useEffect(()=> {
        loadAgents();
    },[])

    return (
        <>
            {/* <Box className='segment-editor-remark'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-queue-name"></InputLabel>
                    <TextField
                        id="agentXferActionQueueName"
                        name='agentXferActionQueueName'
                        value={formik.values?.agentXferActionQueueName}
                        label={t("Queue Name")}
                        onChange={formik.handleChange}
                    />
                </FormControl>
            </Box> */}
            <Box className='segment-editor-remark'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="vistual-agent"></InputLabel>
                    <TextField
                        select
                        id="agentXferActionAgentID"
                        name='agentXferActionAgentID'
                        value={formik.values?.agentXferActionAgentID}
                        label={t("Virtual Agent")}
                        onChange={formik.handleChange}
                    >
                    {agents.map((a) => (
                        <MenuItem value={a.id} key={`agent_${a.id}`}>{a.title}</MenuItem>
                      ))}
                    </TextField>
                </FormControl>
            </Box>
            {/* <Box className='segment-editor-remark'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-agent-id"></InputLabel>
                    <TextField
                        id="agentXferActionAgentID"
                        name='agentXferActionAgentID'
                        value={formik.values?.agentXferActionAgentID}
                        label={t("Agent ID")}
                        onChange={formik.handleChange}
                    />
                </FormControl>
            </Box> */}
            {/* <Box className='segment-editor-remark'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-agent-id"></InputLabel>
                    <TextField
                        id="agentXferActionAgentPwd"
                        name='agentXferActionAgentPwd'
                        value={formik.values?.agentXferActionAgentPwd}
                        label={t("Password")}
                        type="password"
                        onChange={formik.handleChange}
                    />
                </FormControl>
            </Box> */}
            {/* <Box className='segment-editor-remark'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-agent-id"></InputLabel>
                    <TextField
                        id="agentXferActionOrgName"
                        name='agentXferActionOrgName'
                        value={formik.values?.agentXferActionOrgName}
                        label={t("Organisation Name")}
                        onChange={formik.handleChange}
                    />
                </FormControl>
            </Box> */}
            <Box className="prompt-library-checkbox">
                <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                    aria-labelledby="agentXferActionAutoPickup"
                    defaultValue={true}
                    name="agentXferActionAutoPickup"
                    value={formik.values?.agentXferActionAutoPickup}
                    onChange={formik.handleChange}
                >
                    <FormControlLabel value={true} control={<Radio />} label={t("Answer Calls Automatically")} />
                    <FormControlLabel value={false} control={<Radio />} label={t("Answer Calls Manually")} />
                </RadioGroup>
                   
                </FormControl>
            </Box>
            {/* <Box className='segment-editor-remark'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-timeout-for-action"></InputLabel>
                    <TextField
                        id="timeoutAction"
                        name='timeoutAction'
                        value={formik.values?.timeoutAction}
                        label="Timeout for the Action"
                        onChange={formik.handleChange}
                    />
                    Seconds
                </FormControl>
            </Box> */}
            <Box className="prompt-library-checkbox">
                <FormControl sx={{ width: "50%" }}>
                    <InputLabel id="case-editor-timeout-for-action"></InputLabel>
                    <FormControlLabel
                        name="case-select-timeout-for-action"
                        control={
                            <TextField
                                id="agentXferActionActionTimeout"
                                name='agentXferActionActionTimeout'
                                value={formik.values?.agentXferActionActionTimeout}
                                label={t("Timeout for the Action")}
                                onChange={formik.handleChange}
                            />
                        }
                        label={t("Seconds")}
                        labelPlacement="end"
                    />
                </FormControl>
            </Box>
            <Box className="prompt-library-checkbox">
                <FormControl sx={{ width: "50%" }}>
                    <FormControlLabel
                        name="case-select-screenshot-option"
                        control={
                            <Checkbox
                                defaultChecked={formik.values?.agentXferActionScreenshot}
                                id='agentXferActionScreenshot'
                                name='agentXferActionScreenshot'
                                value={formik.values?.agentXferActionScreenshot}
                                onChange={formik.handleChange} />
                        }
                        label={t("Screenshot Option")}
                        labelPlacement="end"
                    />
                </FormControl>
            </Box>
        </>
    );
};
