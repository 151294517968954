import { Checkbox, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { IGridcolumn } from "./interface/column.interface";
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Pagination from '@mui/material/Pagination';
import { PageSizeSelector } from "./pageSizeSelector";
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';

export interface IProps {
    columns: IGridcolumn[];
    data: any;
    selection?: boolean;
    selectionChanged?: (id: string[]) => void;
    sortBy?: string;
    sortDirection?: 'ASC' | 'DESC' | '';
    sortChanged?: (column: string, direction: string) => void;
    pageChanged?: (page: number) => void;
    totalPages?: number;
    pageSizeChanged?: (size: number) => void;
}
export const DataGrid = ({
    columns = [],
    data = [],
    selection = false,
    selectionChanged,
    sortChanged,
    sortBy = '',
    sortDirection = 'ASC',
    totalPages = 0,
    pageChanged,
    pageSizeChanged,
}: IProps) => {
    const { t } = useTranslation();

    const [order, setOrder] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [direction, setDirection] = useState<'ASC' | 'DESC' | ''>('ASC');

    const [selected, setSelected] = useState<string[]>([]);

    const handleItemsPerPageChange = (size: number) => {
        pageSizeChanged && pageSizeChanged(size);
    }

    const RenderItem = ({ col = null as unknown as IGridcolumn, data = {} }) => {
        const Component = col?.redner;

        return (
            <Component  {...data} />
        )
    }

    const updateSort = (col: IGridcolumn) => () => {
        if (col.sortable && sortChanged) {
            if (sort === col.fieldName) {
                sortChanged(col.fieldName, direction === 'ASC' ? 'DESC' : 'ASC');
            } else {
                sortChanged(col.fieldName, 'ASC');
            }
            setSort(col.fieldName);

        }
    }

    const key = useMemo(() => columns.find(c => c.key)?.fieldName || 'id', [columns]);

    const checkChanged = (id: string) => (e: any) => {
        if (e.target.checked) {
            setSelected(selected.concat(id))
        } else {
            setSelected(selected.filter(s => s !== id));
        }
    }

    const onPageChanged = (e: any, page: number) => {
        pageChanged && pageChanged(page)
    }

    useEffect(() => {
        setOrder(sortBy.substring(1));
        setDirection(sortBy.charAt(0) === '-' ? 'DESC' : 'ASC');
    }, [sortBy, sortDirection])

    useEffect(() => {
        setSelected([]);
    }, [data])


    useEffect(() => {
        selectionChanged && selectionChanged(selected);
    }, [selected])

    const ListItem = ({ ...props }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'TESTCASE-ROWS',
            item: {
                ...props.data
            },
            collect: (monitor: any) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }))

        return (<TableRow style={{ height: '30px', padding: 0 }} className="relative" ref={drag}>{props.children}</TableRow>);
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {selection && (
                        <TableCell width={'55px'}>
                            {t('Select')}
                        </TableCell>
                    )}
                    {columns.map(col => (
                        <TableCell width={col.width || ''} key={`col_${col.fieldName}`}>
                            <div onClick={updateSort(col)} className={`flex-center ${col.sortable ? 'pointer' : ''}`}>
                                {col.caption}

                                {col.fieldName === order && (
                                    direction === 'ASC' ?
                                        <ArrowDropUpOutlinedIcon />
                                        :
                                        <ArrowDropDownOutlinedIcon />
                                )}
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                {data.map((row: any, index: number) => (
                    <ListItem key={`row_${index}`} data={row}>                        
                        {selection && (
                            <TableCell>
                                <Checkbox color="primary" onChange={checkChanged(row[key])} checked={selected.indexOf(row[key]) !== -1} />
                            </TableCell>
                        )}
                        {columns.map(col => (
                            col.redner ? <TableCell key={`col_${index}_${col.fieldName}`}><RenderItem col={col} data={row} /></TableCell> :
                                <TableCell key={`col_${index}_${col.fieldName}`}>{row[col.fieldName]}</TableCell>
                        ))}
                    </ListItem>
                ))}
                {data?.length < 20 && (
                    Array.from(new Array(Math.max(1, 10 - (data?.length || 0)))).map((_, index) => (
                        <TableRow key={`extra_row_${index}`}>
                            <TableCell></TableCell>
                        </TableRow>
                    ))
                )}
            </TableBody>

            {totalPages >=0 && (
                <TableFooter >
                    <tr>
                        <td colSpan={(columns.length + (selected ? 1 : 0)) - 1}>
                            <Pagination onChange={onPageChanged} count={totalPages} showFirstButton showLastButton />
                        </td>
                        <td align="right">
                            <PageSizeSelector handleItemsPerPageChange={handleItemsPerPageChange}></PageSizeSelector>
                        </td>
                    </tr>
                </TableFooter>
            )}
        </Table>
    )
}