import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Header, LeftBar } from './layouts';
import { ProjectList } from './views/projectList';
import { TestCaseManagement } from './views/testCaseManagement';
import { TestResults } from './views/testResults';
import { AlertMessage } from './ui/aletMessage';
import { useSelector } from 'react-redux';
import { getGlobalAlert, getGlobalNotification, getHelpCenter, getHelpCenterByKey } from './store/selectors/app.selector';
import { getGoToTypes, getLanguages, getStepTypes, getProjectList, getBasicActions, getDigitalStepTypes, getLocatorTypes, getOperations, getBrowsers, getNoises } from './services/project.service';
import { useAppDispatch } from './store/hooks';
import { setGoToTypes, setLanguages, setStepTypes, getAllProjects, setBasicActions, setDigitalStepTypes, setLocatorTypes, setOperations, setBrowsers, setNoises } from './store/resuders/project.reducer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Scheduler } from './views/scheduler';
import { Login } from './views/login';
import { isLoggedIn } from './services/user.service';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Modal } from './ui';
import { HelpCenterCatalog } from './services/helpcenter';
import CloseIcon from '@mui/icons-material/Close';
import { showHelpDetails } from './store/resuders/app.reducer';
import Draggable from 'react-draggable'; // The default
import { UserProfile } from './views/accounts/userProfile';
import { UserManagement } from './views/usersManagement';
import { VirtualUserManagement } from './views/virtualUserManagement';
import { ScheduledTestResults } from './views/scheduledTestResults';
import { CallBack } from './views/accounts/callback';
import { getLensApi, STORAGE_KEY } from './services/config.service';
import { lensApi } from './services/api.service';

function ProjectDetails() {
  const { projectId } = useParams(); // Use the useParams hook to get the projectId from the URL
  // Render or fetch project details based on projectId
  // return <div>Project Details for ID: {projectId}</div>;
  return projectId
}

function TestCaseDetails() {
  const { testcaseId } = useParams(); // Use the useParams hook to get both projectId and testcaseId from the URL
  // Render or fetch testcase details based on projectId and testcaseId
  // return <div>Test Case Details for Project ID: {projectId}, Test Case ID: {testcaseId}</div>;
  return testcaseId
}

function App() {
  const dispatch = useAppDispatch();
  const [login, setLogin] = useState<boolean>(false);
  const openReminder = useSelector(getGlobalNotification);
  const helpCenter = useSelector(getHelpCenter);
  const helpCenterDetails = useSelector(getHelpCenterByKey);

  useEffect(() => {
    (async () => {

      if(!getLensApi()) {
        const api = await lensApi.get(`?lens=${window.location.hostname.replace('localhost','dev-lensapi.tekvision.com')}`);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(api?.data));
        window.location.reload();
        
        return;
      }

      if (window.location.pathname.indexOf('/login') !== -1) {
        setLogin(true);
        return;
      }

      if (window.location.pathname.indexOf('/login') === -1 && !isLoggedIn() && !document.location.href.includes('/callback')) {
        document.location.href = '/login';
      }

      const lngs = await getLanguages();
      const types = await getGoToTypes();
      const stepTypes = await getStepTypes();
      const digitalStepTypes = await getDigitalStepTypes();
      const locatorTypes = await getLocatorTypes();
      const basicActions = await getBasicActions();
      const operations = await getOperations();
      const browsers = await getBrowsers();
      const noises = await getNoises();
      // const projectList = await getProjectList();  // First project pull to find out how many projects there are
      // const numOfProjects = projectList["data"]["totalElements"];  // Get number of projects
      // const allProjects = await getProjectList(0, numOfProjects, '-lastModifiedTime')  // Second project pull with numOfProjects as pageSize to retreive a list of all projects

      // Create a dictionary mapping the projectIds as the key
      // const projectDictionary: { [key: number]: any } = allProjects.data.content.reduce(
      //   (dictionary: { [key: number]: any }, project: any) => {
      //     dictionary[project.testProjectID] = project;
      //     return dictionary;
      //   },
      //   {}
      // );

      dispatch(setLanguages(lngs))
      dispatch(setGoToTypes(types))
      dispatch(setStepTypes(stepTypes))
      dispatch(setDigitalStepTypes(digitalStepTypes))
      dispatch(setBasicActions(basicActions))
      dispatch(setLocatorTypes(locatorTypes))
      dispatch(setOperations(operations))
      dispatch(setBrowsers(browsers))
      dispatch(setNoises(noises))
      // dispatch(getAllProjects(projectDictionary))
    })()
  }, []);

  const setVisibility = (display:string)=> {
    document.querySelectorAll(".help-icon-float").forEach(i=> {
      ((i) as HTMLElement).style.display=display;
    });
    document.querySelectorAll(".help-icon-float-right").forEach(i=> {
      ((i) as HTMLElement).style.display=display;
    });
    document.querySelectorAll(".help-icon").forEach(i=> {
      ((i) as HTMLElement).style.display=display;
    });
  }

  const closeHelp = ()=> {
    setVisibility('');
    dispatch(showHelpDetails(''));
  }

  const helpDetails = useMemo(()=> {
    if(helpCenterDetails) {
      setVisibility('none');
      return HelpCenterCatalog[helpCenterDetails];
    } else {
      return null;
    }
  },[helpCenterDetails]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={login ? "" : "main-app"}>

      {helpCenter && (
        <div className='help-center'>
            {helpDetails && (
              <Draggable>
                <div className='help-container'>
                  <div className='help-container-header'><span>Help Center</span> <div onClick={closeHelp} className='close-help'><CloseIcon /></div></div>
                  <div className='help-container-body'>
                    <div className='help-container-body-title'>You are looking at: <b>{helpCenterDetails}</b></div>
                    {Object.keys(helpDetails || {}).map(key=>(
                      <div className='flex help-container-row'>
                        <div className='dib'>{key}:</div>
                        <div className='dib'>{helpDetails[key]}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Draggable>
            )}
           
        </div>
      )}
        {!login && (
          <>
            <div className='db'>
              <Header />
            </div>

            <LeftBar />
          </>
        )}
        <div className={login ? '' : 'main-container'}>
      
          <Routes>
            <Route path="/" element={<Navigate replace to="/projects" />} />
            <Route path="/callback" element={<CallBack />} />
            <Route path="/projects" element={<ProjectList />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/projects/:projectId/testcases" element={<TestCaseManagement />} />
            <Route path="/projects/:projectId/testresults" element={<TestResults />} />
            <Route path="/projects/:projectId/testresults/:testcaseId" element={<TestResults />} />
            <Route path="/projects/:projectId/testresults/:testcaseId/:testResultId" element={<TestResults />} />
            <Route path="/projects/:projectId/scheduled/testresults" element={<ScheduledTestResults />} />
            <Route path="/projects/:projectId/scheduled/testresults/:scheduledId" element={<ScheduledTestResults />} />
            <Route path="/projects/:projectId/scheduled/testresults/:scheduledId/:testResultId" element={<ScheduledTestResults />} />
            <Route path="/projects/:projectId/scheduler" element={<Scheduler />} />
            <Route path="/projects/:projectId/scheduler/:testcaseId" element={<Scheduler />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/VAUsers" element={<VirtualUserManagement />} />
          </Routes>
        </div>
        <AlertMessage />
        <Modal
          isOpen={openReminder}
          title={openReminder?.indexOf('Password')!==-1 ? 'Notification': openReminder?.indexOf('created')!==-1 ? 'Item Created': "Item Updated"}
          small={true}
          body={<div className='reminder'><CheckCircleOutlineIcon style={{ fontSize: '53px', color: '#58A148' }} /> <br />{openReminder}</div>}
          deleteConfirmation={false}
        />
      </div >
    </LocalizationProvider>
  );
}

export default App;
