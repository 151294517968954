import { Alert, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store/hooks";
import { toggleAlert } from "../store/resuders/app.reducer";
import { getGlobalAlert } from "../store/selectors/app.selector";

export const AlertMessage = ({ severity = 'error' as any, result = {} as any, code = "", message = '', isOpen = '' }) => {
    const [open, setOpen] = useState<boolean>(false);
    const gloabl = useSelector(getGlobalAlert);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(toggleAlert(''));
    }

    useEffect(() => {
        if (result && code && result?.data?.code === code) {
            setOpen(true);
        } else if (result && result?.response && result?.response?.status !== 200) {
            setOpen(true);
        }
    }, [result]);

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        }
    }, [isOpen]);

    return (
        <Snackbar open={gloabl?.show} onClose={handleClose}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                { gloabl?.message || result?.response?.data?.data?.messages?.join('\r\n') || []}
            </Alert>
        </Snackbar>
    )
}