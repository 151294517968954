import { Button, Card, CardContent, Grid, Link } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { deleteIcon, digital, downloadIcon, lockIcon } from "../assets";
import { CHANNELS, GLOBAL_ACTION } from "../types/global.enum";
import { DataGrid, Modal, SearchInput } from "../ui"
import { AddProject } from "./addProject";
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { deleteProjects, getProjectList } from "../services/project.service";
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { useNavigate } from "react-router-dom";
import { formatDate } from "../helpers/date.helper";
import { useAppDispatch } from "../store/hooks";
import { setCurrentProject } from "../store/resuders/project.reducer";
import { clearSegments, setEditSegment, upsertLibrary } from "../store/resuders/segment.reducer";
import { showHelpDetails, toggleAlert } from "../store/resuders/app.reducer";
import { formatTimestamp } from "../helpers/date.helper";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { getHelpCenter } from "../store/selectors/app.selector";
import InfoIcon from '@mui/icons-material/Info';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const ProjectList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const [action, setAction] = useState<GLOBAL_ACTION>(GLOBAL_ACTION.NONE);
    const [edit, setEdit] = useState<any>(null);
    const [data, setData] = useState<any>([]);
    const [remove, setRemove] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<string>('');
    const helpCenter = useSelector(getHelpCenter);
    
    const [criteria, setCriteria] = useState<any>({
        sort: '-lastModifiedTime',
        pageNo: 0,
        pageSize: 10,
        filter: '',
    });

    const columns: any[] = [{ key: true, caption: t('ID'), width: '5%', fieldName: 'testProjectID', sortable: true },
    {
        caption: t('Channel'), width: '5%', fieldName: 'channelType', sortable: true,
        redner: (param: any) => <div className="flex-center justify-center">{param.channelType === CHANNELS.VOICE ? (<PhoneEnabledOutlinedIcon style={{ fontSize: '17px' }} />) : (<img src={digital} alt="" width={17}/>)}</div>
    },
    {
        fieldName: 'name', caption: t('Project Name'), sortable: true, width: '75%',
        redner: (params: any) => <div className="flex-center">
            <Link onClick={gotoTestCase(params)} className="a-link-black">{params.name}</Link><BorderColorOutlinedIcon onClick={editProject(params)} style={{ fontSize: '17px', cursor: 'pointer' }} /></div>
    }, {
        fieldName: 'lastModifiedTime', caption: t('Modified'), sortable: true, width: '15%',
        // redner: (param: any) => <div>{formatDate(param.lastModifiedTime)}</div>
        redner: (param: any) => <div>{formatTimestamp(param.lastModifiedTime)}</div>
    }];

    const gotoTestCase = (project: any) => () => {
        dispatch(setCurrentProject(project));
        dispatch(upsertLibrary(''));
        dispatch(setEditSegment(null));
        dispatch(clearSegments());
        // history(`/testcase`);
        history(`/projects/${project.testProjectID}/testcases`);
    }

    const editProject = (data: any) => () => {
        setEdit(data);
        setAction(GLOBAL_ACTION.EDIT);
    }

    const updateAction = () => {
        switch (action) {
            case GLOBAL_ACTION.NONE: {
                setAction(GLOBAL_ACTION.ADD);
                break;
            }

            default: {
                setAction(GLOBAL_ACTION.NONE);
                setEdit(null);
            }
        }
    }

    const onDone = async () => {
        setAction(GLOBAL_ACTION.NONE);
        setEdit(null);
        await loadData();
    }

    const sortChanged = (column: string, direction: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
        })
    }

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    const onFilterChanged = (filter: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            filter,
        })
    }

    const pageSizeChanged = (size: number) => {
        setCriteria({
            ...criteria,
            pageSize: size,
            pageNo: 0,
        })
    }

    const loadData = async () => {
        const { data } = await getProjectList(criteria.pageNo, criteria.pageSize, criteria.sort, criteria.filter);
        setData(data)
    }


    const deleteProject = useCallback(async () => {
        try {
            await deleteProjects(remove);
            await loadData();
            setRemove([]);
        } catch (error:any) {
            dispatch(toggleAlert("Projects with existing test cases cannot be deleted")); // Dispatch toggleAlert action with an error alert
        }
    },[remove]);

    const deleteSelected = async () => {
        if (remove?.length) {
            setIsOpen(`${new Date().getTime()}`);
        }
    }

    const selectionChanged = useCallback((id: any) => {
        setRemove(id);
    },[])

    useEffect(() => {
        loadData();
        dispatch(setCurrentProject(null));
    }, [criteria])

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container>
                <Grid item xs={12} className="flex justify-end align-center gap-8" textAlign="right" marginBottom={'16px'}>
                    {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Add a Project'))} className="help-icon"><InfoIcon /></div>}<Button variant={action !== GLOBAL_ACTION.NONE ? "outlined" : "contained"} color="primary" onClick={updateAction}>{t(action !== GLOBAL_ACTION.NONE ? 'Close' : 'Add Project')}</Button>
                </Grid>

                <Grid item xs={action !== GLOBAL_ACTION.NONE ? 6 : 12}>
                    <Card>
                        <CardContent>
                            <div className="action-header">
                                {/* <img src={lockIcon} alt="" /> */}
                                <img src={deleteIcon} alt="Remove" onClick={deleteSelected} />{helpCenter && <div onClick={()=>dispatch(showHelpDetails('Project Page'))} className="help-icon"><InfoIcon /></div>}
                                {/* <img src={downloadIcon} alt="" /> */}
                                <div className="flex-end">
                                    <SearchInput onFilter={onFilterChanged} />
                                </div>
                            </div>

                            <DataGrid pageChanged={pageChanged} totalPages={data?.totalPages || 0} sortChanged={sortChanged} columns={columns} data={data?.content || []} selection={true} sortBy={criteria.sort}
                                selectionChanged={selectionChanged} pageSizeChanged={pageSizeChanged} />
                        </CardContent>
                    </Card>
                </Grid>

                {action !== GLOBAL_ACTION.NONE && (
                    <Grid item xs={6} paddingLeft={1}>
                        <AddProject data={edit} onDone={onDone} />
                    </Grid>
                )}

                <Modal
                    isOpen={isOpen}
                    title="Delete Items"
                    small={true}
                    deleteConfirmation={true}
                    ButtonDelete={deleteProject}
                />

            </Grid>
        </DndProvider>
        

    )
}