import { useState } from 'react';
import { Button, Card, CardContent, Typography, InputLabel, FormControl, Select, MenuItem, Box, TextField } from "@mui/material"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useAppDispatch } from "../store/hooks"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import { getEditSegment, getSelectedSegments } from '../store/selectors/segment.selector';
import { getAllStepTypes, getCurrentProject, getCurrentTestCase, getGoToTypes, getLanguages } from '../store/selectors/project.selector';
import { deleteIcon, editFilledIcon } from '../assets';
import { ports, scheduleTypes, status, trigger } from '../helpers/form.helper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { FormikProps } from "formik";
import { PerpetualScheduleGrid } from '../ui/perpetualScheduleTable.ui';
import { useParams } from 'react-router-dom';
import { setScheduleTime } from '../store/resuders/schedule.reducer';
import { getScheduleTime } from '../store/selectors/schedule.selector';
import { useTranslation } from 'react-i18next';
import {formatDate as fnsFormatDate } from '../helpers/date.helper';

export const PerpetualSchedule = ({
    formik,
    recurrenceFormik,
    setAddRecurrenceOpen,
    addRecurrenceOpen = null as any,
    toggleRecurrenceOpen,
    setRecurrenceEditorState,  // ADD or EDIT
    recurrenceEditorState = null as any,
    paramToEdit,
    setParamToEdit,
    deletePerpetualSchedule
}: {
    formik: FormikProps<any>;
    recurrenceFormik: FormikProps<any>;
    setAddRecurrenceOpen: (value: boolean) => void;
    addRecurrenceOpen: boolean;
    toggleRecurrenceOpen: () => void
    setRecurrenceEditorState: (value: string) => void
    recurrenceEditorState: string;
    paramToEdit: any;
    setParamToEdit: (param: any) => void;
    deletePerpetualSchedule: (value: any) => void;
}) => {
    // const [clear, setClear] = useState(false);
    // const project = useSelector(getCurrentProject);
    const { t } = useTranslation();
    const { projectId} = useParams();  // ProjectID and TestCaseID from URL
    const project = useSelector(getCurrentProject);  // Store Project
    const [perpetualSchedules, setPerpetualSchedules] = useState(useSelector(getScheduleTime))
    const [addTestCaseOpen, setAddTestCaseOpen] = useState(false);
    const [hasRecurrences, setHasRecurrences] = useState(false);

    const dispatch = useAppDispatch();

    const [criteria, setCriteria] = useState<any>({
        sort: '-lastModifiedTime',
        pageNo: 0,
        pageSize: 10,
        filter: '',
        projectId: project ? project?.testProjectID : projectId
    });

    function formatDate(inputDateString: any) {
        const inputDate = new Date(inputDateString);
    
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    

    // Columns for Test Results Status Page
    const perpetualScheduleColumns: any[] = [
        {
            fieldName: 'name',
            caption: t('TRIGGER DETAILS'),
            redner: (params: any) => (
                <div>
                    {params.exclusion === false
                        ? params.dayOrDate.map((day: string) => (
                            <div key={day}>{day}</div>
                        ))
                        : (
                            `Exclude: ${formatDate(params.dayOrDate?.[0] || params.dayOrDate)}`
                            )
                        
                        }
                </div>
            ),
        },
        {
            fieldName: 'lastRunTime',
            caption: t('TIME'),
            redner: (params: any) => {
                // Split the time string by ':'
 
                const fromTimeParts = typeof params?.fromTime === 'string' ? params?.fromTime?.split(':'):fnsFormatDate(params?.fromTime,"HH:mm");
                const toTimeParts = typeof params?.toTime === 'string' ? params?.toTime?.split(':'):fnsFormatDate(params?.toTime,"HH:mm");
            
                // Extract hours and minutes
                const fromHours = fromTimeParts?.[0];
                const fromMinutes = fromTimeParts?.[1];
            
                const toHours = toTimeParts?.[0];
                const toMinutes = toTimeParts?.[1];
            
                // Create formatted time strings
                const formattedFromTime = `${fromHours}:${fromMinutes}`;
                const formattedToTime = `${toHours}:${toMinutes}`;
            
                return (
                    <div className="flex-center">
                        {formattedFromTime} - {formattedToTime}
                    </div>
                );
            }
        },
        {
            fieldName: 'enabled',
            caption: t('STATUS'),
            redner: (params: any) => (
                <div className="flex-center">
                    {params.enabled ? 'Enabled' : 'Disabled'}
                </div>
            ),
        },
        {
            fieldName: '',
            caption: t('ACTION'),
            redner: (params: any) => (
                <div className="flex-center" style={{gap:"10px", justifyContent:"center"}}>
                    <img src={editFilledIcon} alt="Remove" onClick={() => editPerpetualSchedule(params)} />
                    <img src={deleteIcon} alt="Remove" onClick={() => deletePerpetualSchedule(params)} />
                </div>
            )
        },
    ];

    const sortChanged = (column: string, direction: string) => {
        if (column === 'startTime') {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}startTime`,
            });
        } else {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
            });
        }
    };

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    const selectionChanged = (id: any) => {
        // setRemove(id);
    }

    const toggleAddRecurrence = () => {
        setAddRecurrenceOpen(!addRecurrenceOpen)
    }

    const save = () => {
        // Save Button
        formik.submitForm()
        toggleRecurrenceOpen()
    }

    const editPerpetualSchedule = (params: any) => {
        setParamToEdit(params)
        console.log("Editing")
        setRecurrenceEditorState("EDIT")
        setAddRecurrenceOpen(true)

        recurrenceFormik.setValues({
            dayOrDate: params?.exclusion ? dayjs(params?.dayOrDate[0]) : params?.dayOrDate,
            fromTime: dayjs(params?.fromTime, 'HH:mm:ss.SSS'),
            toTime: dayjs(params?.toTime, 'HH:mm:ss.SSS'),
            runInterval: params?.runInterval,
            runIntervalUnit: params?.runIntervalUnit,
            enabled: params?.enabled,
            exclusion: params?.exclusion
        });

    }

    // const deletePerpetualSchedule = (itemToDelete: any) => {
    //     // Find the index of the item to delete
    //     const indexToDelete = formik.values.scheduleTime.findIndex((item: any) => {
    //         // Compare properties of the item to delete with each object in the array
    //         return (
    //             item.dayOrDate[0] === itemToDelete.dayOrDate[0] &&
    //             item.fromTime === itemToDelete.fromTime &&
    //             item.toTime === itemToDelete.toTime &&
    //             item.runInterval === itemToDelete.runInterval &&
    //             item.runIntervalUnit === itemToDelete.runIntervalUnit &&
    //             item.enabled === itemToDelete.enabled &&
    //             item.exclusion === itemToDelete.exclusion
    //         );
    //     });
    
    //     if (indexToDelete !== -1) {
    //         // Create a new array without the item to delete
    //         const updatedScheduleTime = [
    //             ...formik.values.scheduleTime.slice(0, indexToDelete),
    //             ...formik.values.scheduleTime.slice(indexToDelete + 1)
    //         ];
    
    //         // Update formik with the modified scheduleTime
    //         formik.setFieldValue('scheduleTime', updatedScheduleTime);
    //     }
    // }
    
    useEffect(() => {
        setPerpetualSchedules(formik?.values?.scheduleTime)
    }, [formik?.values?.scheduleTime]);


    useEffect(() => {
        setPerpetualSchedules(formik?.values?.scheduleTime)
    }, []);

    useEffect(() => {
        console.log("ParamToEdit:", paramToEdit)
    }, [paramToEdit, setParamToEdit]);


    return (
        <Card className="h-100-p">
            <CardContent>
                <div className="schedule-editor-header">
                    <Typography variant="subtitle1">{t('Perpetual Schedule')}</Typography>
                    <HighlightOffIcon onClick={() => toggleRecurrenceOpen()} />
                </div>

                <div >
                    {formik?.values?.scheduleTime?.length > 0 ? (
                        <Box>
                            <PerpetualScheduleGrid
                                pageChanged={pageChanged}
                                totalPages={0}
                                sortChanged={sortChanged}
                                columns={perpetualScheduleColumns}
                                // data={formatData()?.content || []} selection={true} sortBy={criteria.sort}
                                // data={formik?.values?.scheduleTime || []}
                                data={perpetualSchedules || []}
                                selection={true}
                                sortBy={criteria.sort}
                                selectionChanged={selectionChanged}
                            />
                        </Box>
                    ) : (
                        <div className="schedule-editor-test-case-body">
                            <Typography variant="subtitle2">{t('Create a Recurrence for the Perpetual Schedule')}.</Typography>
                            <Button variant={addRecurrenceOpen ? 'outlined' : 'contained'} color="primary" onClick={toggleAddRecurrence}>
                                {addRecurrenceOpen ? t('Cancel') : t('Add Recurrence')}
                            </Button>
                        </div>
                    )}
                    <div className='schedule-editor-test-case-buttons'>
                        {formik?.values?.scheduleTime?.length > 0 && (
                            <div style={{ display: 'flex', gap: '30px' }}>
                                <Button variant='contained' color="primary" onClick={save}>
                                    {t('Save')}
                                </Button>
                                <Button variant={addRecurrenceOpen ? 'outlined' : 'contained'} color="primary" onClick={toggleAddRecurrence}>
                                    {addRecurrenceOpen ? t('Close') : t('Add')}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

            </CardContent>
        </Card>
    )
}
