import { InputLabel, FormControl, Box, TextField, Checkbox, Select, MenuItem, FormLabel } from "@mui/material"
import { FormikProps } from "formik";
import { useTranslation } from 'react-i18next';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectNoises } from "../../store/selectors/project.selector";
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { getVoiceApi } from "../../services/config.service";

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}


export const Speak = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const noises = useSelector(selectNoises);
    const audio = useRef<any>(null);
    const [hasNoise, setHasNoise]=useState<boolean>(false);
    const [playing, setPlaying]=useState<boolean>(false);
    const { values, handleChange } = formik;
    const { t } = useTranslation();

    const checkedChanged = (e:any)=> {
        setHasNoise(e.target.checked);
    }

    const previewAudio = async()=> {
        if(playing && audio.current) {
            audio.current.pause();
            setPlaying(false);
        } else {
            audio.current = new Audio(`${getVoiceApi()}/mixedTts?archived=false&noiseBackground=${values?.noiseBackground}&tts=${values?.prompt}`);
            audio.current.play();
            setPlaying(true);
        }
       
    }

    useEffect(()=> {
        setHasNoise(values?.noiseBackground?.length>0);
    },[values]);

    return (
        <>
            <Box className='segment-editor-send-to-ivr'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-send-to-ivr"></InputLabel>
                    <TextField
                        id="prompt"
                        name="prompt"
                        value={values?.prompt}
                        label={t("Send to IVR")}
                        onChange={handleChange}

                    />
                </FormControl>
            </Box>
            <Box className='segment-editor-send-to-ivr'>
                <div className="flex-center player-speak">
                    <div className="flex-inline flex-1 align-center">
                        <Checkbox
                            checked={hasNoise}
                            onChange={checkedChanged}
                        />
                        <InputLabel >{t("Play Background Audio")}</InputLabel>
                     </div>
                     {playing ? <PauseCircleFilledIcon onClick={previewAudio}/>:<PlayCircleIcon onClick={previewAudio} />}
                     <InputLabel >{t("Preview Audio")}</InputLabel>
                </div>
            </Box>

            {hasNoise && 
                <Box className='segment-editor-send-to-ivr'>
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="case-editor-background-audio">{t('Background Audio')}</InputLabel>
                        <Select
                            labelId="case-editor-background-audio"
                            id="noiseBackground"
                            name='noiseBackground'
                            value={values?.noiseBackground}
                            label={t("Background Audio")}
                            onChange={handleChange}
                        >
                            {noises?.map((n:any)=>(
                                <MenuItem value={n.id} key={n.id}>{n.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            }
        </>
    );
};
