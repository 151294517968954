import { createAction, createReducer } from "@reduxjs/toolkit";
import { uniqueId } from "../../helpers/form.helper";
import { ISegment, ISegmentLanguage, ISegmentStep } from "../../types/interfaces/segment.interface";

export const addNewSegment = createAction<any>('addNewSegment');
export const removeSegment = createAction<number[]>('removeSegment');
export const updateSegment = createAction<ISegment | null>('updateSegment');
export const setEditSegment = createAction<any>('setEditSegment');
export const removeStep = createAction<any>('removeStep');
export const upsertLibrary = createAction<string>('upsertLibrary');
export const hideSegmentLibrary = createAction('hideSegmentLibrary');
export const clearSegments = createAction('clearSegments');
export const setBatchSegments = createAction<any[]>('setBatchSegments');

export const segmentReducer = createReducer(null, (builder) => {

    builder.addCase(addNewSegment, (state: any, action) => {

        return {
            ...state,
            segment: (state?.segment || []).concat(action.payload),
        }
    }).addCase(setBatchSegments, (state: any, action) => {
        return {
            ...state,
            segment: action.payload,
        }
    }).addCase(clearSegments, (state: any, action) => {

        return {
            ...state,
            segment: [],
        }
    })
        .addCase(removeSegment, (state: any, action) => {
            return {
                ...state,
                segment: state?.segment.filter((_: ISegment, index: number) => !action.payload.includes(index)),
            }
        }).addCase(updateSegment, (state: any, action) => {

            return {
                ...state,
                segment: (state?.segment || []).reduce((result: any, curr: any) => {
                    if (curr.id === action.payload?.id) {
                        result.push({
                            ...curr,
                            ...action.payload,
                        })

                    } else {
                        result.push(curr);
                    }
                    return result;
                }, []),
            }
        })
        .addCase(setEditSegment, (state: any, action) => {

            return {
                ...state,
                editSegment: action.payload,
            }
        })
        .addCase(removeStep, (state: any, action) => {
            const { segmentId, lng, stepId, sub } = action.payload;

            let segments = (state?.segment || []).reduce((result: ISegment[], curr: ISegment) => {
                if (curr.id === segmentId) {
                    result.push({
                        ...curr,
                        segmentLangs: curr.segmentLangs.reduce((lngs: ISegmentLanguage[], lang: ISegmentLanguage) => {

                            if (lang.languageCode.id === lng) {

                                if (!sub) {
                                    let indx = 0;

                                    lngs.push({
                                        ...lang,
                                        steps: lang.steps?.reduce((rr: ISegmentStep[], rs: ISegmentStep) => {
                                            if (rs.stepSeq !== stepId) {
                                                rr.push({
                                                    ...rs,
                                                    stepSeq: ++indx,
                                                });
                                            }

                                            return rr;
                                        }, []),
                                    });
                                } else {
                                    let indx = 0;
                                    lngs.push({
                                        ...lang,
                                        steps: lang.steps?.reduce((rSteps: ISegmentStep[], currStep: ISegmentStep) => {

                                            if (currStep.stepSeq === stepId) {
                                                rSteps.push({
                                                    ...currStep,
                                                    listenSubStep: currStep.listenSubStep?.filter(s => s.name !== sub),
                                                    stepSeq: ++indx,
                                                })
                                            } else {
                                                rSteps.push({
                                                    ...currStep,
                                                    stepSeq: ++indx,
                                                });
                                            }
                                            return rSteps;
                                        }, []),
                                    });
                                }

                            } else {
                                lngs.push(lang);
                            }

                            return lngs;
                        }, []),
                    })
                } else {
                    result.push(curr);
                }

                return result;
            }, []) as ISegment[];

            segments = segments.reduce((results: ISegment[], curr: ISegment) => {
                const lngs = [] as ISegmentLanguage[];

                curr.segmentLangs.forEach((lng: ISegmentLanguage) => {
                    if (lng.steps?.length) {
                        lngs.push(lng);
                    }
                });

                if (lngs.length) {
                    results.push({
                        ...curr,
                        segmentLangs: lngs,
                        defaultLng: lngs[0].languageCode.id,
                    });
                }

                return results;
            }, []);

            return {
                ...state,
                segment: segments,
            }
        }).addCase(upsertLibrary, (state: any, action) => {

            return {
                ...state,
                upsertLibrary: action.payload,
            }
        }).addCase(hideSegmentLibrary, (state: any, action) => {

            return {
                ...state,
                upsertLibrary: '',
            }
        })
})