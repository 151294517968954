import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useState } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useTranslation } from "react-i18next";

export interface IProps {
    onFilter?: (text: string) => void;
}
export const SearchInput = ({
    onFilter,
}: IProps) => {
    const [filter, setFilter] = useState<string>('');
    const { t } = useTranslation();

    const clearFilter = ()=> {
        setFilter('');
    }

    const onClick = () => {
        onFilter && onFilter(filter);
    }

    const keyPress = (e: any) => {
        if (e.keyCode == 13) {
            onClick();
        }
    }

    return (
        <FormControl variant="outlined" className="search-input">
            <InputLabel htmlFor="outlined-adornment-password">{t('Filter')}</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={'text'}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                onKeyDown={keyPress}
                endAdornment={
                    <InputAdornment position="end">
                        {filter && (
                         <IconButton
                            onClick={clearFilter}
                            aria-label="toggle password visibility"
                            edge="end"
                        >
                            <ClearOutlinedIcon />
                        </IconButton>
                        )}

                        <IconButton
                            onClick={onClick}
                            aria-label="toggle password visibility"
                            edge="end"
                        >
                            <FilterAltOutlinedIcon />
                        </IconButton>
                    </InputAdornment>
                }
                label="Password"
            />
        </FormControl>
    )
}