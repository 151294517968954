import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, InputLabel, FormControl, TextField, Box, Button, Typography, Checkbox, Select, MenuItem, Switch } from "@mui/material";
import {
    agentIcon, audioIcon, copyIcon, dragIcon, greenDeleteIcon, hangupIcon, listenIcon, pauseIcon, phoneIcon, playIcon, speakIcon, transIcon, undoIcon, voiceIcon, mouseactionIcon,
    scrapIcon,
    projectIcon,
    screendataIcon,
    screenshotIcon,
    calldataIcon,
    pauseTypeIcon,
    resetIcon,
    inresetIcon,
    intrash,
    scessors,
    clickIcon,
    enterTextIcon,
    findItemIcon,
    loadPageIcon,
    mouseOverIcon,
    shotIcon,
    switchFrameIcon,
    pauseItemIcon,
} from '../assets';
import { useDrag, useDrop } from 'react-dnd'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ISegment, ISegmentLanguage, ISegmentStep } from '../types/interfaces/segment.interface';
import { useSelector } from 'react-redux';
import { getEditSegment, getSelectedSegments } from '../store/selectors/segment.selector';
import { useAppDispatch } from '../store/hooks';
import { addNewSegment, removeSegment, setBatchSegments, setEditSegment, updateSegment, upsertLibrary } from '../store/resuders/segment.reducer';
import { getCurrentProject, getCurrentTestCase, getLanguages, selectBrowsers } from '../store/selectors/project.selector';
import { CHANNELS, DIGITAL_STEP_TYPE, RUN_STATUS, STEP_TYPE, TESTCASE_RUN_STATUS } from '../types/global.enum';
import { useFormik } from 'formik';
import { globalNotification, toggleAlert, toggleSpinner } from '../store/resuders/app.reducer';
import { ITestCase } from '../types/interfaces/testCase.interface';
import { getLastRunResult, getTestCaseById, getTranscriptionFile, hasMultiVariable, manualTestCaseRun, runDigitalTestCases, runStatusStream, runStatusStreamDigital, transcription, upsertDigitalTestCase, upsertTestCase } from '../services/testcase.service';
import { setCurrentTestCase } from '../store/resuders/project.reducer';
import { Modal } from '../ui';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { orderBy, uniqueId } from 'lodash';
import { copyTextToClipboard } from '../helpers/form.helper';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { findVariables, searchVariable } from '../services/grammar.service';
import { getVoiceApi } from '../services/config.service';

export const NewTestCase = ({ toggleSegmentEditor, data = null }: { toggleSegmentEditor: (close?: boolean, open?: boolean) => void, data?: any }) => {
    const { t } = useTranslation();

    const [removeSegments, setRemoveSegment] = React.useState<number[]>([]);
    const [varContents, setVarContents] = React.useState<any>({});
    const [runAll, setRunAll] = React.useState<boolean>(false);
    const segments = useSelector(getSelectedSegments);
    const [testCase, setTestCase] = useState<ITestCase | null>(null);
    const [triggerSave, setTriggerSave] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const languages = useSelector(getLanguages);
    const project = useSelector(getCurrentProject);
    const [addSegment, setAddSegment] = useState(false);
    const [lastRunResult, setLastRunResult] = useState<any>(null);
    const [runStatus, setRunStatus] = useState<TESTCASE_RUN_STATUS>(TESTCASE_RUN_STATUS.NONE);
    const currentTestCase = useSelector(getCurrentTestCase);
    const editMode = useSelector(getEditSegment);
    const run = useRef<ISegment[] | null>(null);
    const requestId = useRef<string>('');
    const [trans, setTrans] = useState<any>({});
    const [currentAudioId, setCurrentAudioId] = useState<any>(null);
    const [openReminder, setOpenReminder] = useState('')
    const [runParameter, setRunParameter] = useState('')
    const [multiValueVariableRestrict, setMultiValueVariableRestrict] = useState('')
    const [iterationCount, setIterationCount] = useState(0)
    const [audio, setAudio] = useState<any>(null);
    const [playingAudio, setPlayingAudio] = useState<boolean>(false);
    const [orderChanged, setOrderChanged] = useState<string>('');
    const [openSelectBrowser, setOpenSelectBrowser] = useState<string>('');
    const [browsersToRun, setBrowsersToRun] = useState<string[]>([]);
    const allIterationCount  = useRef<any>(null);
    const browsers = useSelector(selectBrowsers);

    const formik = useFormik({
        initialValues: {
            name: '',
            ivrPhoneNumber: '',
            callerId: '',
            remark: '',
            projectId: 0,
            testCaseWID: null,
            id: null,
            testCaseId: null,
            saveAsNew: false,
        },
        onSubmit: async values => {
            if(project?.data?.channelType === CHANNELS.VOICE) {
                if (!values || !values.name || !values.ivrPhoneNumber || !values.callerId) {
                    // Check if fields are empty/undefined
                    dispatch(toggleAlert("Please fill in all required fields."));
                    return;
                }
                // Check if fields are empty/whitespace
                if (!values.name.trim() || !values.ivrPhoneNumber.trim() || !values.callerId.trim()) {
                    dispatch(toggleAlert("Please fill in all required fields."));
                    return;
                }
            }

            dispatch(toggleSpinner(true));

            if(project?.data?.channelType === CHANNELS.DIGITAL) {

                const result = await upsertDigitalTestCase(segments, values.name, project?.testProjectID || 1, values?.saveAsNew ? null : values.testCaseId, false, values?.saveAsNew);
                dispatch(toggleSpinner(false));

                if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
                    dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))
                } else {
                    const { data } = await getTestCaseById(project?.testProjectID || 1, result?.data?.testCaseId || 0, project?.data?.channelType)(dispatch);
                    dispatch(setCurrentTestCase(data));
    
                    dispatch(globalNotification(values.testCaseId && !values?.saveAsNew ? 'The test case is updated.' : 'The new test case is created.'))
    
                }
                setTriggerSave(false);

                return;
            }

            const formData = {
                ...values,
                projectId: project?.testProjectID || 0,
                testCaseWID: values?.saveAsNew ? undefined : testCase?.testCaseWID,
                id: values?.saveAsNew ? undefined : testCase?.id,
                testCaseId: values?.saveAsNew ? undefined : testCase?.testCaseId,
                segments: (segments || []).map((s: ISegment, index: number) => ({
                    testCaseSegmentId: values?.saveAsNew ? 0 : s.testCaseSegmentId || 0,
                    segmentSeq: index,
                    testSegmentId: s.segmentLangs.find(sl => sl.languageCode.id === s.defaultLng)?.id,
                    languageCode: s.defaultLng,
                })),
            }

            const result = await upsertTestCase(project?.testProjectID, formData);

            dispatch(toggleSpinner(false));
            if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
                dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))
            } else {
                const testCase = result?.data?.data as ITestCase;
                const { data } = await getTestCaseById(testCase.projectId || 1, testCase.testCaseId || 0, project?.data?.channelType)(dispatch);
                dispatch(setCurrentTestCase(data));

                dispatch(globalNotification(formData.id || formData.testCaseId ? 'The test case is updated.' : 'The new test case is created.'))

            }
            setTriggerSave(false);
        },
    })

    const scrollToel = (index:number)=> {
        const el = document.getElementById(`segment_row_${index}`);

        if(el) {
            el.scrollIntoView({  block: "start" });
        }
    }

    const updateSegmentLanguage = (seg: ISegment) => (e: any) => {
        dispatch(updateSegment({
            ...seg,
            defaultLng: e.target.value,
        }));
        setTriggerSave(true);
    }

    const deleteTest = () => {
        dispatch(removeSegment(removeSegments));

        setRemoveSegment([]);
        setTriggerSave(true);
    }

    const undo = async () => {
        if (currentTestCase) {
            const { data } = await getTestCaseById(currentTestCase?.projectId || 1, currentTestCase?.testCaseId || 0, project?.data?.channelType)(dispatch);
            dispatch(setCurrentTestCase(data));
            setTriggerSave(false);
            setOrderChanged('');
        }
        setBrowsersToRun([]);
        scrollToel(0);
    }

    const handleAddSegment = () => {
        dispatch(setEditSegment(null));

        setAddSegment(!addSegment);
        toggleSegmentEditor();
    };

    const saveTestCase = async () => {
        setRunStatus(TESTCASE_RUN_STATUS.NONE);
        run.current = [];
        await formik.submitForm();
    }

    const handleSave = async () => {
        formik.setFieldValue('saveAsNew', false);
        setOrderChanged('');
        await saveTestCase();
    }

    const handleSaveAsNew = async () => {
        formik.setFieldValue('saveAsNew', true);
        await saveTestCase();
    }

    const browserSelected = (e:any)=> {
        if(e.target.checked) {
            setBrowsersToRun(browsersToRun.concat(e.target.value))
        } else {
            setBrowsersToRun(browsersToRun.filter(b=> b!== e.target.value))
        }
    }

    const runDigital = async ()=> {
        setRunParameter('');
        if (browsersToRun.length > 0) {
           const runs =  await runDigitalTestCases(testCase?.projectId || 1, [{id:testCase?.id || testCase?.testCaseId || 0, runAll:allIterationCount.current ? true:false}], browsersToRun.join(','));
           
           requestId.current = runs[0]?.data?.data;
           setRunStatus(TESTCASE_RUN_STATUS.STARTED);
           run.current = segments.map((seg, index) => (
            {
                ...seg,
                segmentLangs: seg.segmentLangs.map(sl => ({
                    ...sl,
                    steps: sl.steps?.map(stp => ({
                        ...stp,
                        testResult: null,
                    })),
                })),
                runStatus: index === 0 ? RUN_STATUS.STARTED : RUN_STATUS.NONE
            }
        )) as ISegment[];

        dispatch(setBatchSegments(run.current));
           const stream = runStatusStreamDigital(requestId.current);

           stream.onerror = (e: any) => {
                console.log('stream error', e);
                stream.close();
            };

           stream.onmessage = async (e:any)=> {
            const data = JSON.parse(e.data);

                    run.current = (run.current || []).map((seg,index) => {
                            const currSegment = data.segments[index];

                            if (currSegment?.testResult?.status) {
                                console.log('Stream Segment', currSegment)
                                scrollToel(index);
                                return {
                                    ...seg,
                                    testResult: currSegment?.testResult,
                                    segmentLangs: seg.segmentLangs.reduce((lngs: ISegmentLanguage[], currLngs: ISegmentLanguage) => {
                                        lngs.push({
                                            ...currLngs,
                                            steps: currLngs.steps?.reduce((rlt: ISegmentStep[], currStep: ISegmentStep) => {
                                                const res = currSegment?.steps?.find((stp: ISegmentStep) => stp.testStepId === currStep.testStepId);

                                                if (res) {
                                                    rlt.push({
                                                        ...currStep,
                                                        testResult: {
                                                            ...res.testResult,
                                                            messageToPlay: res.messageToPlayOriginal !== res.messageToPlay ? '@'+res.messageToPlayOriginal?.replace(/@/g,'') + ' = ' + res.messageToPlay+'@' :res.messageToPlay,
                                                        },
                                                    });
                                                } else {
                                                    rlt.push(currStep);
                                                }
                                                return rlt;
                                            }, []),
                                        });
                                        
                                        return lngs;
                                    }, []),
                                    runStatus: currSegment?.testResult?.status,
                                };
                            }
                        

                        return {
                            ...seg,
                        }
                    })

                    dispatch(setBatchSegments(run.current));
   
                    if (run.current.some(seg => seg.runStatus === RUN_STATUS.FAILED)) {
                        setRunStatus(TESTCASE_RUN_STATUS.STOPPED);
                        await lastRun();
                    } else if (run.current.every(seg => seg.runStatus === RUN_STATUS.SUCCESS)) {
                        setRunStatus(TESTCASE_RUN_STATUS.FINISHED);
                        await lastRun();
                    }
           }

            setOpenSelectBrowser('');
        }
    }

    const runTestCaseWithVariation = async ()=> {
        if([TESTCASE_RUN_STATUS.FINISHED, TESTCASE_RUN_STATUS.STOPPED].includes(runStatus)) {
            await runTestCase(true)();
        } else {
            const {iterationCount, numMultiValueVariables} = await hasMultiVariable(testCase?.projectId || 1, testCase?.id || testCase?.testCaseId || 0, project?.data?.channelType);
            
            if(numMultiValueVariables>1) {
                setMultiValueVariableRestrict(uniqueId());
            } else if(iterationCount > 1) {
                setIterationCount(iterationCount)
                setRunParameter(uniqueId());
            } else {

                if(project?.data?.channelType === CHANNELS.DIGITAL) {
                    if (runStatus !== TESTCASE_RUN_STATUS.NONE) {
                        setRunStatus(TESTCASE_RUN_STATUS.NONE);
                        await undo();
                        return;
                    }
                    setOpenSelectBrowser(uniqueId());
                    return;
                }

                await runTestCase(true)();
            }
        }
        
    }

    const setItCount = (e:any)=> {
        allIterationCount.current = (e.target.checked);
        setRunAll(e.target.checked);
    }

    const runTestCase = (start: boolean) => async () => {
        setRunParameter('')
        if (start) {
            if (runStatus !== TESTCASE_RUN_STATUS.NONE) {
                setRunStatus(TESTCASE_RUN_STATUS.NONE);
                await undo();
                return;
            }
            setLastRunResult(null);
            dispatch(toggleSpinner(true));
            dispatch(setEditSegment(null));
            const { data } = await manualTestCaseRun(testCase?.id || testCase?.testCaseId || 0, testCase?.projectId || 1, allIterationCount.current ? 'all':'default');
            dispatch(toggleSpinner(false));

            if (data?.data && data?.code === "success") {
                requestId.current = data.data;
                setRunStatus(TESTCASE_RUN_STATUS.STARTED);

                run.current = segments.map((seg, index) => (
                    {
                        ...seg,
                        segmentLangs: seg.segmentLangs.map(sl => ({
                            ...sl,
                            steps: sl.steps?.map(stp => ({
                                ...stp,
                                testResult: null,
                            })),
                        })),
                        runStatus: index === 0 ? RUN_STATUS.STARTED : RUN_STATUS.NONE
                    }
                )) as ISegment[];

                dispatch(setBatchSegments(run.current));

                const stream = runStatusStream(requestId.current);

                stream.onerror = (e: any) => {
                    console.log('stream error', e);
                    
                    if(run.current?.[0]?.runStatus === RUN_STATUS.STARTED) {
                        run.current = (run.current || []).reduce((res:ISegment[], seg:ISegment)=> {
                            res.push({
                                ...seg,
                                runStatus: RUN_STATUS.FAILED,
                            })
                            return res;
                        },[]);

                        dispatch(setBatchSegments(run.current));
                    }
                    stream.close();
                };

                stream.onmessage = async (e: any) => {
                    const data = JSON.parse(e.data);

                    run.current = (run.current || []).map((seg,iii) => {
                        const { id } = seg?.segmentLangs?.find(s => s.languageCode.id === seg.defaultLng) || {};

                        if (id) {
                            const currSegment = data.segments.find((s: any) => +s.id === +id && +s.segmentSeq === +(seg.segmentSeq || 0));

                            if (currSegment?.testResult?.status) {
                                console.log('Stream Segment', currSegment)
                                scrollToel(iii);
                                return {
                                    ...seg,
                                    testResult: currSegment?.testResult,
                                    segmentLangs: seg.segmentLangs.reduce((lngs: ISegmentLanguage[], currLngs: ISegmentLanguage) => {
                                        if (currLngs.languageCode.id === seg.defaultLng) {
                                            lngs.push({
                                                ...currLngs,
                                                steps: currLngs.steps?.reduce((rlt: ISegmentStep[], currStep: ISegmentStep) => {
                                                    const res = currSegment?.steps?.find((stp: ISegmentStep) => stp.testStepId === currStep.testStepId);

                                                    if (res) {
                                                        rlt.push({
                                                            ...currStep,
                                                            testResult: {
                                                                ...res.testResult,
                                                                messageToPlay: res.messageToPlayOriginal !== res.messageToPlay ? '@'+res.messageToPlayOriginal?.replace(/@/g,'') + ' = ' + res.messageToPlay+'@' :res.messageToPlay,
                                                            },
                                                        });
                                                    } else {
                                                        rlt.push(currStep);
                                                    }
                                                    return rlt;
                                                }, []),
                                            });
                                        } else {
                                            lngs.push(currLngs);
                                        }
                                        return lngs;
                                    }, []),
                                    runStatus: currSegment?.testResult?.status,
                                };
                            }
                        }

                        return {
                            ...seg,
                        }
                    })

                    dispatch(setBatchSegments(run.current));

                    if (run.current.some(seg => seg.runStatus === RUN_STATUS.FAILED)) {
                        setRunStatus(TESTCASE_RUN_STATUS.STOPPED);
                        await lastRun();
                    } else if (run.current.every(seg => seg.runStatus !== RUN_STATUS.STARTED)) {
                        setRunStatus(TESTCASE_RUN_STATUS.FINISHED);
                        await lastRun();
                    }
                }
            } else {
                return;
            }

        }
    }


    const reorderSegments = (item: any, targetIndex: number) => {
        if(item.index === undefined) {
            //If segment is dropped from Segment tab
            dispatch(addNewSegment(item));
            let result = [...segments];
            result.splice(targetIndex, 0, item)
            const seg = result.reduce((res: ISegment[], curr: ISegment, index: number) => {
                res.push({
                    ...curr,
                    segmentSeq: index + 1,
                });
                return res;
            }, []);
            dispatch(setBatchSegments(orderBy(seg, 'segmentSeq')));
            setTriggerSave(true);
            setOrderChanged(uniqueId());
        } else if (item.index !== targetIndex) {
            let result = [...segments];
            const temp = segments[item.index];
            result.splice(item.index, 1);
            result.splice(targetIndex, 0, temp);

            const seg = result.reduce((res: ISegment[], curr: ISegment, index: number) => {
                res.push({
                    ...curr,
                    id:`${curr.id}`,
                    segmentSeq: index + 1,
                });
                return res;
            }, []);

            dispatch(setBatchSegments(orderBy(seg, 'segmentSeq')));
            setTriggerSave(true);
            setOrderChanged(uniqueId());
        }
    }

    const segmentSelectionChanged = (index: number) => (e: any) => {
        if (e.target.checked) {
            setRemoveSegment(removeSegments.concat(index))
        } else {
            setRemoveSegment(removeSegments.filter(c => c !== index))

        }
    }

    const editSegment = (seg: ISegment, lang?: ISegmentLanguage, step?: ISegmentStep, sub?: string) => () => {
        dispatch(upsertLibrary(''));
        dispatch(setEditSegment({
            segment: seg,
            step,
            lang,
            sub,
        }))

        setAddSegment(true);
        toggleSegmentEditor(false, true);
    }

    useEffect(() => {
        if (project) {
            formik.setFieldValue('projectId', project.testProjectID);
        }
    }, [project]);

    const handleLastRunResult = () => {
        window.open(`/projects/${currentTestCase.projectId}/testresults/${lastRunResult?.testCaseId || currentTestCase?.testCaseId}/${lastRunResult?.testResult?.id}`)
    };


    const lastRun = async () => {
        const { data } = await getLastRunResult(currentTestCase.projectId, currentTestCase.testCaseId, project?.data?.channelType);
        if (data?.data) {
            setLastRunResult(data.data);
        }
    }

    useEffect(() => {
        setTestCase(currentTestCase);
        formik.setValues({
            ...(currentTestCase || {}),
        })

        if (currentTestCase) {
            lastRun();
        } else {
            setLastRunResult(null);
        }
    }, [currentTestCase]);


    const getRunStatusStyle = (status?: RUN_STATUS) => {

        switch (status) {
            case RUN_STATUS.STARTED: return 'run-status';
            case RUN_STATUS.SUCCESS: return 'run-status-pass';
            case RUN_STATUS.FAILED: return 'run-status-fail';
        }
        return runStatus === TESTCASE_RUN_STATUS.NONE ? '' : 'all-run';
    }

    const downloadScreenshot = (url: string) => () => {
        window.open(url);
    }

    const playAudioFile = (url: string) => async () => {
        setAudio(new Audio(`${getVoiceApi().replace('/api/voice/v1', '')}/${url}`));
        setCurrentAudioId(url)
    }

    const playTrans = (stepId?: number) => async () => {

        if (currentAudioId !== stepId) {
            setAudio(new Audio(`${getVoiceApi()}/results/${stepId}/audio`));
        } else if (!playingAudio && audio) {
            audio.play();
            setPlayingAudio(true);
        } else if (playingAudio) {
            setPlayingAudio(false);
            audio.pause();
        } else {
            setAudio(new Audio(`${getVoiceApi()}/results/${stepId}/audio`));
        }

        setCurrentAudioId(stepId);
    }

    const TranslateStepTypes = ({ seg = null as ISegment | null, lang = null as ISegmentLanguage | null, step = null as ISegmentStep | null }) => {

        if (!seg || !lang || !step) return null;
        let icon = hangupIcon;
        let text = '';

        switch (step?.stepType) {
            case STEP_TYPE.TRANSFER_CALL_TO_AGENT: { text = 'Transfer to Agent'; icon = agentIcon; break; }
            case STEP_TYPE.SCREEN_SHOT: { text = 'Screen Shot'; icon = screenshotIcon; break; }
            case STEP_TYPE.GET_CALL_DATA: { text = 'Get Call Data'; icon = calldataIcon; break; }
            case STEP_TYPE.SCREENSCRAPE_DATA: { text = 'Get Screen Data'; icon = scrapIcon; break; }
            case STEP_TYPE.MOUSE_ACTION: { text = 'Mouse Action'; icon = mouseactionIcon; break; }
            case STEP_TYPE.ENTER_SCREEN_DATA: { text = 'Enter Screen Data'; icon = screendataIcon; break; }
            case STEP_TYPE.HANGUP: { text = 'Hang Up'; break; }
        }

        return (
            <div className='speech hang'>
                <div className='hangup' onClick={editSegment(seg, lang, step)}>
                    <img src={icon} alt="" />{text}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (audio) {

            audio.addEventListener('ended', () => {
                setPlayingAudio(false);
                setAudio(null);
            });

            setPlayingAudio(true);
            audio.play();
        }

    }, [audio])

    const getFailedListenFile = (seg: ISegment, step: ISegmentStep, lang: ISegmentLanguage) => async () => {
        if (seg?.testResult?.id && step.testResult?.id) {
            if (!trans[`${seg?.testResult?.id}-${step.testResult?.id}`]) {
                const { data } = await transcription(seg?.testResult?.id, step.testResult?.id, seg?.defaultLng || 'english');

                if (data?.code === 'success') {
                    setTrans({
                        ...trans,
                        [`${seg?.testResult?.id}-${step.testResult?.id}`]: data?.data?.transcript,
                    })
                } else {

                }
            } else {

                copyTextToClipboard(trans[`${seg?.testResult?.id}-${step.testResult?.id}`]);

                if (step.stepType === STEP_TYPE.LISTEN) {
                    setOpenReminder(uniqueId());
                }

                editSegment(seg, lang, {
                    ...step,
                    stepType: step.stepType === STEP_TYPE.LISTEN_ANYTHING ? STEP_TYPE.LISTEN : step.stepType,
                    prompt: step.stepType === STEP_TYPE.LISTEN_ANYTHING ? trans[`${seg?.testResult?.id}-${step.testResult?.id}`] : step.prompt,
                })();

                if (step.stepType === STEP_TYPE.LISTEN_ANYTHING) {

                    setTimeout(() => {
                        editSegment(seg, lang, {
                            ...step,
                            stepType: step.stepType === STEP_TYPE.LISTEN_ANYTHING ? STEP_TYPE.LISTEN : step.stepType,
                            prompt: step.stepType === STEP_TYPE.LISTEN_ANYTHING ? trans[`${seg?.testResult?.id}-${step.testResult?.id}`] : step.prompt,
                        })();
                    }, 300);

                }


            }

        }
    }

    const getTransIcon = (segId?: number, stepId?: number) => {
        return !trans[`${segId}-${stepId}`] ? transIcon : copyIcon;
    }

    const getTranscription = (segId?: number, stepId?: number) => {
        return trans[`${segId}-${stepId}`] || '';
    }

    const langChanged = () => {
        let changed = false;
        currentTestCase.segments?.forEach((seg: any) => {
            const f = segments?.find(s => s.segmentSeq === seg.segmentSeq);

            if (!f) {
                changed = true;
                return;
            } else if (f && f.defaultLng !== seg.languageCode) {
                changed = true;
                return;
            }
        });

        return changed;
    }

    useEffect(() => {
        if (orderChanged) {
            setTriggerSave(true);
            return;
        }
        if (!currentTestCase) {
            if (formik.values.name && segments?.length) {
                setTriggerSave(true);
            } else {
                setTriggerSave(false);
            }
        } else {
            if (currentTestCase.name !== formik.values?.name ||
                currentTestCase.ivrPhoneNumber !== formik.values?.ivrPhoneNumber ||
                currentTestCase.callerId !== formik.values?.callerId ||
                currentTestCase.segments?.length !== segments?.length ||
                langChanged()) {
                setTriggerSave(true);
            } else {
                setTriggerSave(false);
            }
        }
    }, [currentTestCase, formik.values, segments, orderChanged]);


    const SegmentItem = ({ ...props }) => {
        const [{ isOver }, drops] = useDrop(() => ({
            accept: 'SEGMENT-ROWS',
            drop: (item: any) => reorderSegments(item, props.data.index),
            collect: (monitor: any) => ({
                isOver: !!monitor.isOver(),
            }),
        }))

        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'SEGMENT-ROWS',
            item: {
                ...props.data,
                order: true,
            },
            collect: (monitor: any) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }))

        const ref = useRef<any>(null);
        drag(drops(ref))

        return (<div style={isOver ? { backgroundColor: '#CECECE' } : {}} ref={ref} data-handler-id={props.data.lang.id} className="relative show-drag pointer">
            <img src={dragIcon} alt="" className={"drag-icon left--5 none"} />

            {props.children}
        </div>);
    }

    const addSegmentFromLibrary = (item: ISegment | any) => {

        dispatch(addNewSegment(item));
       
        setTriggerSave(true);
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'SEGMENT-ROWS',
        drop: addSegmentFromLibrary,
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
        hover: (item: any, monitor) => {
            //console.log('hover', monitor)
        }
    }), [segments])

    const loadVars = (name:string)=>async()=> {
        if(varContents?.[name]) return;

        const d = await searchVariable(testCase?.projectId || 1, name.replace(/@/g, ''));
        setVarContents({
            ...varContents,
            [name]:d,
    })
    }
   
    const renderDigitalStep  =(step:ISegmentStep)=> {
        const map = {
            [DIGITAL_STEP_TYPE.CLICK]:clickIcon,
            [DIGITAL_STEP_TYPE.ENTER_TEXT]:enterTextIcon,
            [DIGITAL_STEP_TYPE.FIND_ITEM]:findItemIcon,
            [DIGITAL_STEP_TYPE.LOAD_PAGE]:loadPageIcon,
            [DIGITAL_STEP_TYPE.MOUSE_OVER]:mouseOverIcon,
            [DIGITAL_STEP_TYPE.PAUSE]:pauseItemIcon,
            [DIGITAL_STEP_TYPE.SCREENSHOT]:shotIcon,
            [DIGITAL_STEP_TYPE.SWITCH_FRAME]:switchFrameIcon,
        } as any;

        return <img src={map[step.actionType || '']} alt="" />;
    }

    return (
        <Card className="h-100-p">
            <CardContent>
                <div className="test-case-container">
                    <Box className="test-case-input">
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="test-case-name"></InputLabel>
                            <TextField
                                label={t("Test Case Name")}
                                id="name"
                                name="name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                            />
                        </FormControl>
                    </Box>
                    <Box className="test-case-input">
                        <img className='pointer' src={!removeSegments?.length ? intrash : greenDeleteIcon} onClick={deleteTest} />
                        <img className='pointer' src={!currentTestCase || !triggerSave ? inresetIcon : resetIcon} onClick={undo} />
                        
                        {project?.data?.channelType === CHANNELS.VOICE && 
                            <>
                                <FormControl sx={{ width: "40%" }}>
                                    <InputLabel id="test-case-clid"></InputLabel>
                                    <TextField
                                        id="callerId"
                                        name="callerId"
                                        value={formik.values?.callerId}
                                        onChange={formik.handleChange}
                                        label={t("CLID")}
                                    />
                                </FormControl>
                                <FormControl sx={{ width: "50%" }}>
                                    <InputLabel id="test-case-number"></InputLabel>
                                    <TextField
                                        id="ivrPhoneNumber"
                                        name="ivrPhoneNumber"
                                        value={formik.values?.ivrPhoneNumber}
                                        onChange={formik.handleChange}
                                        label={t("IVR Tel. Number")}
                                    />
                                </FormControl>
                            </>
                        }
                    </Box>

                    <Box>
                        <div className="test-case-display">
                        {!segments?.length ? (
                            <div className='no-segment' ref={drop}>
                                <div className='db w-100 pt-40'><AddCircleOutlineIcon onClick={handleAddSegment} style={{ fill: '#7D6C6C', fontSize: '51px' }} /></div>
                                <div className='db w-100 pt-15'><Typography variant='caption'>{t('Add a segment')}</Typography></div>
                            </div>
                        ) : (
                            <>
                                {project?.data?.channelType === CHANNELS.DIGITAL && segments.map((seg: ISegment, indx: number)=>(
                                    <SegmentItem key={`seg_list_render_${indx}`} data={{ seg, lang:'', index: indx }}>
                                        <div id={`segment_row_${indx}`} className={'db seg-list ' + (indx === segments.length - 1 && runStatus !== TESTCASE_RUN_STATUS.FINISHED ? ' mb-80' : ' mb-16')} key={`segment_${indx}`}>
                                            <div className='flex align-center'>
                                                        {runStatus === TESTCASE_RUN_STATUS.NONE ? (
                                                            <Checkbox checked={removeSegments.includes(indx)}
                                                                onChange={segmentSelectionChanged(indx)} />) : (<div className={'circle-' + getRunStatusStyle(seg.runStatus)}>&nbsp;</div>)}
                                                        <span onClick={editSegment(seg)} className='segment-name-hover'>{t('Segment')} {indx + 1}: {seg.name}</span></div>
                                                    <div className={'progress-bar ' + getRunStatusStyle(seg.runStatus) + (editMode?.segment?.id == seg.id ? ' bar-edit' : '')}>&nbsp;</div>
                                                    
                                                    {(seg?.segmentLangs?.[0]?.steps || []).map((step: ISegmentStep, i: number) => (
                                                    <div key={`step_${i}`}>
                                                        <div className='speech hang flex-column'>
                                                            <div className='pause digital-steps' onClick={editSegment(seg, seg.segmentLangs[0], step)}>
                                                                <div>{renderDigitalStep(step)}</div>
                                                                <div>{t(step.actionType || '')}</div>
                                                            </div>

                                                            {step?.criteria?.map((c:any)=>(
                                                                <div className='digital-step-criteria' onClick={editSegment(seg, seg.segmentLangs[0], step)}>
                                                                   {'>> '} [{t(c.locatorType)}]
                                                                   <p>{c.attributeName || c?.elementName} [{c.operation}] [{c.attributeValue || c.displayText }]</p>
                                                                </div>
                                                            ))}

                                                            {step?.criteria2?.map((c:any)=>(
                                                                <div className='digital-step-criteria' onClick={editSegment(seg, seg.segmentLangs[0], step)}>
                                                                   {'>> '} [{t(c.locatorType)}]
                                                                   <p>{c.attributeName || c?.elementName} [{c.operation}] [{c.attributeValue || c.displayText}]</p>
                                                                </div>
                                                            ))}
                                                           
                                                        </div>
                                                    </div>
                                                    ))}
                                        </div>
                                    </SegmentItem>
                                ))}

                                {project?.data?.channelType === CHANNELS.VOICE && segments.map((seg: ISegment, indx: number) => (
                                    seg.segmentLangs.filter(l => l.languageCode.id === seg.defaultLng)
                                        .map((lang: ISegmentLanguage, index: number) => (
                                            <SegmentItem key={`seg_list_render_${seg.id}`} data={{ seg, lang, index: indx }}>
                                                <div id={`segment_row_${indx}`} className={'db seg-list ' + (indx === segments.length - 1 && runStatus !== TESTCASE_RUN_STATUS.FINISHED ? ' mb-80' : ' mb-16')} key={`segment_${index}`}>
                                                    <div className='flex align-center'>
                                                        {runStatus === TESTCASE_RUN_STATUS.NONE ? (
                                                            <Checkbox checked={removeSegments.includes(indx)}
                                                                onChange={segmentSelectionChanged(indx)} />) : (<div className={'circle-' + getRunStatusStyle(seg.runStatus)}>&nbsp;</div>)}
                                                        <span onClick={editSegment(seg, lang)} className='segment-name-hover'>{t('Segment')} {indx + 1}: {seg.name}</span></div>
                                                    <div className={'progress-bar ' + getRunStatusStyle(seg.runStatus) + (editMode?.segment?.id == seg.id ? ' bar-edit' : '')}>&nbsp;</div>

                                                    {runStatus === TESTCASE_RUN_STATUS.NONE && (
                                                        <div className='db segment-language'>
                                                            <Select
                                                                value={seg.defaultLng || seg.segmentLangs?.[0]?.languageCode?.id}
                                                                label="Language"
                                                                name="language"
                                                                size='small'
                                                                className='min-w-150'
                                                                onChange={updateSegmentLanguage(seg)}
                                                            >
                                                                {seg.segmentLangs.filter(l => l.steps?.length).map(lng => (
                                                                    <MenuItem key={`opt_lng_${lng.id}`} value={lng?.languageCode?.id}>{languages.find((l: any) => l.id === (lng?.languageCode?.id || languages[0].id))?.name || ''}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}

                                                    {(lang.steps || []).map((step: ISegmentStep, i: number) => (
                                                        <div key={`step_${i}`} >
                                                            {[STEP_TYPE.RECORD_VOICE, STEP_TYPE.LISTEN].includes(step.stepType || STEP_TYPE.NONE) ? (
                                                                <div onClick={editSegment(seg, lang, step)} >
                                                                    <div className={'prompt flex' + (step.listenSubStep ? ' gap-8' : '') + (step.stepType === STEP_TYPE.RECORD_VOICE ? ' record-voice' : '')}>
                                                                        {step.listenSubStep?.some(c => c.name) ? (
                                                                            <div className='branching'>{step?.testResult?.voice?.listenSubstep ? `${step.stepSeq}${step?.testResult?.voice?.listenSubstep}` : step.stepSeq + 'A'}
                                                                                <div>
                                                                                    <img src={step.stepType === STEP_TYPE.RECORD_VOICE ? voiceIcon : listenIcon} alt="" className="absolute top-15 pl-20" />
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <img src={step.stepType === STEP_TYPE.RECORD_VOICE ? voiceIcon : listenIcon} alt="" className="absolute top-15" />
                                                                        )}

                                                                        <div className='f-w-500 pl-30'>
                                                                            {t(step.stepType?.replace('RECORD_VOICE', 'Record Voice'))}
                                                                            <span className={'f-w-400 db ' + (step?.testResult?.status === RUN_STATUS.SUCCESS ? ' step-passed' : '')}
                                                                            dangerouslySetInnerHTML={{ __html: `${step.testResult && step?.testResult?.voice?.listenSubstep && step?.testResult?.voice?.listenSubstep !== 'A' ? step.listenSubStep?.find(l => l.name === step?.testResult?.voice?.listenSubstep)?.prompt :
                                                                            step.testResult && step?.testResult?.voice?.utterance ? step.testResult?.voice?.utterance : (step?.libraryItemIds?.length ?  step?.libraryItemIds?.map((s:any)=> s.textContent).join("<br/>"):step.prompt)}` }}>
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                [STEP_TYPE.HANGUP, STEP_TYPE.ENTER_SCREEN_DATA, STEP_TYPE.GET_CALL_DATA, STEP_TYPE.MOUSE_ACTION,
                                                                STEP_TYPE.SCREENSCRAPE_DATA, STEP_TYPE.SCREEN_SHOT, STEP_TYPE.TRANSFER_CALL_TO_AGENT].includes(step.stepType || STEP_TYPE.NONE) ? (
                                                                    <>
                                                                        <TranslateStepTypes seg={seg} lang={lang} step={step} />
                                                                    </>
                                                                ) : (
                                                                    step.stepType === STEP_TYPE.PAUSE ? (
                                                                        <div className='speech hang'>
                                                                            <div className='pause' onClick={editSegment(seg, lang, step)}>
                                                                                <img src={pauseTypeIcon} alt="" />Pause
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        step.stepType === STEP_TYPE.LISTEN_ANYTHING ? (

                                                                            <div onClick={editSegment(seg, lang, step)} >
                                                                                <div className={'prompt flex listen-anything'}>
                                                                                    <img src={phoneIcon} alt="" className="absolute top-15" />
                                                                                    <div className='f-w-500 pl-30'>
                                                                                        Listen Anything
                                                                                        <span className='f-w-400 db'>{step.prompt}</span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        ) : (
                                                                            <div className='speech' onClick={editSegment(seg, lang, step)}>
                                                                                <div>
                                                                                    <div className={'prompt' + (step.stepType === STEP_TYPE.PLAY_AUDIO ? ' record-voice' : '')}>
                                                                                        <img src={step.stepType === STEP_TYPE.PLAY_AUDIO ? audioIcon : speakIcon} alt="" className="absolute top-15" />
                                                                                        <div className='f-w-500 pl-30'>
                                                                                            {t(step.stepType === STEP_TYPE.PLAY_AUDIO ? 'Play Audio' : step.stepType)}<br />
                                                                                            
                                                                                            {findVariables(step.messageToPlay || '').map((msg,ind)=>(
                                                                                                <span onMouseEnter={loadVars(msg.msg)} title={varContents?.[msg.msg] || ''} key={`row_message_${ind}`} className='f-w-400 db'>
                                                                                                    {step?.testResult?.messageToPlay ? step?.testResult?.messageToPlay:msg.msg}
                                                                                                </span>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                )
                                                            )}

                                                            {step?.listenSubStep && (
                                                                <div className='flex segment-subs'>
                                                                    {step?.testResult?.voice?.listenSubstep && (
                                                                        <div onClick={editSegment(seg, lang, step, 'A')} className={'branching ' + (runStatus !== TESTCASE_RUN_STATUS.NONE ? ' running' : '')}>
                                                                            {step.stepSeq}A
                                                                        </div>
                                                                    )}
                                                                    {step.listenSubStep.filter(ss => !step.testResult || ss.name !== step?.testResult?.voice?.listenSubstep).map((sub: ISegmentStep) => (
                                                                        <div onClick={editSegment(seg, lang, step, sub.name)} className={'branching ' + (runStatus !== TESTCASE_RUN_STATUS.NONE ? ' running' : '')} key={`sub_render_${sub.name}`}>
                                                                            {step.stepSeq}{sub.name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            {[STEP_TYPE.LISTEN].includes(step.stepType || STEP_TYPE.NONE) && (
                                                                step?.testResult?.status === RUN_STATUS.SUCCESS ? (
                                                                    <div className="latency">Pass Latency: {((step?.testResult?.voice?.latency || 0) / 1000).toFixed(2)} s</div>
                                                                ) : (
                                                                    step?.testResult?.status === RUN_STATUS.FAILED && (
                                                                        <>
                                                                            {getTranscription(seg.testResult?.id, step.testResult?.id) && (
                                                                                <div className='prompt flex no-match'>
                                                                                    [Transcript] {getTranscription(seg.testResult?.id, step.testResult?.id)}
                                                                                </div>
                                                                            )}
                                                                            <div className="latency fail-color flex-center gap-8">
                                                                                <div>No Match</div>
                                                                                <img className='pl-20 pointer' src={currentAudioId === step.testResult?.id && playingAudio ? pauseIcon : playIcon} onClick={playTrans(step.testResult?.id)} alt="" />
                                                                                <img onClick={getFailedListenFile(seg, step, lang)} src={getTransIcon(seg.testResult?.id, step.testResult?.id)} alt="" className='pointer' />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                )
                                                            )}
                                                            {step?.testResult?.voice?.agentResponse?.screenshot && (
                                                                <div className="agentResponse" onClick={downloadScreenshot(step?.testResult?.voice?.agentResponse?.screenshot)}>
                                                                    <img src={scessors} />
                                                                </div>
                                                            )}

                                                            {[STEP_TYPE.LISTEN_ANYTHING].includes(step.stepType || STEP_TYPE.NONE) && step?.testResult?.status === RUN_STATUS.SUCCESS && (
                                                                <>
                                                                    {getTranscription(seg.testResult?.id, step.testResult?.id) && (
                                                                        <div className='prompt flex no-match'>
                                                                            [Transcript] {getTranscription(seg.testResult?.id, step.testResult?.id)}
                                                                        </div>
                                                                    )}
                                                                    <div className="latency fail-color flex-center gap-8 pl-0">
                                                                        <div className="latency">Pass Latency: {((step?.testResult?.voice?.latency || 0) / 1000).toFixed(2)} s</div>
                                                                        <img className='pl-20 pointer' src={currentAudioId === step.testResult?.id && playingAudio ? pauseIcon : playIcon} onClick={playTrans(step.testResult?.id)} alt="" />
                                                                        <img onClick={getFailedListenFile(seg, step, lang)} src={getTransIcon(seg.testResult?.id, step.testResult?.id)} alt="" className='pointer' />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {[STEP_TYPE.RECORD_VOICE].includes(step.stepType || STEP_TYPE.NONE) && step?.testResult?.status === RUN_STATUS.SUCCESS && (
                                                                <div className="latency fail-color flex-center gap-8 pl-0">
                                                                    <div className="latency">Pass Latency: {((step?.testResult?.voice?.latency || 0) / 1000).toFixed(2)} s</div>
                                                                    <img className='pl-20 pointer' src={currentAudioId === step.testResult?.attachmentUrl && playingAudio ? pauseIcon : playIcon} onClick={playAudioFile(step.testResult?.attachmentUrl || '')} alt="" />
                                                                </div>
                                                            )}

                                                        </div>
                                                    ))}

                                                </div>
                                                {seg.runStatus === RUN_STATUS.FAILED && (
                                                    <div className={'db seg-list mb-80 result-circle'}>
                                                        <div className='end failed'>STOP</div> <span>Failed</span>
                                                    </div>
                                                )}
                                            </SegmentItem>
                                        ))

                                ))}

                                {runStatus === TESTCASE_RUN_STATUS.FINISHED && (
                                    <div className='flex'>
                                        <div className={'db seg-list mb-80 end'}>
                                            END
                                        </div>
                                        <div className='passed-text'>PASS</div>

                                        {runStatus === TESTCASE_RUN_STATUS.FINISHED && project?.data?.channelType === CHANNELS.VOICE && (
                                            <div className='mos'>
                                                {!lastRunResult?.testResult?.voice?.mos ? (
                                                    <p className='calc-mos'>Calculating MOS</p>
                                                ) : (
                                                    <>
                                                        MOS {lastRunResult?.testResult?.voice?.mos}
                                                    </>
                                                )}

                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        </div>
                        {segments?.length > 0 && (
                            <div className='no-segment-bottom' ref={drop}>
                                <div className='db w-100 pt-40'><AddCircleOutlineIcon onClick={handleAddSegment} style={{ fill: '#7D6C6C', fontSize: '51px' }} /></div>
                                <div className='db w-100 pt-15'><Typography variant='caption'>{t('Add a segment')}</Typography></div>
                            </div>
                        )}
                    </Box>
                    <Box className="segment-editor-save-buttons">
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', paddingTop: '21px', flexFlow: 'wrap' }}>
                            <Button
                                variant={'contained'}
                                color={!triggerSave ? "secondary" : "primary"}
                                className='max-h-30'
                                onClick={handleSave}
                                disabled={!triggerSave}
                            >
                                {t('Save')}
                            </Button>

                            {testCase?.name && (
                                <Button
                                variant={'contained'}
                                color={!triggerSave ? "secondary" : "primary"}
                                className='max-h-30'
                                onClick={handleSaveAsNew}
                                disabled={!triggerSave}
                                >
                                    {t('Save As New')}
                                </Button>
                            )}
                            

                            {testCase?.name && (
                                <Button
                                    variant={'contained'}
                                    color={!(triggerSave && project?.data?.channelType === CHANNELS.VOICE) &&  segments?.length && [TESTCASE_RUN_STATUS.FINISHED, TESTCASE_RUN_STATUS.NONE, TESTCASE_RUN_STATUS.STOPPED].includes(runStatus) ? "primary" : "secondary"}
                                    className='max-h-30'
                                    onClick={runTestCaseWithVariation}
                                    disabled={(triggerSave && project?.data?.channelType === CHANNELS.VOICE) || !segments?.length || ![TESTCASE_RUN_STATUS.FINISHED, TESTCASE_RUN_STATUS.NONE, TESTCASE_RUN_STATUS.STOPPED].includes(runStatus)}
                                >
                                    {t(runStatus === TESTCASE_RUN_STATUS.STARTED ? 'Running' : [TESTCASE_RUN_STATUS.FINISHED, TESTCASE_RUN_STATUS.STOPPED].includes(runStatus) ? 'Run Complete' : 'Run')}
                                </Button>
                            )}

                            <Button
                                variant={"contained"}
                                color={lastRunResult && runStatus === TESTCASE_RUN_STATUS.NONE ? "primary" : "secondary"}
                                className='max-h-30'
                                onClick={handleLastRunResult}
                                disabled={!lastRunResult || runStatus !== TESTCASE_RUN_STATUS.NONE}
                            >
                                {t('Detailed Run Result')}
                            </Button>
                        </div>
                    </Box>
                </div>
            </CardContent>

            <Modal
                isOpen={openReminder}
                title="Reminder"
                small={true}
                body={<div className='reminder'><CheckCircleOutlineIcon style={{ fontSize: '53px', color: '#58A148' }} /> <br />The Transcript has been copied to the clipboard.<br />
                    You can apply copied text to segment editor now.</div>}
                deleteConfirmation={false}
            />

            <Modal
                isOpen={multiValueVariableRestrict}
                title="Notification"
                small={true}
                body={<div className='reminder'><InfoOutlinedIcon style={{ fontSize: '53px', color: '#FD5900' }} /> 
                <br />This test case has multiple multi-variable.<br /><br/>
                You are limited to using only a single multi-variable.</div>}
                deleteConfirmation={false}
            />
           
            <Modal
                isOpen={runParameter}
                title="Run Parameter"
                small={true}
                body={
                    <div className='reminder'><InfoOutlinedIcon style={{ fontSize: '53px', color: '#FD5900' }} /> <br />{t('Total number of runs:')} {runAll ? iterationCount:1}<br /><br/>
                        <div className='run-param'>{t('DEFAULT')}<Switch onChange={setItCount} />{t('ALL')}</div><br/>
                        {project?.data?.channelType !== CHANNELS.DIGITAL && <Box className="segment-editor-save-buttons">
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30'
                                        onClick={()=>setRunParameter('')}
                                    >
                                        {t('Back')}
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30 ml-80'
                                        onClick={runTestCase(true)}
                                    >
                                        {t('Run')}
                                    </Button>
                            </Box>
                        }
                        {project?.data?.channelType === CHANNELS.DIGITAL && (
                           <div className='db w-100'>
                                <div className='db'><h5>{t('Available Browsers:')}</h5></div>
                                {browsers?.map((b:any)=>(
                                    <div className='flex align-center gap-8 mt-24'>
                                        <Checkbox onChange={browserSelected} value={b.browserCode} /> {b.browserName} ({b.browserVersion})
                                    </div>
                                ))}
        
                                    <Box className="segment-editor-save-buttons mt-22 flex-end gap-8">
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30'
                                        onClick={()=>setOpenSelectBrowser('')}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30'
                                        onClick={runDigital}
                                    >
                                        {t('Run')}
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </div>
                    
                }
                deleteConfirmation={false}
            />

                <Modal 
                  isOpen={openSelectBrowser}
                  title={t("Browser Selections")}
                  small={true}
                  body={
                    <div className='db w-100'>
                        <div className='db'><h5>{t('Available Browsers:')}</h5></div>
                        {browsers?.map((b:any)=>(
                            <div className='flex align-center gap-8 mt-24'>
                               <Checkbox onChange={browserSelected} value={b.browserCode} /> {b.browserName} ({b.browserVersion})
                            </div>
                        ))}

                         <Box className="segment-editor-save-buttons mt-22 flex-end gap-8">
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                className='max-h-30'
                                onClick={()=>setOpenSelectBrowser('')}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                className='max-h-30'
                                onClick={runDigital}
                            >
                                {t('Run')}
                            </Button>
                        </Box>
                    </div>
                  }
                  />
        </Card>
    );
};
