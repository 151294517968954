import React, { useEffect, useState } from 'react';
import { InputLabel, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Box, Checkbox, TextField, Radio, RadioGroup, Typography } from "@mui/material"
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { FormikProps } from "formik";
import { completeTimeoutOptions, incompleteTimeoutOptions, promptLatencyOptions, promptTimeoutOptions } from '../../helpers/form.helper';
import { useDrop } from 'react-dnd'
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../../store/hooks";
import { IPrompt } from "../../types/interfaces/prompt.interface";
import { addSelectedPrompts } from '../../store/resuders/prompt.reducer';
import { getSelectedPrompts } from "../../store/selectors/prompt.selector";
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}

export const Listen = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const prompts = useSelector(getSelectedPrompts);
    const [removePrompts, setRemovePrompts] = React.useState<string[]>([]);
    const dispatch = useAppDispatch();
    const [usePromptLibrary, setUsePromptLibrary] = useState(false);
    const { t } = useTranslation();

    const addPromptFromLibrary = (item: any) => {
        console.log('addPromptFromLibrary', item)
        if (item && item.itemType === 'prompts') {
            dispatch(addSelectedPrompts(item))
        }
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'ROWS',
        drop: addPromptFromLibrary,
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [prompts])

    const promptSelectionChanged = (seg: IPrompt) => (e: any) => {
        if (e.target.checked) {
            setRemovePrompts(removePrompts.concat(seg.id))
        } else {
            setRemovePrompts(removePrompts.filter(c => c !== seg.id))

        }
    }

    const handleSelectPromptLibrary = () => {
        setUsePromptLibrary(!usePromptLibrary)
        handleChange({ target: { name: 'selectPromptLibrary', value: !usePromptLibrary } });
    }

    useEffect(() => {
        formik.setFieldValue('libraryItemIds', prompts?.map(p => ({ libraryID: p.libraryID })));
    }, [prompts]);

    // Style property for textfield prompts
    const boldStyle = { fontWeight: 'bold' };
    return (
        <>
            <Box className='prompt-select' ref={usePromptLibrary ? drop : null}>
                <FormControl sx={{ width: "100%" }}>
                    {usePromptLibrary || prompts?.filter(p => p.libraryID)?.length ? (
                        <>
                            {!prompts?.length ? (
                                <>
                                    <InputLabel id="case-editor-prompt"></InputLabel>
                                    <TextField
                                        id="prompt"
                                        name='prompt'
                                        value={"Add Prompt from the Library"}
                                        label={t("Prompt")}
                                        onChange={formik.handleChange}
                                    />
                                </>) : (
                                <>
                                    <TextField
                                        id="prompt"
                                        name='prompt'
                                        inputProps={{
                                            style: boldStyle,
                                        }}
                                        value={prompts.reduce(
                                            (acc: string, prompt: IPrompt) =>
                                                `${acc}${prompt.libraryID} ${prompt.name}\n`,
                                            ''
                                        )}
                                        label={t("Prompt")}
                                        onChange={handleChange}
                                        multiline
                                        rows={1}
                                    />
                                </>
                            )}


                        </>
                    ) : (
                        <>
                            <InputLabel id="case-editor-prompt"></InputLabel>
                            <TextField
                                id="prompt"
                                name='prompt'
                                value={formik.values.prompt}
                                label={t("Prompt")}
                                onChange={formik.handleChange}
                            />
                        </>
                    )}
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        name="case-select-prompt-library"
                        control={
                            <Checkbox
                                id='selectPromptLibrary'
                                name='selectPromptLibrary'
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleCheckedFilled />}
                                checked={values?.selectPromptLibrary}
                                value={values?.selectPromptLibrary}
                                onChange={handleSelectPromptLibrary}
                            />
                        }
                        label={t("Select From the Prompt Library")}
                        labelPlacement="end"
                    />
                </FormControl>
            </Box>

            <Box sx={{ display: 'flex' }}>
                {/* Left Side of Prompt Library */}
                <Box className='prompt-library-left' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-latency-min">{t('Prompt Latency Min')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-latency-min"
                                    id="promptLatencyMin"
                                    name='promptLatencyMin'
                                    value={values?.promptLatencyMin}
                                    label={t("latency-min")}
                                    onChange={handleChange}
                                >
                                    {promptLatencyOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-incomplete-timeout">{t('Incomplete Timeout')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-incomplete-timeout"
                                    id="incompleteTimeout"
                                    name='incompleteTimeout'
                                    value={values?.incompleteTimeout}
                                    label={t("incomplete-timeout")}
                                    onChange={handleChange}
                                >
                                    {incompleteTimeoutOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-prompt-timeout">{t('Prompt Timeout')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-prompt-timeout"
                                    id="stepTimeout"
                                    name='stepTimeout'
                                    value={values?.stepTimeout}
                                    label={t("prompt-timeout")}
                                    onChange={handleChange}
                                >
                                    {promptTimeoutOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                </Box>


                {/* Right Side of Prompt Library */}
                <Box className='prompt-library-right' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-latency-max">{t('Prompt Latency Max')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-latency-max"
                                    id="promptLatencyMax"
                                    name="promptLatencyMax"
                                    value={values?.promptLatencyMax}
                                    label={t("latency-max")}
                                    onChange={handleChange}
                                >
                                    {promptLatencyOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-complete-timeout">{t('Complete Timeout')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-complete-timeout"
                                    id="completeTimeout"
                                    name="completeTimeout"
                                    value={values?.completeTimeout}
                                    label={t("complete-timeout")}
                                    onChange={handleChange}
                                >
                                    {completeTimeoutOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-prompt-confidence">{t('Prompt Confidence')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <TextField
                                    id="promptConfidenceLevel"
                                    name="promptConfidenceLevel"
                                    value={values?.promptConfidenceLevel}
                                    onChange={handleChange}
                                    inputProps={{
                                        step: 0.01,
                                        min: 0,
                                        max: 1,
                                        type: 'number',
                                        style: { textAlign: 'center' },
                                    }}
                                />
                            </FormControl>

                        </div>
                    </Box>
                </Box>
            </Box >
            {/* Radio Buttons */}
            <Box className="prompt-library-checkbox">
                <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    id='voiceOrDTMF'
                    name="voiceOrDTMF"
                    value={values?.voiceOrDTMF}
                    onChange={handleChange}
                >
                    <FormControl sx={{ width: "50%" }}>
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t("Voice Only")}
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl sx={{ width: "50%" }}>
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t("DTMF Only")}
                            labelPlacement="end"
                        />
                    </FormControl>
                </RadioGroup>
            </Box>

            <Box className="prompt-library-checkbox">
                <FormControl sx={{ width: "50%" }}>
                    <FormControlLabel
                        name="case-select-barge-in"
                        control={
                            <Checkbox
                                id='grammar'
                                name='grammar'
                                checked={values?.grammar?.[0]}
                                onChange={handleChange} />
                        }
                        label={t("Use In-line Grammar")}
                        labelPlacement="end"
                    />
                </FormControl>
            </Box>
        </>
    );
};
