import React, { useState } from 'react';
import { Button, Card, CardContent, MenuItem, Select, TextField, Typography } from "@mui/material";
import { deleteIcon, editFilledIcon } from '../assets';
import { useFormik } from "formik";
import { LibraryDataGrid } from '../ui';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

export const GlobalLibrary = () => {
    const { t } = useTranslation();

    const [addButton, setAddButton] = useState(false);
    const [importButton, setImportButton] = useState(false);
    const [edit, setEdit] = useState<any>(null);
    const [value, setValue] = React.useState('one');
    const [currentPage, setCurrentPage] = useState(1); // Track the current page state of Pagination
    const [itemsPerPage, setItemsPerPage] = useState(5); // Track the items per page state of Pagination
    const [inputFocused, setInputFocused] = useState(false); // Track the focus state of the TextField
    const [segmentsTab, setSegmentsTab] = useState(true); // Track Segments Tab State
    const [variablesTab, setVariablesTab] = useState(false); // Track Variables Tab State
    const [grammarsTab, setGrammarsTab] = useState(false); // Track Grammars Tab State
    const [promptsTab, setPromptsTab] = useState(false); // Track Prompts Tab State
    const [audiosTab, setAudiosTab] = useState(false); // Track Audios Tab State
    const [tabColor, setTabColor] = useState("#006A68");
    const [backgroundColor, setBackgroundColor] = useState("#CCE8E6");

    const columns: any[] = [
        { fieldName: 'id', width: '55px', sortable: true },
        { fieldName: 'segmentName' },
        {
            fieldName: 'edit',
            redner: (params: any) => (
                <div className="flex-center">
                    {params.name}
                    <img src={editFilledIcon} onClick={() => editProject(params.id)} style={{ fontSize: '17px', cursor: 'pointer' }} />
                </div>
            )
        }
    ];

    const data: any[] = [
        { id: 'IVR2', segmentName: 'Segment Name' },
        { id: 'IVR2', segmentName: 'Segment Name' },
        { id: 'IVR2', segmentName: 'Segment Name' },
        { id: 'IVR2', segmentName: 'Segment Name' },
        { id: 'IVR2', segmentName: 'Segment Name' },
        { id: 'IVR2', segmentName: 'Segment Name' }
    ];

    const formik = useFormik({

        initialValues: {

            channelType: 'voice',

            language: 'english',

        },

        onSubmit: async (values: any) => {
            console.log('submit', values);
            // TODO: Add onSubmit actions here
        }

    })

    const editProject = (id: string) => {
        setEdit(data.find((d: any) => +d.id === +id));
    };

    // const toggleAddButton = () => {
    //     setAddButton(!addButton);
    //     setEdit(null);
    // };

    // const toggleImportButton = () => {
    //     setImportButton(!importButton);
    // };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
        const value = parseInt(event.target.value as string, 10); // Convert string to number
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const toggleSegmentsTab = () => {
        setSegmentsTab(true)
        setVariablesTab(false)
        setGrammarsTab(false)
        setPromptsTab(false)
        setAudiosTab(false)
        setTabColor("#006A68")
        setBackgroundColor("#CCE8E6");
    };
    const toggleVariablesTab = () => {
        setVariablesTab(true)
        setSegmentsTab(false)
        setGrammarsTab(false)
        setPromptsTab(false)
        setAudiosTab(false)
        setTabColor("#3818D9")
        if (variablesTab) {
            setBackgroundColor("#F7F5FF")
        } else {
            setBackgroundColor("#E3DEF8")
        }
    };
    const toggleGrammarsTab = () => {
        setGrammarsTab(true)
        setVariablesTab(false)
        setSegmentsTab(false)
        setPromptsTab(false)
        setAudiosTab(false)
        setTabColor("#FFA800")
        if (grammarsTab) {
            setBackgroundColor("#FCFAF1")
        } else {
            setBackgroundColor("#EDE9D6")
        }
    };
    const togglePromptsTab = () => {
        setPromptsTab(true)
        setVariablesTab(false)
        setSegmentsTab(false)
        setGrammarsTab(false)
        setAudiosTab(false)
        setTabColor("#006A68")
        if (promptsTab) {
            setBackgroundColor("#F0F9F2")
        } else {
            setBackgroundColor("#D3E7D7")
        }
    };
    const toggleAudiosTab = () => {
        setAudiosTab(true)
        setVariablesTab(false)
        setSegmentsTab(false)
        setGrammarsTab(false)
        setPromptsTab(false)
        setTabColor("#2F80ED")
        if (audiosTab){
        setBackgroundColor("#F0F8FF")
        } else {
            setBackgroundColor("#D4E6F7")
        }
    };

    const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <Card className="h-full">
            <CardContent>
                <div className="test-case-header">
                    <Typography variant="subtitle1">{t('Global Library')}</Typography>
                </div>

                <div className="library-searchbox">
                    <div className="delete-icon">
                        <img src={deleteIcon} alt="" />
                    </div>
                    <div className="search-input-lib">
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            label={
                                <React.Fragment>
                                    <SearchIcon sx={{ marginRight: '10px' }} />
                                    <span>{t('Search')}</span>
                                </React.Fragment>
                            }
                            variant="outlined"
                            sx={{
                                borderRadius: 32.5,
                                backgroundColor: "#f7f7f7",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: 32.5,
                                    "& fieldset": {
                                        borderColor: "#f7f7f7",
                                        borderWidth: 2,
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#f7f7f7",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#f7f7f7",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: { display: 'flex', 
                                alignItems: 'center', 
                                marginLeft: inputFocused ? '0px' : '35px',
                            }, 
                            }}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                        />
                    </div>
                </div>

                <Box sx={{ width: '100%' }}>
                <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        sx={{
                            ".Mui-selected": {
                                color: tabColor , // Tab font color
                            },
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: tabColor // Line color underneath tab
                            }
                        }}
                        aria-label="secondary tabs example"
                        variant="fullWidth"
                    >
                         <Tab value="Segments" label={t("Segments")} sx={{ color: "#006A68", backgroundColor: (segmentsTab ? "#CCE8E6" : "#F1FFFE") }} onClick={toggleSegmentsTab} />
                        <Tab value="Variables" label={t("Variables")} sx={{ color: "#3818D9", backgroundColor: (variablesTab ? "#E3DEF8" : "#F7F5FF") }} onClick={toggleVariablesTab} />
                        <Tab value="Grammers" label={t("Grammars")} sx={{ color: "#FFA800", backgroundColor: (grammarsTab ? "#EDE9D6" : "#FCFAF1") }} onClick={toggleGrammarsTab} />
                        <Tab value="Prompts" label={t("Prompts")} sx={{ color: "#006A68", backgroundColor: (promptsTab ? "#D3E7D7" : "#F0F9F2") }} onClick={togglePromptsTab} />
                        <Tab value="Audios" label={t("Audios")} sx={{ color: "#2F80ED", backgroundColor: (audiosTab? "#D4E6F7" : "#F0F8FF") }} onClick={toggleAudiosTab} />
                    </Tabs>
                </Box>

                <div className="library-container" style={{backgroundColor: backgroundColor}}>
                    <div className="datagrid-wrapper">
                    <LibraryDataGrid formik={formik} columns={columns} data={paginatedData} selection={true} sortBy={'id'} />
                    </div>
                    <div className="pagination-wrapper">
                        <div className="items-per-page">
                            <FormHelperText sx={{ color: '#2196F3' }}>{t('items per page')}</FormHelperText>
                            <Select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                variant="standard"
                                style={{ width: '82px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </div>
                        <div className="pagination">
                            <Pagination
                                count={Math.ceil(data.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                showFirstButton
                                showLastButton
                                color="primary"
                            />
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
