import { FormikProps } from "formik";

export interface IProps {
    formik: FormikProps<any>; 
    segmentName?: string;
    type?: string;
    language?: string;
}

export const ScreenShot= ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    // const { values, handleChange } = formik;

    return (
        <>
            
        </>
    );
};
