import apiService from "./api.service";
import { IVariable } from "../types/interfaces/variable.interface";

export const getVariableList = async (projectId = 1, pageSize = 10, pageNo = 0, sort = '', filter = '') => {
    const { data } = await apiService.get(`/projects/${projectId}/variables?pageNo=${pageNo - 1}&pageSize=${pageSize}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`);
    return data;
}

export const deleteVariable = async (projectId: number, variableIds: number[]) => {
    return await Promise.all(
        variableIds.map(async id => {
            if (id) {
                await apiService.delete(`projects/${projectId}/variables/${id}`)
            }

            return null;
        })
    ).catch(e => e)

}

export const upsertVariable = async (projectId: number, variable: any, editorState: string) => {
    if (editorState == "create") {
        return await apiService.post(`/projects/${projectId}/variables`, { ...variable })
            .catch(e => e);
    } else {
        return await apiService.put(`/projects/${projectId}/variables/${variable.libraryID}`, { ...variable })
            .catch(e => e);
    }
}