import { v4 as uuidv4 } from 'uuid';
import { upsertAudio } from '../store/resuders/audio.reducer';
import { upsertGrammar } from '../store/resuders/grammar.reducer';
import { upsertPrompt } from '../store/resuders/prompt.reducer';
import { upsertLibrary } from '../store/resuders/segment.reducer';
import { upsertVariable } from '../store/resuders/variable.reducer';

export const uniqueId = () => uuidv4();

export const pauseTimeOptions = [0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 30, 45, 60];
export const promptLatencyOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const incompleteTimeoutOptions = [0.5, 1, 2, 3, 4, 5];
export const completeTimeoutOptions = [0.2, 0.5, 1, 2, 3, 4, 5];
export const promptTimeoutOptions = [5, 10, 15, 20, 25, 30, 45, 60];
export const promptConfidenceOptions = [5, 10, 15, 20, 25, 30, 45, 60];
export const timeoutOptions = [1, 2, 5, 10, 15, 20, 25, 30, 45, 60]
export const finalSilenceOptions = [0.5, 1, 2, 3, 4, 5, 10]

// Scheduler Options
export const ports = [1, 2, 3, 4]
export const scheduleTypes = ["One Time", "Perpetual"]
export const status = ["Disabled", "Enabled"]
export const trigger = ["Future", "Now"]
export const browser = ["FIREFOX", "CHROME", "EDGE"]

// Recurrence Editor Options:
export const unit = ["minutes", "hours"]

// Project Library Editor
export const languageCodeId = ["english", "english-GB", "english-IN", "french", /*"spanish-US"*/]

export const permissions = [
    { value: "ROLE_ADMIN", label: "Administrator" },
    { value: "ROLE_USER", label: "User" },
  ];

export const regions  = [
    "Asia Pacific (Seoul)",
    "Asia Pacific (Mumbai)",
    "Asia Pacific (Sydney)",
    "Americas (Canada)",
    "EMEA (Frankfurt)",
    "EMEA (London)",    
    "FedRAMP",
    "EMEA (Dublin)",
    "Asia Pacific (Tokyo)",
    "Asia Pacific (Osaka)",
    "EMEA (Zurich)",
    "EMEA (UAE)",
    "Americas (Sāo Paulo)",
    "Americas (US East)",
    "Americas (US West)",
    "us_west_2"
];

export const closeLibraryEditor = (dispatch: any) => {
    dispatch(upsertVariable(''));
    dispatch(upsertPrompt(''));
    dispatch(upsertAudio(''));
    dispatch(upsertGrammar(''));
    dispatch(upsertLibrary(''));
}
export const copyTextToClipboard = async (text: string) => {
    try {
        // if ('clipboard' in navigator) {
        //     return await navigator.clipboard.writeText(text);
        // } else {
        //     return document.execCommand('copy', true, text);
        // }
        let textField = document.createElement('textarea')
        textField.innerText = text;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy');
        textField.remove()
    } catch (e: any) {
        console.log('copyTextToClipboard error', e);
    }

}
