import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const grammarSelectors = (state: IAppState) => state.grammar;


export const getGrammars = createSelector(
    [grammarSelectors],
    (app) => app?.grammar || []
);

export const getSelectedGrammars = createSelector(
    [grammarSelectors],
    (app) => app?.selectedGrammars || []
);

export const allowUpsertGrammar = createSelector(
    [grammarSelectors],
    (app) => app?.upsertGrammar,
);
