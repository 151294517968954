import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const variableSelectors = (state: IAppState) => state.variable;


export const getVariables = createSelector(
    [variableSelectors],
    (app) => app?.variable || []
);

export const getSelectedvariables = createSelector(
    [variableSelectors],
    (app) => app?.selectedVariables || []
);

export const allowUpsertVariable = createSelector(
    [variableSelectors],
    (app) => app?.upsertVariable,
);
