import { useState } from 'react';
import { Button, Card, CardContent, Typography, InputLabel, FormControl, FormLabel, Checkbox, Select, MenuItem, Box, TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useAppDispatch } from "../store/hooks"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import { getEditSegment, getSelectedSegments } from '../store/selectors/segment.selector';
import { getAllStepTypes, getCurrentProject, getCurrentTestCase, getGoToTypes, getLanguages } from '../store/selectors/project.selector';
import { deleteIcon } from '../assets';
import { ports, scheduleTypes, status, trigger, unit } from '../helpers/form.helper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { FormikProps } from "formik";
import { useTranslation } from 'react-i18next';


export const RecurrenceEditor = ({
    formik,
    setAddRecurrenceOpen,
    addRecurrenceOpen = null as any,
    setRecurrenceEditorState,  // ADD or EDIT
    recurrenceEditorState = null as any,
    paramToEdit,
    setParamToEdit,
    deletePerpetualSchedule
}: {
    formik: FormikProps<any>;
    setAddRecurrenceOpen: (value: boolean) => void;
    addRecurrenceOpen: boolean;
    setRecurrenceEditorState: (value: string) => void
    recurrenceEditorState: string;
    paramToEdit: any;
    setParamToEdit: (param: any) => void;
    deletePerpetualSchedule: (value: any) => void;
}) => {
    const { t } = useTranslation();
    const project = useSelector(getCurrentProject);
    // const languages = useSelector(getLanguages);
    const [addTestCaseOpen, setAddTestCaseOpen] = useState(false);
    const [hasRecurrences, setHasRecurrences] = useState(false);
    // const [recurrenceOpen, setRecurrenceOpen] = useState(false);
    // const [addRecurrenceOpen, setAddRecurrenceOpen] = useState(false);



    const dispatch = useAppDispatch();


    const toggleAddRecurrence = () => {
        setAddRecurrenceOpen(!addRecurrenceOpen)
        setRecurrenceEditorState("ADD")
        if (addRecurrenceOpen == true){
            setParamToEdit(null)
        }
    }

    const save = async () => {
        // Save Button
    
        if (paramToEdit !== null && typeof paramToEdit === 'object' && Object.keys(paramToEdit).length !== 0) {
            deletePerpetualSchedule(paramToEdit);
            setParamToEdit(null);
        }
    
        //Validate form before submitting
        const error = await formik.validateForm();
        if(!error.missingFields){
            formik.submitForm();
            setParamToEdit(null);
            setAddRecurrenceOpen(false);
            setRecurrenceEditorState("ADD");
        }
    };
    
    

    const clear = () => {
        // Clear Button
        formik.setValues({
            dayOrDate: [],
            fromTime: dayjs(), // Current time in 24-hour format
            toTime: dayjs(),   // Current time in 24-hour format
            runInterval: 1,
            runIntervalUnit: 'hours',
            enabled: false,
            exclusion: formik?.values?.exclusion
        });
    }

    // Define an array for the days of the week
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    // Function to toggle the selection of a day
    const toggleDay = (day: string) => {
        const { dayOrDate } = formik.values;
        if (dayOrDate.includes(day)) {
            // Day is selected, so remove it from the array
            formik.setFieldValue('dayOrDate', dayOrDate.filter((d: string) => d !== day));
        } else {
            // Day is not selected, so add it to the array
            formik.setFieldValue('dayOrDate', [...dayOrDate, day]);
        }
    };

    
    // Reset Form When Schedule Type Changes From Include/Exclude
    useEffect(() => {
        // formik.setValues({
        //     dayOrDate: [],
        //     // fromTime: dayjs(), // Current time in 24-hour format
        //     // toTime: dayjs(),   // Current time in 24-hour format
        //     fromTime: dayjs().format('HH:mm:ss.SSS'),
        //     toTime: dayjs().format('HH:mm:ss.SSS'),
        //     runInterval: 1,
        //     runIntervalUnit: 'Hours',
        //     enabled: false,
        //     exclusion: formik?.values?.exclusion
        // });
    }, [formik.values.exclusion]);

    const clearRecurrenceFormik = () => {
        formik.setValues({
            dayOrDate: [],
            // fromTime: dayjs(), // Current time in 24-hour format
            // toTime: dayjs(),   // Current time in 24-hour format
            fromTime: dayjs().format('HH:mm:ss.SSS'),
            toTime: dayjs().format('HH:mm:ss.SSS'),
            runInterval: 1,
            runIntervalUnit: 'hours',
            enabled: false,
            exclusion: formik?.values?.exclusion
        });
    };

    return (
        <Card className="h-100-p">
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <div className="case-editor-header">
                        <Typography variant="subtitle1">{recurrenceEditorState === "ADD" ? t('Create New Recurrence') : t('Edit Recurrence')}</Typography>
                        <HighlightOffIcon onClick={() => {
                            clearRecurrenceFormik();
                            toggleAddRecurrence();
                            }} />
                    </div>

                    <div className="form-container">
                        <Box className="prompt-library-checkbox">
                            <Typography variant="subtitle2">{t('Setting')}</Typography>
                            <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                id='setting'
                                name="setting"
                                value={formik.values?.exclusion}
                                onChange={(e) => {
                                    // Update 'exclusion' based on the selected option
                                    clearRecurrenceFormik();
                                    formik.setFieldValue('exclusion', e.target.value === 'true');
                                }}
                            >
                                <FormControl sx={{ width: "50%" }}>
                                    <FormControlLabel
                                        value={false.toString()}
                                        control={<Radio />}
                                        label={t('Include Schedule')}
                                        labelPlacement="end"
                                    />
                                </FormControl>
                                <FormControl sx={{ width: "50%" }}>
                                    <FormControlLabel
                                        value={true.toString()}
                                        control={<Radio />}
                                        label={t('Exclude Schedule')}
                                        labelPlacement="end"
                                    />
                                </FormControl>
                            </RadioGroup>
                        </Box>
                        {formik?.values?.exclusion ? (
                            <Box className="recurrence-exclude-container">
                                <Typography variant="subtitle2">{t('Select Time')}</Typography>
                                <Box className="recurrence-editor-fields">

                                    <FormControl sx={{ width: "100%" }}>
                                        <DatePicker
                                            label={t('Exclude Date')}
                                            format="YYYY/MM/DD"
                                            value={formik.values.dayOrDate}
                                            onChange={(date) => formik.setFieldValue('dayOrDate', [date])}
                                        />
                                    </FormControl>

                                </Box>
                                <Box className="recurrence-editor-fields">

                                    <FormControl sx={{ width: "50%" }}>
                                        {/* <InputLabel id="recurrence-exclude-from">From</InputLabel> */}
                                        <TimePicker
                                            label={t('From')}
                                            value={formik?.values?.fromTime}
                                            // onChange={(time) => formik.setFieldValue('fromTime', time)}
                                            onChange={(time) => formik.setFieldValue('fromTime', time.format('HH:mm:ss.SSS'))}
                                        />
                                    </FormControl>

                                    <FormControl sx={{ width: "50%" }}>
                                        {/* <InputLabel id="recurrence-exclude-to">To</InputLabel> */}
                                        <TimePicker
                                            label={t('To')}
                                            value={formik?.values?.toTime}
                                            // onChange={(time) => formik.setFieldValue('toTime', time)}
                                            onChange={(time) => formik.setFieldValue('toTime', time.format('HH:mm:ss.SSS'))}
                                        />
                                    </FormControl>
                                </Box>
                                {/* <Box className="recurrence-editor-fields">
                                    <FormControl sx={{ width: "50%" }}> 
                                        <TextField
                                            id="runInterval"
                                            name="runInterval"
                                            label={t("Repeat Task Every")}
                                            value={formik?.values?.runInterval}
                                            onChange={formik?.handleChange}
                                            inputProps={{
                                                step: 1,
                                                min: 0,
                                                max: 99,
                                                type: 'number',
                                                style: { textAlign: 'center' },
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl sx={{ width: "50%" }}>
                                        <InputLabel id="recurrence-exclude-unit">Unit</InputLabel>
                                        <Select
                                            labelId="recurrence-exclude-unit"
                                            id="runIntervalUnit"
                                            name='runIntervalUnit'
                                            value={formik?.values?.runIntervalUnit}
                                            label={t("Unit")}
                                            onChange={formik?.handleChange}
                                        >
                                            {unit.map(id => (
                                                    <MenuItem value={id}>{t(id)}</MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box> */}

                                <Box className="recurrence-editor-checkbox">
                                    <FormControl sx={{ width: "50%" }}>
                                        <FormControlLabel
                                            name="recurrence-exclude-enable"
                                            control={
                                                <Checkbox
                                                    id='enabled'
                                                    name='enabled'
                                                    checked={formik?.values?.enabled}
                                                    onChange={formik?.handleChange} />
                                            }
                                            label={t("Enable")}
                                            labelPlacement="end"
                                        />
                                    </FormControl>
                                </Box>

                            </Box>
                        ) : (
                            <>
                                <Box className="recurrence-exclude-container">
                                    <Typography variant="subtitle2">{t('Select Days')}</Typography>
                                    <Box className="recurrence-editor-fields">
                                        {/* First Row */}
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {daysOfWeek.slice(0, 4).map(day => (
                                                <Button
                                                    key={day}
                                                    variant="contained"
                                                    color={formik.values?.dayOrDate?.includes(day) ? "secondary" : "primary"}
                                                    onClick={() => toggleDay(day)}
                                                >
                                                    {t(day)}
                                                </Button>
                                            ))}
                                        </div>
                                    </Box>

                                    <Box className="recurrence-editor-fields">
                                        {/* Second Row */}
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {daysOfWeek.slice(4).map(day => (
                                                <Button
                                                    key={day}
                                                    variant="contained"
                                                    color={formik.values?.dayOrDate?.includes(day) ? "secondary" : "primary"}
                                                    onClick={() => toggleDay(day)}
                                                >
                                                    {t(day)}
                                                </Button>
                                            ))}
                                        </div>
                                    </Box>
                                    <Box className="recurrence-editor-fields">

                                        <FormControl sx={{ width: "50%" }}>
                                            <TimePicker
                                                label={t("From")}
                                                value={formik?.values?.fromTime}
                                                // onChange={(time) => formik.setFieldValue('fromTime', time)}
                                                onChange={(time) => formik.setFieldValue('fromTime', time.format('HH:mm:ss.SSS'))}
                                            />
                                        </FormControl>

                                        <FormControl sx={{ width: "50%" }}>
                                            <TimePicker
                                                label={t("To")}
                                                value={formik?.values?.toTime}
                                                // onChange={(time) => formik.setFieldValue('toTime', time)}
                                                onChange={(time) => formik.setFieldValue('toTime', time.format('HH:mm:ss.SSS'))}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box className="recurrence-editor-fields">
                                        <FormControl sx={{ width: "50%" }}>
                                            <TextField
                                            id="runInterval"
                                            name="runInterval"
                                            label={t("Repeat Task Every")}
                                            value={formik?.values?.runInterval}
                                            onChange={formik?.handleChange}
                                            inputProps={{
                                                step: 1,
                                                min: 0,
                                                max: 99,
                                                type: 'number',
                                                style: { textAlign: 'center' },
                                            }}
                                        />
                                        </FormControl>

                                        <FormControl sx={{ width: "50%" }}>
                                            <InputLabel id="recurrence-exclude-unit">Unit</InputLabel>
                                            <Select
                                                labelId="recurrence-exclude-unit"
                                                id="runIntervalUnit"
                                                name='runIntervalUnit'
                                                value={formik?.values?.runIntervalUnit}
                                                label={t("Unit")}
                                                onChange={formik?.handleChange}
                                            >
                                                {unit.map(id => (
                                                    <MenuItem value={id}>{t(id)}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Box className="recurrence-editor-checkbox">
                                        <FormControl sx={{ width: "50%" }}>
                                            <FormControlLabel
                                                name="recurrence-exclude-enable"
                                                control={
                                                    <Checkbox
                                                        id='enabled'
                                                        name='enabled'
                                                        checked={formik?.values?.enabled}
                                                        onChange={formik?.handleChange} />
                                                }
                                                label={t("Enable")}
                                                labelPlacement="end"
                                            />
                                        </FormControl>
                                    </Box>

                                </Box>
                            </>
                        )}
                    </div>

                    <div className='schedule-editor-test-case-buttons'>
                        <div style={{ display: 'flex', gap: '30px' }}>
                            <Button variant='contained' color="primary" onClick={save}>
                                {t('Save')}
                            </Button>
                            <Button variant='contained' color="primary" onClick={clear}>
                                {t('Clear')}
                            </Button>
                        </div>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}
