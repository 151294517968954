import { Button, Card, CardContent, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changeUserPassword, getUSerDetails, updateUserProfile } from "../../services/user.service";
import { Modal } from "../../ui";
import { uniqueId } from "lodash";
import { useAppDispatch } from "../../store/hooks";
import { globalNotification } from "../../store/resuders/app.reducer";
import { users } from "../../assets";

export const UserProfile = ()=> {
    const { t } = useTranslation();
    const [open, isOpen] = useState('');
    const [submit, setSubmit] = useState(false);
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '',
            oldPassword:'',
            newPassword:'',
            confirmPassword:'',
            projects: [],
            role: 'ROLE_ADMIN'

        },
        onSubmit: async (values: any) => {
            const result = await updateUserProfile(values, dispatch);
            if(result) dispatch(globalNotification(t('User is updated')));
        }
    })

    const changePassword = async ()=> {
        setSubmit(true);

        if(!formik.values?.oldPassword || !formik.values?.newPassword ||  formik.values?.confirmPassword !== formik.values?.newPassword) {
            return;
        }
        
        const result = await changeUserPassword({
            oldPassword:formik.values?.oldPassword,
            newPassword:formik.values?.newPassword,
        },dispatch);
        if(result) {
            dispatch(globalNotification(t('New Password Saved.')));
            formik?.setFieldValue('oldPassword', '');
            formik?.setFieldValue('newPassword', '');
            formik?.setFieldValue('confirmPassword', '');
        }
        
        isOpen('');
    }

    const openModal = ()=> {
        setSubmit(false);
        isOpen(uniqueId());
    }

    useEffect(()=> {
        const getUser = async()=> {
            const user = await getUSerDetails();
            formik.setValues(user);
        }
        getUser();
    },[]);

    return (
    <>
        <div className="flex-center mb-16 pl-20 mt-16">
            <img src={users} alt="" /> 
            <Typography variant="subtitle2">{t('Admin')} </Typography>
            <Typography variant="subtitle2">{'>'}</Typography>
            <Typography variant="subtitle2">{t('User Profile')}</Typography>
        </div>
        <Card className="h-full">
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-header flex-start">
                        <Typography variant="subtitle1">{t('Edit User : ')}{formik.values?.firstName}{' '} {formik.values?.lastName}</Typography>
                    </div>
                    <Grid container className="pl-20">
                        <Grid item xs={6}  className="pt-32 flex gap-24 ">
                            <TextField id="firstName" name="firstName" onChange={formik.handleChange} fullWidth label={t("First Name")} value={formik.values?.firstName} />
                            <TextField id="lastName" name="lastName" onChange={formik.handleChange} fullWidth label={t("Last Name")} value={formik.values?.lastName} />
                            <TextField id="username" name="username" onChange={formik.handleChange} fullWidth label={t("User Name")} value={formik.values?.username} />
                        </Grid>

                        <Grid item xs={12} className="pt-32">
                            <Button variant="contained" onClick={openModal} color="primary">{t('Reset Password')}</Button>
                        </Grid>
                        {(formik.values?.role === 'ROLE_USER') && (
                            <Grid item xs={6}  className="pt-32 flex gap-24 ">
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="project">{t("Project")}</InputLabel>
                                    <Select
                                        labelId="project"
                                        id="project"
                                        label={t("Project")}
                                        name="projects"
                                        multiple
                                        value={formik.values?.projects || []}
                                        // input={<OutlinedInput label="Projects" />}
                                        renderValue={() => formik.values?.projects?.map((project: any) => project.name).join(", ")}
                                        >
                                        {formik.values?.projects.map((option: any) => (
                                            <MenuItem key={option?.testProjectID} value={option}>
                                            <Checkbox
                                                checked={true}
                                            />
                                            <ListItemText primary={option?.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            
                            </Grid>
                        )}
                    </Grid>
                
                    <div className="profile-save-button">
                        <Button variant="contained" type="submit" color="primary">{t('Save Changes')}</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
            <Modal
                isOpen={open}
                title="Change Password"
                small={true}
                body={
                    <div className='reminder'>
                       <Grid container className="pl-20" spacing={4}>
                            <Grid item xs={12}>
                                <TextField  error={submit && !formik.values?.oldPassword}  id="oldPassword" type="password" name="oldPassword" 
                                            onChange={formik.handleChange} fullWidth label={t("Current Password")} value={formik.values?.oldPassword} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={submit && !formik.values?.newPassword} id="newPassword" type="password" name="newPassword" 
                                            onChange={formik.handleChange} fullWidth label={t("New Password")} value={formik.values?.newPassword} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={submit && !formik.values?.confirmPassword} id="confirmPassword" type="password" name="confirmPassword" 
                                            onChange={formik.handleChange} fullWidth label={t("Confirm Password")} value={formik.values?.confirmPassword} />
                            </Grid>

                            <Grid item xs={12} className="pt-32">
                                <Button variant="contained" onClick={changePassword} color="primary">{t('Change Password')}</Button>
                            </Grid>
                    </Grid>
                    </div>}
                deleteConfirmation={false}
            />
     </>
    )
}