import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const scheduleSelectors = (state: IAppState) => state.schedule;


export const getSchedule = createSelector(
    [scheduleSelectors],
    (app) => app?.schedule || []
);

export const getSelectedTestCases = createSelector(
    [scheduleSelectors],
    (app) => app?.scheduleTestCases || []
);

export const getScheduleTime = createSelector(
    [scheduleSelectors],
    (app) => app?.scheduleTime || []
);

