import { Button, Checkbox, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { lensLogo, tekWhite } from "../assets"
import { doLogin } from "../services/user.service"
import { useAppDispatch } from "../store/hooks"
import { useTranslation } from 'react-i18next';
import { SSO_URL } from "../services/api.service"

export const Login = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [form, setForm] = useState<any>({
        username: '',
        password: '',
        remember:false,
    })

    const loginOnEnter = async (e:any)=> {
        if(e.keyCode === 13) {
            await login();
        }
    }

    const sso = ()=> {
        document.location.href=`${SSO_URL}/?redirectUrl=${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`:``}/callback`;
    }

    const login = async () => {
        const status = await doLogin(form.username, form.password, dispatch);

        if (status) {
            localStorage.setItem('APP_USERNAME', form.remember ? form.username:'');
            document.location.href = "/";
        }
    }

    useEffect(()=> {
        const username = localStorage.getItem('APP_USERNAME');
        if(username) {
            setForm({
                username,
                remember:true,
            })
        }
    },[]);


    return (
        <div className="login-container">
            <div className="login-area">
                <div className="login-left">
                    <img src={tekWhite} alt="" />
                    <div className="version">v5.1</div>
                </div>
                <div className="login-right">
                    <div className="center-logo">
                        <img src={lensLogo} alt="" />

                        <div className="db mt-24">
                            <TextField value={form.username} 
                                onChange={e => setForm({ ...form, username: e.target.value })} 
                                onKeyDown={loginOnEnter}
                                label={t("User Name")} 
                                InputLabelProps={{
                                shrink: true,
                            }} style={{ width: '245px' }}></TextField>
                        </div>

                        <div className="db mt-24">
                            <TextField value={form.password} label={t("Password")}
                                onChange={e => setForm({ ...form, password: e.target.value })}
                                type="password"
                                onKeyDown={loginOnEnter}
                                InputLabelProps={{
                                    shrink: true,
                                }} style={{ width: '245px' }}></TextField>
                        </div>
                        <div className="db mt-24">
                            <Checkbox checked={form.remember} onChange={e => setForm({ ...form, remember: e.target.checked })} color="primary"></Checkbox>{t('Remember Me')}
                        </div>
                     
                        <div className="flex mt-24 gap-24">
                            <Button
                                variant={'contained'}
                                color={"primary"}
                                className='btn-primary'
                                onClick={login}
                            >
                                {t('Login')}
                            </Button>
                            <Button
                                variant={'contained'}
                                color={"primary"}
                                className='btn-primary'
                                onClick={sso}
                            >
                                {t('SSO')}
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}