import React, { useState } from 'react';
import { InputLabel, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Box, TextField, Checkbox } from "@mui/material"
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { FormikProps } from "formik";
import { finalSilenceOptions, promptLatencyOptions, promptTimeoutOptions } from '../../helpers/form.helper';
import { useDrop } from 'react-dnd'
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../../store/hooks";
import { IPrompt } from "../../types/interfaces/prompt.interface";
import { addSelectedPrompts } from '../../store/resuders/prompt.reducer';
import { getSelectedPrompts } from "../../store/selectors/prompt.selector";
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}

export const ListenAnything = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const prompts = useSelector(getSelectedPrompts);
    const [removePrompts, setRemovePrompts] = React.useState<string[]>([]);
    const dispatch = useAppDispatch();
    const [usePromptLibrary, setUsePromptLibrary] = useState(false);
    const { t } = useTranslation();

    const addPromptFromLibrary = (item: any) => {
        if (item && item.itemType === 'prompts') {
        dispatch(addSelectedPrompts(item))
        }
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'ROWS',
        drop: addPromptFromLibrary,
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [prompts])

    const promptSelectionChanged = (seg: IPrompt) => (e: any) => {
        if (e.target.checked) {
            setRemovePrompts(removePrompts.concat(seg.id))
        } else {
            setRemovePrompts(removePrompts.filter(c => c !== seg.id))

        }
    }

    const handleSelectPromptLibrary = ()  => {
        setUsePromptLibrary(!usePromptLibrary)
        handleChange({ target: { name: 'selectPromptLibrary', value: !usePromptLibrary } });
    }


    // Style property for textfield prompts
    const boldStyle = { fontWeight: 'bold' };
    return (
        <>
            <Box className='prompt-select' ref={usePromptLibrary? drop: null}>
                <FormControl sx={{ width: "100%" }}>
                {usePromptLibrary ? (
                        <>
                            {!prompts?.length ? (
                                <>
                            <InputLabel id="case-editor-prompt"></InputLabel>
                            <TextField
                                id="prompt"
                                name='prompt'
                                value={"Add Prompt from the Library"}
                                label={t("Prompt")}
                                onChange={handleChange}
                            />
                            </>) : (
                            <>
                            <TextField
                                id="prompt"
                                name='prompt'
                                inputProps={{
                                    style: boldStyle,
                                  }}
                                value={prompts.reduce(
                                    (acc: string, prompt: IPrompt) =>
                                    `${acc}${prompt.libraryID} ${prompt.name}\n`,
                                    ''
                                )}
                                label={t("Prompt")}
                                onChange={handleChange}
                                multiline 
                                rows={1}  
                            />
                            </>
                            )}

                            
                        </>
                    ) : (
                        <>
                            <InputLabel id="case-editor-prompt"></InputLabel>
                            <TextField
                                id="prompt"
                                name='prompt'
                                value={values.prompt}
                                label={t("Prompt")}
                                onChange={handleChange}
                            />
                        </>
                    )}
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        name="case-select-prompt-library"
                        control={
                            <Checkbox
                                id='selectPromptLibrary'
                                name='selectPromptLibrary'
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleCheckedFilled />}
                                value={values?.selectPromptLibrary}
                                onChange={handleSelectPromptLibrary}
                            />
                        }
                        label="Select From the Prompt Library"
                        labelPlacement="end"
                    />
                </FormControl>
            </Box>

            <Box sx={{ display: 'flex' }}>
                {/* Left Side of Prompt Library */}
                <Box className='prompt-library-left' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-latency-min">{t('Prompt Latency Min')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-latency-min"
                                    id="promptLatencyMin"
                                    name='promptLatencyMin'
                                    value={values?.promptLatencyMin}
                                    label={t("latency-min")}
                                    onChange={handleChange}
                                >
                                    {promptLatencyOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-prompt-timeout">{t('Prompt Timeout')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-prompt-timeout"
                                    id="stepTimeout"
                                    name='stepTimeout'
                                    value={values?.stepTimeout}
                                    label={t("prompt-timeout")}
                                    onChange={handleChange}
                                >
                                    {promptTimeoutOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                </Box>

                {/* Right Side of Prompt Library */}
                <Box className='prompt-library-right' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-latency-max">{t('Prompt Latency Max')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-latency-max"
                                    id="promptLatencyMax"
                                    name='promptLatencyMax'
                                    value={values?.promptLatencyMax}
                                    label={t("latency-max")}
                                    onChange={handleChange}
                                >
                                    {promptLatencyOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-final-silence">{t('Final Silence')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-final-silence"
                                    id="finalSilence"
                                    name='finalSilence'
                                    value={values?.finalSilence}
                                    label={t("final-silence")}
                                    onChange={handleChange}
                                >
                                    {finalSilenceOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                </Box>
               
            </Box>
        </>
    );
};
