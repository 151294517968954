import { ITestCase } from "../types/interfaces/testCase.interface";
import apiService from "./api.service";
import digitalApiService from "./digitalApi.service";
import store from "../store/store";
import { toggleAlert } from "../store/resuders/app.reducer";
import { CHANNELS } from "../types/global.enum";
import { getVoiceApi } from "./config.service";

export const getProjectList = async (pageNo = 0, pageSize = 10, sort = '', filter = '') => {
    const { data } = await apiService.get(`/projects?pageSize=${pageSize}&pageNo=${pageNo}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`);
    return data;
}

export const getAllProjectList = async () => {
    const { data } = await apiService.get(`/projects/list`);
    return data;
}


export const getProjectById = async (projectId = 1) => {
    const { data } = await apiService.get(`/projects/${projectId}`);
    return data;
}

export const getSegments = async (id: any, channel: CHANNELS, pageSize = 5, pageNo = 1, language = '', filter = '') => {
    const lngFilter = language ? `&language=${language}`:'';
    const filterCriteria = filter ? `&filter=${filter}`:'';
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${id}/segments?includeStep=true&pageSize=${pageSize}&pageNo=${pageNo - 1}${filterCriteria}`) :
        await apiService.get(`/projects/${id}/segments?includeStep=true&pageSize=${pageSize}&pageNo=${pageNo - 1}${lngFilter}${filterCriteria}`);

    return data;
}

export const importData = async (id: number, type: string, language: string, file: any) => {
    let formdata = new FormData();
    formdata.append("document", file);
    formdata.append("lang", language);

    let requestOptions = {
        method: 'POST',
        body: formdata,
    } as any;
    const url = `${getVoiceApi()}/projects/${id}/${type.toLowerCase()}/import`;

    return new Promise((res) => {
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => res(result))
            .catch(error => res(error));

    })


}

export const exportDataTypes = async (id: number, type: string) => {
    const { data } = await apiService.get(`/projects/${id}/${type}/export`);
    return data;
}

export const deleteProjects = async (id: number[]) => {
    await Promise.all(
        id.map(async i => {

            await apiService.delete(`/projects/${i}`);

            return true;
        })
    )
}



export const upsertProject = async (data: any) => {

    if (data.testProjectID) {
        return await apiService.put(`/projects/${data.testProjectID}`, {
            ...data,
        }).catch(e => e);
    } else {
        return await apiService.post(`/projects`, {
            ...data,
        }).catch(e => e);
    }
}

export const getLanguages = async () => {
    const { data } = await apiService.get(`/languageCode`);

    return data?.data;
}

export const getGoToTypes = async () => {
    const { data } = await apiService.get(`/GoToType`);

    return data?.data;
}

export const getStepTypes = async () => {
    const { data } = await apiService.get(`/stepTypes`);

    return data?.data;
}

export const getNoises = async () => {
    const { data } = await apiService.get(`/noiseBackgrounds`);

    return data?.data;
}

export const getDigitalStepTypes = async () => {
    const { data } = await digitalApiService.get(`/stepTypes`);

    return data?.data;
}

export const getOperations = async () => {
    const { data } = await digitalApiService.get(`/matchOperations`);

    return data?.data;
}

export const getBrowsers = async () => {
    const { data } = await digitalApiService.get(`/browserTypes`);

    return data?.data;
}




export const getLocatorTypes = async () => {
    const { data } = await digitalApiService.get(`/htmlLocatorTypes`);

    return data?.data;
}


export const getBasicActions = async () => {
    const { data } = await apiService.get(`/virtualAgent/basicActions`);

    return data?.data?.basicActionResponseDTOList || [];
}


export const getScheduleTypes = async () => {
    const { data } = await apiService.get(`/schedules/types`);

    return data?.data;
}