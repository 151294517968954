import { configureStore } from '@reduxjs/toolkit'
import { appReducer } from './resuders/app.reducer';
import { projectReducer } from './resuders/project.reducer';
import { segmentReducer } from './resuders/segment.reducer';
import { promptReducer } from './resuders/prompt.reducer';
import { audioReducer } from './resuders/audio.reducer';
import { grammarReducer } from './resuders/grammar.reducer';
import { variableReducer } from './resuders/variable.reducer';
import { scheduleReducer } from './resuders/schedule.reducer';


const store = configureStore({
  reducer: {
    app: appReducer,
    project: projectReducer,
    segment: segmentReducer,
    prompt: promptReducer,
    audio: audioReducer,
    grammar: grammarReducer,
    variable: variableReducer,
    schedule: scheduleReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;