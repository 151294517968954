import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigationType, useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link, Checkbox, Tooltip } from '@mui/material';
import { deleteIcon, digital, greenCheckIcon, redCrossIcon } from '../assets';
import { DataGrid, Modal, TestResultStatusGrid, TestResultGrid } from '../ui';
import { useSelector } from 'react-redux';
import { getCurrentProject, getCurrentTestCase, getCurrentTestResult, getCurrentTestResultDetails } from '../store/selectors/project.selector';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useAppDispatch } from '../store/hooks';
import { deleteTestCases, deleteTestResults, downloadFile } from "../services/testcase.service";
import { setCurrentProjectId, setCurrentTestCase, setCurrentTestResult, setCurrentTestResultDetails, setCurrentProject } from '../store/resuders/project.reducer';
import { getAllProjects } from '../store/selectors/project.selector';
import { SearchInput } from '../ui';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getTestResultList, getTestResultlistDetails, getTestResultDetails } from '../services/testresults.service';
import { useNavigate } from "react-router-dom";
import { TestResultDetails } from './testResultsDetails';
import { getProjectById } from '../services/project.service';
import { formatTimestamp } from '../helpers/date.helper';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import { useTranslation } from 'react-i18next';
import { showHelpDetails, toggleAlert } from '../store/resuders/app.reducer';
import dayjs from 'dayjs';
import { getHelpCenter } from "../store/selectors/app.selector";
import InfoIcon from '@mui/icons-material/Info';
import { CHANNELS } from '../types/global.enum';
import apiService from '../services/api.service';
import { getVoiceApi } from '../services/config.service';



export const TestResults = () => {
    // const projects = useSelector(getAllProjects);  // Contains all projects
    const { projectId, testcaseId, testResultId } = useParams();  // ProjectID and TestCaseID from URL
    const { t } = useTranslation();

    const project = useSelector(getCurrentProject);  // Store Project
    const testResult = useSelector(getCurrentTestResult)  // Store Test Case
    const testResultStatus = useSelector(getCurrentTestCase)
    const testResultDetails = useSelector(getCurrentTestResultDetails)  // Store Test Result Details
    const [testResultsPage, setTestResultsPage] = useState(true);
    const [testStatusPage, setTestStatusPage] = useState(false);
    const [testDetailsPage, setTestDetailsPage] = useState(false);
    const [testResultFullAudio, setTestResultFullAudio] = useState<any>(null);

    const helpCenter = useSelector(getHelpCenter);

    const dispatch = useAppDispatch();
    const history = useNavigate();

    const currentDate: any = dayjs();
    const oneWeekAgoDate: any = currentDate.subtract(7,'Day');

    const [data, setData] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [remove, setRemove] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<string>('');
    const [startDateFilter, setStartDateFilter] = useState<any>(oneWeekAgoDate);
    const [stopDateFilter, setStopDateFilter] = useState<any>(currentDate);
    const [criteria, setCriteria] = useState<any>({
        sort: '-recentStart',
        pageNo: 0,
        pageSize: 10,
        filter: '',
        startDate: oneWeekAgoDate.$d.getTime(),
        stopDate: currentDate.$d.getTime(),
        projectId: project ? project?.testProjectID : projectId,
    });

    const [audioPosition, setAudioPosition] = useState<number[]>([]); // State to track audio playback position for each segment
    const [playingAudioIndex, setPlayingAudioIndex] = useState<number | null>(
        null
    ); // Index of the segment with playing audio
    const audioElementsRef = useRef<(HTMLAudioElement | null)[]>([]); // Ref to hold an array of <audio> elements




   
    //     if (data && data.content && Array.isArray(data.content)) {
    //         const aggregatedData = data.content.reduce((acc: any, result: any) => {
    //             if (!acc[result.testCaseId]) {
    //                 acc[result.testCaseId] = {
    //                     passed: result.status === 'PASS' ? 1 : 0,
    //                     failed: result.status === 'FAILED' ? 1 : 0,
    //                     totalCount: 1, // Initialize the total count
    //                     testCaseName: result.testCaseName,
    //                     startTime: result.startTime, // Store the timestamp for comparison
    //                     endTime: new Date(result.endTime).getTime(), // Store the timestamp for comparison
    //                     results: [result], // Initialize the results array with the current result
    //                     data: data.content
    //                 };
    //             } else {
    //                 if (result.status === 'PASS') {
    //                     acc[result.testCaseId].passed++;
    //                 } else if (result.status === 'FAILED') {
    //                     acc[result.testCaseId].failed++;
    //                 }
    //                 acc[result.testCaseId].totalCount++; // Increment the total count

    //                 // Update startTime if the current startTime is earlier
    //                 const startTime = new Date(result.startTime).getTime();
    //                 if (startTime > acc[result.testCaseId].startTime) {
    //                     acc[result.testCaseId].startTime = startTime;
    //                 }
    //                 acc[result.testCaseId].results.push(result);
    //             }
    //             return acc;
    //         }, {});

    //         const formattedData = {
    //             content: Object.keys(aggregatedData).map(testCaseId => ({
    //                 ...aggregatedData[testCaseId],
    //                 testCaseId: parseInt(testCaseId, 10),
    //                 startTime: (aggregatedData[testCaseId].startTime), // Use earliest startTime for each test case
    //             })),
    //         };
    //         return formattedData;
    //     } else {

    //         return null;
    //     }
    // };

    const isDigital = useMemo(()=> project?.data?.channelType === CHANNELS.DIGITAL ,[project]);

    const formatData = () => {
            if (data && Array.isArray(data)) {
            const aggregatedData = data.reduce((acc: any, result: any) => {
                if (!acc[result.testCaseId]) {
                    acc[result.testCaseId] = {
                        passed: result.status === 'PASS' ? 1 : 0,
                        failed: result.status === 'FAILED' ? 1 : 0,
                        totalCount: 1, // Initialize the total count
                        testCaseName: result.testCaseName,
                        passPercentage: result.passPercentage,
                        recentStart: result.recentStart,
                        projectId: result.projectId,
                        startTime: result.startTime, // Store the timestamp for comparison
                        // endTime: new Date(result.endTime).getTime(), // Store the timestamp for comparison
                        results: [result], // Initialize the results array with the current result
                        data: data,
                    };
                } else {
                    if (result.status === 'PASS') {
                        acc[result.testCaseId].passed++;
                    } else if (result.status === 'FAILED') {
                        acc[result.testCaseId].failed++;
                    }
                    acc[result.testCaseId].totalCount++; // Increment the total count
    
                    // Update startTime if the current startTime is earlier
                    const startTime = new Date(result.startTime).getTime();
                    if (startTime > acc[result.testCaseId].startTime) {
                        acc[result.testCaseId].startTime = startTime;
                    }
                    acc[result.testCaseId].results.push(result);
                }
    
                // Calculate the 'score' field
                acc[result.testCaseId].score = ((acc[result.testCaseId].passed / acc[result.testCaseId].totalCount) * 100).toFixed(1);
                return acc;
            }, {});
    
            const formattedData = {
                content: Object.keys(aggregatedData).map((testCaseId) => ({
                    ...aggregatedData[testCaseId],
                    testCaseId: parseInt(testCaseId, 10),
                    startTime: aggregatedData[testCaseId].startTime, // Use earliest startTime for each test case
                })),
            };
    
            return formattedData;
        } else {
            return null;
        }
    };
    

    // Function to find the earliest startTime
    function getEarliestStartTime(data: any) {
        if (data.length === 0) {
            return null; // Return null if the array is empty
        }

        let earliestStartTime = data[0].startTime;

        for (let i = 1; i < data.length; i++) {
            if (data[i].startTime < earliestStartTime) {
                earliestStartTime = data[i].startTime;
            }
        }

        return earliestStartTime;
    }

    // Function to find the latest endTime
    function getLatestEndTime(data: any) {
        if (data.length === 0) {
            return null; // Return null if the array is empty
        }

        let latestEndTime = data[0].endTime;

        for (let i = 1; i < data.length; i++) {
            if (data[i].endTime > latestEndTime) {
                latestEndTime = data[i].endTime;
            }
        }
        if (latestEndTime){
        return latestEndTime;
        } else{
             return new Date()
            }
    }


    // Columns for Test Results Status Page
    const testResultsColumns: any[] = [
        {
            caption: t('Sts'), width: '5%',
            redner: (params: any) => (
                <div className="flex-center">                    
                    <img src={params.lastRunStatus === 'PASS' ? greenCheckIcon : redCrossIcon} alt="" />
                </div>
            ),
        },
        {
            fieldName: 'testCaseId', caption: t('ID'), sortable:true, width: '5%'
        },
        {
            fieldName: 'testCaseName', caption: t('Test Case Name'), sortable: true, width: '60%',
            redner: (params: any) => (
                <div className="flex-center">
                    <Link onClick={gotoTestCaseStatusPage(params)} className="a-link-black">
                        {params.testCaseName}
                    </Link>
                </div>
            )
        },
        {
            fieldName: 'passPercentage', caption: t('Passed'), sortable:true, width: '10%',
            redner: (params: any) => {
                return <span>{`${params.numPassed}/${params.numRuns} (${params.passPercentage}%)`}</span>;
            }
        },
        {
            fieldName: 'recentStart', caption: t('Date/Time'), sortable: true, width: '15%',
            redner: (params: any) => formatTimestamp(params.recentStart)
        },
    ];


    // Columns for Test Results Status Page
    const testStatusColumns: any[] = [
        { caption: 'ID', width: '7%', fieldName: 'id', sortable: false, key: true },
        {
            fieldName: 'testCaseName', caption: t('TEST CASE NAME'), width: '40%',
            redner: (params: any) => <div className="flex-center">
                <Link onClick={gotoTestCaseDetailsPage(params)} className="a-link-black">{params.testCaseName}</Link></div>
        },
        {
            fieldName: 'startTime', caption: t('DATE TIME'), width: '15%',
            redner: (params: any) => {
                const startTime = new Date(params.startTime);
                return (formatTimestamp(startTime))

                // const startTime = new Date(params.startTime);
                // const formattedDate = startTime.toLocaleDateString('en-US', {
                //     year: 'numeric',
                //     month: '2-digit',
                //     day: '2-digit'
                // });
                // const formattedTime = startTime.toLocaleTimeString('en-US', {
                //     hour: '2-digit',
                //     minute: '2-digit',
                //     second: '2-digit'
                // });
                // return <span>{formattedDate}&nbsp;&nbsp;&nbsp;{formattedTime}</span>;
            }
        },

        {
            fieldName: 'duration', caption: t('DURATION'), width: '10%',
            redner: (params: any) => {
                const startTime = new Date(params.startTime);
                const endTime = new Date(params.endTime);
                const durationMilliseconds = endTime.getTime() - startTime.getTime();
                const durationInSeconds = durationMilliseconds / 1000;
                if (durationInSeconds < 0) {
                    return `0 secs`;
                } else {
                    return `${durationInSeconds.toFixed(2)} secs`;
                }
            }
        },
        {
            fieldName: 'mosScore', caption: t('MOS'), width: '5%',
        },
        {
            fieldName: 'status', caption: t('STATUS'), width: '5%',
            redner: (params: any) => (
                <span style={{ color: params?.status === 'FAILED' ? 'red' : params?.status === 'RUNNING' ? 'orange' : '#58A148' }}>
                    {params?.status}
                </span>
            )
        },
        {
            fieldName: 'action', width: '20%',
            caption: t('ACTION'),
            redner: (params: any) => (

                <div>
                    {/* <Tooltip title="Download Audio">
                            <FileDownloadOutlinedIcon
                                onClick={() => {
                                    downloadAudio(params?.recordingUrl);
                                    console.log("RecordingUrl:", params)
                                }}
                                style={{ fontSize: '35px', cursor: 'pointer' }}
                            />
                        </Tooltip> */}
                    <audio controls>
                        <source src={params?.recordingUrl} type="audio/wav" />
                    </audio>
                </div>

            ),
        }
    ];

    // Columns for Test Results Status Page
    const testDetailsColumns: any[] = [
        { caption: t('Test Case ID'), width: '55px', fieldName: 'id' },
        { caption: t('Test Case Name'), width: '55px', fieldName: 'name' },
        {
            caption: t('MOS Score'), width: '55px', fieldName: 'mos',
            redner: (params: any) => {
                return `${params.data.testResult.voice.mos}`;
            }
        },
        {
            caption: t('Test Run ID'), width: '55px', fieldName: 'testRunId',
            redner: (params: any) => {
                return `${params.data.testResult.id}`;
            }
        },
        {
            caption: t('From'), width: '55px', fieldName: 'creationTime',
            redner: (params: any) => {
                // return `${formatDate(params.data.testResult?.startTime)} to ${formatDate(params.data.testResult?.endTime)}`;
                return `${formatTimestamp(params.data.testResult?.startTime)} to ${formatTimestamp(params.data.testResult?.endTime)}`;
            }
        },
        {
            caption: t('Duration'), width: '55px', fieldName: 'duration',
            redner: (params: any) => {
                const startTime = new Date(params.data.testResult.startTime);
                const endTime = new Date(params.data.testResult.endTime);
                const durationMilliseconds = endTime.getTime() - startTime.getTime();
                const durationInSeconds = durationMilliseconds / 1000;
                if (durationInSeconds < 0) {
                    return `0 secs`;
                } else {
                    return `${durationInSeconds.toFixed(2)} secs`;
                }
            }
        }
    ];



    const openTestResultsView = () => {
        setTestResultsPage(true)
        setTestStatusPage(false)
        setTestDetailsPage(false)
        history(`/projects/${project?.testProjectID || projectId}/testresults`);
    }

    const openTestResultsStatusView = () => {
        setTestResultsPage(false)
        setTestStatusPage(true)
        setTestDetailsPage(false)
    }

    const openTestResultsDetailsView = () => {
        setTestResultsPage(false)
        setTestStatusPage(false)
        setTestDetailsPage(true)
    }

    const gotoTestCaseStatusPage = (testCase: any, navigate = true) => async () => {
        dispatch(setCurrentTestResult(testCase));
        const { data: tempData } = await getTestResultlistDetails(testCase?.projectId || projectId ,testCase?.testCaseId || testcaseId,10,criteria.startDate, criteria.stopDate,project.data.channelType);
        const { data } = await getTestResultlistDetails(testCase?.projectId || projectId ,testCase?.testCaseId || testcaseId, tempData.totalElements || 10 ,criteria.startDate, criteria.stopDate,project.data.channelType);
        dispatch(setCurrentTestCase(data));

        if(navigate) {
             history(`/projects/${project?.testProjectID || projectId}/testresults/${testCase?.testCaseId || testcaseId}`);
            openTestResultsStatusView();
        }
       
    }

    const gotoTestCaseDetailsPage = (testResult: any) => async () => {
        const { data } = await getTestResultDetails(testResult?.id, +(project?.testProjectID || projectId || 1), project.data.channelType);
        dispatch(setCurrentTestResultDetails(data));

        const downloadedTestResultFullAudio : Blob = await downloadFile(testResult?.id);
        setTestResultFullAudio(downloadedTestResultFullAudio);

        history(`/projects/${project?.testProjectID || projectId}/testresults/${testResult.testCaseId}/${data.testResult.id || data.testResult.resultId}`);
        openTestResultsDetailsView();
    }


    const loadData = async () => {
        const project = await getProjectById(Number(projectId));
        dispatch(setCurrentProject(project));
        dispatch(setCurrentProjectId(projectId));
        if (testResultsPage) {
            const { data } = await getTestResultList(criteria.projectId, 10, criteria.pageNo, criteria.filter, criteria.startDate, criteria.stopDate, project.data.channelType, criteria.sort);
            setData(data?.content);
            setTotalPages(data?.totalPages || 0);
        } else if (testStatusPage) {
            const { data: tempData } = await getTestResultList(criteria.projectId);
            const { data } = await getTestResultList(criteria.projectId, tempData.totalElements || 10, 0, criteria.filter);
            setData(data?.content);
        } else if (testDetailsPage) {
            // Handle testDetailsPage logic if needed
        }
    };


    const playAudio = (attachmentUrl: string | undefined) => {
        if (attachmentUrl) {
            const desiredURL = attachmentUrl

            // Create an audio element if it doesn't exist
            if (!audioElementsRef.current[0]) {
                const audio = new Audio(desiredURL);

                audio.addEventListener("ended", () => {
                    setPlayingAudioIndex(null); // Reset the playing audio index
                });

                audioElementsRef.current[0] = audio;
            }

            const audioElement = audioElementsRef.current[0];

            if (audioElement.paused) {
                // If the audio is paused, play it
                audioElement.play();
                setPlayingAudioIndex(0);
            } else {
                // If the audio is playing, pause it
                audioElement.pause();
                setPlayingAudioIndex(null);
            }
        }
    };



    // const sortChanged = (column: string, direction: string) => {
    //     if (column === 'startTime') {
    //         setCriteria({
    //             ...criteria,
    //             pageNo: 0,
    //             sort: `${direction === 'ASC' ? '+' : '-'}startTime`,
    //         });
    //     } else {
    //         setCriteria({
    //             ...criteria,
    //             pageNo: 0,
    //             sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
    //         });
    //     }
    // };
    const sortChanged = (column: string, direction: string) => {

        // if (column === 'startTime') {
        //     setCriteria({
        //         ...criteria,
        //         pageNo: 0,
        //         sort: `${direction === 'ASC' ? '+' : '-'}startTime`,
        //     });
        // } else if (column === 'status') {
        //     // Handle sorting by the 'status' field
        //     setCriteria({
        //         ...criteria,
        //         pageNo: 0,
        //         sort: `${direction === 'ASC' ? '+' : '-'}score`,
        //     });
        // } else {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
            });
        // }
    };
    

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    

    const selectionChanged = (id: any) => {
        setRemove(id);
    }

    const deleteSelected = async () => {
        if (remove?.length) {
            setIsOpen(`${new Date().getTime()}`);
        }
    }

    const deleteTestResult = async () => {
        await deleteTestResults(project.testProjectID, remove);
        await gotoTestCaseStatusPage(testResultStatus, false)();
        setRemove([]);
    }

    const onFilterChanged = (filter: string) => {
        if(startDateFilter || stopDateFilter){
            if(startDateFilter && stopDateFilter){
                setCriteria({
                    ...criteria,
                    pageNo: 0,
                    filter,
                    startDate: startDateFilter.$d.getTime(),
                    stopDate: stopDateFilter.$d.getTime()
                })
            } else{
                dispatch(toggleAlert('Please pick both start and stop date.'));
            }
        }else{
            setCriteria({
                ...criteria,
                pageNo: 0,
                filter
            })
        }
    }

    const onStartDateChanged = (startDate: any) => {
        if(!startDate){
            setStartDateFilter(null);
            return;
        }
        if (typeof startDate === 'object') setStartDateFilter(startDate);
    }

    const onStopDateChanged = (stopDate: any) => {
        if(!stopDate){
            setStopDateFilter(null);
            return;
        }

        if (stopDate) setStopDateFilter(stopDate);
    }

    const formatDate = (dateTimeString: any) => {
        if (!dateTimeString) return '';

        const date = new Date(dateTimeString);
        const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    };


    useEffect(() => {
        loadData();
        dispatch(setCurrentTestCase(null));
        if (testResultId) {
            openTestResultsDetailsView();
        } else if (testcaseId) {
            openTestResultsStatusView();
        } else {
            openTestResultsView();
        }
    }, [criteria])



    const gridItemSize = 12


    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container>
                {/* Header */}
                <Grid item xs={testStatusPage || testDetailsPage ? 12:6} paddingTop={'16px'} paddingBottom={'10px'}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                        <Typography variant="subtitle2">{t('Project')} :</Typography>
                        <Typography variant="subtitle2">{project ? project?.testProjectID : projectId}</Typography>
                        {project?.data?.channelType === CHANNELS.VOICE ? (<PhoneEnabledOutlinedIcon style={{ fontSize: '17px' }} />) : (<img src={digital} alt="" width={17}/>)}
                        {project ?
                            <>
                                <Typography variant="subtitle2">
                                    <Link
                                        onClick={() => {
                                            openTestResultsView();
                                            // gotoTestCaseStatusPage(testResult)();
                                        }}
                                        className="a-link-black"
                                    >
                                        {/* {projects[`${projectId}`]?.name} */}
                                        {project?.data?.name}
                                    </Link>
                                </Typography>
                            </>
                            : null}
                            <Typography variant="subtitle2">{'>'}</Typography>
                            <Typography variant="subtitle2">
                                <Link
                                    onClick={() => {
                                        openTestResultsView();
                                    }}
                                    className="a-link-black"
                                >
                                    {t('Manual Test Results')}
                                </Link>
                            </Typography>
                        {(testStatusPage || (testDetailsPage)) &&
                            <>
                                <Typography variant="subtitle2">{'>'}</Typography>
                                <Link onClick={gotoTestCaseStatusPage(testResult)} className="a-link-black">
                                    <Typography variant="subtitle2">{testResult?.testCaseId ? testResult?.testCaseId : testResultDetails?.id} {testResult?.testCaseName ? testResult?.testCaseName : testResultDetails?.name}</Typography>
                                </Link>
                            </>
                        }
                        {testDetailsPage &&
                            <>
                                <Typography variant="subtitle2">{'>'}</Typography>
                                <Typography variant="subtitle2">{testResultDetails?.testResult?.id || testResultDetails?.testResult?.resultId}</Typography>

                            </>
                        }
                        {helpCenter && <div onClick={()=>dispatch(showHelpDetails(''))} className="help-icon-float-right"><InfoIcon /></div>}
                    </div>
                </Grid>


                {/* Case Editor or DataGrid */}
                {testResultsPage && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <div>
                            {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Test Results'))} className="help-icon-float"><InfoIcon /></div>}
                            <Card>
                                <CardContent>
                                    <div className='test-result-header'>
                                        <div className="action-header">
                                            {/* <FileUploadOutlinedIcon style={{ fontSize: '30px' }} />
                                            <img src={deleteIcon} alt={t('Remove')}onClick={deleteSelected} /> */}
                                        </div>
                                        <div className="test-results-filters">
                                            <DatePicker
                                                value={startDateFilter}
                                                format='YYYY/MM/DD'
                                                label={t("Start Date")}
                                                className='start-date-field'
                                                onChange={onStartDateChanged}
                                                
                                                slotProps={{ textField: { size: 'medium' }}}
                                            />
                                            <DatePicker
                                                value={stopDateFilter}
                                                format='YYYY/MM/DD'
                                                label={t("Stop Date")}
                                                className='stop-date-field'
                                                onChange={onStopDateChanged}
                                                slotProps={{ textField: { size: 'medium' }}}
                                            />
                                            <SearchInput onFilter={onFilterChanged} />
                                        </div>
                                    </div>
                                    <TestResultGrid 
                                        pageChanged={pageChanged} 
                                        totalPages={totalPages}
                                        sortChanged={sortChanged}
                                        columns={testResultsColumns} 
                                        data={data} 
                                        selection={false} 
                                        sortBy={criteria.sort}
                                        selectionChanged={selectionChanged} />
                                </CardContent>
                            </Card>
                        </div>
                        
                    </Grid>
                )}
                {testStatusPage && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <Card>
                            <CardContent>
                                <div className='test-result-header'>
                                    <div className="action-header">
                                        <img src={deleteIcon} alt={t('Remove')}onClick={deleteSelected} />
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                                            <Typography variant="subtitle2"><strong>{t('Test Case ID')}:</strong> #{testResult?.testCaseId}</Typography>
                                            <Typography variant="subtitle2"><strong>{t('Test Case Name')}:</strong> {testResult?.testCaseName}</Typography>
                                            <Typography variant="subtitle2">
                                                {/* <strong>From:</strong> {formatDate(testResult?.startTime)} to {formatDate(testResult?.endTime)} */}
                                                <strong>{t('From')}:</strong> {formatTimestamp(getEarliestStartTime(testResultStatus?.content || []))} to {formatTimestamp(getLatestEndTime(testResultStatus?.content || []))}
                                            </Typography>
                                        </div>

                                    </div>
                                    {/* <div className="test-results-filters">
                                        <SearchInput onFilter={onFilterChanged} />
                                    </div> */}
                                </div>
                                <TestResultStatusGrid
                                    pageChanged={pageChanged}
                                    totalPages={data?.totalPages || 0}
                                    sortChanged={sortChanged}
                                    columns={testStatusColumns.filter(c=>!isDigital || (c.fieldName !== 'mosScore' && c.fieldName !== 'action'))}
                                    data={
                                        testResultStatus?.content || []
                                    }
                                    selection={true}
                                    sortBy={criteria.sort}
                                    selectionChanged={selectionChanged}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {testDetailsPage && (
                    <TestResultDetails
                        pageChanged={pageChanged}
                        totalPages={data?.totalPages || 0}
                        sortChanged={sortChanged}
                        columns={testDetailsColumns}
                        data={
                            testResultDetails || []
                        }
                        selection={true}
                        sortBy={criteria.sort}
                        selectionChanged={selectionChanged}
                        formatDate={formatDate}
                        onFilterChanged={onFilterChanged}
                        criteria={criteria}
                        testResultFullAudio={testResultFullAudio}
                    />

                )}



                <Modal
                    isOpen={isOpen}
                    title={t("Delete Items")}
                    small={true}
                    deleteConfirmation={true}
                    ButtonDelete={deleteTestResult}
                />

            </Grid>
        </DndProvider>

    );
};
