import { Checkbox, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { IGridcolumn } from "./interface/column.interface";
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Pagination from '@mui/material/Pagination';

export interface IProps {
    columns: IGridcolumn[];
    data: any;
    selection?: boolean;
    selectionChanged?: (id: string[]) => void;
    sortBy?: string;
    sortDirection?: 'ASC' | 'DESC' | '';
    sortChanged?: (column: string, direction: string) => void;
    pageChanged?: (page: number) => void;
    totalPages?: number;
}
export const PerpetualScheduleGrid = ({
    columns = [],
    data = [],
    selection = false,
    selectionChanged,
    sortChanged,
    sortBy = '',
    sortDirection = 'ASC',
    totalPages = 0,
    pageChanged,
}: IProps) => {

    const [order, setOrder] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [direction, setDirection] = useState<'ASC' | 'DESC' | ''>('ASC');

    const [selected, setSelected] = useState<string[]>([]);

    const RenderItem = ({ col = null as unknown as IGridcolumn, data = {} }) => {
        const Component = col?.redner;

        return (
            <Component  {...data} />
        )
    }

    const updateSort = (col: IGridcolumn) => () => {
        if (col.sortable && sortChanged) {
            if (sort === col.fieldName) {
                sortChanged(col.fieldName, direction === 'ASC' ? 'DESC' : 'ASC');
            } else {
                sortChanged(col.fieldName, 'ASC');
            }
            setSort(col.fieldName);

        }
    }

    const key = useMemo(() => columns.find(c => c.key)?.fieldName || 'id', [columns]);

    const checkChanged = (id: string) => (e: any) => {
        if (e.target.checked) {
            setSelected(selected.concat(id))
        } else {
            setSelected(selected.filter(s => s !== id));
        }
    }

    const onPageChanged = (e: any, page: number) => {
        pageChanged && pageChanged(page)
    }

    useEffect(() => {
        setOrder(sortBy.substring(1));
        setDirection(sortBy.charAt(0) === '-' ? 'DESC' : 'ASC');
    }, [sortBy, sortDirection])


    useEffect(() => {
        selectionChanged && selectionChanged(selected);
    }, [selected])

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {/* {selection && (
                        <TableCell width={'55px'}>
                            Select
                        </TableCell>
                    )} */}
                    {columns.map(col => (
                        <TableCell
                            width={col.width || ''}
                            key={`col_${col.fieldName}`}
                            style={{
                                textAlign: col?.caption === "ACTION" ? 'center' : 'left'
                            }}
                        >
                            <div onClick={updateSort(col)} className={`${col?.caption === 'ACTION' ? '' : 'flex-center'} ${col.sortable ? 'pointer' : ''}`}>
                                {col.caption}

                                {col.fieldName === order && (
                                    direction === 'ASC' ?
                                        <ArrowDropUpOutlinedIcon />
                                        :
                                        <ArrowDropDownOutlinedIcon />
                                )}
                            </div>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                {data.map((row: any, index: number) => (
                    <TableRow key={`row_${index}`}>
                        {/* {selection && (
                            <TableCell>
                                <Checkbox color="primary" onChange={checkChanged(row[key])} checked={selected.indexOf(row[key]) !== -1} />
                            </TableCell>
                        )} */}
                        {columns.map(col => (
                            col.redner ? 
                            <TableCell 
                            key={`col_${index}_${col.fieldName}`}
                            style={{
                                textAlign: col?.caption === "ACTION" ? 'center' : 'left'
                            }}
                            >
                                <RenderItem col={col} data={row} />
                                </TableCell> :
                                <TableCell 
                                key={`col_${index}_${col.fieldName}`}>
                                    {row[col.fieldName]}
                                    </TableCell>
                        ))}
                    </TableRow>
                ))}
                {data?.length < 10 && (
                    Array.from(new Array(10 - data.length)).map((_, index) => (
                        <TableRow key={`extra_row_${index}`}>
                            <TableCell></TableCell>
                        </TableRow>
                    ))
                )}
            </TableBody>

            {/* {totalPages>1 && (
                <TableFooter >
                    <tr>
                        <td colSpan={columns.length + (selected ? 1 : 0)}>
                            <Pagination onChange={onPageChanged} count={totalPages} showFirstButton showLastButton />
                        </td>
                    </tr>
                </TableFooter>
            )} */}
        </Table>
    )
}