import { createAction, createReducer } from "@reduxjs/toolkit";

export const getAllProjects = createAction<any>('getAllProjects');
export const setCurrentProject = createAction<any>('setCurrentProject');
export const setprojectSegments = createAction<any[]>('setprojectSegments');
export const addProjectSegments = createAction<any[]>('addProjectSegments');
export const updateProjectSegments = createAction<any[]>('updateProjectSegments');
export const setLanguages = createAction<any[]>('setLanguages');
export const setGoToTypes = createAction<any[]>('setGoToTypes');
export const setStepTypes = createAction<any[]>('setStepTypes');
export const setDigitalStepTypes = createAction<any[]>('setDigitalStepTypes');
export const setLocatorTypes = createAction<any[]>('setLocatorTypes');
export const setOperations = createAction<any[]>('setOperations');
export const setBasicActions = createAction<any[]>('setBasicActions');
export const setCurrentTestCase = createAction<any>('setCurrentTestCase');
export const setCurrentTestResult = createAction<any>('setCurrentTestResult');
export const setCurrentTestResultDetails = createAction<any>('setCurrentTestResultDetails');
export const setCurrentProjectId = createAction<any>('setCurrentProjectId');
export const setScheduleTypes = createAction<any[]>('setScheduleTypes');
export const setBrowsers = createAction<any[]>('setBrowsers');
export const setNoises = createAction<any[]>('setNoises');

export const projectReducer = createReducer(null, (builder) => {

    builder.addCase(getAllProjects, (state: any, action) => {

        return {
            ...state,
            allProjects: action.payload,
        }
    }).addCase(setCurrentProject, (state: any, action) => {

        return {
            ...state,
            project: action.payload,
        }
    }).addCase(setLocatorTypes, (state: any, action) => {

        return {
            ...state,
            locatorTypes: action.payload,
        }
    }).addCase(setOperations, (state: any, action) => {

        return {
            ...state,
            operations: action.payload,
        }
    }).addCase(setCurrentProjectId, (state: any, action) => {

        return {
            ...state,
            project: {
                ...state?.project,
                testProjectID: +action.payload,
            },
        }
    }).addCase(setStepTypes, (state: any, action) => {

        return {
            ...state,
            stepTypes: action.payload,
        }
    }).addCase(setDigitalStepTypes, (state: any, action) => {

        return {
            ...state,
            digitalStepTypes: action.payload,
        }
    }).addCase(setBrowsers, (state: any, action) => {

        return {
            ...state,
            browsers: action.payload,
        }
    }).addCase(setNoises, (state: any, action) => {

        return {
            ...state,
            noises: action.payload,
        }
    }).addCase(setBasicActions, (state: any, action) => {

        return {
            ...state,
            basicActions: action.payload,
        }
    }).addCase(setCurrentTestCase, (state: any, action) => {

        return {
            ...state,
            testCase: action.payload,
        }
    }).addCase(setCurrentTestResult, (state: any, action) => {

        return {
            ...state,
            testResult: action.payload,
        }
    }).addCase(setCurrentTestResultDetails, (state: any, action) => {

        return {
            ...state,
            testResultDetails: action.payload,
        }
    }).addCase(setGoToTypes, (state: any, action) => {

        return {
            ...state,
            goToTypes: action.payload,
        }
    }).addCase(setLanguages, (state: any, action) => {

        return {
            ...state,
            languages: action.payload,
        }
    }).addCase(setprojectSegments, (state: any, action) => {

        return {
            ...state,
            projectSegments: action.payload,
        }
    }).addCase(addProjectSegments, (state: any, action) => {

        return {
            ...state,
            projectSegments: (state.projectSegments || []).concat(action.payload),
        }
    }).addCase(updateProjectSegments, (state: any, action: any) => {

        return {
            ...state,
            projectSegments: (state.projectSegments || []).reduce((result: any, curr: any) => {
                if (curr.testSegmentVoiceId === action.payload.testSegmentVoiceId) {
                    result.push({
                        ...curr,
                        ...action.payload,
                    });
                } else {
                    result.push(curr);
                }

                return result;
            }, [])
        }
    }).addCase(setScheduleTypes, (state: any, action) => {

        return {
            ...state,
            scheduleTypes: action.payload,
        }
    })
})

