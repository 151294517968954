import apiService from "./api.service";
import { convertDateFormat } from "../helpers/date.helper";


export const getAudioList = async (projectId = 1, pageSize = 10, pageNo = 0, sort = '', filter = '') => {
    const { data } = await apiService.get(`/projects/${projectId}/audios?pageNo=${pageNo - 1}&pageSize=${pageSize}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`);
    return data;
}

export const previewNoise = async (noiseBackground: string, tts: string) => {
    const { data } = await apiService.post(`/mixedTts?archived=false`, {
        noiseBackground,
        tts
    });
    return data;
}

export const deleteAudio = async (projectId: number, audioIds: number[]) => {
    return await Promise.all(
        audioIds.map(async id => {
            if (id) {
                await apiService.delete(`projects/${projectId}/audios/${id}`)
            }

            return null;
        })
    ).catch(e => e)

}

// export const upsertAudio = async (projectId: number, audio: any, editorState: string) => {
//     console.log("Audio:", audio)
//     if (editorState == "create") {
//         return await apiService.post(`/projects/${projectId}/audios`, { ...audio })
//             .catch(e => e);
//     } else {
//         return await apiService.put(`/projects/${projectId}/audios/${audio.libraryID}`, { ...audio })
//             .catch(e => e);
//     }
// }

export const upsertAudio = async (projectId: number, audio: any, editorState: string) => {
    const formData = new FormData();

    // Add the audio file to the form data
    formData.append('file', audio.binaryContent);

    // Add other fields to the form data
    formData.append('name', audio.name);
    formData.append('description', audio.description);
    formData.append('textContent', audio.textContent);
    formData.append('languageCodeId', audio.languageCodeId);
    formData.append('projectId', projectId.toString());
    formData.append('lastModifiedTime', convertDateFormat(audio.lastModifiedTime))

    if (editorState === "create") {
        return await apiService.post(`/projects/${projectId}/audios`, formData)
            .catch(e => e);
    } else {
        return await apiService.put(`/projects/${projectId}/audios/${audio.libraryID}`, formData)
            .catch(e => e);
    }
}
