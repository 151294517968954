import { FormHelperText, MenuItem, Select } from "@mui/material";
import { useTranslation } from 'react-i18next';


export interface IProps {
    itemsPerPage?: number;
    handleItemsPerPageChange?: (size: number) => void;
}
export const PageSizeSelector = ({
    itemsPerPage = 10,
    handleItemsPerPageChange,
}: IProps) => {
    const { t } = useTranslation();

    const changed = (e: any) => {
        handleItemsPerPageChange && handleItemsPerPageChange(+e.target.value);
    }

    return (
        <div className="flex-center justify-end">
            <FormHelperText sx={{ color: '#2196F3' }}>{t('items per page')}</FormHelperText>
            <Select
                defaultValue={itemsPerPage}
                onChange={changed}
                variant="standard"
                style={{ width: '82px' }}
            >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
            </Select>
        </div>
    )
}