import { Checkbox, Table, TableBody, TableCell, TableRow, FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { IGridcolumn } from "./interface/column.interface";
import { useDrag } from 'react-dnd'
import { dragIcon } from "../assets";
import { FormikProps } from "formik";
import { ISegment } from "../types/interfaces/segment.interface";
import { useSelector } from "react-redux";
import { getLanguages } from "../store/selectors/project.selector";
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    columns: IGridcolumn[];
    data: any;
    selection?: boolean;
    selectionChanged?: (id: string[]) => void;
    sortBy?: string;
    sortDirection?: 'ASC' | 'DESC' | '';
    sortChanged?: (column: string, direction: string) => void;
    showLanguages?:boolean;
}
export const VariableLibraryDataGrid = ({
    formik,
    columns = [],
    data = [],
    selection = false,
    selectionChanged,
    sortChanged,
    sortBy = '',
    sortDirection = 'ASC',
    showLanguages = true,
}: IProps) => {
    const { t } = useTranslation();

    const [order, setOrder] = useState<string>('');
    const [direction, setDirection] = useState<'ASC' | 'DESC' | ''>('ASC');
    const [selected, setSelected] = useState<string[]>([]);
    const languages = useSelector(getLanguages);
    const RenderItem = ({ col = null as unknown as IGridcolumn, data = {} }) => {
        const Component = col?.redner;
        return <Component  {...data} />;
    };
    const updateSort = (col: IGridcolumn) => () => {
        if (col.sortable && sortChanged) {
            sortChanged(col.fieldName, direction === 'ASC' ? 'DESC' : 'ASC');
        }
    };
    const checkChanged = (id: string) => (e: any) => {
        if (e.target.checked) {
            setSelected(selected.concat(id));
        } else {
            setSelected(selected.filter(s => s !== id));
        }
    };
    useEffect(() => {
        setOrder(sortBy);
        setDirection(sortDirection);
    }, [sortBy, sortDirection]);
    useEffect(() => {
        selectionChanged && selectionChanged(selected);
    }, [selected]);
    const ListItem = ({ ...props }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'ROWS',
            item: {
                ...props.data,
                defaultLng: formik.values?.language,
            },
            collect: (monitor: any) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }))
        return (<TableRow style={{ height: '30px', padding: 0 }} className="relative" ref={drag}>{props.children}</TableRow>);
    }

    return (
        <>
            {showLanguages && <Box className="library-language">
                <FormControl sx={{ width: "40%" }}>
                    <InputLabel id="case-editor-language">{t('Language')}</InputLabel>
                    <Select
                        labelId="case-editor-language"
                        id="language"
                        value={formik.values?.language}
                        label={t("Language")}
                        name="language"
                        onChange={formik.handleChange}
                    >
                       {languages.map((lng:any) => (
                            <MenuItem key={`opt_lng_${lng.id}`} value={lng.id}>{lng.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>}
            
            <Table className="table-library">
                <TableBody>
                    {data.map((row: any, index: number) => (
                        <ListItem key={`row_${index}`} data={row} >
                            <img src={dragIcon} alt="" className={"drag-icon none " + (selected.indexOf(row.id) !== -1 ? 'visible' : '')} />

                            {selection && (
                                <TableCell background-color={"red"} style={{ maxWidth: '60px', width: '60px' }} >
                                    <Checkbox color="primary" onChange={checkChanged(row.id)} checked={selected.indexOf(row.id) !== -1} />
                                </TableCell>
                            )}
                            
                            {columns.map(col => (
                                col.redner ? (
                                    <TableCell key={`col_${index}_${col.fieldName}`} style={col.width ? { maxWidth: col.width, width: col.width, textAlign: 'left' } : { textAlign: 'left' }} >
                                        <RenderItem col={col} data={row} />
                                    </TableCell>
                                ) : (
                                    <TableCell key={`col_${index}_${col.fieldName}`} style={col.width ? { maxWidth: col.width, width: col.width, textAlign: 'left' } : { textAlign: 'left' }}>
                                        {row[col.fieldName]}
                                    </TableCell>
                                )
                            ))}
                        </ListItem>

                    ))}
                    {data?.length < 20 && (
                        Array.from(new Array(Math.abs(12 - data.length))).map((_, index) => (
                            <TableRow key={`extra_row_${index}`}>
                                <TableCell sx={{ height: '33px' }}></TableCell>
                            </TableRow>
                        ))
                    )}

                </TableBody>
            </Table>
        </>
    );
};