import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const appSelectors = (state: IAppState) => state.app;


export const getTypeData = createSelector(
    [appSelectors],
    (app) => app?.data
);

export const getGlobalNotification = createSelector(
    [appSelectors],
    (app) => app?.globalNotification
);


export const getReloadSegments = createSelector(
    [appSelectors],
    (app) => app?.reloadSegments,
);


export const getSpinnerStatus = createSelector(
    [appSelectors],
    (app) => app?.showSpinner || false
);

export const getReloadLibraries = createSelector(
    [appSelectors],
    (app) => app?.reloadLibraries,
);

export const getGlobalAlert = createSelector(
    [appSelectors],
    (app) => app?.globalAlert
);


export const getProjectGrammarOptions = createSelector(
    [appSelectors],
    (app) => app?.grammarOptions || [],
);

export const getHelpCenter = createSelector(
    [appSelectors],
    (app) => app?.helpCenter || '',
);

export const getHelpCenterByKey = createSelector(
    [appSelectors],
    (app) => app?.helpKey || '',
);