import axios from "axios";
import { getVoiceApi } from "./config.service";


export const getToken = () => {
    if (localStorage.getItem('TOKEN')) {

        return {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
    }

    return {};
}

export const SSO_URL = 'https://sso-lensapi.tekvision.com';

export default axios.create({
    baseURL: getVoiceApi(),
    //withCredentials: true,
    timeout: 30000,
    ...getToken(),
});


export const UserApi = axios.create({
    baseURL: getVoiceApi()?.replace('/voice/', '/user/'),
    timeout: 30000,
    ...getToken(),
});


export const ssoApi = axios.create({
    baseURL: `${SSO_URL}/api`,
    timeout: 30000,
});


export const lensApi = axios.create({
    baseURL: `https://api.tekvision.com`,
    timeout: 30000,
});