import { useState } from 'react';
import { Button, Card, CardContent, Typography, InputLabel, FormControl, Select, MenuItem, Box, TextField, Grid } from "@mui/material"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useAppDispatch } from "../store/hooks"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import { getEditSegment, getSelectedSegments } from '../store/selectors/segment.selector';
import { getAllStepTypes, getCurrentProject, getCurrentTestCase, getGoToTypes, getLanguages } from '../store/selectors/project.selector';
import { ports, scheduleTypes, status, trigger } from '../helpers/form.helper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { PerpetualSchedule } from './perpetualSchedule';
import { FormikProps } from "formik";
import { RecurrenceEditor } from './recurrenceEditor';
import { useParams } from 'react-router-dom';
import { greenCheckIcon } from '../assets';
import { formatTimestamp } from '../helpers/date.helper';
import { DataGrid } from '../ui';
import { getTestCaseList, getTestCase } from '../services/testcase.service';
import { SearchInput } from '../ui';
import { addTestCasesToSchedule, clearScheduleTestCases } from '../store/resuders/schedule.reducer';
import { getSelectedTestCases } from '../store/selectors/schedule.selector';
import { useTranslation } from 'react-i18next';
import { CHANNELS} from "../types/global.enum";

export const ScheduleTestCaseEditor = ({
    formik,
    setAddRecurrenceOpen,
    addRecurrenceOpen = null as any,
    toggleRecurrenceOpen,
    setRecurrenceEditorState,  // ADD or EDIT
    recurrenceEditorState = null as any,
    testCaseData = null as any,
    setTestCaseData
}: {
    formik: FormikProps<any>;
    setAddRecurrenceOpen: (value: boolean) => void;
    addRecurrenceOpen: boolean;
    toggleRecurrenceOpen: () => void
    setRecurrenceEditorState: (value: string) => void
    recurrenceEditorState: string;
    testCaseData: any
    setTestCaseData: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const { t } = useTranslation();
    const project = useSelector(getCurrentProject);
    const selectedTestCases = useSelector(getSelectedTestCases);
    const { projectId } = useParams();  // ProjectID and TestCaseID from URL
    const dispatch = useAppDispatch();

    const [data, setData] = useState<any>([]);
    // const [testCaseData, setTestCaseData] = useState<any>([]);
    const [testcasesToAdd, setTestcasesToAdd] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<string>('');
    const [criteria, setCriteria] = useState<any>({
        sort: '-lastModifiedTime',
        pageNo: 0,
        pageSize: 10,
        filter: '',
        projectId: project ? project.testProjectID : projectId
    });


    const columns: any[] = [
        { caption: t('ID'), width: '55px', fieldName: 'testCaseId', sortable: true, key: true },
        {
            fieldName: 'name', caption: t('Test Case Name')
        },
        { fieldName: 'lastModifiedTime', caption: t('Last Manual Run Date'), redner: (params: any) => formatTimestamp(params.lastModifiedTime) },
        {
            caption: t('Last Manual Run Status'), width: '51px',
            redner: (params: any) => (
                <div className="flex-center">
                    <img src={greenCheckIcon} alt="" />
                </div>
            ),
        },
    ];


    const loadData = async () => {
        const { data } = await getTestCaseList(criteria.projectId, project?.data?.channelType, criteria.pageNo, criteria.pageSize, criteria.sort, criteria.filter);
        setData(data)
    }



    const sortChanged = (column: string, direction: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
        })
    }

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    const pageSizeChanged = (pageSize:number)=> {
        setCriteria({
            ...criteria,
            pageNo: 0,
            pageSize,
        })
    }

    const selectionChanged = async (id: any) => {
        setTestcasesToAdd(id)
    }

    const onFilterChanged = (filter: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            filter,
        })
    }


    const addTestCases = async () => {
        console.log("TestCases to Add:", testcasesToAdd);
    
        // Fetch and add test cases to the schedule
        const updatedTestCases = [...formik.values.testCases];
    
        const testCasesPromises = testcasesToAdd.map(async (id: any) => {
            console.log("ID:", id);
            try {
                const response = await getTestCase(criteria.projectId, id, project?.data?.channelType);
                const testcase = response.data.data; // Entire response is the testcase
                testcase.caseSeq = updatedTestCases.length + 1;
                updatedTestCases.push(testcase);
    
                // Update the Formik field value
                formik.setFieldValue('testCases', updatedTestCases);
    
                console.log("TestCaseData:", updatedTestCases);
            } catch (error) {
                console.error("Error fetching testcase:", error);
            }
        });
    
        // Wait for all promises to resolve
        await Promise.all(testCasesPromises);
    };
    
    

    useEffect(() => {
        loadData();
        dispatch(clearScheduleTestCases())
        // dispatch(setCurrentTestCase(null));
    }, [criteria])



    return (
        <Card>
            <CardContent>
                {/* <DataGrid columns={columns} data={data} selection={true} sortBy={'id'} /> */}
                <div className="test-case-editor-filter">
                    <SearchInput onFilter={onFilterChanged} />
                </div>

                <DataGrid
                    pageChanged={pageChanged}
                    totalPages={data?.totalPages || 0}
                    sortChanged={sortChanged}
                    columns={columns}
                    data={data?.content || []} selection={true}
                    sortBy={criteria.sort}
                    pageSizeChanged={pageSizeChanged}
                    selectionChanged={selectionChanged}
                />
                <div className="test-case-editor-add">
                    <Button variant='contained' color="primary" onClick={addTestCases}>
                        {t('Add')}
                    </Button>
                </div>


            </CardContent>
        </Card>

    )
}
