import { Grid } from "@mui/material";
import { BatchDetails } from "./batchDetails";

export interface IProps {
    projectId? : any;
    closeCompareBatch?: () => void;
    firstbatch : any;
    secondBatch : any;
    openTestResultsDetailsView: () => void;
    reloadBatchList: () => void;
}

export const CompareBatches = ({
    projectId,
    closeCompareBatch,
    firstbatch,
    secondBatch,
    openTestResultsDetailsView,
    reloadBatchList
}: IProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>

            </Grid>

            <Grid item xs={6} paddingRight={'9px'} style={{}}>
                <BatchDetails 
                projectId={projectId} 
                testcaseId={1551} 
                selectedBatch={firstbatch} 
                openTestResultsDetailsView={openTestResultsDetailsView} 
                reloadBatchList={reloadBatchList}/>
            </Grid>
            
            <Grid item xs={6} paddingRight={'9px'} style={{}}>
                <BatchDetails 
                showCloseButton = {true} 
                closeBatchDetails={closeCompareBatch} 
                projectId={projectId} 
                testcaseId={1551} 
                selectedBatch={secondBatch} 
                openTestResultsDetailsView={openTestResultsDetailsView}
                reloadBatchList={reloadBatchList} />
            </Grid>
        </Grid>
    );
}