import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const AudioRecorder = ({ onDone = null as any, onStart = null as any }) => {
    const mimeType = "audio/mp3";
    const [permission, setPermission] = useState(false);
    const mediaRecorder = useRef<any>(null);
    const [recordingStatus, setRecordingStatus] = useState<string>("inactive");
    const [stream, setStream] = useState<any>(null);
    const [audioChunks, setAudioChunks] = useState<any[]>([]);
    const [audio, setAudio] = useState<any>(null);
    const [audioFile, setAudioFile] = useState<File>();
    const [duration, setDuration] = useState<number>(0);
    const time = useRef<any>(null);
    const { t } = useTranslation();

    const startRecording = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(streamData);

                setRecordingStatus("recording");
                const media = new MediaRecorder(streamData, { type: mimeType } as any);
                mediaRecorder.current = media;
                mediaRecorder.current.start();
                let localAudioChunks = [] as any[];
                mediaRecorder.current.ondataavailable = (event: any) => {
                    if (typeof event.data === "undefined") return;
                    if (event.data.size === 0) return;
                    localAudioChunks.push(event.data);
                };
                setAudioChunks(localAudioChunks);
                onStart && onStart();
                setDuration(0);

                time.current = window.setInterval(() => {
                    setDuration(prevTime => prevTime + 1); // <-- Change this line!
                }, 1000);
            } catch (err: any) {
                alert(err.message);
            }
        } else {
            alert(t("The MediaRecorder API is not supported in your browser."));
        }

    };



    const stopRecording = () => {
        setRecordingStatus("inactive");
        clearInterval(time.current);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            const audioUrl = URL.createObjectURL(audioBlob);

            const audioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' });

            // const speech = await httpPostUpload(`voiceToText/upload`,[audioFile]);

            //    const speech = await VoiceToText(audioBlob);

            //console.log(audioUrl);
            //console.log(speech);
            onDone && onDone(audioFile);
            setAudio(audioUrl);
            setAudioFile(audioFile);
            setAudioChunks([]);
        };
    };

    return (
        <div>
            <div className="audio-controls">
                {recordingStatus !== "recording" && (
                    <div onClick={startRecording} className="interview-buttons-long link-cursor purple-button"
                    >
                        <Button variant="outlined"
                            color="primary"><FiberManualRecordIcon /> {t('Start Recording')}</Button>
                    </div>
                )}

                {permission && recordingStatus === "recording" && (
                        <div onClick={stopRecording} className="interview-buttons-long link-cursor purple-button"
                        >

                            <Button variant="outlined"
                                color="primary"> <span className="blink flex-center"><StopCircleIcon style={{ color: 'red' }} /> {t('Stop')} {new Date(duration * 1000).toISOString().slice(14, 19)}</span></Button>
                        </div>
                )}

                {/* {audio && (
                    <div className="audio-container">
                        <audio src={audio} controls></audio>
                        <a download href={audio}>
                            Download Recording
                        </a>
                    </div>
                )} */}
            </div>
        </div>
    );
}
