import { Button, Divider, Typography } from "@mui/material";
import { DIGITAL_STEP_TYPE, LOCATOR_TYPES } from "../types/global.enum";

export interface IProps {
    step: any;
    stepType: any;
    onClose: () => void;
}

export const ActionInfoBody = ({
    step,
    stepType,
    onClose
}: IProps) => {
    const getLocatorType = (paramType: any) : any => {
        switch(paramType)
        {
            case LOCATOR_TYPES.DISPLAY_TEXT:
                return 'Find by display text';
            case LOCATOR_TYPES.HTML_ATTRIBUTE:
                return 'Find by html attribute';
            case LOCATOR_TYPES.HTML_ELEMENT:
                return 'Find by html element';
        }
    }

    return (
        <>
        {stepType === DIGITAL_STEP_TYPE.LOAD_PAGE && (
            <div className='db w-100'>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Action Type Info: <span className="step-detail-value"> {step?.stepType}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        URL*: <span className="step-detail-value"> {step?.simpleParameters[0]?.value}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Remark: <span className="step-detail-value"> {step.remark || 'N/A'}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Timeout(in seconds): <span className="step-detail-value"> {step.latencyStep.timeout}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Locator Type*: <span className="step-detail-value"> {getLocatorType(step.latencyStep.itemLocators[0].paramType)}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                    Operations: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].matchOp}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Display Text*: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].value}</span>
                    </Typography>
                </div>
            </div>
        )}
        {stepType === DIGITAL_STEP_TYPE.PAUSE && (
            <div className='db w-100'>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Action Type Info: <span className="step-detail-value"> {step?.stepType}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Seconds: <span className="step-detail-value"> {step?.simpleParameters[0]?.value}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Remark: <span className="step-detail-value"> {step.remark || 'N/A'}</span>
                    </Typography>
                </div>
            </div>
        )}
        {stepType === DIGITAL_STEP_TYPE.CLICK && (
            <div className='db w-100'>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Action Type Info: <span className="step-detail-value"> {step?.stepType}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Remark: <span className="step-detail-value"> {step.remark || 'N/A'}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                {/* <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Timeout(in seconds): <span className="step-detail-value"> {step.timeout}</span>
                    </Typography>
                </div> */}
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Locator Type*: <span className="step-detail-value"> {getLocatorType(step.itemLocators[0].paramType)}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                    Operations: <span className="step-detail-value"> {step.itemLocators[0]?.matchOp}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                    Attribute Value: <span className="step-detail-value"> {step.itemLocators[0]?.value}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Timeout (in seconds)*: <span className="step-detail-value"> {step.latencyStep.timeout}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Locator Type*: <span className="step-detail-value"> {getLocatorType(step.latencyStep.itemLocators[0].paramType)}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Attribute Name: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].name}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Operations*: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].matchOp}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Attribute Value*: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].value}</span>
                    </Typography>
                </div>
            </div>
        )}
        {stepType === DIGITAL_STEP_TYPE.ENTER_TEXT && (
            <div className='db w-100'>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Action Type Info: <span className="step-detail-value"> {step?.stepType}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                    {step?.simpleParameters[0]?.name === 'sp.simple.enter_text.value' ? "Text to Send" : "Send Enter Key"}: <span className="step-detail-value"> {step?.simpleParameters[0]?.value}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Remark: <span className="step-detail-value"> {step.remark || 'N/A'}</span>
                    </Typography>
                </div>                
                {/* <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Timeout(in seconds): <span className="step-detail-value"> {step.timeout}</span>
                    </Typography>
                </div> */}
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Locator Type*: <span className="step-detail-value"> {getLocatorType(step.itemLocators[0].paramType)}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                    Operations: <span className="step-detail-value"> {step.itemLocators[0]?.matchOp}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                    Attribute Value: <span className="step-detail-value"> {step.itemLocators[0]?.value}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Timeout (in seconds)*: <span className="step-detail-value"> {step.latencyStep.timeout}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Locator Type*: <span className="step-detail-value"> {getLocatorType(step.latencyStep.itemLocators[0].paramType)}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Attribute Name: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].name}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Operations*: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].matchOp}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Attribute Value*: <span className="step-detail-value"> {step.latencyStep.itemLocators[0].value}</span>
                    </Typography>
                </div>
            </div>
        )}
        {stepType === DIGITAL_STEP_TYPE.SWITCH_FRAME && (
            <div className='db w-100'>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Action Type Info: <span className="step-detail-value"> {step?.stepType}</span>
                    </Typography>
                </div>
                <Divider orientation="horizontal"/>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Locator Type*: <span className="step-detail-value" > {getLocatorType(step.itemLocators[0].paramType)}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Attribute Name: <span className="step-detail-value"> {step.itemLocators[0].name}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Operations*: <span className="step-detail-value"> {step.itemLocators[0].matchOp}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Attribute Value*: <span className="step-detail-value"> {step.itemLocators[0].value}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Remark: <span className="step-detail-value"> {step.remark || 'N/A'}</span>
                    </Typography>
                </div>
            </div>
        )}
        {stepType === DIGITAL_STEP_TYPE.SCREENSHOT && (
            <div className='db w-100'>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Action Type Info: <span className="step-detail-value"> {step?.stepType}</span>
                    </Typography>
                </div>
                <div className="ptb-10">
                    <Typography fontWeight={500} fontSize='18px' lineHeight='22px' color='#006A68'>
                        Remark: <span className="step-detail-value"> {step.remark || 'N/A'}</span>
                    </Typography>
                </div>
            </div>
        )}
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
            <Button variant={"contained"} color="primary" onClick={onClose}>Close</Button>
        </div>
        
        </>        
        
    );
}