import * as React from 'react';
import { InputLabel, FormLabel, FormControl, Select, MenuItem, Box, TextField } from "@mui/material"
import { FormikProps } from "formik";
import { finalSilenceOptions, timeoutOptions } from '../../helpers/form.helper';
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}


export const RecordVoice = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {/* Left Side of Prompt Library */}
                <Box className='prompt-library-left' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-timeout">{t('Timeout')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-timeout"
                                    id="stepTimeout"
                                    name='stepTimeout'
                                    value={values?.stepTimeout}
                                    label={t("timeout")}
                                    onChange={handleChange}
                                >
                                    {timeoutOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                </Box>

                {/* Right Side of Prompt Library */}
                <Box className='prompt-library-right' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-final-silence">{t('Final Silence')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-final-silence"
                                    id="finalSilence"
                                    name='finalSilence'
                                    value={values?.finalSilence}
                                    label={t("final-silence")}
                                    onChange={handleChange}
                                >
                                    {finalSilenceOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                </Box>
            </Box>
            <Box className='segment-editor-recording-hint'>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="case-editor-recording-hint"></InputLabel>
                    <TextField
                        id="recordingHint"
                        name='recordingHint'
                        value={values?.recordingHint}
                        label={t("Recording Hint")}
                        onChange={handleChange}
                    />
                </FormControl>
            </Box>
        </>
    );
};
