import apiService from "./api.service";
import { IVariable } from "../types/interfaces/variable.interface";
import { CHANNELS } from "../types/global.enum";
import digitalApiService from "./digitalApi.service";
export const getScheduleList = async (projectId = 1, pageSize = 10, pageNo = 0, filter = '', channel: CHANNELS = CHANNELS.VOICE) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/schedules?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}`)
        : await apiService.get(`/projects/${projectId}/schedules?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}`);
    return data;
}

export const getSchedulerDetails = async (projectId = 1, schedulerId = 1) => {
    const { data } = await digitalApiService.get(`/projects/${projectId}/schedules/${schedulerId}`);
    return data;
}

export const getCaseListofLastTestResultofSchedule = async (projectId = 1,scheduleId = 1, scheduledResultId = 1, pageSize = 10, pageNo = 0, filter = '') => {
    const { data } = await apiService.get(`/projects/${projectId}/schedules/${scheduleId}/results/${scheduledResultId}/cases?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}`);
    return data;
}

// export const addTestCasesToSchedule = async (projectID: number, caseId: number[]) => {
//     await Promise.all(
//         caseId.map(async id => {
//             if (id) {
//                 await apiService.delete(`/projects/${projectID}/cases/${id}`);
//             }
//             return null;
//         })
//     ).catch(e => e)
// }


export const upsertSchedule = async (projectId: number, schedule: any, editorState: string, channel: CHANNELS = CHANNELS.VOICE) => {
    console.log("Payload:", schedule)
    if (editorState === "ADD") {
        return channel === CHANNELS.DIGITAL ? await digitalApiService.post(`/projects/${projectId}/schedules`, { ...schedule }).catch(e => e)
            : await apiService.post(`/projects/${projectId}/schedules`, { ...schedule }).catch(e => e);
    } else {
        return channel === CHANNELS.DIGITAL ? await digitalApiService.put(`/projects/${projectId}/schedules/${schedule.testScheduleId}`, { ...schedule }).catch(e => e)
            : await apiService.put(`/projects/${projectId}/schedules/${schedule.testScheduleId}`, { ...schedule }).catch(e => e);
    }
}

export const deleteSchedules = async (projectID: number, scheduleId: number[], channel: CHANNELS = CHANNELS.VOICE) => {
    await Promise.all(
        scheduleId.map(async id => {
            if (id) {
                channel === CHANNELS.DIGITAL ? await digitalApiService.delete(`/projects/${projectID}/schedules/${id}`)
                    : await apiService.delete(`/projects/${projectID}/schedules/${id}`);
            }
            return null;
        })
    ).catch(e => e)
}

export const deleteSelectedBatches = async (projectID: number, scheduleId: number, archiveId: number[], channel: CHANNELS = CHANNELS.VOICE) => {
    channel === CHANNELS.DIGITAL ? await digitalApiService.delete(`/projects/${projectID}/schedules/${scheduleId}/batches`, { data: archiveId }).catch(e => e)
        : await apiService.delete(`/projects/${projectID}/schedules/${scheduleId}/batches`, { data: archiveId }).catch(e => e);
}

export const getBatchList = async (projectId: number,scheduleId: number, channel: CHANNELS = CHANNELS.VOICE ) => {
   const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/schedules/${scheduleId}/batches`)
    : await apiService.get(`/projects/${projectId}/schedules/${scheduleId}/batches`);

    return data;
}