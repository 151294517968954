import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useAppDispatch } from "../../store/hooks";
import { addNewSegment, setBatchSegments, setEditSegment } from "../../store/resuders/segment.reducer";
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";
import { getEditSegment } from "../../store/selectors/segment.selector";
import { getAllDigitalStepTypes, getCurrentProject } from "../../store/selectors/project.selector";
import { DIGITAL_STEP_TYPE } from "../../types/global.enum";
import { uniqueId } from "../../helpers/form.helper";
import { getSelectedSegments } from "../../store/selectors/segment.selector";
import { max } from "lodash";
import { ISegment, ISegmentStep } from "../../types/interfaces/segment.interface";
import { ActionTypeCriteria } from "./actionTYpeCriteria";
import { reloadSegments } from "../../store/resuders/app.reducer";
import { upsertDigitalTestCase } from "../../services/testcase.service";

const steps = [
    {
        element: '#segmentName',
        intro: 'segment name goes here',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#type',
        intro: 'Type',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#branchCondition',
        intro: 'branch condition',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
];

export const DigitalSegmentEditor = ({
    data = null as any,
    onDone = null as any,
    toggleSegmentEditor,
    projectLibrary = false,
    setDiscardChangeConfirmation
}: {
    toggleSegmentEditor: (close?: boolean, open?: boolean) => void;
    data?: any;
    onDone?: any;
    projectLibrary?: boolean;
    setDiscardChangeConfirmation?: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [submit, setSubmit] = useState<boolean>(false);
    const [intro, setIntro] = useState<any>(null);
    const [stepOptions, setStepOptions] = React.useState<number[]>([1]);
    const project = useSelector(getCurrentProject);
    const editSegment = useSelector(getEditSegment);
    const allStepTypes = useSelector(getAllDigitalStepTypes);
    const selectedSegments = useSelector(getSelectedSegments);

    const disabledSave = false;
    const formik = useFormik({
        initialValues:{
            segmentName:'',
            step:1,
            type:DIGITAL_STEP_TYPE.NONE,
            segmentRemark:'',
            actionType: "", seconds: '', remark: '', url: '', timeOut: 10, textToSend: '', dontNotSend: true, latencyTimeOut: 10,
            criteria:[],
            criteria2:[],
        },
        onSubmit: async (data:any) => {
            setSubmit(true);
            if(!data.segmentName) return;

            switch(data.type) {
                case DIGITAL_STEP_TYPE.ENTER_TEXT:{
                    if(!data.textToSend || !data.criteria?.length || !data.criteria2?.length) return;
                    break;
                }

                case DIGITAL_STEP_TYPE.CLICK:{
                    if(!data.latencyTimeOut || !data.criteria?.length || !data.criteria2?.length) return;
                    break;
                }

                case DIGITAL_STEP_TYPE.LOAD_PAGE:{
                    if(!data.url || !data.latencyTimeOut || !data.criteria2?.length) return;
                    break;
                }

                case DIGITAL_STEP_TYPE.SWITCH_FRAME:{
                    if(!data.criteria?.length) return;
                    break;
                }

                case DIGITAL_STEP_TYPE.PAUSE:{
                    if(!data.seconds) return;
                    break;
                }

                case DIGITAL_STEP_TYPE.FIND_ITEM:{
                    if(!data.criteria?.length || !data.timeOut) return;
                    break;
                }
            }
            
            if(editSegment && !projectLibrary) {
                const { segment, step } = editSegment;

                let updated:ISegment[] = [];

                if(step?.stepSeq !== data.step) {
                    updated = selectedSegments?.reduce((segs:ISegment[], currSeg:ISegment)=> {
                        if(currSeg.id === segment.id) {
    
                            segs.push({
                                ...currSeg,
                                name: data.segmentName,
                                remark: data.segmentRemark,
                                segmentLangs:[
                                    {
                                        languageCode:{},
                                        steps:(currSeg?.segmentLangs?.[0]?.steps || []).concat({
                                            stepSeq:data.step,
                                            actionType: data.type,
                                            criteria: data.criteria || [],
                                            dontNotSend: data.dontNotSend,
                                            latencyTimeOut: data.latencyTimeOut,
                                            remark: data.remark,
                                            seconds: data.seconds,
                                            textToSend: data.textToSend,
                                            timeOut: data.timeOut,
                                            url: data.url,
                                            criteria2:data.criteria2 || [],
                                        }),
                                    }
                                ]
                            })
    
                        } else {
                            segs.push(currSeg);
                        }
    
                        return segs;
                    },[])
                } else {
                    updated = selectedSegments?.reduce((segs:ISegment[], currSeg:ISegment)=> {
                        if(currSeg.id === segment.id) {
    
                            segs.push({
                                ...currSeg,
                                name: data.segmentName,
                                remark: data.segmentRemark,
                                segmentLangs:[
                                    {
                                        languageCode:{},
                                        steps:currSeg.segmentLangs[0].steps?.reduce((steps:ISegmentStep[], currStep:ISegmentStep)=>{
                                            if(currStep.stepSeq === step.stepSeq) {
                                                steps.push({
                                                    ...currStep,
                                                    actionType: data.type,
                                                    criteria: data.criteria || [],
                                                    dontNotSend: data.dontNotSend,
                                                    latencyTimeOut: data.latencyTimeOut,
                                                    remark: data.remark,
                                                    seconds: data.seconds,
                                                    textToSend: data.textToSend,
                                                    timeOut: data.timeOut,
                                                    url: data.url,
                                                    stepSeq:data.step,
                                                    criteria2: data.criteria2 || [],
                                                });
                                            } else {
                                                steps.push(currStep);
                                            }
                                            return steps;
                                        },[])
                                    }
                                ]
                            })
    
                        } else {
                            segs.push(currSeg);
                        }
    
                        return segs;
                    },[])
                }

                dispatch(setBatchSegments(updated));
                
            } else {
                let payload = {
                    name: data.segmentName,
                    id: uniqueId(),
                    remark: data.segmentRemark,
                    segmentLangs:[
                        {
                            steps:[
                                {
                                    actionType: data.type,
                                    criteria: data.criteria || [],
                                    dontNotSend: data.dontNotSend,
                                    latencyTimeOut: data.latencyTimeOut,
                                    remark: data.remark,
                                    seconds: data.seconds,
                                    textToSend: data.textToSend,
                                    timeOut: data.timeOut,
                                    url: data.url,
                                    stepSeq:data.step,
                                    criteria2:data.criteria2 || [],
                                },
                            ],
                        },
                    ],
                } as any;

                if(projectLibrary && editSegment) {
                    const { segment } = editSegment;

                    payload = {
                        name: data.segmentName,
                        id: segment.id,
                        remark: data.segmentRemark,
                        testSegmentVoiceId:segment.testSegmentVoiceId,
                        testSegmentId: segment.testSegmentId,
                        projectId: segment.projectId,
                        segmentLangs:[
                            {
                                steps:(segment?.steps || []).filter((s:ISegmentStep)=> s.stepSeq !== +data.step).concat(payload.segmentLangs[0].steps[0])?.filter((s:ISegmentStep)=>s.actionType),
                            },
                        ],
                    };
                }


                 if(projectLibrary) {
                    console.log(payload)
                    await upsertDigitalTestCase([payload] as any, '', project.testProjectID, null, true);
                 } else {
                    dispatch(addNewSegment(payload));
                 }
              
            }

            if (projectLibrary) {
                dispatch(reloadSegments());
            }
            handleClear();
        }
    });

    useEffect(()=> {
        if(editSegment) {

            const { segment, step } = editSegment;

            formik.setValues({
                segmentName:segment?.name,
                step:step?.stepSeq ||  segment?.segmentLangs?.[0]?.steps?.[0]?.stepSeq,
                type:step?.actionType,
                segmentRemark:segment.remark,
                actionType: step?.actionType, seconds: step?.seconds, remark: step?.remark, url: step?.url, timeOut: step?.timeOut, 
                textToSend: step?.textToSend, 
                dontNotSend: step?.dontNotSend, latencyTimeOut: step?.latencyTimeOut,
                criteria: step?.criteria || [],
                criteria2:step?.criteria2 || [],
            });

            const steps: any = (segment as ISegment).segmentLangs?.[0]?.steps?.map(c => c.stepSeq) || [1];
            setStepOptions(steps);

            if(segment && !step) {
                stepChanged({
                    target:{
                        value:segment?.segmentLangs?.[0]?.steps?.[0]?.stepSeq,
                    }
                })
            }
        }
    },[editSegment]);

    useEffect(()=> {
        if(!editSegment) setStepOptions(!selectedSegments?.length ? [1] : selectedSegments?.map((_,i)=> i+1))
    }, [editSegment , selectedSegments])

    const closeEditor = () => {
        dispatch(setEditSegment(null));
        toggleSegmentEditor(projectLibrary)
    }

    const showIntro = (el: string) => () => {
        setIntro({
            visible: true,
            step: steps.findIndex(c => c.element === `#${el}`),
        })
    }

    const confirm = async () => {
        await formik.submitForm();
    }

    const handleClear = () => {
        setSubmit(false);
        setStepOptions([1]);

        formik.setValues({
            segmentName:'',
            step:1,
            type:DIGITAL_STEP_TYPE.NONE,
            segmentRemark:'',
            actionType: "", seconds: '', remark: '', url: '', timeOut: 10, textToSend: '', dontNotSend: true, latencyTimeOut: 10,
            criteria:[],
            criteria2:[],
        });

        dispatch(setEditSegment(null))
    };

    const criteria2Changed = (data?:any)=> {
        formik.setFieldValue('criteria2',data || []);
    }

    const criteriaChanged = (data?:any)=> {
        formik.setFieldValue('criteria',data || []);
    }

    useEffect(() => {
        if (formik.values.step === 0) {
            console.log('useEffect',formik.values.step)
            const newId = (max(stepOptions) || 0) + 1;
            setStepOptions([...stepOptions, newId]);

            formik.setFieldValue('step', newId);
            formik.setFieldValue('type', DIGITAL_STEP_TYPE.NONE);
            formik.setFieldValue('actionType', newId);
            formik.setFieldValue('seconds', 10);
            formik.setFieldValue('remark', '');
            formik.setFieldValue('url', '');
            formik.setFieldValue('timeOut', 10);
            formik.setFieldValue('textToSend', '');
            formik.setFieldValue('dontNotSend', true);
            formik.setFieldValue('latencyTimeOut', 10);
            formik.setFieldValue('criteria', []);
            formik.setFieldValue('criteria2', []);
        }
    }, [formik.values.step]);


    const stepChanged = (e:any)=> { 

        const value = +(e.target?.value || '0');

        if(value!==0 && (selectedSegments?.length || (editSegment && projectLibrary))) {
            const { segment } = editSegment || {};

            if(segment) {
                const newStep =projectLibrary ? segment?.segmentLangs?.[0]?.steps?.find((s:ISegmentStep)=> s.stepSeq === value):
                                                 selectedSegments.find(s=> s.id == segment.id)?.segmentLangs?.[0]?.steps?.find(s=>s.stepSeq === value);

                if(newStep) {
                   dispatch(setEditSegment({
                    segment,
                    step:newStep,
                   }))
                }
            }
        }
        formik.setFieldValue('step', value);
    }


    return (
        <Card className="h-100 min-h-80 sticky-top">
            <CardContent>
                <Steps
                    enabled={intro?.visible}
                    steps={steps}
                    initialStep={intro?.step}
                    onExit={() => setIntro(null)}
                />
            <form onSubmit={formik.handleSubmit}>
                <div className="case-editor-header">
                    <Typography variant="subtitle1">{t(projectLibrary ? 'Editing a Segment from Library' : 'Segment Editor')}</Typography>
                    <HighlightOffIcon onClick={closeEditor} />
                </div>
                <div className="form-container">
                        <Box className='segment-name-select relative'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="case-editor-segment-name"></InputLabel>
                                <TextField
                                    id="segmentName"
                                    value={formik.values?.segmentName}
                                    label={t("Segment Name")}
                                    name='segmentName'
                                    onChange={formik.handleChange}
                                    error={!formik.values?.segmentName && submit}
                                    helperText={!formik.values?.segmentName && submit ? '* Required':''}
                                />
                            </FormControl>
                            <InfoIcon className='introl-icon' onClick={showIntro('segmentName')} />
                        </Box>
                        <Box className='segment-editor-remark'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="case-editor-remark"></InputLabel>
                                <TextField
                                    id="segmentRemark"
                                    name='segmentRemark'
                                    value={formik.values?.segmentRemark}
                                    label="Remark"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Box>
                        <Box className='segment-editor-remark'>
                            <FormControl sx={{ width: "15%",mr:2 }}>
                                <InputLabel id="case-editor-step">Step</InputLabel>
                                <Select
                                    labelId="case-editor-step"
                                    id="step"
                                    value={formik.values?.step}
                                    label={t("Step")}
                                    name="step"
                                    disabled={!editSegment}
                                    onChange={stepChanged}
                                >
                                    {stepOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                    <MenuItem value={0}>+</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: "80%" }} className="relative">
                                <InputLabel id="case-editor-type">Type</InputLabel>
                                <Select
                                    labelId="case-editor-type"
                                    id="type"
                                    value={formik.values?.type}
                                    label={t("Type")}
                                    name="type"
                                    fullWidth
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                >
                                    {allStepTypes?.map((item: any) => (
                                        <MenuItem key={`step_types_${item.typeCode}`} value={item.typeCode}>{item.name}</MenuItem>

                                    ))}
                                </Select>
                                <InfoIcon className='introl-icon' onClick={showIntro('type')} />
                            </FormControl>  
                        </Box>

                        {formik.values?.type === DIGITAL_STEP_TYPE.ENTER_TEXT && 
                            <Box className='segment-editor-remark'>
                                <FormControl sx={{width:'100%'}} >
                                    <InputLabel id="textToSend"></InputLabel>
                                    <TextField
                                        fullWidth
                                        id="textToSend"
                                        name='textToSend'
                                        value={formik.values?.textToSend}
                                        label="Text to Send"
                                        error={!formik.values?.textToSend && submit}
                                        helperText={!formik.values?.textToSend  && submit ? 'Required*':''}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>

                                <FormControl sx={{width:'100%',mt:2}} >
                                    <div className="flex-center">
                                    <Checkbox   id="dontNotSend" name='dontNotSend' checked={formik.values?.dontNotSend} onChange={formik.handleChange}/> Send Enter Key
                                    </div>
                                </FormControl>
                            </Box>
                        }

                        {[DIGITAL_STEP_TYPE.ENTER_TEXT, DIGITAL_STEP_TYPE.CLICK].includes(formik.values?.type) && <Box className="type-language-select mb-0">
                            <InputLabel id="case-editor-type">Find Element</InputLabel>
                        </Box>}
                         

                        <Box className='segment-editor-remark'>
                            {formik.values?.type === DIGITAL_STEP_TYPE.SWITCH_FRAME && 
                                    <>
                                        <Box className="type-language-select mb-0 m-0">
                                            <InputLabel id="case-editor-type">Criteria</InputLabel>
                                        </Box>

                                        <ActionTypeCriteria value={formik.values?.criteria}   onChange={criteriaChanged} />

                                        {!formik.values?.criteria?.length && submit && <p className="error">* Required</p>}
                                    </>
                            }

                            {formik.values?.type === DIGITAL_STEP_TYPE.FIND_ITEM && 
                                    <>
                                    <Box className="type-language-select mb-0 m-0">
                                            <InputLabel id="case-editor-type">Response Search Info</InputLabel>
                                        </Box>
                                        <FormControl sx={{mt:2}} >
                                            <InputLabel id="timeOut"></InputLabel>
                                            <TextField
                                                type="number"
                                                id="timeOut"
                                                name='timeOut'
                                                value={formik.values?.timeOut}
                                                label="Timeout (in seconds)"
                                                error={!formik.values?.timeOut && submit}
                                                helperText={!formik.values?.timeOut  && submit ? 'Required*':''}
                                                onChange={formik.handleChange}
                                            />
                                        </FormControl>

                                        <ActionTypeCriteria value={formik.values?.criteria}   onChange={criteriaChanged} />
                                        {!formik.values?.criteria?.length && submit && <p className="error">* Required</p>}
                                    </>
                            }

                            {[DIGITAL_STEP_TYPE.CLICK, DIGITAL_STEP_TYPE.ENTER_TEXT].includes(formik.values?.type) && 
                                <>
                                    <ActionTypeCriteria value={formik.values?.criteria}  onChange={criteriaChanged} />
                                    {!formik.values?.criteria?.length && submit && <p className="error">* Required</p>}

                                    <Box className="type-language-select mb-0 m-0">
                                        <InputLabel id="case-editor-type">Response Search Info</InputLabel>
                                    </Box>
                                    <FormControl sx={{mt:2}} >
                                        <InputLabel id="case-editor-latencyTimeOut"></InputLabel>
                                        <TextField
                                            type="number"
                                            id="latencyTimeOut"
                                            name='latencyTimeOut'
                                            value={formik.values?.latencyTimeOut}
                                            label="Timeout (in seconds)"
                                            error={!formik.values?.latencyTimeOut && submit}
                                            helperText={!formik.values?.latencyTimeOut  && submit ? 'Required*':''}
                                            onChange={formik.handleChange}
                                        />
                                    </FormControl>

                                    <ActionTypeCriteria value={formik.values?.criteria2} property="criteria2"  onChange={criteria2Changed} />
                                    {!formik.values?.criteria2?.length && submit && <p className="error">* Required</p>}
                                </>

                            }

                             {formik.values?.type === DIGITAL_STEP_TYPE.LOAD_PAGE && 
                                <>
                                    <FormControl sx={{ width: "100%", mt:1 }}>
                                        <InputLabel id="case-editor-url"></InputLabel>
                                        <TextField
                                            id="url"
                                            name='url'
                                            value={formik.values?.url}
                                            label="Url"
                                            error={!formik.values?.url && submit}
                                            helperText={!formik.values?.url  && submit ? 'Required*':''}
                                            onChange={formik.handleChange}
                                        />
                                    </FormControl>
                                    <FormControl sx={{mt:2}} >
                                        <InputLabel id="case-editor-latencyTimeOut"></InputLabel>
                                        <TextField
                                            type="number"
                                            id="latencyTimeOut"
                                            name='latencyTimeOut'
                                            value={formik.values?.latencyTimeOut}
                                            label="Timeout (in seconds)"
                                            error={!formik.values?.latencyTimeOut && submit}
                                            helperText={!formik.values?.latencyTimeOut  && submit ? 'Required*':''}
                                            onChange={formik.handleChange}
                                        />
                                    </FormControl>

                                    <ActionTypeCriteria value={formik.values?.criteria2} property="criteria2" onChange={criteria2Changed} />
                                    {!formik.values?.criteria2?.length && submit && <p className="error">* Required</p>}
                                </>
                             }

                            {formik.values?.type === DIGITAL_STEP_TYPE.PAUSE && 
                                <FormControl >
                                    <InputLabel id="case-editor-seconds"></InputLabel>
                                    <TextField
                                        type="number"
                                        id="seconds"
                                        name='seconds'
                                        value={formik.values?.seconds}
                                        label="Seconds"
                                        error={!formik.values?.seconds && submit}
                                        helperText={!formik.values?.seconds  && submit ? 'Required*':''}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                            }

                            {[DIGITAL_STEP_TYPE.SCREENSHOT, DIGITAL_STEP_TYPE.PAUSE, DIGITAL_STEP_TYPE.LOAD_PAGE, DIGITAL_STEP_TYPE.CLICK, 
                            DIGITAL_STEP_TYPE.FIND_ITEM, DIGITAL_STEP_TYPE.MOUSE_OVER, DIGITAL_STEP_TYPE.SWITCH_FRAME].includes(formik.values?.type) && 
                                    <FormControl sx={{ width: "100%", mt:1 }}>
                                        <InputLabel id="case-editor-remark"></InputLabel>
                                        <TextField
                                            id="remark"
                                            name='remark'
                                            value={formik.values?.remark}
                                            label="Remark"
                                            onChange={formik.handleChange}
                                        />
                                    </FormControl>
                            
                            }
                        </Box>

                        <Box className="segment-editor-save-buttons">
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', paddingTop: '14px' }}>
                                <Button
                                    variant={'contained'}
                                    color={disabledSave ? "secondary" : "primary"}
                                    disabled={disabledSave}
                                    className='max-h-30'
                                    onClick={confirm}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant={'contained'}
                                    color="primary"
                                    className='max-h-30'
                                    onClick={handleClear}
                                >
                                    {t('Clear')}
                                </Button>
                            </div>
                        </Box>
                </div>
            </form>
            </CardContent>
        </Card>
    )
}