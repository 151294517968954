import { CHANNELS } from "../types/global.enum";
import { ISegment } from "../types/interfaces/segment.interface";
import apiService from "./api.service";
import digitalApiService from "./digitalApi.service";

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('').map(a => a.toUpperCase());

export const upsertSegment = async (projectId: number, segment: ISegment) => {
    if (segment.testSegmentVoiceId) {
        return await apiService.put(`/projects/${projectId}/segments/${segment.testSegmentVoiceId}`, { ...segment })
            .catch(e => e);
    } else {
        return await apiService.post(`/projects/${projectId}/segments`, { ...segment })
            .catch(e => e);
    }
}


export const deleteSegment = async (projectId: number, segmentIds: number[]) => {
    return await Promise.all(
        segmentIds.map(async id => {
            if (id) {
                await apiService.delete(`projects/${projectId}/segments/${id}`)
            }

            return null;
        })
    ).catch(e => e)

}

export const deleteLibraryItems = async (projectId: number, library: string, ids: number[], channel: CHANNELS) => {

    return await Promise.all(
        ids.map(async id => {
            if (id) {
                return channel === CHANNELS.DIGITAL ? await digitalApiService.delete(`projects/${projectId}/${library}/${id}`).catch(e => e) : await apiService.delete(`projects/${projectId}/${library}/${id}`)
                    .catch(e => e)
            }

            return null;
        })
    ).catch(e => e)

}
