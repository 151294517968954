import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography, Link, DialogContentText, Switch, Box, Checkbox } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deleteIcon, greenCheckIcon, runmultiple, downloadIcon, runmultiples, digital, redCrossIcon } from '../assets';
import { DataGrid, Modal } from '../ui';
import { GlobalLibrary } from './globalLibrary';
import { ProjectLibrary } from './projectLibrary';
import { NewTestCase } from './newTestCase';
import { SegmentEditor } from './segmentEditor';
import { useSelector } from 'react-redux';
import { upsertVariable as upsertLibraryVariable } from '../store/resuders/variable.reducer';
import { upsertGrammar as upsertLibraryGrammar } from '../store/resuders/grammar.reducer';
import { upsertPrompt as upsertLibraryPrompt } from '../store/resuders/prompt.reducer';
import { upsertAudio as upsertLibraryAudio } from '../store/resuders/audio.reducer';
import InfoIcon from '@mui/icons-material/Info';

import { getCurrentProject, getCurrentTestCase, selectBrowsers } from '../store/selectors/project.selector';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { allowUpsertLibrary } from '../store/selectors/segment.selector';
import { allowUpsertVariable } from '../store/selectors/variable.selector';
import { allowUpsertGrammar } from '../store/selectors/grammar.selector';
import { allowUpsertPrompt } from '../store/selectors/prompt.selector';
import { allowUpsertAudio } from '../store/selectors/audio.selector';
import { useAppDispatch } from '../store/hooks';
import { hideSegmentLibrary, setBatchSegments, setEditSegment, upsertLibrary as upsertSegmentLibrary } from '../store/resuders/segment.reducer';
import { getTestCaseList, deleteTestCases, getTestCaseById, checkMultiVariable, runMultipleVariableTestCase, runDigitalTestCases } from "../services/testcase.service";
import { ITestCase } from '../types/interfaces/testCase.interface';
import { setCurrentProjectId, setCurrentTestCase, setCurrentProject } from '../store/resuders/project.reducer';
import { LibraryEditor } from '../ui/libraryEditor';
import { getProjectById } from '../services/project.service';
import { getGrammarOptions } from '../services/grammar.service';
import { serGrammaroptions, showHelpDetails } from '../store/resuders/app.reducer';
import { formatTimestamp } from '../helpers/date.helper';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../ui';
import { getHelpCenter } from '../store/selectors/app.selector';
import { uniqueId } from '../helpers/form.helper';
import { CHANNELS } from '../types/global.enum';
import { DigitalSegmentEditor } from './digital/digitalSegmentEditor';

export const TestCaseManagement = () => {
    // const projects = useSelector(getAllProjects);  // Contains all projects
    const { projectId } = useParams();  // ProjectID and TestCaseID from URL
    const currentTestCase = useSelector(getCurrentTestCase);
    const { t } = useTranslation();

    const project = useSelector(getCurrentProject);
    const [caseEditorOpen, setCaseEditorOpen] = useState(false);
    const [globalLibraryOpen, setGlobalLibraryOpen] = useState(false);
    const [projectLibraryOpen, setProjectLibraryOpen] = useState(false);
    const [edit, setEdit] = useState<any>(null);
    const [runParameters, setRunParameters] = useState<any[]>([]);
    const [browsersToRun, setBrowsersToRun] = useState<string[]>([]);
    const [showSegmentEditor, setShowSegmentEditor] = useState(false); // Add the showSegmentEditor state
    const upsertLibrary = useSelector(allowUpsertLibrary);
    const upsertVariable = useSelector(allowUpsertVariable);
    const upsertGrammar = useSelector(allowUpsertGrammar);
    const upsertPrompt = useSelector(allowUpsertPrompt);
    const upsertAudio = useSelector(allowUpsertAudio);
    const browsers = useSelector(selectBrowsers);
    const dispatch = useAppDispatch();
    // Newly Added
    const [editorOpen, setEditorOpen] = useState(false)  // Track Library Creator/Editor State
    const [editorState, setEditorState] = useState("")
    const [selectedData, setSelectedData] = useState<any[]>([]);  // Contains the data of the clicked library item (edit icon)
    //
    const history = useNavigate();
    const helpCenter = useSelector(getHelpCenter);

    const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState('');
    
    const [data, setData] = useState<any>([]);
    const [remove, setRemove] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<string>('');
    const [isRunOpen, setIsRunOpen] = useState<string>('');
    const [openSelectBrowser, setOpenSelectBrowser] = useState<string>('');
    const [criteria, setCriteria] = useState<any>({
        sort: '-lastModifiedTime',
        pageNo: 0,
        pageSize: 10,
        filter: '',
        // projectId: `${project?.testProjectID}`
        projectId: project ? project.testProjectID : projectId
    });

    const [discardChangeConfirmation, setDiscardChangeConfirmation] = useState<any>({
        callAfter: () => {},
        onConfrimAction: ()=> {},
        onDiscardAction: ()=> {}
    });

    const pageSizeChanged = (size: number) => {
        setCriteria({
            ...criteria,
            pageSize: size,
            pageNo: 0,
        })
    }

    const runDigital = async ()=> {
        if (browsersToRun.length > 0) {
             await runDigitalTestCases(project?.testProjectID  || projectId || 1, runParameters, browsersToRun.join(','));
            setOpenSelectBrowser('');
        }
    }
    
    const browserSelected = (e:any)=> {
        if(e.target.checked) {
            setBrowsersToRun(browsersToRun.concat(e.target.value))
        } else {
            setBrowsersToRun(browsersToRun.filter(b=> b!== e.target.value))
        }
    }
    // const columns: any[] = [
    //     {
    //         caption: t('Sts'), width: '51px',
    //         redner: (params: any) => (
    //             <div className="flex-center">
    //                 <img src={greenCheckIcon} alt="" />
    //             </div>
    //         ),
    //     },
    //     { caption: t('ID'), width: '55px', fieldName: 'testCaseId', sortable: true, key: true },
    //     {
    //         fieldName: 'name', caption: t('Test Case Name'),
    //         redner: (params: any) => <div className="flex-center">
    //             <Link onClick={gotoTestCase(params)} className="a-link-black">{params.name}</Link></div>
    //     },
    //     { fieldName: 'ivrPhoneNumber', caption: t('Tel No.') },
    //     { fieldName: 'lastModifiedTime', caption: t('Modified'), redner: (params: any) => formatTimestamp(params.lastModifiedTime) },
    // ];

    const columns = useMemo(()=> 
        [
            {
                caption: t('Sts'), width: '5%',
                redner: (params: any) => (
                    <div className="flex-center">
                        {!params?.lastTestRun ? null:<img src={params?.lastTestRun === 'PASS' || params?.lastTestRun?.[0]?.status  === 'PASS' ? greenCheckIcon : redCrossIcon} alt="" />}
                    </div>
                ),
            },
            {
                caption: t('ID'), width: projectLibraryOpen ? '10%': '5%', fieldName: project?.data?.channelType === CHANNELS.DIGITAL ? 'testCaseId' : 'testCaseId', key: true, sortable: true,
                redner: (params: any) => <div className="flex-center">
                    {params.testCaseId}
                </div>
            },
            {
                fieldName: 'name', caption: t('Test Case Name'), sortable: true, width: '55%',
                redner: (params: any) => <div className="flex-center">
                    <Link onClick={gotoTestCase(params)} className="a-link-black">{params.name}</Link></div>
            },
            project?.data?.channelType === CHANNELS.DIGITAL ? {
                fieldName: 'ivrPhoneNumber', caption: '', sortable: true, width: '15%',
                redner: (params: any) => <div className="flex-center"></div>
            }:{
                fieldName: 'ivrPhoneNumber', caption: t('Tel No.'), sortable: true, width: '15%',
                redner: (params: any) => <div className="flex-center">
                    {params.ivrPhoneNumber}
                </div>
            },
            { fieldName: 'lastModifiedTime', caption: t('Modified'), sortable: true, width: '15%',redner: (params: any) => formatTimestamp(params.lastModifiedTime) },
        ] as any ,[project, t])
    
    const gotoTestCase = (testcase: ITestCase) => async () => {
        const { data } = await getTestCaseById(testcase.projectId || 1, testcase.testCaseId || 0, project?.data?.channelType)(dispatch);
        dispatch(setCurrentTestCase(data));
        setCaseEditorOpen(true);
        setProjectLibraryOpen(true);
    }

    const loadData = async () => {
        /* Load Project based on ID in URL */
        const project = await getProjectById(Number(projectId));
        const projectGrammar = await getGrammarOptions(Number(projectId));

        dispatch(serGrammaroptions(projectGrammar));
        dispatch(setCurrentProject(project));
        dispatch(setCurrentProjectId(projectId));
        /* */
        const { data } = await getTestCaseList(criteria.projectId, project?.data?.channelType, criteria.pageNo, criteria.pageSize, criteria.sort, criteria.filter);
        setData(data)
        
        dispatch(setCurrentProjectId(criteria.projectId));
    }

    const editProject = (id: string) => {
        setEdit(data.find((d: any) => +d.id === +id));
        setCaseEditorOpen(true);
    };

    const toggleCaseEditor = () => {
        if (!caseEditorOpen) {
            dispatch(setCurrentTestCase(null));
            dispatch(setBatchSegments([]));
        }

        setCaseEditorOpen(!caseEditorOpen);
        setEdit(null);
        setShowSegmentEditor(false)
        setProjectLibraryOpen(true);
    };

    const toggleGlobalLibrary = () => {
        setGlobalLibraryOpen(!globalLibraryOpen);
        setProjectLibraryOpen(false);
    };

    const toggleProjectLibrary = () => {
        setProjectLibraryOpen(!projectLibraryOpen);
        setGlobalLibraryOpen(false);
    };

    const toggleSegmentEditor = (close?: boolean, open?: boolean) => {
        if (open) {
            setShowSegmentEditor(true);
        } else if (close) {
            setShowSegmentEditor(false);
            dispatch(hideSegmentLibrary());
        } else {
            setShowSegmentEditor(!showSegmentEditor); // Toggle the showSegmentEditor state
        }
    };

    const onFilterChanged = (filter: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            filter,
        })
    }

    const sortChanged = (column: string, direction: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
        })
    }

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    const selectionChanged = (id: any) => {
        setRemove(id);
    }

    const deleteSelected = async () => {
        if (remove?.length) {
            setIsOpen(`${new Date().getTime()}`);
        }
    }

    const deleteTestCase = async () => {
        await deleteTestCases(project.testProjectID, remove,project?.data?.channelType);

        await loadData();
        setRemove([]);
    }

    useEffect(() => {
        loadData();
        dispatch(setCurrentTestCase(null));
    }, [criteria])

    let gridItemSize = 12;
    if (upsertLibrary || upsertVariable || upsertGrammar || upsertPrompt || upsertAudio) {
        gridItemSize = 4;
    } else if (caseEditorOpen && showSegmentEditor && (globalLibraryOpen || projectLibraryOpen)) {
        gridItemSize = 4;
    } else if (caseEditorOpen && (globalLibraryOpen || projectLibraryOpen || showSegmentEditor)) {
        gridItemSize = 6;
    } else if (globalLibraryOpen || projectLibraryOpen) {
        gridItemSize = 6;
    } else {
        gridItemSize = 12;
    }


    const getTabType = () => {
        if (upsertVariable) {
            return "Variable"
        } else if (upsertGrammar) {
            return "Grammar"
        } else if (upsertPrompt) {
            return "Prompt"
        } else if (upsertAudio) {
            return "Audio"
        }
    }

    const goToTestCases = async () => {
        if (!currentTestCase) return;
        await loadData();
        setCaseEditorOpen(false);
        setEdit(null);
        setShowSegmentEditor(false)
        setProjectLibraryOpen(false);
        dispatch(setCurrentTestCase(null));
        dispatch(setEditSegment(null));
        dispatch(upsertSegmentLibrary(''))
        dispatch(upsertLibraryAudio(''))
        dispatch(upsertLibraryGrammar(''))
        dispatch(upsertLibraryPrompt(''))
        dispatch(upsertLibraryVariable(''))
    }

    const checkIsAnyEditorOpen  = () => showSegmentEditor || editorOpen;

    const text = (<>
        <DialogContentText sx={{
                        fontSize: "16px",
                        color: '#000000',
                        textAlign: "center",
                        fontFamily: 'Roboto',
                        mt: 5,
                        mb: 5,
                    }}>
                    <div>
                        <ErrorOutlineIcon style={{ fontSize: '50px', color: '#FD5900', paddingBottom: '33px' }} />
                    </div>
                    {`Do you want to save the changes to the ${getTabType()} Library?`}
        </DialogContentText>
    </>);

    const checkMultiRun = async()=> {
        const runCheck = await checkMultiVariable(project.testProjectID, remove.join(','), project?.data?.channelType);
        setRunParameters(runCheck);
        setIsRunOpen(uniqueId());
    }

    const setItCount = (testCase:any)=>(e:any)=> {
        setRunParameters(
            runParameters.reduce((res:any[],curr:any)=> {
                if(curr.testCaseId === testCase.testCaseId) {
                    res.push({
                        ...curr,
                        runAll:e.target.checked,
                    })
                } else {
                    res.push(curr);
                }
                return res;
            }, [])
        )
    }

    const runMultiple = async()=> {
        await runMultipleVariableTestCase(project.testProjectID, runParameters);
        setIsRunOpen('');
    }

    const handleDiscardChangeConfirmation = async () => {
        await discardChangeConfirmation?.onConfrimAction();
        discardChangeConfirmation.callAfter();
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container>
                {/* Header */}
                <Grid item xs={6}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                        <Typography variant="subtitle2">{t('Project')} :</Typography>
                        <Typography variant="subtitle2">{project ? project.testProjectID : projectId}</Typography>
                        {project?.data?.channelType === CHANNELS.VOICE ? (<PhoneEnabledOutlinedIcon style={{ fontSize: '17px' }} />) : (<img src={digital} alt=""/>)}
                        {/* <Typography onClick={goToTestCases} className={currentTestCase ? 'underline' : ''} variant="subtitle2">{projects[`${projectId}`]?.name}</Typography> */}
                        <Typography onClick={goToTestCases} className={currentTestCase ? 'underline' : ''} variant="subtitle2">{project?.data?.name}</Typography>

                        {currentTestCase && (
                            <>
                                <Typography variant="subtitle2">{'>'} {currentTestCase?.name}</Typography>
                                {helpCenter && <div  onClick={()=>dispatch(showHelpDetails('Test Case Navigation Panel'))} className="help-icon-float-right"><InfoIcon /></div>}
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6} textAlign="right" marginBottom={'16px'}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', alignItems:'center', marginRight: '12px' }}>
                        {/* New Test Case Button */}
                        {helpCenter && !caseEditorOpen && <div id='testcasebuttons' onClick={()=>dispatch(showHelpDetails('New Test Case'))} className="help-icon"><InfoIcon /></div>} <Button variant={caseEditorOpen ? 'outlined' : 'contained'} color="primary" onClick={toggleCaseEditor}>
                            {caseEditorOpen ? t('Close Case Editor') : t('New Test Case')}
                        </Button>
                        {/* Global Library Button */}
                        {/* <Button
                            variant={globalLibraryOpen ? 'outlined' : 'contained'}
                            color="primary"
                            onClick={toggleGlobalLibrary}
                        >
                            {globalLibraryOpen ? t('Close Library') : t('Global Library')}
                        </Button> */}
                        {/* Project Library Button */}
                        <Button
                            variant={projectLibraryOpen ? 'outlined' : 'contained'}
                            color="primary"
                            onClick={toggleProjectLibrary}
                        >
                            {projectLibraryOpen ? t('Close Library') : t('Project Library')}
                        </Button>
                    </div>
                </Grid>

                {/* Case Editor or DataGrid */}
                {caseEditorOpen ? (
                    <Grid item xs={gridItemSize} paddingRight={'9px'} >
                        {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Test Case Editor/Add Segment'))} className="help-icon-float"><InfoIcon /></div>}<NewTestCase toggleSegmentEditor={toggleSegmentEditor} />
                    </Grid>
                ) : (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <Card>
                            <CardContent>
                                <div className='test-result-header'>
                                    <div className="action-header">
                                        {/* <img src={lockIcon} alt="" /> */}
                                        <img src={deleteIcon} alt={t("Remove")} onClick={deleteSelected} />
                                        <img src={downloadIcon} alt="" />  
                                        <img onClick={checkMultiRun} src={remove?.length>0 ? runmultiples:runmultiple} alt="" />  {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Project View page'))} className="help-icon"><InfoIcon /></div>}
                                    </div>
                                    <div className="test-results-filters">
                                        <SearchInput onFilter={onFilterChanged} />
                                    </div>
                                </div>

                                <DataGrid
                                    pageChanged={pageChanged}
                                    totalPages={data?.totalPages || 0}
                                    sortChanged={sortChanged}
                                    columns={columns}
                                    data={data?.content || []}
                                    selection={true}
                                    sortBy={criteria.sort}
                                    selectionChanged={selectionChanged}
                                    pageSizeChanged={pageSizeChanged} />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                
                {/* Segment Editor */}
                {(showSegmentEditor && !(upsertLibrary || upsertVariable || upsertGrammar || upsertPrompt || upsertAudio)) && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        {showSegmentEditor && <div>{helpCenter && <div id="Segment Editor Help"  onClick={()=>dispatch(showHelpDetails('Segment Editor'))} className="help-icon-float"><InfoIcon /></div>}
                        
                        {project?.data?.channelType === CHANNELS.VOICE ? <SegmentEditor 
                            projectLibrary={false} 
                            toggleSegmentEditor={toggleSegmentEditor} 
                            setDiscardChangeConfirmation={setDiscardChangeConfirmation}/>:<DigitalSegmentEditor  projectLibrary={false} toggleSegmentEditor={toggleSegmentEditor} />}
                            </div>
                        }
                    </Grid>
                )}

                {/* Global/Project Library */}
                {(globalLibraryOpen || projectLibraryOpen) && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        {globalLibraryOpen && <GlobalLibrary />}
                        {projectLibraryOpen && 
                            <div> {helpCenter && <div id="Project Library"  onClick={()=>dispatch(showHelpDetails('Project Library'))} className="help-icon-float"><InfoIcon /></div>}<ProjectLibrary
                            toggleSegmentEditor={toggleSegmentEditor}
                            gridItemSize={gridItemSize}
                            editorOpen={editorOpen}
                            setEditorOpen={setEditorOpen}
                            selectedData={selectedData}
                            setSelectedData={setSelectedData}
                            editorState={editorState}
                            setEditorState={setEditorState}
                            caseEditorOpen={caseEditorOpen}
                            checkIsAnyEditorOpen={checkIsAnyEditorOpen}
                            discardChangeConfirmation={discardChangeConfirmation}
                            setDiscardChangeConfirmation={setDiscardChangeConfirmation}
                            setOpenDiscardConfirmation={setOpenDiscardConfirmation}
                        /> </div>}
                    </Grid>
                )}

                {upsertLibrary && !editorOpen && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        {helpCenter && <div id="Segment Editor Help"  onClick={()=>dispatch(showHelpDetails('Segment Editor'))} className="help-icon-float"><InfoIcon /></div>}
                        
                        {project?.data?.channelType === CHANNELS.VOICE ? <SegmentEditor 
                            projectLibrary={true} 
                            toggleSegmentEditor={toggleSegmentEditor} 
                            setDiscardChangeConfirmation={setDiscardChangeConfirmation} />
                            :<DigitalSegmentEditor  projectLibrary={true} toggleSegmentEditor={toggleSegmentEditor} />}
                    </Grid>
                )}


                {/* Segment Editor */}
                {(upsertVariable || upsertGrammar || upsertPrompt || upsertAudio) && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <LibraryEditor
                            selectedData={selectedData} //
                            setSelectedData={setSelectedData} //
                            editorOpen={true}
                            setEditorOpen={setEditorOpen} //
                            type={getTabType()}
                            project={project}
                            editorState={editorState} //
                            toggleSegmentEditor={toggleSegmentEditor}
                            setDiscardChangeConfirmation={setDiscardChangeConfirmation}
                        />
                    </Grid>
                )}


                <Modal
                    isOpen={isOpen}
                    title="Delete Items"
                    small={true}
                    deleteConfirmation={true}
                    ButtonDelete={deleteTestCase}
                />
                <Modal 
                    isOpen={openDiscardConfirmation}
                    title='Discard the changes'
                    small={true}
                    deleteConfirmation={true}
                    ButtonDelete={handleDiscardChangeConfirmation}
                    onClosed={() => {
                        discardChangeConfirmation?.onDiscardAction();
                        discardChangeConfirmation?.callAfter()
                    }}
                    callOnClosed={false} //to stop calling onClosed function during confirm
                    body={text}
                />

                <Modal 
                  isOpen={openSelectBrowser}
                  title={t("Browser Selections")}
                  small={true}
                  body={
                    <div className='db w-100'>
                        <div className='db'><h5>{t('Available Browsers:')}</h5></div>
                        {browsers?.map((b:any)=>(
                            <div className='flex align-center gap-8 mt-24'>
                               <Checkbox onChange={browserSelected} value={b.browserCode} /> {b.browserName} ({b.browserVersion})
                            </div>
                        ))}

                         <Box className="segment-editor-save-buttons mt-22 flex-end gap-8">
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                className='max-h-30'
                                onClick={()=>setOpenSelectBrowser('')}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                className='max-h-30'
                                onClick={runDigital}
                            >
                                {t('Run')}
                            </Button>
                        </Box>
                    </div>
                  }
                  />

                <Modal
                    isOpen={isRunOpen}
                    title={t("Run Parameter")}
                    small={true}
                    body ={
                        <div className='db w-100 center'>
                            <div className='db border-blue'>
                                <div className='dib w-50 center'><Typography variant="body1" className="black">{t('Text Case Name')}</Typography></div> <div className='dib w-20 center' >{t('Total Runs')}</div> 
                                <div className='dib center  w-30'>{t('Variables')}</div>
                            </div>
                            <div className='db mb-16'>
                                <div className='dib w-50 center'></div> <div className='dib w-20 center' ></div> 
                                <div className='dib center  w-30 '><div className='flex justify-center gap-24 light-silver'><span>{t('DEFAULT')}</span><span> {t('ALL')}</span></div></div>
                            </div>
                            {runParameters.map(r=>(
                                <div className='db mb-8'>
                                    <div className='dib w-50'><Typography variant="body1" className="black">{r?.testCaseName}</Typography></div> 
                                    <div className='dib w-20 center'><Typography variant="body1" className="black">{r?.iterationCount}</Typography></div> 
                                    <div className='dib center w-30'>
                                        <div className='run-param2'><Switch onChange={setItCount(r)} disabled={r?.iterationCount === 1} /></div>
                                    </div>
                                </div>
                            ))}

                            {project?.data?.channelType !== CHANNELS.DIGITAL ? <Box className="segment-editor-save-buttons mt-22">
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30'
                                        onClick={()=>setIsRunOpen('')}
                                    >
                                        {t('Back')}
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30 ml-80'
                                        onClick={runMultiple}
                                    >
                                        {t('Run')}
                                    </Button>
                            </Box>:
                            <div className='db w-100'>
                                <div className='db'><h5>{t('Available Browsers:')}</h5></div>
                                {browsers?.map((b:any)=>(
                                    <div className='flex align-center gap-8 mt-24'>
                                    <Checkbox onChange={browserSelected} value={b.browserCode} /> {b.browserName} ({b.browserVersion})
                                    </div>
                                ))}
        
                                <Box className="segment-editor-save-buttons mt-22 flex-end gap-8">
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30'
                                        onClick={()=>{
                                            setOpenSelectBrowser('');
                                            setIsRunOpen('');
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        className='max-h-30'
                                        onClick={runDigital}
                                    >
                                        {t('Run')}
                                    </Button>
                                </Box>
                            </div>
                            }
                        </div>
                    }
                />

            </Grid>
        </DndProvider>

    );
};
