export enum HELP_CENTER {
    NONE = '',
    PROJECT = 'PROJECT',
}

export enum GLOBAL_ACTION {
    ADD = 1,
    NONE = 0,
    CLOSE = 2,
    EDIT = 3,
}

export enum RUN_STATUS {
    STARTED = 'RUNNING',
    FAILED = 'FAILED',
    SUCCESS = 'PASS',
    NONE = '',
}


export enum TESTCASE_RUN_STATUS {
    NONE = 0,
    STARTED = 1,
    FINISHED = 2,
    STOPPED = 3,
}

export enum STEP_TYPE {
    LISTEN = 'LISTEN',
    SPEAK = 'SPEAK',
    PLAY_AUDIO = 'PLAYBACK_AUDIO',
    PAUSE = 'KEEP_SILENCE',
    HANGUP = 'HANGUP',
    LISTEN_ANYTHING = 'LISTEN_ANYTHING',
    RECORD_VOICE = 'RECORD_VOICE',
    TRANSFER_CALL_TO_AGENT = 'VA_INIT_TRX_AGENT',
    SCREEN_SHOT = 'VA_SCRN_SHOT',
    GET_CALL_DATA = 'VA_GET_FILE_DATA',
    ENTER_SCREEN_DATA = 'VA_FILL_SCRN_DATA',
    MOUSE_ACTION = 'VA_MOUSE_CTRL',
    SCREENSCRAPE_DATA = 'VA_GET_SCRN_DATA',
    NONE = '',
}


export enum BRANCH_CONDITION {
    GO_To_NEXT = '1',
    GO_TO_STEP = '2',
    NUMBER_OF_RUNS = '4',
    NEXT_SEGMENT = '5',
    STEP_TEST_RUN = '6',
}

export enum CHANNELS {
    VOICE = 'voice',
    DIGITAL = 'web',
}


export enum DIGITAL_STEP_TYPE {
    CLICK = 'CLICK',
    FIND_ITEM = 'FIND_ITEM',
    LOAD_PAGE = 'LOAD_PAGE',
    PAUSE = 'PAUSE',
    ENTER_TEXT = 'ENTER_TEXT',
    SCREENSHOT = 'SCREENSHOT',
    SWITCH_FRAME = 'SWITCH_FRAME',
    NONE = '',
    MOUSE_OVER = 'MOUSE_OVER',
}

export enum LOCATOR_TYPES {
    HTML_ELEMENT = 'SP.HTML.BY_ELEMENT',
    HTML_ATTRIBUTE = 'SP.HTML.BY_ATTRIBUTE',
    DISPLAY_TEXT = 'SP.HTML.BY_DISPLAY',
}

export enum PARAM_TYPES {
    SIMPLE_NAME_VALUE = "sp.simple.name_value",
    SIMPLE_URL = "sp.simple.load_page.url",
    SIMPLE_TEXT = "sp.simple.enter_text.value",
    SIMPLE_TEXT_LINE = "sp.simple.enter_text.newline",
    SIMPLE_PAUSE = "sp.simple.pause.seconds",
}

