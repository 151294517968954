import { Checkbox, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TableContainer, Typography, Slider, Button, Box, Tooltip, Link } from "@mui/material";
import { useEffect, useRef, useState, Fragment, useMemo, useCallback } from "react";
import { IGridcolumn } from "./interface/column.interface";
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Pagination from '@mui/material/Pagination';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import { useAppDispatch } from '../store/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { setCurrentTestResultDetails } from '../store/resuders/project.reducer';
import { getCurrentProject, getCurrentTestResult, getCurrentTestResultDetails } from '../store/selectors/project.selector';
import { getTestResultList, getTestResultDetails } from '../services/testresults.service';
import { useSelector } from 'react-redux';
import { formatTimestamp } from "../helpers/date.helper";
import { useTranslation } from 'react-i18next';
import { CHANNELS, DIGITAL_STEP_TYPE, LOCATOR_TYPES } from "../types/global.enum";
import { Modal } from "./modal";
import { ActionInfoBody } from "./actionInfoBody";
import { getDigitalApi, getVoiceApi } from "../services/config.service";
import { handleScreenShotsUrl } from "../services/testcase.service";


export interface IProps {
    columns: IGridcolumn[];
    data: any;
    selection?: boolean;
    selectionChanged?: (id: string[]) => void;
    sortBy?: string;
    sortDirection?: 'ASC' | 'DESC' | '';
    sortChanged?: (column: string, direction: string) => void;
    pageChanged?: (page: number) => void;
    totalPages?: number;
    criteria: any;
}
export const TestResultDetailsGrid = ({
    columns = [],
    data = {},
    selection = false,
    selectionChanged,
    sortChanged,
    sortBy = '',
    sortDirection = 'ASC',
    totalPages = 0,
    pageChanged,
    criteria
}: IProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { projectId, testcaseId, testResultId } = useParams();  // ProjectID and TestCaseID from URL
     const project = useSelector(getCurrentProject);  // Store Project
    const testResultDetails = useSelector(getCurrentTestResultDetails)  // Store Test Result Details
    const [audioPosition, setAudioPosition] = useState<number[]>([]); // State to track audio playback position for each segment
    const [playingAudioIndex, setPlayingAudioIndex] = useState<number | null>(
        null
    ); // Index of the segment with playing audio
    const audioElementsRef = useRef<(HTMLAudioElement | null)[]>([]); // Ref to hold an array of <audio> elements
    const [isFullScreen, setIsFullScreen] = useState(false); // Image/Screenshot Full Screen State
    const [fullImage, setFullImage] = useState(''); // Image/Screenshot Full Screen State

    const [openActionInfo, setOpenActionInfo] = useState<string>('');
    const [clickedStep, setClickedStep] = useState<any>(null);

    const toggleFullScreen = (url:string) => ()=> {
        setIsFullScreen(!isFullScreen);
        setFullImage(url);
    };


    const formatDate = (dateTimeString: any) => {
        if (!dateTimeString) return '';

        const date = new Date(dateTimeString);
        const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    };

    const formatOutput = (run: any) => {
        let output = '';

        switch (run.stepType) {
            case DIGITAL_STEP_TYPE.LOAD_PAGE: {
                output = 'URL:' + '  ' + `${run.simpleParameters[0]?.value}` + '*' + 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                break;
            }
            case DIGITAL_STEP_TYPE.PAUSE: {
                output = 'Seconds:' + '  ' + `${run.simpleParameters[0]?.value}` + '*' + 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                break;
            }
            case DIGITAL_STEP_TYPE.FIND_ITEM: {
                switch (run.itemLocators[0]?.paramType) {
                    case LOCATOR_TYPES.DISPLAY_TEXT: {
                        output = 'Locator Type: Find by display text' + ' , ' +
                            'Operations: ' + `${run.itemLocators[0]?.matchOp}` + ' , ' +
                            'Display Text: ' + `${run.itemLocators[0]?.value}` + '*' + 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                        break;
                    }
                    case LOCATOR_TYPES.HTML_ATTRIBUTE: {
                        output = 'Locator Type: Find by html attribute' + ' , ' +
                            'Attribute: ' + `${run.itemLocators[0]?.name}` + ' , ' +
                            'Operations: ' + `${run.itemLocators[0]?.matchOp}` + ' , ' +
                            'Attribute Value: ' + `${run.itemLocators[0]?.value}` + '*' + 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                        break;
                    }
                    case LOCATOR_TYPES.HTML_ELEMENT: {
                        output = 'Locator Type: Find by html element' + ' , ' +
                            'Element Name: ' + `${run.itemLocators[0]?.name}` +
                            '*' + 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                        break;
                    }
                }


                break;
            }
            case DIGITAL_STEP_TYPE.CLICK: {
                output = 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                break;
            }
            case DIGITAL_STEP_TYPE.ENTER_TEXT: {
                output = 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                break;
            }

            case DIGITAL_STEP_TYPE.SWITCH_FRAME: {
                output = 'Remark:' + '  ' + `${run.remark || 'N/A'}`
                break;
            }
            case DIGITAL_STEP_TYPE.SCREENSHOT: {
                output =!run.testResult?.attachmentUrl ? "#": getDigitalApi().replace('/api/chat/v1','') + `${run.testResult?.attachmentUrl}`;
                break;
            }

        }
        return output;
    }

    const downloadAudio = (attachmentUrl: any | undefined) => {
        if (attachmentUrl) {
            const modifiedVariable = attachmentUrl?.replace("/api/voice/v1", "");
            const desiredURL =
            getVoiceApi() + modifiedVariable;

            // Create a hidden <a> tag to trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = desiredURL;
            downloadLink.download = "audio.wav";
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const playAudio = (attachmentUrl: string | undefined, index: number) => {
        if (attachmentUrl) {
            const modifiedVariable = attachmentUrl?.replace("/api/voice/v1", "");
            const desiredURL = getVoiceApi() + modifiedVariable;

            // Check if the audio element for this segment already exists
            if (!audioElementsRef.current[index]) {
                const audio = new Audio(desiredURL);

                audio.addEventListener("ended", () => {
                    setPlayingAudioIndex(null); // Reset the playing audio index
                });

                audioElementsRef.current[index] = audio;
            }

            const audioElement = audioElementsRef.current[index];

            if (index === playingAudioIndex) {
                // If the audio for the clicked segment is already playing, pause it
                audioElement?.pause();
                setPlayingAudioIndex(null);
            } else {
                // Stop any previously playing audio
                if (playingAudioIndex !== null) {
                    audioElementsRef.current[playingAudioIndex]?.pause();
                }

                // Play the clicked audio
                audioElement?.play();
                setPlayingAudioIndex(index);
            }
        }
    };

    // const loadData = async () => {
    //     const { data: tempData } = await getTestResultList(criteria.projectId);
    //     const { data: testResultData } = await getTestResultList(criteria.projectId, tempData.totalElements || 10, 0, criteria.filter);

    //     const testResultToFetch = testResultId ? testResultId : testResultData?.id;
    //     if (testResultToFetch) {
    //         const { data } = await getTestResultDetails(testResultToFetch);
    //         dispatch(setCurrentTestResultDetails(data));
    //     }
    // };


    // useEffect(() => {
    //     loadData();
    // }, []);

    useEffect(() => {
        // Add event listeners for each audio element
        audioElementsRef.current.forEach((audioElement, index) => {
            if (audioElement) {
                // Time update when moving position
                audioElement.addEventListener("timeupdate", () => {
                    // Update audio position here if needed
                });
                audioElement.addEventListener("ended", () => {
                    setPlayingAudioIndex(null); // Reset the playing audio index
                });
            }
        });

        // Clean up event listeners when the component unmounts
        return () => {
            audioElementsRef.current.forEach((audioElement, index) => {
                if (audioElement) {
                    // Time update when moving position
                    audioElement.removeEventListener("timeupdate", () => {
                        // Update audio position here if needed
                    });
                    audioElement.removeEventListener("ended", () => {
                        setPlayingAudioIndex(null);
                    });
                }
            });
        };
    }, [audioPosition]);


    const isDigital = useMemo(()=> project?.data?.channelType === CHANNELS.DIGITAL ,[project]);

    const showStepDetails = (step : any) => {
        setClickedStep(step);
        setOpenActionInfo(`${new Date().getTime()}`);
    };

    const onStepDetailsModalClose = () => {
        setOpenActionInfo('');
    };


    const downloadImage = useCallback((url: any | undefined, filename: string) => {
        window.open(handleScreenShotsUrl(url, isDigital));
    },[isDigital]);

    return (
        <TableContainer className="test-result-details-container">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                            <div className="test-result-details-text-wrapper">{t('Test Run Details')}</div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Custom row for Test Case ID, Test Case Name, and MOS */}
                    <TableRow>
                        <TableCell>{t('Test Run ID')}: {testResultId}</TableCell>
                        <TableCell colSpan={columns.length}>{t('From')}: {formatTimestamp(data?.testResult?.startTime)} to {formatTimestamp(data?.testResult?.endTime)}</TableCell>
                    </TableRow>
                    {/* Custom row for Test Run ID, From, and Duration */}
                    <TableRow>
                        <TableCell>{isDigital ? t('BROWSER'): t('CLID')}: {isDigital ? data?.testResult?.browserType: data?.callerId}</TableCell>
                        {!isDigital && <TableCell colSpan={columns.length-3}>{t('IVR Tel. Number')}: {data?.ivrPhoneNumber}</TableCell>}
                        
                        <TableCell>{t('Duration')}: {
                            (() => {
                                const startTime = new Date(data?.testResult?.startTime);
                                const endTime = new Date(data?.testResult?.endTime);
                                const durationMilliseconds = endTime.getTime() - startTime.getTime();
                                const durationInSeconds = durationMilliseconds / 1000;
                                if (durationInSeconds < 0) {
                                    return `0 secs`;
                                } else {
                                    return !durationInSeconds ? '':`${durationInSeconds.toFixed(2)} secs`;
                                }
                            })()
                        }
                        </TableCell>
                        {!isDigital && <TableCell>{t('MOS')}: {data?.testResult?.voice?.mos ? data.testResult.voice.mos : 0}</TableCell>}
                    </TableRow>
                </TableBody>
                {data?.segments?.map((segment: any, segmentIndex: any) => (
                    segment?.steps?.map((step: any, stepIndex: any) => (
                        <Fragment key={segmentIndex}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={columns.length + 1}>
                                        <Typography className="test-result-details-text-wrapper">
                                        {t('Test Segment')}: {segment?.id || segment?.testSegmentId} - {segment?.name}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead className="test-result-details-column-header">
                                <TableRow>
                                    <TableCell className="white-background-cell">{t('Step ID')}</TableCell>
                                    <TableCell className="white-background-cell step-type-cell" >{t('Step Type')}</TableCell>
                                    <TableCell className="white-background-cell">{t('Details')}</TableCell>
                                    <TableCell className="white-background-cell">{t('Status')}</TableCell>
                                    <TableCell className="white-background-cell">{t('Prompt Latency')} {'[sec]'}</TableCell>
                                    {!isDigital && <TableCell className="white-background-cell">{t('Duration')}</TableCell>}
                                    {!isDigital && <TableCell className="white-background-cell" style={{ textAlign: 'center' }}>{t('Action')}</TableCell>}
                                </TableRow>

                            </TableHead>
                            <TableBody className="test-result-details-column-body">
                                <TableRow key={stepIndex}>
                                    <TableCell className="light-green-background-cell">{step?.testStepId}</TableCell>
                                    <TableCell className="light-green-background-cell">
                                        {isDigital ? 
                                        <Link onClick={()=>{showStepDetails(step)}} className="a-link-black">
                                        {step?.stepType}
                                        </Link>   
                                        :
                                            step?.stepType
                                        }
                                        </TableCell>
                                    <TableCell className="light-green-background-cell">
                                        <div>

                                            {project?.data?.channelType === CHANNELS.DIGITAL && step?.stepType !== DIGITAL_STEP_TYPE.SCREENSHOT && (
                                                 <div>{formatOutput(step).split('*')[0]}<br /> {formatOutput(step).split('*')[1]}</div>
                                            )}

                                            {step?.stepType === DIGITAL_STEP_TYPE.SCREENSHOT && (
                                                <>
                                                 {step.testResult !== null && (<div className="image-container"> <a className="underscore" href={formatOutput(step)}><img
                                                            src={formatOutput(step)}
                                                            alt="screenshot"
                                                            className="image-clickable"
                                                            onClick={toggleFullScreen('')}
                                                        /></a></div>)}
                                                 <div>Remark: {step?.remark || 'N/A'}</div>
                                                </>
                                            )}


                                            {step?.stepType.toLowerCase() === "listen" && (
                                                <>
                                                    <div>{step?.prompt}</div>
                                                    {(step?.listenSubStep || []).map((c:any)=>(<div className="mt-16">({c.name}){c.prompt}</div>))}
                                                    <div style={{ marginTop: '20px' }}>
                                                        [{step?.promptLatencyMin},{step?.promptLatencyMax}] {(step?.testResult?.voice?.utterance) ? (step?.testResult?.voice?.utterance) : '<Utterance not recognized>'} [{step?.incompleteTimeout},{step?.completeTimeout}]
                                                    </div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "hang up" && (
                                                <>
                                                    {/* Empty Details*/}
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "pause" && (
                                                <>
                                                    {/* Empty Details */}
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "listen anything" && (
                                                <>
                                                    <div>{step?.prompt}</div>
                                                    <div style={{ marginTop: '20px' }}>
                                                        [{step?.promptLatencyMin},{step?.promptLatencyMax}] {step?.testResult?.voice?.utterance} [{step?.incompleteTimeout},{step?.completeTimeout}]
                                                    </div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "play audio" && (
                                                <>
                                                    {/* Render Play Audio-specific content here */}
                                                    <div>
                                                        {step?.libraryItemIds.map((item: any, index: any) => (
                                                            <div key={index}>{item.name}</div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}


                                            {step?.stepType.toLowerCase() === "record audio" && (
                                                <>
                                                    {/* Render Record Audio-specific content here */}
                                                    <div>{step?.recordingHint}</div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "speak" && (
                                                <>
                                                    <div>{step?.messageToPlay}</div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "va - transfer call to agent" && (
                                                <>
                                                    {/* Render va - transfer call to agent-specific content here */}
                                                    <div>{step?.agentXferActionQueueName}</div>
                                                    <div>{step?.agentXferActionAgentID}</div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "va - screenshot" && (
                                                <>
                                                    {/* Render va - screenshot-specific content here */}
                                                    <div className={`image-container ${isFullScreen ? 'full-screen' : ''}`}>
                                                        {isFullScreen && (
                                                            <button className="close-button" onClick={toggleFullScreen('')}>
                                                                <strong>X</strong>
                                                            </button>
                                                        )}
                                                        {fullImage && <img src={fullImage} />}
                                                        <img
                                                            src={handleScreenShotsUrl(step?.testResult?.voice?.agentResponse?.screenshot, isDigital)}
                                                            alt="screenshot"
                                                            className="image-clickable"
                                                            onClick={toggleFullScreen(handleScreenShotsUrl(step?.testResult?.voice?.agentResponse?.screenshot, isDigital))}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "va - mouse action" && (
                                                <>
                                                    {/* Render va - mouse action-specific content here */}
                                                    <div>{step?.mouseControlActionElementName}</div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "va - get call data" && (
                                                <>
                                                    {/* Render va - get call data-specific content here */}
                                                    <div>
                                                        {Object.keys(step?.participantDataActionAttributeNames || {}).map((key) => (
                                                            <div key={key}>
                                                                <strong>{key}:</strong> {step.participantDataActionAttributeNames[key]}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}


                                            {step?.stepType.toLowerCase() === "va - get screen data" && (
                                                <>
                                                    {/* Render va - get screen data-specific content here */}
                                                    <div>{step?.testResult?.voice?.agentResponse?.text || step?.screenDataActionExpectedValue}</div>
                                                </>
                                            )}

                                            {step?.stepType.toLowerCase() === "va - enter screen data" && (
                                                <>
                                                    {/* Render va - enter screen data-specific content here */}
                                                    <div>{step?.fillScreenDataActionValue}</div>
                                                </>
                                            )}
                                        </div>

                                    </TableCell>
                                    <TableCell className="light-green-background-cell"
                                        style={{
                                            color:
                                                step?.testResult?.status === "PASS" ? '#58A148' :
                                                    step?.testResult?.status === "FAILED" ? 'red' :
                                                        'grey'
                                        }}>
                                        {step?.testResult?.status ? step?.testResult?.status : 'NOT RUN'}
                                    </TableCell>
                                    <TableCell className="light-green-background-cell">
                                        {project?.data?.channelType === CHANNELS.DIGITAL && (
                                                <>
                                                {step?.latencyStep?.testResult?.latency || 0}
                                                </>
                                        )}
                                        {project?.data?.channelType === CHANNELS.VOICE && !["hangup", "pause", "play audio", "speak", "va - transfer call to agent", "va - screenshot", "va - get call data"].includes(
                                            step?.stepType.toLowerCase()
                                        ) && (
                                                <>
                                                  {((step?.testResult?.voice?.latency || step?.promptLatencyMax || 0)/1000).toFixed(2)}
                                                </>
                                            )}

                                    </TableCell>
                                    {!isDigital && <TableCell className="light-green-background-cell">
                                        {step?.stepType.toLowerCase() === "listen" && (
                                            <>
                                                {step?.testResult?.voice?.recordingDuration ? (step?.testResult?.voice.recordingDuration / 1000).toFixed(2) + ' sec'
                                                    : '0 sec'}
                                                <br />
                                                {step?.testResult?.voice?.confidence?.toFixed(2)  || step?.promptConfidenceLevel.toFixed(2) || '--'}
                                            </>
                                        )}
                                        {step?.stepType.toLowerCase() === "va - transfer call to agent" && (
                                            <>
                                                {step?.testResult?.voice?.latency ? (step?.testResult?.voice?.latency / 1000).toFixed(2) + ' sec'
                                                    : '0 sec'}
                                            </>
                                        )}
                                        {["pause", "listen anything", "play audio", "record audio", "speak", "va - screenshot", "va - mouse action", "va - get call data", "va - get screen data", "va - enter screen data"].includes((step?.stepType).toLowerCase()) && (
                                            <>
                                                {(step?.testResult?.startTime && step?.testResult?.endTime) ? (
                                                    (
                                                        (new Date(step.testResult.endTime).getTime() - new Date(step.testResult.startTime).getTime()) / 1000
                                                    ).toFixed(2) + ' sec'
                                                ) : '0 sec'}
                                            </>
                                        )}

                                    </TableCell>}

                                    {!isDigital && <TableCell className="light-green-background-cell">
                                        {!["hangup", "pause", "play audio", "speak", "va - get call data"].includes(
                                            step?.stepType.toLowerCase()
                                        ) && (
                                                <>
                                                    <div className="test-result-details-action-container">
                                                        <div className="action-buttons">
                                                            {["va - transfer call to agent", "va - screenshot", "va - mouse action", "va - get screen data", "va - enter screen data"].includes(
                                                                step?.stepType.toLowerCase()) ?
                                                                step?.testResult?.voice?.agentResponse?.screenshot && (<Tooltip title="Download Screenshot">
                                                                    <FileDownloadOutlinedIcon
                                                                        onClick={() => {
                                                                            downloadImage(step?.testResult?.voice?.agentResponse?.screenshot, "screenshot");
                                                                        }}
                                                                        style={{ fontSize: '35px', cursor: 'pointer' }}
                                                                    />
                                                                </Tooltip>
                                                                ) :
                                                                step?.testResult?.attachmentUrl && (
                                                                    <Tooltip title="Download Audio">
                                                                        <FileDownloadOutlinedIcon
                                                                            onClick={() => {
                                                                                downloadAudio(step?.testResult?.attachmentUrl);
                                                                            }}
                                                                            style={{ fontSize: '35px', cursor: 'pointer' }}
                                                                        />
                                                                    </Tooltip>

                                                                )
                                                            }
                                                            {["listen", "listen anything", "play audio", "record audio", "speak"].includes(
                                                                step?.stepType.toLowerCase()
                                                            ) && (
                                                                    <>
                                                                        {/* {playingAudioIndex === segmentIndex ? (
                                                                            <Tooltip title="Pause Audio">
                                                                                <PauseCircleFilledOutlinedIcon
                                                                                    onClick={() => {
                                                                                        playAudio(step?.testResult?.attachmentUrl, segmentIndex);
                                                                                    }}
                                                                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Play Audio">
                                                                                <PlayCircleFilledWhiteOutlinedIcon
                                                                                    onClick={() => {
                                                                                        playAudio(step?.testResult?.attachmentUrl, segmentIndex);
                                                                                    }}
                                                                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                                                                />
                                                                            </Tooltip>
                                                                        )} */}
                                                                        <audio controls>
                                                                            <source src={getVoiceApi() + (step?.testResult?.attachmentUrl)?.replace("/api/voice/v1", "")} type="audio/wav" />
                                                                        </audio>
                                                                    </>
                                                                )}
                                                        </div>


                                                        {["listen", "listen anything", "play audio", "record audio", "speak", "va - transfer call to agent"].includes(
                                                            step?.stepType.toLowerCase()
                                                        ) && (
                                                                <>
                                                                    {/* Audio Slider Component */}
                                                                </>
                                                                // <>
                                                                //     <Box className="play-audio-slider">
                                                                //         {/* Audio Current Position */}
                                                                //         <Box className="audio-position" >
                                                                //             <span>{audioPosition[segmentIndex]?.toFixed(2) || '0.00'}</span>
                                                                //         </Box>

                                                                //         <Slider
                                                                //             className="slider"
                                                                //             // Set the value of the Slider to the audioPosition for this segment
                                                                //             value={audioPosition[segmentIndex] || 0}
                                                                //             // Handle slider changes
                                                                //             onChange={(event, newValue) => {
                                                                //                 if (audioElementsRef.current[segmentIndex]) {
                                                                //                     // Pause the audio when the slider is moved
                                                                //                     audioElementsRef.current[segmentIndex]?.pause();
                                                                //                     // Update the audio playback position for this segment
                                                                //                     const newPosition = [...audioPosition];
                                                                //                     newPosition[segmentIndex] = newValue as number;
                                                                //                     setAudioPosition(newPosition);
                                                                //                 }
                                                                //             }}
                                                                //             // Handle slider change complete
                                                                //             onChangeCommitted={(event, newValue) => {
                                                                //                 if (audioElementsRef.current[segmentIndex]) {
                                                                //                     const audioElement = audioElementsRef.current[segmentIndex];
                                                                //                     if (audioElement) {
                                                                //                         // Seek the audio to the new position for this segment
                                                                //                         audioElement.currentTime = newValue as number;
                                                                //                         // Resume playing the audio when the slider change is complete
                                                                //                         audioElement.play().catch((error) => {
                                                                //                             console.error("Error resuming audio playback:", error);
                                                                //                         });
                                                                //                     }
                                                                //                 }
                                                                //             }}
                                                                //             min={0}
                                                                //             max={
                                                                //                 audioElementsRef.current[segmentIndex]
                                                                //                     ? audioElementsRef.current[segmentIndex]?.duration
                                                                //                     : 0
                                                                //             }
                                                                //         />

                                                                //         {/* Audio Length */}
                                                                //         <Box className="audio-duration" >
                                                                //             <span>
                                                                //                 {audioElementsRef.current[segmentIndex]
                                                                //                     ? audioElementsRef.current[segmentIndex]?.duration.toFixed(2)
                                                                //                     : '0.00'}
                                                                //             </span>
                                                                //         </Box>
                                                                //     </Box>
                                                                // </>
                                                            )}
                                                    </div>
                                                </>
                                            )}

                                    </TableCell>}

                                </TableRow>
                            </TableBody>
                        </Fragment>
                    ))
                ))}


            </Table>
            <Modal
                isOpen={openActionInfo}
                title={`Action Type Info - ${clickedStep?.stepType || ''}`}
                small={true}
                onClosed={onStepDetailsModalClose}
                primaryButton="Close"
                body={<ActionInfoBody step={clickedStep} stepType={clickedStep?.stepType || ''} onClose={onStepDetailsModalClose}/>}
                
            />
        </TableContainer>
    );
}