import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const audioSelectors = (state: IAppState) => state.audio;


export const getAudios = createSelector(
    [audioSelectors],
    (app) => app?.audio || []
);

export const getSelectedAudios = createSelector(
    [audioSelectors],
    (app) => app?.selectedAudios || []
);

export const allowUpsertAudio = createSelector(
    [audioSelectors],
    (app) => app?.upsertAudio,
);
