import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link, Checkbox } from '@mui/material';
import { DataGrid, Modal, TestResultStatusGrid, TestResultDetailsGrid } from '../ui';
import { useSelector } from 'react-redux';
import { getCurrentProject, getCurrentTestResult, getCurrentTestResultDetails } from '../store/selectors/project.selector';
import { getAllProjects } from '../store/selectors/project.selector';
import { SearchInput } from '../ui';
import { getTestResultList, getTestResultDetails } from '../services/testresults.service';
import { setCurrentTestResultDetails } from '../store/resuders/project.reducer';
import { useAppDispatch } from '../store/hooks';
import { useTranslation } from 'react-i18next';


export interface TestResultDetailsProps {
    pageChanged: (page: number) => void;
    totalPages: number;
    sortChanged: (column: string, direction: string) => void;
    columns: any[]; // Specify the correct type for columns
    data: any[]; // Specify the correct type for data
    selection: boolean;
    sortBy: string;
    selectionChanged: (id: any) => void;
    formatDate: (dateTimeString: any) => string;
    onFilterChanged: (filter: string) => void;
    criteria: any;
    schedule: any;
}

export const ScheduleTestResult: React.FC<TestResultDetailsProps> = ({
    pageChanged,
    totalPages,
    sortChanged,
    columns,
    data,
    selection,
    sortBy,
    selectionChanged,
    formatDate,
    onFilterChanged,
    criteria,
    schedule
}) => {
    const { t } = useTranslation();
    console.log("Data:", data)
    console.log("Schedule:", schedule)
    const dispatch = useAppDispatch();

    const { projectId, testcaseId } = useParams();  // ProjectID and TestCaseID from URL
    // const projects = useSelector(getAllProjects);  // Contains all projects
    const project = useSelector(getCurrentProject);  // Store Project
    const testResult = useSelector(getCurrentTestResult)  // Store Test Case
    // const testResultDetails = useSelector(getCurrentTestResultDetails)  // Store Test Result Details


    // Function to find the earliest startTime
    function getEarliestStartTime(data: any) {
        if (data.length === 0) {
            return null; // Return null if the array is empty
        }

        let earliestStartTime = data[0].startTime;

        for (let i = 1; i < data.length; i++) {
            if (data[i].startTime < earliestStartTime) {
                earliestStartTime = data[i].startTime;
            }
        }

        return earliestStartTime;
    }

    // Function to find the latest endTime
    function getLatestEndTime(data: any) {
        if (data.length === 0) {
            return null; // Return null if the array is empty
        }

        let latestEndTime = data[0].endTime;

        for (let i = 1; i < data.length; i++) {
            if (data[i].endTime > latestEndTime) {
                latestEndTime = data[i].endTime;
            }
        }
        if (latestEndTime){
        return latestEndTime;
        } else{
             return new Date()
            }
    }


    const loadData = async () => {

        // const { data: tempData } = await getTestResultList(criteria.projectId);
        // const { data: testResultData } = await getTestResultList(criteria.projectId, tempData.totalElements || 10, 0, criteria.filter);
        
        // const testResultToFetch = testResultId ? testResultId : testResultData?.id;

        // if (testResultToFetch) {
        //     const { data } = await getTestResultDetails(testResultToFetch);
        //     dispatch(setCurrentTestResultDetails(data));
        // }
    };
    
    useEffect(() => {
        loadData();
    }, []);
    


    const gridItemSize = 12


    return (
        // <Grid item xs={gridItemSize} paddingRight={'9px'}>
        //     <Card>
        //         <CardContent>
        //             <div className='test-result-header'>
        //                 <div className="action-header">
        //                     <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
        //                         <Typography variant="subtitle2"><strong>Test Case ID:</strong> #{testResultDetails?.id}</Typography>
        //                         <Typography variant="subtitle2"><strong>Test Case Name:</strong> {testResultDetails?.name}</Typography>
        //                         <Typography variant="subtitle2">
        //                             <strong>Status:</strong>{' '}
        //                             <span style={{ color: testResultDetails?.testResult.status === 'FAILED' ? 'red' : '#58A148' }}>
        //                                 {testResultDetails?.testResult.status}
        //                             </span>
        //                         </Typography>
        //                         <Typography variant="subtitle2">
        //                             <strong>From:</strong> {formatDate(testResultDetails?.testResult.startTime)} to {formatDate(testResultDetails?.testResult.endTime)}
        //                         </Typography>
        //                     </div>

        //                 </div>
        //                 <div className="test-results-filters">
        //                     <SearchInput onFilter={onFilterChanged} />
        //                 </div>
        //             </div>
        //             {/* <TestResultDetailsGrid
        //                             pageChanged={pageChanged}
        //                             totalPages={data?.totalPages || 0}
        //                             sortChanged={sortChanged}
        //                             columns={testDetailsColumns}
        //                             data={
        //                                 testResultDetails || []
        //                             }
        //                             selection={true}
        //                             sortBy={criteria.sort}
        //                             selectionChanged={selectionChanged}
        //                         /> */}
        //             <TestResultDetailsGrid
        //                 pageChanged={pageChanged}
        //                 totalPages={totalPages || 0}
        //                 sortChanged={sortChanged}
        //                 columns={columns}
        //                 data={testResultDetails || []}
        //                 selection={selection}
        //                 sortBy={sortBy}
        //                 selectionChanged={selectionChanged}
        //                 criteria
        //             />
        //         </CardContent>
        //     </Card>
        // </Grid>

        <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <Card>
                            <CardContent>
                                <div className='test-result-header'>
                                    <div className="action-header">
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                                            <Typography variant="subtitle2"><strong>{t('ID')}:</strong> #{schedule?.testScheduleId}</Typography>
                                            <Typography variant="subtitle2"><strong>{t('Name')}:</strong> {schedule?.name}</Typography>
                                            <Typography variant="subtitle2"><strong>{t('Type')}:</strong> {schedule?.type}</Typography>
                                            <Typography variant="subtitle2">
                                                <strong>{t('From')}:</strong> {formatDate(getEarliestStartTime(data))} {t('To')} {formatDate(getLatestEndTime(data))}
                                            </Typography>
                                        </div>

                                    </div>
                                    <div className="test-results-filters">
                                        <SearchInput onFilter={onFilterChanged} />
                                    </div>
                                </div>
                                <TestResultStatusGrid
                                    pageChanged={pageChanged}
                                    totalPages={totalPages || 0}
                                    sortChanged={sortChanged}
                                    columns={columns}
                                    // data={
                                    //     formatData()?.content.find(item => item.testCaseId === Number(testcaseId))?.results || []
                                    // }
                                    data={data || []}
                                    selection={true}
                                    sortBy={criteria.sort}
                                    selectionChanged={selectionChanged}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

    );
};
