import React, { useEffect, useState } from 'react';
import { InputLabel, FormControl, Box, Checkbox, FormControlLabel, TextField } from "@mui/material"
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { FormikProps } from "formik";
import { useDrop } from 'react-dnd'
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../../store/hooks";
import { IAudio } from "../../types/interfaces/audio.interface";
import { addSelectedAudios } from '../../store/resuders/audio.reducer';
import { getSelectedAudios} from "../../store/selectors/audio.selector";
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}


export const PlaybackAudioFile = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const audios = useSelector(getSelectedAudios);
    const [removeAudios, setRemoveAudios] = React.useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const addAudioFromLibrary = (item: any) => {
        if (item && item.itemType === 'audios') {
            dispatch(addSelectedAudios(item));
        }
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'ROWS',
        drop: addAudioFromLibrary,
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [audios])

    const promptSelectionChanged = (seg: IAudio) => (e: any) => {
        if (e.target.checked) {
            setRemoveAudios(removeAudios.concat(seg.id))
        } else {
            setRemoveAudios(removeAudios.filter(c => c !== seg.id))

        }
    }
    useEffect(() => {
        formik.setFieldValue('libraryItemIds', audios?.map(p => ({ libraryID: p.libraryID })));
    }, [audios]);
    // Style property for textfield prompts
    const boldStyle = { fontWeight: 'bold' };

    return (
        <>
            <Box className='audio-files-textarea' ref={drop}>
                <FormControl sx={{ width: "100%" }}>
                {!audios?.length ? (
                        <>
                    <InputLabel id="case-editor-audio-files"></InputLabel>
                    <TextField
                        id="audioFiles"
                        name='audioFiles'
                        value={"Add Audio from the Library"}
                        label={t("Audio Files")}
                        onChange={handleChange}
                    />
                    </>
                    ) : (
                        <>
                        <TextField
                                id="audioFiles"
                                name='audioFiles'
                                inputProps={{
                                    style: boldStyle,
                                  }}
                                value={audios.reduce(
                                    (acc: string, audio: IAudio) =>
                                    `${acc}${audio.libraryID} ${audio.name}\n`,
                                    ''
                                )}
                                label={t("Audio Files")}
                                onChange={handleChange}
                                multiline 
                                rows={1}  
                            />
                        </>
                        )}
                </FormControl>
            </Box>
        </>
    );
};
