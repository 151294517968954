import { Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { importData } from "../services/project.service";

export interface IProps {
    tab: string;
    language?:string;
    id:number;
    close: () => void;
}
export const ImportData = ({
    tab,
    close,
    language= 'english',
    id = 1,
}: IProps) => {
    const { t } = useTranslation();

    const selectFile = () => {
        document.getElementById('selectFile')?.click();
    }

    const fileChanged = async (e:any)=> {
        const result:any = await importData(id, tab, language, e?.target?.files[0]);
        if(result?.code==="success") {
            close();
        }
    }

    return (
        <div className="db p-5 text-center">
            <div className="db">
                <InfoOutlinedIcon style={{ fontSize: '53px', color: '#FD5900' }} />
            </div>
            <div className="db mt-16 calc-mos f-w-700">
                {t('Warning')}
            </div>
            <span className="f-w-700">
                {t('Importing might override some of the existing {tab}.').replace('{tab}',tab)}
                <br/>
                {t('Do you want to continue?')}
            </span>
            <div className="db mt-24">
                <div className="flex-center justify-center segment-editor-save-buttons gap-32">
                    <Button
                        variant={'contained'}
                        color="secondary"
                        className='max-h-30'
                        onClick={close}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant={'contained'}
                        color="primary"
                        onClick={selectFile}
                        className='max-h-30'
                    >
                        {t("Import")}
                    </Button>
                </div>
            </div>
            <input type="file" onChange={fileChanged} className="hidden" id="selectFile"></input>
        </div>
    )
}