import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const promptSelectors = (state: IAppState) => state.prompt;


export const getPrompts = createSelector(
    [promptSelectors],
    (app) => app?.prompt || []
);

export const getSelectedPrompts = createSelector(
    [promptSelectors],
    (app) => app?.selectedPrompts || []
);

export const allowUpsertPrompt = createSelector(
    [promptSelectors],
    (app) => app?.upsertPrompt,
);