import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link, Checkbox, Button, Box } from '@mui/material';
import { deleteIcon, greenCheckIcon } from '../assets';
import { DataGrid, Modal, TestResultStatusGrid, TestResultGrid } from '../ui';
import { useSelector } from 'react-redux';
import { getCurrentProject, getCurrentTestResult, getCurrentTestResultDetails } from '../store/selectors/project.selector';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useAppDispatch } from '../store/hooks';
import { deleteTestCases } from "../services/testcase.service";
import { setCurrentProjectId, setCurrentTestCase, setCurrentTestResult, setCurrentTestResultDetails, setCurrentProject } from '../store/resuders/project.reducer';
import { getAllProjects } from '../store/selectors/project.selector';
import { SearchInput } from '../ui';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getTestResultList, getTestResultDetails } from '../services/testresults.service';
import { useNavigate } from "react-router-dom";
import { TestResultDetails } from './testResultsDetails';
import { getProjectById } from '../services/project.service';
import { TestResultStatus } from './testResultsStatus';
// import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { ScheduleEditor } from './scheduleEditor';
import { deleteSchedules, getCaseListofLastTestResultofSchedule, getScheduleList, getSchedulerDetails, upsertSchedule } from '../services/scheduler.service';
import { SchedulerGrid } from '../ui/schedulerGrid.ui';
import { useFormik } from "formik"
import dayjs from 'dayjs';
import { formatTimestamp } from '../helpers/date.helper';
import { showHelpDetails, toggleAlert } from '../store/resuders/app.reducer';
import { setCurrentSchedule } from '../store/resuders/schedule.reducer';
import { ScheduleTestResult } from './scheduleTestResult';
import { useTranslation } from 'react-i18next';
import { getHelpCenter } from "../store/selectors/app.selector";
import InfoIcon from '@mui/icons-material/Info';
import { DigitalScheduleEditor } from './digitalScheduleEditor';
import { CHANNELS } from "../types/global.enum";
import { getVoiceApi } from '../services/config.service';

interface Schedule {
    testScheduleId: number;
    name: string;
    type: string;
    status: string;
    browser: string[];
    remark: string | null;
    lastModifiedTime: Date;
    testCases: any[]; 
    triggerTimeForRunOnce: Date | null;
    scheduleTime: any[];
    lastRunTime: Date | null;
    lastRunStatus: any | null;
    projectId: number;
    voice: string | null;
  }

export const Scheduler = () => {
    // const projects = useSelector(getAllProjects);  // Contains all projects
    const { projectId, testcaseId, testResultId } = useParams();  // ProjectID and TestCaseID from URL
    const { t } = useTranslation();
    const project = useSelector(getCurrentProject);  // Store Project
    const testResult = useSelector(getCurrentTestResult)  // Store Test Case
    const testResultDetails = useSelector(getCurrentTestResultDetails)  // Store Test Result Details
    const [schedulerPage, setSchedulerPage] = useState(true);
    const [testStatusPage, setTestStatusPage] = useState(false);
    const [scheduleEditorOpen, setScheduleEditorOpen] = useState(false);
    const [scheduleEditorAction, setScheduleEditorAction] = useState('ADD');
    const [currentSchedule, setCurrentSchedule] = useState<Schedule>({
        testScheduleId: 0,
        name: "",
        type: "",
        status: "",
        browser: [],
        remark: null,
        lastModifiedTime: new Date(),
        testCases: [],
        triggerTimeForRunOnce: null,
        scheduleTime: [],
        lastRunTime: null,
        lastRunStatus: null,
        projectId: 0,
        voice: null,
      });


    const dispatch = useAppDispatch();
    const history = useNavigate();
    const [data, setData] = useState<any>([]);
    const [scheduleLastResultCaseList, setScheduleLastResultCaseList] = useState<any>([]);
    const [remove, setRemove] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<string>('');
    const [criteria, setCriteria] = useState<any>({
        sort: '-lastModifiedTime',
        pageNo: 0,
        pageSize: 10,
        filter: '',
        projectId: project ? project?.testProjectID : projectId
    });
    const helpCenter = useSelector(getHelpCenter);

    const pageSizeChanged = (size: number) => {
        setCriteria({
            ...criteria,
            pageSize: size,
        })
    }

    //Validate Add Schedular Form Data
    const validate = (values:any) => {
        const errors:any = {};
        const missingFields:string[] = [];

        if(!values.name) missingFields.push('Schedule Name');
        if(!values.type) {
            missingFields.push('Schedule Type');
        } else if(values.type === 'Perpetual') {
            if(values.scheduleTime.length === 0) missingFields.push('Recurrence');
        }
        if(!values.status) missingFields.push('Status');
        if(values?.testCases?.length === 0) missingFields.push('Test case');
        if(!values?.voice?.port) missingFields.push('Port is missing');

        if(missingFields.length > 0) {
            errors.missingFields = missingFields.toString();
            dispatch(toggleAlert('Please fill-in the missing fields: '+errors.missingFields));
        }

        return errors;
    };

    const formik = useFormik({
        validateOnChange:false,
        validateOnBlur:false,
        initialValues: {
            // testScheduleId: null,
            // lastRunStatus: null,
            // lastRunTime: null,
            projectId: projectId,
            remark: '',
            browser: ["FIREFOX"],
            lastModifiedTime: new Date(),
            scheduleTime: [],
            triggerTimeForRunOnce: "Now",
            name: '',
            voice: {
                port: 1,
            },
            type: '',
            status: 'Disabled',
            // trigger: 'Future',
            // startDate: dayjs(),
            // stopDate: dayjs(),
            testCases: []
        } as any,
        onSubmit: async (values: any) => {
            // if (formik?.values?.trigger === 'Future') {
            //     formik.setFieldValue('triggerTimeForRunOnce', formik?.values?.startDate.format('YYYY-MM-DDTHH:mm:ss'));
            // } else {
            //     formik.setFieldValue('triggerTimeForRunOnce', "Now");
            // }           

            console.log('submit', values);

            let result;

            if ( scheduleEditorAction === "ADD") {
                result = await upsertSchedule(project?.testProjectID, formik.values, "ADD", project?.data?.channelType);
            } else {
                result = await upsertSchedule(project?.testProjectID, formik.values, "UPDATE", project?.data?.channelType);
            }


            
            if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result.payload)) {

                dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))

            } else if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result.code)) {

                dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))

            }else {
                onDone();
            }


        },
        validate
    })


    const handleClear = () => {
        formik.setValues({
            // testScheduleId: null,
            // lastRunStatus: null,
            // lastRunTime: null,
            projectId: projectId,
            remark: '',
            browser: ["FIREFOX"],
            lastModifiedTime: new Date(),
            scheduleTime: [],
            triggerTimeForRunOnce: 'Now',
            name: '',
            voice: {
                port: 1
            },
            type: '',
            status: 'Disabled',
            // trigger: 'Future',
            // startDate: dayjs(),
            // stopDate: dayjs(),
            testCases: []
        });
    };



    // function formatTimestamp(timestamp: any) {
    //     const date = new Date(timestamp);
    //     const formattedDate = date.toLocaleDateString('en-US', {
    //         year: 'numeric',
    //         month: '2-digit',
    //         day: '2-digit'
    //     });
    //     const formattedTime = date.toLocaleTimeString('en-US', {
    //         hour: 'numeric',
    //         minute: 'numeric',
    //         hour12: true
    //     });
    //     return `${formattedDate} ${formattedTime}`;
    // }

    const downloadAudio = (attachmentUrl: any | undefined) => {
        console.log(downloadAudio);
        if (attachmentUrl) {
            const modifiedVariable = attachmentUrl?.replace("/api/voice/v1", "");
            const desiredURL =
            getVoiceApi() + modifiedVariable;

            // Create a hidden <a> tag to trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = attachmentUrl;
            downloadLink.download = "audio.wav";
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const formatData = () => {
        if (data && data.content && Array.isArray(data.content)) {
            const aggregatedData = data.content.reduce((acc: any, result: any) => {
                if (!acc[result.testCaseId]) {
                    acc[result.testCaseId] = {
                        passed: result.status === 'PASS' ? 1 : 0,
                        failed: result.status === 'FAILED' ? 1 : 0,
                        totalCount: 1, // Initialize the total count
                        testCaseName: result.testCaseName,
                        startTime: new Date(result.startTime).getTime(), // Store the timestamp for comparison
                        endTime: new Date(result.endTime).getTime(), // Store the timestamp for comparison
                        results: [result], // Initialize the results array with the current result
                        data: data.content
                    };
                } else {
                    if (result.status === 'PASS') {
                        acc[result.testCaseId].passed++;
                    } else if (result.status === 'FAILED') {
                        acc[result.testCaseId].failed++;
                    }
                    acc[result.testCaseId].totalCount++; // Increment the total count

                    // Update startTime if the current startTime is earlier
                    const startTime = new Date(result.startTime).getTime();
                    if (startTime > acc[result.testCaseId].startTime) {
                        acc[result.testCaseId].startTime = startTime;
                    }
                    acc[result.testCaseId].results.push(result);
                }
                return acc;
            }, {});

            const formattedData = {
                content: Object.keys(aggregatedData).map(testCaseId => ({
                    ...aggregatedData[testCaseId],
                    testCaseId: parseInt(testCaseId, 10),
                    startTime: formatTimestamp(aggregatedData[testCaseId].startTime), // Use earliest startTime for each test case
                })),
            };
            return formattedData;
        } else {

            return null;
        }
    };


    // Columns for Test Results Status Page
    const schedulerColumns: any[] = [
        {
            fieldName: 'testScheduleId', caption: t('ID'), width: '5%'
        },
        {
            fieldName: 'name', caption: t('SCHEDULE NAME'), width: '35%',
            redner: (params: any) => (
                <div className="flex-center">
                    <Link onClick={gotoScheduleEditorPage(params)} className="a-link-black">
                        {params.name}
                    </Link>
                </div>
            )
        },
        {
            fieldName: 'lastRunTime', caption: t('LAST RUN DATE'),width: '15%',
            redner: (params: any) => (
                <div className="flex-center">
                    {params.lastRunTime ? formatTimestamp(params.lastRunTime) : t('No Last Run')}
                </div>

            )
        },
        {
            fieldName: 'lastRunStatus', caption: t('LAST RUN STATUS'), width: '15%',
            redner: (params: any) => (
                <div className="flex-center">
                    <div className="last-run-status-circle last-run-status-green-circle">
                        {params.lastRunStatus?.success || 0}
                    </div>
                    <div className="last-run-status-circle last-run-status-yellow-circle">
                        {params.lastRunStatus?.warning || 0}
                    </div>
                    <div className="last-run-status-circle last-run-status-red-circle">
                        {params.lastRunStatus?.fail || 0}
                    </div>
                    <div>Total: {params.lastRunStatus?.total || 0}</div>
                </div>
            )
        },
        {
            fieldName: '', caption: t('RESULT'),width: '5%',
            redner: (params: any) => (
                <div className="flex-center">
                    <GradingOutlinedIcon onClick={gotoTestCaseStatusPage(params)} className="a-link-black" />
                </div>
            )
        },
        {
            fieldName: 'type', caption: t('SCHEDULE TYPE'), sortable: true, width: '10%'
        },
        { fieldName: 'status', caption: t('STATUS'), width: '10%' }
    ];


    // Columns for Test Results Status Page
    const testStatusColumns: any[] = [
        { caption: t('ID'), width: '55px', fieldName: 'testCaseId', sortable: true, key: true },
        {
            fieldName: 'testCaseName', caption: t('TEST CASE NAME'),
            redner: (params: any) => <div className="flex-center">
                <Link onClick={gotoTestResultDetailsPage(params)} className="a-link-black">{params.testCaseName}</Link></div>
        },
        {
            fieldName: 'startTime', caption: t('DATE TIME'),
            redner: (params: any) => {
                const startTime = new Date(params.startTime);
                const formattedDate = startTime.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                });
                const formattedTime = startTime.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
                return <span>{formattedDate}&nbsp;&nbsp;&nbsp;{formattedTime}</span>;
            }
        },

        {
            fieldName: 'duration', caption: t('DURATION'),
            redner: (params: any) => {
                const startTime = new Date(params.startTime);
                const endTime = new Date(params.endTime);
                const durationMilliseconds = endTime.getTime() - startTime.getTime();
                const durationInSeconds = durationMilliseconds / 1000;
                if (durationInSeconds < 0) {
                    return `0 secs`;
                } else {
                    return `${durationInSeconds.toFixed(2)} secs`;
                }
            }
        },
        {
            fieldName: 'mosScore', caption: t('MOS'),
        },
        {
            fieldName: 'status', caption: t('STATUS'),
            redner: (params: any) => (
                <span style={{ color: params?.status === 'FAILED' ? 'red' : params?.status === 'RUNNING' ? 'orange' : '#58A148' }}>
                    {params?.status}
                </span>
            )
        },

        {
            fieldName: 'action', caption: t('ACTION'),
            redner: (params: any) => (
                <div className='flex-center'>
                    {params?.audioUrl && (
                        <FileDownloadOutlinedIcon
                            onClick={() => {
                                downloadAudio(params?.audioUrl);
                            }}
                            style={{ fontSize: '30px', cursor: 'pointer' }}
                        />
                    )}
                    
                </div>
            )
        }
    ];



    const openSchedulerView = () => {
        setSchedulerPage(true)
        setTestStatusPage(false)
        setScheduleEditorOpen(false)
        history(`/projects/${project?.testProjectID || projectId}/scheduler`);
    }

    const openTestResultsStatusView = () => {
        setSchedulerPage(false)
        setTestStatusPage(true)
        setScheduleEditorOpen(false)
    }

    const openScheduleEditor = () => {
        setSchedulerPage(false)
        setTestStatusPage(false)
        setScheduleEditorOpen(true)
    }

    const toggleScheduleEditor = () => {
        if (scheduleEditorOpen) {
            handleClear()
            setScheduleEditorOpen(false);
            setScheduleEditorAction('ADD');
            openSchedulerView();
        } else {
            // setScheduleEditorOpen(true)
            openScheduleEditor();
        }
    };

    const addSchedule = () => {
        setScheduleEditorAction('ADD');
        setScheduleEditorOpen(true);
    };

    const editSchedule = () => {
        setScheduleEditorAction('EDIT');
        setScheduleEditorOpen(true);
    };




    // const gotoTestCaseStatusPage = (testCase: any) => async () => {
    //     console.log("TestCase:", testCase)
    //     dispatch(setCurrentTestResult(testCase));
    //     history(`/projects/${project?.testProjectID || projectId}/scheduler/${testCase?.testCaseId || testcaseId}`);
    //     openTestResultsStatusView();
    // }
    const gotoTestCaseStatusPage = (schedule: any) => async () => {
        window.location.replace(`/projects/${projectId}/scheduled/testresults/${schedule.testScheduleId}`);
        // if(project?.data?.channelType === CHANNELS.DIGITAL){
        //     alert('Not avillable for digital Scheduler');
        //     return;
        // }
        // setCurrentSchedule(schedule)
        // const { data } = await getCaseListofLastTestResultofSchedule(schedule?.projectId ,schedule?.testScheduleId,schedule?.lastRunStatus?.resultId);
        // setScheduleLastResultCaseList(data);
        // openTestResultsStatusView();
    }

    // Open Schedule Editor When Schedule Name is Clicked
    const gotoScheduleEditorPage = (params: any) => async () => {
        // const { data } = await getTestResultDetails(testResult?.id);
        // dispatch(setCurrentTestResultDetails(data));
        // history(`/projects/${project?.testProjectID || projectId}/testresults/${testResult.testCaseId}/${data.testResult.id}`);
        
        if(project.data.channelType === CHANNELS.DIGITAL){
            const { data } = await getSchedulerDetails( Number(projectId), params?.testScheduleId);
            formik.setValues({
                testScheduleId: data?.testScheduleId || null,
                lastRunStatus: data?.lastRunStatus || null,
                lastRunTime: data?.lastRunTime || null,
                name: data?.name || null,
                projectId: projectId,
                remark: data?.remark || null,
                browser: data?.browser || null,
                lastModifiedTime: data?.lastModifiedTime || null,
                scheduleTime: data?.scheduleTime || null,
                triggerTimeForRunOnce: data?.triggerTimeForRunOnce || 'Now',
                voice: {
                    port: data?.voice?.port || 1,
                },
                type: data?.type || null,
                status: data?.status || null,
                // trigger: 'Future',
                // startDate: dayjs(),
                // stopDate: dayjs(),
                testCases: data?.testCases || null,
            });
        } else {
            formik.setValues({
                testScheduleId: params?.testScheduleId || null,
                lastRunStatus: params?.lastRunStatus || null,
                lastRunTime: params?.lastRunTime || null,
                name: params?.name || null,
                projectId: projectId,
                remark: params?.remark || null,
                browser: params?.browser || null,
                lastModifiedTime: params?.lastModifiedTime || null,
                scheduleTime: params?.scheduleTime || null,
                triggerTimeForRunOnce: params?.triggerTimeForRunOnce || 'Now',
                voice: {
                    port: params?.voice?.port || 1,
                },
                type: params?.type || null,
                status: params?.status || null,
                // trigger: 'Future',
                // startDate: dayjs(),
                // stopDate: dayjs(),
                testCases: params?.testCases || null,
            });
        }
            
        editSchedule();
        openScheduleEditor();
    }

    const gotoTestResultDetailsPage = (params: any) => async () => {
        const { data } = await getTestResultDetails(params?.resultId);
        dispatch(setCurrentTestResultDetails(data));
        history(`/projects/${project?.testProjectID || projectId}/testresults/${params.testCaseId}/${params.resultId}`);
    }


    const loadData = async () => {
        const project = await getProjectById(Number(projectId));
        dispatch(setCurrentProject(project));
        dispatch(setCurrentProjectId(projectId));
        if (schedulerPage) {
            // const { data: tempData } = await getTestResultList(criteria.projectId);
            // const { data } = await getTestResultList(criteria.projectId, tempData.totalElements || 10, 0, criteria.filter);
            const { data } = await getScheduleList(criteria.projectId, criteria.pageSize, criteria.pageNo, criteria.filter, project?.data?.channelType);
            // dispatch(setCurrentSchedule(data))
            setData(data);
        } else if (testStatusPage) {
            const { data: tempData } = await getTestResultList(criteria.projectId);
            const { data } = await getTestResultList(criteria.projectId, tempData.totalElements || 10, 0, criteria.filter);
            setData(data);
        }
        // else if (testDetailsPage) {
        //     // Handle testDetailsPage logic if needed
        // }
    };

    const onDone = () => {
        handleClear()
        openSchedulerView()
        window.location.reload();
    }


    const sortChanged = (column: string, direction: string) => {
        if (column === 'startTime') {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}startTime`,
            });
        } else {
            setCriteria({
                ...criteria,
                pageNo: 0,
                sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
            });
        }
    };

    const pageChanged = (page: number) => {
        setCriteria({
            ...criteria,
            pageNo: page - 1,
        })
    }

    const selectionChanged = (id: any) => {
        setRemove(id);
    }

    const deleteSelected = async () => {
        if (remove?.length) {
            setIsOpen(`${new Date().getTime()}`);
        }
    }

    const deleteSchedule = async () => {
        await deleteSchedules(project.testProjectID, remove, project?.data?.channelType);

        await loadData();
        setRemove([]);
    }

    const onFilterChanged = (filter: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            filter,
        })
    }

    const formatDate = (dateTimeString: any) => {
        if (!dateTimeString) return '';

        const date = new Date(dateTimeString);
        const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    };


    useEffect(() => {
        loadData();
        dispatch(setCurrentTestCase(null));
        if (testcaseId) {
            openTestResultsStatusView();
        }
        else {
            openSchedulerView();
        }
    }, [criteria])


    let gridItemSize = 12;
    // if (upsertLibrary || upsertVariable || upsertGrammar || upsertPrompt || upsertAudio) {
    //     gridItemSize = 4;
    // } else if (caseEditorOpen && showSegmentEditor && (globalLibraryOpen || projectLibraryOpen)) {
    //     gridItemSize = 4;
    // } else if (caseEditorOpen && (globalLibraryOpen || projectLibraryOpen || showSegmentEditor)) {
    //     gridItemSize = 6;
    // } else if (globalLibraryOpen || projectLibraryOpen) {
    //     gridItemSize = 6;
    // } else {
    //     gridItemSize = 12;
    // }


    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container>
                {/* Header */}
                <Grid item xs={6} paddingTop={'16px'} paddingBottom={'10px'}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                        <Typography variant="subtitle2">Project :</Typography>
                        <Typography variant="subtitle2">{project ? project?.testProjectID : projectId}</Typography>
                        {project?.data?.channelType === 'voice' ? (<PhoneEnabledOutlinedIcon style={{ fontSize: '17px' }} />) : (<TextsmsOutlinedIcon style={{ fontSize: '17px' }} />)}
                        {project ?
                            <>
                                <Typography variant="subtitle2">
                                    <Link
                                        onClick={() => {
                                            openSchedulerView();
                                            // gotoTestCaseStatusPage(testResult)();
                                        }}
                                        className="a-link-black"
                                    >
                                        {/* {projects[`${projectId}`]?.name} */}
                                        {project?.data?.name}
                                    </Link>
                                </Typography>
                            </>
                            : null}
                            {helpCenter && <div onClick={()=>dispatch(showHelpDetails(''))} className="help-icon-float-right"><InfoIcon /></div>}
                        {testStatusPage &&
                            <>
                                <Typography variant="subtitle2">{'>'}</Typography>
                                <Link onClick={gotoTestCaseStatusPage(currentSchedule)} className="a-link-black">
                                    <Typography variant="subtitle2">{currentSchedule?.testScheduleId} {currentSchedule?.name}</Typography>
                                </Link>
                            </>
                        }
                    </div>
                </Grid>
                <Grid item xs={6} textAlign="right" marginBottom={'16px'}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginRight: '12px' }}>
                    {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Add Schedule page'))} className="help-icon"><InfoIcon /></div>}
                        {/* Add Schedule Button */}
                        <Button variant={scheduleEditorOpen ? 'outlined' : 'contained'} color="primary" onClick={toggleScheduleEditor}>
                            {scheduleEditorOpen ? t('Close Schedule') : t('Add Schedule')}
                        </Button>
                    </div>
                </Grid>


                {/* Case Editor or DataGrid */}
                {schedulerPage && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        <div>
                        {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Schedule page'))} className="help-icon-float"><InfoIcon /></div>}
                            <Card>
                                <CardContent>
                                    <div className='test-result-header'>
                                        <div className="action-header">
                                            <img src={deleteIcon} alt="Remove" onClick={deleteSelected} />
                                            
                                        </div>
                                        <div className="test-results-filters">
                                            <SearchInput onFilter={onFilterChanged} />
                                        </div>
                                    </div>
                                    <SchedulerGrid
                                        pageChanged={pageChanged}
                                        totalPages={data?.totalPages || 0}
                                        sortChanged={sortChanged}
                                        columns={schedulerColumns}
                                        data={project?.data?.channelType === CHANNELS.DIGITAL ? data?.content || [] : data?.content || []}
                                        selection={true}
                                        sortBy={criteria.sort}
                                        selectionChanged={selectionChanged} 
                                        pageSizeChanged={pageSizeChanged}/>
                                </CardContent>
                            </Card>
                        </div>
                        
                    </Grid>
                )}
                {testStatusPage && (
                    // <TestResultStatus
                    //     pageChanged={pageChanged}
                    //     totalPages={data?.totalPages || 0}
                    //     sortChanged={sortChanged}
                    //     columns={testStatusColumns}
                    //     // data={
                    //     //     testResultDetails || []
                    //     // }
                    //     data={
                    //         formatData()?.content.find(item => item.testCaseId === Number(testcaseId))?.results || []
                    //     }
                    //     selection={true}
                    //     sortBy={criteria.sort}
                    //     selectionChanged={selectionChanged}
                    //     formatDate={formatDate}
                    //     onFilterChanged={onFilterChanged}
                    //     criteria={criteria}
                    // />
                    <ScheduleTestResult
                        pageChanged={pageChanged}
                        totalPages={0}
                        sortChanged={sortChanged}
                        columns={testStatusColumns}
                        // data={
                        //     testResultDetails || []
                        // }
                        data={
                            // formatData()?.content.find(item => item.testCaseId === Number(testcaseId))?.results || []
                            scheduleLastResultCaseList || []
                        }
                        selection={true}
                        sortBy={criteria.sort}
                        selectionChanged={selectionChanged}
                        formatDate={formatDate}
                        onFilterChanged={onFilterChanged}
                        criteria={criteria}
                        schedule={currentSchedule}
                    />

                )}
                {scheduleEditorOpen && (
                    <Grid item xs={gridItemSize} paddingRight={'9px'}>
                        {project?.data?.channelType === CHANNELS.DIGITAL ? (
                            <DigitalScheduleEditor 
                                formik={formik}
                                setScheduleEditorAction={setScheduleEditorAction}/> 
                        ) : (
                            <ScheduleEditor
                                data={data || []} 
                                formik={formik} 
                                toggleScheduleEditor={toggleScheduleEditor} 
                                scheduleEditorAction={scheduleEditorAction} 
                                setScheduleEditorAction={setScheduleEditorAction}
                            />
                        )}
                        
                        
                    </Grid>
                )}


                <Modal
                    isOpen={isOpen}
                    title={t("Delete Items")}
                    small={true}
                    deleteConfirmation={true}
                    ButtonDelete={deleteSchedule}
                />

            </Grid>
        </DndProvider>

    );
};
