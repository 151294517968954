import { format, parseISO, differenceInSeconds } from 'date-fns'

export const formatDate = (date: any, formatString = 'yyyy/MM/dd HH:mm:ss'): string => {
    if (typeof date === 'string' && date.indexOf('T') !== -1) {
        return formatDate(parseISO(date), formatString);
    }

    return date && format(new Date(date), formatString);
};


// export const formatTimestamp = (timestamp: any) => {
//     const date = new Date(timestamp);
//     const formattedDate = date.toLocaleDateString('en-US', {
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit'
//     });
//     const formattedTime = date.toLocaleTimeString('en-US', {
//         hour: 'numeric',
//         minute: 'numeric',
//         hour12: true
//     });
//     return `${formattedDate} ${formattedTime}`;
// }

export const formatTimestamp = (timestamp: any) => {
    if(!timestamp) return '';
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds} ${ampm}`;
};

export const getDiifInSec = (start?: any, end?: any) => start && end ? differenceInSeconds(parseISO(end), parseISO(start)) : 0;
export const getIntDiifInSec = (start?: any, end?: any) => start && end ? differenceInSeconds((end), (start)) : 0;


export const convertDateFormat = (originalDateStr : any) => {
    const originalDate = new Date(originalDateStr);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(originalDate.getDate()).padStart(2, '0');
    const hours = String(originalDate.getHours()).padStart(2, '0');
    const minutes = String(originalDate.getMinutes()).padStart(2, '0');
    const seconds = String(originalDate.getSeconds()).padStart(2, '0');

    // Assuming you want the final format to be in UTC time zone
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    
    return formattedDate;
}