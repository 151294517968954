import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLocatorTypes, selectOperations } from "../../store/selectors/project.selector";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { LOCATOR_TYPES } from "../../types/global.enum";
import DeleteIcon from '@mui/icons-material/Delete';

export const ActionTypeCriteria = ({
    property = 'criteria',
    onChange = null as any,
    value = null as any,
})=> {
    const { t } = useTranslation();
    const [criterias, setCriterias] = useState<any>(null);

    const allStepTypes = useSelector(selectLocatorTypes);
    const operations = useSelector(selectOperations);

    const addCriteria = ()=> {
        setCriterias((criterias || []).concat({}));
    }

    const updateOption = (key:string, index:number)=>(e:any)=> {
        setCriterias(
            criterias?.reduce((res:any[],curr:any,i:number)=> {
                if(i === index) {   
                    res.push({
                        ...curr,
                        [key]:e.target.value,
                    })
                }else {
                    res.push(curr)
                } 
                return res;
            },[])
        )
    }

    const remove = (i:number)=>()=> {
        setCriterias(criterias.filter((_:any,index:number)=> i!== index));
    }

    useEffect(()=> {
        if(value?.length && !criterias?.length) {
            setCriterias(value);
        }
    }, [value]);

    useEffect(()=> {
        onChange && onChange(criterias);
    }, [criterias]);

    return (
        <div>
            {criterias?.map((c:any,i:number)=>(
                <div key={`criteria_${i}`} className="criterai-container mt-16 relative">
                    <FormControl sx={{ width: "80%",display:'block' }} className="relative">
                        <InputLabel id="locatorType">Locator Type</InputLabel>
                        <Select
                            labelId="case-editor-type"
                            id="locatorType"
                            label={t("Locator Type")}
                            name="locatorType"
                            fullWidth
                            onChange={updateOption('locatorType',i)}
                            value={c.locatorType}
                        >
                            {allStepTypes?.map((item: any) => (
                                <MenuItem key={`step_types_${item.typeCode}`} value={item.typeCode}>{item.description}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                    <div className="remove-criteria">
                        <DeleteIcon onClick={remove(i)} />
                    </div>
                     {c.locatorType === LOCATOR_TYPES.HTML_ELEMENT && 
                        <FormControl sx={{ width: "80%",display:'block',mt:2 }} className="relative">
                            <InputLabel id="elementName"></InputLabel>
                            <TextField
                                fullWidth
                                id="elementName"
                                name='elementName'
                                value={c.elementName}
                                label="Element Name"
                                onChange={updateOption('elementName',i)}
                            />
                        </FormControl>}

                        {c.locatorType === LOCATOR_TYPES.DISPLAY_TEXT && 
                            <>
                                <FormControl sx={{ width: "80%",display:'block',mt:2 }} className="relative">
                                    <InputLabel id="operation"></InputLabel>
                                    <TextField
                                        select
                                        fullWidth
                                        id="operation"
                                        name='operation'
                                        value={c.operation}
                                        label="Operations"
                                        onChange={updateOption('operation',i)}
                                    >
                                        {operations.map((item:any) => (
                                            <MenuItem key={item.typeCode} value={item.typeCode} >{item.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>

                                <FormControl sx={{ width: "80%",display:'block',mt:2 }} className="relative">
                                    <InputLabel id="displayText"></InputLabel>
                                    <TextField
                                        fullWidth
                                        id="displayText"
                                        name='displayText'
                                        value={c.displayText}
                                        label="Display Text"
                                        onChange={updateOption('displayText',i)}
                                    />
                                </FormControl>
                            </>
                        }
                    
                     {c.locatorType === LOCATOR_TYPES.HTML_ATTRIBUTE && 
                        <>
                            <FormControl sx={{ width: "80%",display:'block',mt:2 }} className="relative">
                                <InputLabel id="attributeName"></InputLabel>
                                <TextField
                                    fullWidth
                                    id="attributeName"
                                    name='attributeName'
                                    value={c.attributeName}
                                    label="Attribute Name"
                                    onChange={updateOption('attributeName',i)}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "80%",display:'block',mt:2 }} className="relative">
                                <InputLabel id="operation"></InputLabel>
                                <TextField
                                    select
                                    fullWidth
                                    id="operation"
                                    name='operation'
                                    value={c.operation}
                                    label="Operations"
                                    onChange={updateOption('operation',i)}
                                >
                                    {operations.map((item:any) => (
                                        <MenuItem key={item.typeCode} value={item.typeCode} >{item.name}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl sx={{ width: "80%",display:'block',mt:2 }} className="relative">
                                <InputLabel id="attributeValue"></InputLabel>
                                <TextField
                                    fullWidth
                                    id="attributeValue"
                                    name='attributeValue'
                                    value={c.attributeValue}
                                    label="Attribute Value"
                                    onChange={updateOption('attributeValue',i)}
                                />
                            </FormControl>
                        </>
                    }
                </div>
            ))}
           
            <div className="mt-16">            
                <Button onClick={addCriteria} variant="contained" color="secondary" className="btn-criteria">Add Criteria</Button>
            </div>
        </div>
    )
}