import { createAction, createReducer } from "@reduxjs/toolkit";
import { IAudio } from "../../types/interfaces/audio.interface";

export const setCurrentAudio = createAction<IAudio[]>('setCurrentAudio');
export const addAudios = createAction<IAudio[]>('addAudios');
export const addSelectedAudios = createAction<IAudio[]>('addSelectedAudios');
export const setSelectedAudios = createAction<IAudio[]>('setSelectedAudios');
export const clearSelectedAudios = createAction('clearSelectedAudios');
export const removeAudios = createAction<string[]>('removeAudios');
export const updateAudios = createAction<IAudio[]>('updateAudios');
export const upsertAudio = createAction<string>('upsertAudio');
export const setEditAudio = createAction<any>('setEditAudio');


export const audioReducer = createReducer<IAudio[] | null>([], (builder) => {

    builder.addCase(setCurrentAudio, (state: any, action) => {
        return {
            ...state,
            audio: action.payload,
        }
    }).addCase(addAudios, (state: any, action) => {
        return {
            ...state,
            audio: (state?.audio || []).concat(action.payload),
        }
    }).addCase(setSelectedAudios, (state: any, action) => {
        return {
            ...state,
            selectedAudios: action.payload,
        }
    }).addCase(addSelectedAudios, (state: any, action) => {
        return {
            ...state,
            selectedAudios: (state?.selectedAudios || []).concat(action.payload),
        }
    }).addCase(clearSelectedAudios, (state: any, action) => {

        return {
            ...state,
            selectedAudios: [],
        }
    }).addCase(removeAudios, (state: any, action) => {

        return {
            ...state,
            audio: state?.audio.filter((c: IAudio) => !action.payload.includes(c.id)),
        }
    }).addCase(updateAudios, (state: any, action: any) => {
        return {
            ...state,
            audio: (state.audio || []).reduce((result: any, curr: any) => {
                if (curr.audioId === action.payload.audioId) {
                    result.push({
                        ...curr,
                        ...action.payload,
                    });
                } else {
                    result.push(curr);
                }

                return result;
            }, [])
        }
    }).addCase(setEditAudio, (state: any, action) => {

        return {
            ...state,
            editAudio: action.payload,
        }
    }).addCase(upsertAudio, (state: any, action) => {

        return {
            ...state,
            upsertAudio: action.payload,
        }
    })
})

