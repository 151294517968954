import { Button, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { GLOBAL_ACTION } from "../types/global.enum";
import { useEffect, useState } from "react";
import { deleteIcon, editFilledIcon, users } from "../assets";
import { DataGrid, SearchInput, Modal } from "../ui";
import { useTranslation } from "react-i18next";
import { AddUser } from "./addUser";
import { deleteUsers, getUserList } from "../services/user.service";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getAllProjectList } from "../services/project.service";
import { permissions } from "../helpers/form.helper";
import { globalNotification, toggleAlert } from "../store/resuders/app.reducer";
import { useAppDispatch } from "../store/hooks";

export const UserManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [action, setAction] = useState<GLOBAL_ACTION>(GLOBAL_ACTION.NONE);
  const [edit, setEdit] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<string>('');

  const [criteria, setCriteria] = useState<any>({
    sort: "-username",
    pageNo: 0,
    pageSize: 10,
    filter: "",
  });

  const columns: any[] = [
    {
      caption: t("First Name"),
      width: "18%",
      fieldName: "firstName",
      sortable: true,
      redner: (params: any) => (
        <div className="flex-center">
          <Link onClick={editUser(params)} className="a-link-black">
            {params.firstName}
          </Link>
        </div>
      ),
    },
    { caption: t("Last Name"), width: "18%", fieldName: "lastName", sortable: true, },
    { caption: t("Username"), width: "20%", fieldName: "username", sortable: true, },
    { 
      fieldName: "role",
      caption: t("Permissions"), 
      sortable: true,
      width: "18%", 
      redner: (param: any) => (<div>{permissions.find((p) => p.value === param.role)?.label}</div>),
    },
    {
      fieldName: t("Projects"),
      caption: "Projects",
      width: "16%",
      // redner: (param: any) => <div>{formatDate(param.lastModifiedTime)}</div>
      redner: (param: any) => <div>{param.role === 'ROLE_ADMIN' ? 'All' : param?.projects?.map((project: any) => project.testProjectID).join(", ")}</div>,
    },
  ];

  const sortChanged = (column: string, direction: string) => {
    setCriteria({
      ...criteria,
      pageNo: 0,
      sort: `${direction === "ASC" ? "+" : "-"}${column}`,
    });
  };

  const pageChanged = (page: number) => {
    setCriteria({
      ...criteria,
      pageNo: page - 1,
    });
  };

  const onFilterChanged = (filter: string) => {
    setCriteria({
      ...criteria,
      pageNo: 0,
      filter,
    });
  };

  const pageSizeChanged = (size: number) => {
    setCriteria({
      ...criteria,
      pageSize: size,
    });
  };
  const loadData = async () => {
    const { data } = await getUserList(criteria.pageNo, criteria.pageSize, criteria.sort, criteria.filter);
    setData(data?.data);
    const projectData = await getAllProjectList();
    setProjectList(projectData?.data || []);

  };


  useEffect(() => {
    loadData();
  }, [criteria]);

  const updateAction = () => {
    switch (action) {
      case GLOBAL_ACTION.NONE: {
        setAction(GLOBAL_ACTION.ADD);
        break;
      }

      default: {
        setAction(GLOBAL_ACTION.NONE);
        setEdit(null);
      }
    }
  };

  const selectionChanged = (id: any) => {
    setSelectedUser(id);
  };

  const editUser = (data: any) => () => {
    setEdit(data);
    setAction(GLOBAL_ACTION.EDIT);
  };

  const onDone = async () => {
    dispatch(globalNotification( edit ? t('User is updated') : t('User is created')));
    setAction(GLOBAL_ACTION.NONE);
    setEdit(null);
    await loadData();
  };

  const deleteSelected = async () => {
    if (selectedUser?.length) {
      setIsOpen(`${new Date().getTime()}`);
    }
  };

  const deleteUser = async () => {
    try {
        await deleteUsers(selectedUser);
        dispatch(globalNotification( t('User is deleted')));
        await loadData();
        setSelectedUser([]);
    } catch (error) {
        dispatch(toggleAlert("Users deletion failed")); // Dispatch toggleAlert action with an error alert
    }
  }

  const editSelected = () => {
    if(selectedUser?.length < 1) {
      dispatch(toggleAlert(t('Select an User first')));
    } else if(selectedUser?.length > 1) {
      dispatch(toggleAlert(t('Select one user to edit')));
    } else {
      const value = data?.content?.find((d: any) => d.id === selectedUser[0]);
      setEdit(value);
      setAction(GLOBAL_ACTION.EDIT);
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container>
        <Grid
          item
          xs={12}
          className="flex justify-end align-center gap-8"
          display='flex'
          justifyContent='space-between'
          marginBottom={"16px"}
        >
          <div className="flex-center mb-16 pl-20 mt-16">
            <img src={users} alt="" /> 
            <Typography variant="subtitle2">{t('Admin')} </Typography>
            <Typography variant="subtitle2">{'>'}</Typography>
            <Typography variant="subtitle2">{t('User Manager')}</Typography>
          </div>
          <Button
            variant={action !== GLOBAL_ACTION.NONE ? "outlined" : "contained"}
            color="primary"
            onClick={updateAction}
          >
            {t(action !== GLOBAL_ACTION.NONE ? t("Close") : t("Add User"))}
          </Button>
        </Grid>

        <Grid item xs={action !== GLOBAL_ACTION.NONE ? 6 : 12}>
          <Card className="h-full">
            <CardContent>
              <div className="action-header">
                {/* <img src={lockIcon} alt="" /> */}
                <img src={editFilledIcon} alt="Edit" onClick={editSelected} />
                <img src={deleteIcon} alt="Remove" onClick={deleteSelected} />
                {/* <img src={downloadIcon} alt="" /> */}
                <div className="flex-end">
                  <SearchInput onFilter={onFilterChanged} />
                </div>
              </div>

              <DataGrid
                pageChanged={pageChanged}
                totalPages={data?.totalPages || 0}
                sortChanged={sortChanged}
                columns={columns}
                data={data?.content || []}
                selection={true}
                sortBy={criteria.sort}
                selectionChanged={selectionChanged}
                pageSizeChanged={pageSizeChanged}
              />
            </CardContent>
          </Card>
        </Grid>

        {action !== GLOBAL_ACTION.NONE && (
          <Grid item xs={6} paddingLeft={1}>
            <AddUser data={edit} projectList={projectList} onDone={onDone} />
          </Grid>
        )}

          <Modal
            isOpen={isOpen}
            title="Delete Users"
            small={true}
            deleteConfirmation={true}
            ButtonDelete={deleteUser}
          />
      </Grid>
    </DndProvider>
  );
};
