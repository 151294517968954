import { createAction, createReducer } from "@reduxjs/toolkit";
import { IVariable } from "../../types/interfaces/variable.interface";

export const setCurrentVariable = createAction<IVariable[]>('setCurrentVariable');
export const addVariables = createAction<IVariable[]>('addVariables');
export const addSelectedVariables = createAction<IVariable[]>('addSelectedVariables');
export const clearSelectedVariables = createAction('clearSelectedVariables');
export const removeVariables = createAction<string[]>('removeVariables');
export const updateVariables = createAction<IVariable[]>('updateVariables');
export const upsertVariable = createAction<string>('upsertVariable');
export const setEditVariable = createAction<any>('setEditVariable');

export const variableReducer = createReducer<IVariable[] | null>([], (builder) => {

    builder.addCase(setCurrentVariable, (state: any, action) => {
        return {
            ...state,
            variable: action.payload,
        }
    }).addCase(addVariables, (state: any, action) => {
        return {
            ...state,
            variable: (state?.variable || []).concat(action.payload),
        }
    }).addCase(addSelectedVariables, (state: any, action) => {
        return {
            ...state,
            selectedVariables: (state?.selectedVariables || []).concat(action.payload),
        }
    }).addCase(clearSelectedVariables, (state: any, action) => {

        return {
            ...state,
            selectedVariables: [],
        }
    }).addCase(removeVariables, (state: any, action) => {

        return {
            ...state,
            variable: state?.variable.filter((c: IVariable) => !action.payload.includes(c.id)),
        }
    }).addCase(updateVariables, (state: any, action: any) => {
        return {
            ...state,
            variable: (state.variable || []).reduce((result: any, curr: any) => {
                if (curr.variableId === action.payload.variableId) {
                    result.push({
                        ...curr,
                        ...action.payload,
                    });
                } else {
                    result.push(curr);
                }

                return result;
            }, [])
        }
    }).addCase(setEditVariable, (state: any, action) => {

        return {
            ...state,
            editVariable: action.payload,
        }
    }).addCase(upsertVariable, (state: any, action) => {

        return {
            ...state,
            upsertVariable: action.payload,
        }
    })
})

