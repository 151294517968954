import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link, Checkbox } from '@mui/material';
import { DataGrid, Modal, TestResultStatusGrid, TestResultDetailsGrid, AudioPlayer } from '../ui';
import { useSelector } from 'react-redux';
import { getCurrentProject, getCurrentTestResult, getCurrentTestResultDetails } from '../store/selectors/project.selector';
import { getAllProjects } from '../store/selectors/project.selector';
import { SearchInput } from '../ui';
import { getTestResultList, getTestResultDetails } from '../services/testresults.service';
import { setCurrentTestResultDetails } from '../store/resuders/project.reducer';
import { useAppDispatch } from '../store/hooks';
import { formatTimestamp } from '../helpers/date.helper';
import { useTranslation } from 'react-i18next';
import { CHANNELS } from '../types/global.enum';
import { downloadFile } from '../services/testcase.service';


export interface TestResultDetailsProps {
    pageChanged: (page: number) => void;
    totalPages: number;
    sortChanged: (column: string, direction: string) => void;
    columns: any[]; // Specify the correct type for columns
    data: any[]; // Specify the correct type for data
    selection: boolean;
    sortBy: string;
    selectionChanged: (id: any) => void;
    formatDate: (dateTimeString: any) => string;
    onFilterChanged: (filter: string) => void;
    criteria: any;
    testResultFullAudio?: any;
}

export const TestResultDetails: React.FC<TestResultDetailsProps> = ({
    pageChanged,
    totalPages,
    sortChanged,
    columns,
    data,
    selection,
    sortBy,
    selectionChanged,
    formatDate,
    onFilterChanged,
    criteria,
    testResultFullAudio
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [audioFile, setAudioFile] = useState<any>(null);
    const { projectId, testcaseId, testResultId } = useParams();  // ProjectID and TestCaseID from URL
    // const projects = useSelector(getAllProjects);  // Contains all projects
    const project = useSelector(getCurrentProject);  // Store Project
    const testResult = useSelector(getCurrentTestResult)  // Store Test Case
    const testResultDetails = useSelector(getCurrentTestResultDetails)  // Store Test Result Details

    const loadData = async () => {

        const { data: tempData } = await getTestResultList(criteria.projectId);
        const { data: testResultData } = await getTestResultList(criteria.projectId, tempData.totalElements || 10, 0, criteria.filter);
        
        const testResultToFetch = testResultId ? testResultId : testResultData?.id;
        
        if (testResultToFetch && project) {
            const { data } = await getTestResultDetails(testResultToFetch,+(projectId || 1),project?.data?.channelType);
            dispatch(setCurrentTestResultDetails(data));
        }
    };
    const isDigital = useMemo(()=> project?.data?.channelType === CHANNELS.DIGITAL ,[project]);
    
    useEffect(() => {
        loadData();
    }, [project]);


    const loadAudio = async()=> {
        const downloadedTestResultFullAudio : Blob = await downloadFile(testResultDetails?.testResult?.id);
        setAudioFile(downloadedTestResultFullAudio);
    }
        
    useEffect(() => {
        if(project && !isDigital && testResultDetails && !testResultFullAudio) {
            loadAudio();
        }
    }, [project, testResultDetails, testResultFullAudio, isDigital]);
    
    const gridItemSize = 12


    return (
        <Grid item xs={gridItemSize} paddingRight={'9px'}>
            <Card>
                <CardContent>
                    <div className='test-result-header'>
                        <div className="action-header">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: '100%' }}>
                                <Typography variant="subtitle2" className='ml-20'><strong>{t('Test Case ID')}:</strong> #{testResultDetails?.id || testResultDetails?.testCaseId}</Typography>
                                <Typography variant="subtitle2"><strong>{t('Test Case Name')}:</strong> {testResultDetails?.name}</Typography>
                                <Typography variant="subtitle2">
                                    <strong>{t('Status')}:</strong>{' '}
                                    <span style={{ color: testResultDetails?.testResult.status === 'FAILED' ? 'red' : '#58A148' }}>
                                        {testResultDetails?.testResult.status}
                                    </span>
                                </Typography>
                                {!isDigital && <Typography variant="subtitle2">
                                    {/* <strong>From:</strong> {formatDate(testResultDetails?.testResult.startTime)} to {formatDate(testResultDetails?.testResult.endTime)} */}
                                    <strong>{t('From')}:</strong> {formatTimestamp(testResultDetails?.testResult.startTime)} to {formatTimestamp(testResultDetails?.testResult.endTime)}
                                </Typography>}
                            </div>

                        </div>
                        <div className="test-results-filters">
                            <SearchInput onFilter={onFilterChanged} />
                        </div>
                    </div>
                    {/* <TestResultDetailsGrid
                                    pageChanged={pageChanged}
                                    totalPages={data?.totalPages || 0}
                                    sortChanged={sortChanged}
                                    columns={testDetailsColumns}
                                    data={
                                        testResultDetails || []
                                    }
                                    selection={true}
                                    sortBy={criteria.sort}
                                    selectionChanged={selectionChanged}
                                /> */}
                                <AudioPlayer audioBlob={testResultFullAudio || audioFile} data={testResultDetails}/>
                    <TestResultDetailsGrid
                        pageChanged={pageChanged}
                        totalPages={totalPages || 0}
                        sortChanged={sortChanged}
                        columns={columns}
                        data={testResultDetails || []}
                        selection={selection}
                        sortBy={sortBy}
                        selectionChanged={selectionChanged}
                        criteria
                    />
                </CardContent>
            </Card>
        </Grid>

    );
};
