import apiService from "./api.service";
import { IGrammar } from "../types/interfaces/grammar.interface";

export const getGrammarList = async (projectId = 1, pageSize = 10, pageNo = 0, sort = '', filter = '') => {
    const { data } = await apiService.get(`/projects/${projectId}/grammars?pageNo=${pageNo - 1}&pageSize=${pageSize}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`);
    return data;
}

export const deleteGrammar = async (projectId: number, grammarIds: number[]) => {
    return await Promise.all(
        grammarIds.map(async id => {
            if (id) {
                await apiService.delete(`projects/${projectId}/grammars/${id}`)
            }

            return null;
        })
    ).catch(e => e)

}

export const upsertGrammar = async (projectId: number, grammar: any, editorState: string) => {
    if (editorState == "create") {
        return await apiService.post(`/projects/${projectId}/grammars`, { ...grammar })
            .catch(e => e);
    } else {
        return await apiService.put(`/projects/${projectId}/grammars/${grammar.libraryID}`, { ...grammar })
            .catch(e => e);
    }
}



export const getGrammarOptions = async (projectId: number) => {
    const { data } = await apiService.get(`/projects/${projectId}/grammarlist`);

    return data?.data || [];
}

export const findGrammrts = (input: string) => input.match(/%(.*?)%/g);

export const findVariables = (input: string) => {
    const result = input.match(/@(.*?)@/g);

    if (!result?.length) return [{ msg: input, alt: '' }];

    return result.map(m => ({
        msg: m,
        alt: m.replace(/@/g, ''),
    }));
}


export const searchVariable = async (projectId: number, name: string) => {
    const { data } = await apiService.get(`/projects/${projectId}/variables/search?name=${name}`).catch(e=>e);

    return data?.data?.textContents?.join('\n') || data?.data?.textContent;
}