import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";
import { IProject } from "../../types/interfaces/project.interface";

const projectSelectors = (state: IAppState) => state.project;


export const getCurrentProject = createSelector(
    [projectSelectors],
    (app) => app?.project as IProject
);

export const getCurrentProjectSegments = createSelector(
    [projectSelectors],
    (app) => app?.projectSegments || []
);

export const getAllProjects = createSelector(
    [projectSelectors],
    (app) => app?.allProjects || []
);


export const getLanguages = createSelector(
    [projectSelectors],
    (app) => app?.languages || []
);

export const getGoToTypes = createSelector(
    [projectSelectors],
    (app) => app?.goToTypes || []
);

export const getAllStepTypes = createSelector(
    [projectSelectors],
    (app) => app?.stepTypes || []
);

export const getAllDigitalStepTypes = createSelector(
    [projectSelectors],
    (app) => app?.digitalStepTypes || []
);


export const getBasicActions = createSelector(
    [projectSelectors],
    (app) => app?.basicActions || []
);

export const getCurrentTestCase = createSelector(
    [projectSelectors],
    (app) => app?.testCase || null
);

export const getCurrentTestResult = createSelector(
    [projectSelectors],
    (app) => app?.testResult || null
);

export const getCurrentTestResultDetails = createSelector(
    [projectSelectors],
    (app) => app?.testResultDetails || null
);

export const getScheduleTypes = createSelector(
    [projectSelectors],
    (app) => app?.scheduleTypes || []
);

export const selectOperations = createSelector(
    [projectSelectors],
    (app) => app?.operations || []
);

export const selectBrowsers = createSelector(
    [projectSelectors],
    (app) => app?.browsers || []
);

export const selectLocatorTypes = createSelector(
    [projectSelectors],
    (app) => app?.locatorTypes || []
);

export const selectNoises = createSelector(
    [projectSelectors],
    (app) => app?.noises || []
);