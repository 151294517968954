import { Button, Card, CardContent, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useEffect } from "react"
import { upsertProject } from "../services/project.service"
import { useAppDispatch } from "../store/hooks"
import { toggleAlert, toggleSpinner } from "../store/resuders/app.reducer"
import { useTranslation } from 'react-i18next';
import { CHANNELS } from "../types/global.enum"

export const AddProject = ({ data = null as any, onDone = null as any }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            channelType: 'voice',
            name: '',

        },
        onSubmit: async (values: any) => {
            dispatch(toggleSpinner(true));
            const result = await upsertProject(values);
            dispatch(toggleSpinner(false));
            if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
                dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))
            } else {
                onDone && onDone();
            }
        }
    })

    const confirm = () => {
        formik.submitForm();
    }

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
    }, [data]);

    return (
        <Card className="h-full">
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-header">
                        <Typography variant="subtitle1">{data ? t('Edit') : 'Adding a'} {t('Project')}</Typography>
                    </div>
                    <div className="pt-32 pl-40 pr-40">
                        <RadioGroup id="channelType" name="channelType" row style={{ marginLeft: 10 }} onChange={formik.handleChange} value={formik.values?.channelType}>
                            <FormControlLabel value={CHANNELS.VOICE} control={<Radio color="default" />} label={t("Voice")} />
                            <FormControlLabel  value={CHANNELS.DIGITAL} className="ml-80" control={<Radio color="default" />} label={t("Digital")} />
                        </RadioGroup>
                        <div className="mt-22">
                            <TextField id="name" name="name" onChange={formik.handleChange} fullWidth label={t("Project Name")} value={formik.values?.name} />
                        </div>
                    </div>
                    <div className="db center mt-54">
                        <Button variant="contained" onClick={confirm} color="primary">{t('Confirm')}</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}