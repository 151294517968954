import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { resetUserPassword, upsertUser } from "../services/user.service";
import { IUser } from "../types/interfaces/user.interface";
import { permissions } from "../helpers/form.helper";
import { globalNotification, toggleAlert } from "../store/resuders/app.reducer";
import { useAppDispatch } from "../store/hooks";
import { useTranslation } from "react-i18next";

export const AddUser = ({
  data = null as any,
  projectList = [] as any,
  onDone = null as any,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleProjectChange = (
    event: SelectChangeEvent<typeof selectedProjects>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedProjects(
      // On autofill we get a stringified value.
      value
    );
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      role: "ROLE_ADMIN",
      projects: [],
    },
    onSubmit: async (values) => {
      const formData = {
        ...values,
        id: values?.id ? values?.id : undefined,
        projects: selectedProjects,
      };
      const result = await upsertUser(formData);

      if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
        dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))
      } else {
          onDone && onDone();
      }
    },
  });

  useEffect(() => {
    if (data) {
      formik.setValues(data);
      setSelectedProjects(data?.projects);
    }
  }, [data]);

  const handleSave = async () => {
    // formik.setFieldValue('projects', selectedProjects)
    await formik.submitForm();
  };

  const handleResetPassword = async () => {
    if (resetPassword) {
      if(formik?.values?.password){
        await resetUserPassword(formik?.values?.id, formik?.values?.password);
        dispatch(globalNotification(t('New Password Saved.')))
      } else {
        dispatch(toggleAlert(t("Password shouldn't be blanked")));
        return;
      }      
    }
    setResetPassword(!resetPassword);
  };


  return (
    <Card className="h-full">
      <CardContent>
        <form>
          <div className="form-header">
            <Typography variant="subtitle1">{formik.values?.id ? t('Edit Users') : t('Create a New User')}</Typography>
          </div>
          <div className="pt-32 pl-40 pr-40">
            <Box className="user-input">
              <FormControl sx={{ width: "50%" }}>
                <InputLabel htmlFor="firstName"></InputLabel>
                <TextField
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={t("First Name")}
                  value={formik.values?.firstName}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel htmlFor="lastName"></InputLabel>
                <TextField
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={t("Last Name")}
                  value={formik.values?.lastName}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Box>

            <Box className="user-input">
              <FormControl sx={{ width: "50%" }}>
                <InputLabel htmlFor="username"></InputLabel>
                <TextField
                  type="text"
                  id="username"
                  name="username"
                  label={t("Username")}
                  value={formik.values?.username}
                  onChange={formik.handleChange}
                />
              </FormControl>

              {(!formik.values?.id || resetPassword) && (
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                  <TextField
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    label={t("Password")}
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values?.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              )}
            </Box>

            {formik?.values?.id && (
              <Box className="user-input">
                {
                  <Button
                    variant="contained"
                    onClick={handleResetPassword}
                    color="primary"
                  >
                    {resetPassword ? t("Save Password") : t("Reset Password")}
                  </Button>
                }
              </Box>
            )}

            <Box className="user-input">
              <FormControl sx={{ width: "45%" }}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label={t("Permissions")}
                  name="role"
                  onChange={formik.handleChange}
                  value={formik.values?.role}
                  required
                >
                  {permissions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Box>
            {formik?.values?.role === "ROLE_USER" && (
              <Box className="user-input">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="project">{t("Projects")}</InputLabel>
                  <Select
                    labelId="project"
                    id="project"
                    label={t("Projects")}
                    name="projects"
                    multiple
                    value={selectedProjects || []}
                    onChange={handleProjectChange}
                    input={<OutlinedInput label="Projects" />}
                    renderValue={() => selectedProjects?.map((project: any) => project.name).join(", ")}
                  >
                    {projectList.map((option: any) => (
                      <MenuItem key={option?.testProjectID} value={option}>
                        <Checkbox
                          checked={selectedProjects?.find((element: any) => element.testProjectID === option.testProjectID)}
                        />
                        <ListItemText primary={option?.name} />
                      </MenuItem>
                      // <MenuItem key={id.} value={id}>
                      //   <Checkbox checked={selectedProjects.indexOf(id) > -1} />
                      //   <ListItemText primary={id} />
                      // </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <FormControl sx={{ width: "100%" }}>
                <TextField id="outlined-select-currency" select label="Project">
                  {projectList.map((option: any) => (
                    <MenuItem key={option?.testProjectID} value={option?.testProjectID}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl> */}
              </Box>
            )}
          </div>
          <div className="db center mt-54">
            <Button variant="contained" onClick={handleSave} color="primary">
              {t("Save")}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};
