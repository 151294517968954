import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux"
import { getSpinnerStatus } from "../store/selectors/app.selector"

export const LoaderProvider = ({ ...props }) => {

    const display = useSelector(getSpinnerStatus);

    return (
        <div>
            {display && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            {props.children}
        </div>
    )
}