import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, CardContent, Typography, InputLabel, FormControl, Select, MenuItem, Box, TextField, Radio, FormControlLabel } from "@mui/material"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useFormik } from "formik"
import { useAppDispatch } from "../store/hooks"
import { reloadLibraries, toggleAlert } from '../store/resuders/app.reducer';
import { upsertVariable } from '../services/variable.service';
import { upsertGrammar } from '../services/grammar.service';
import { upsertPrompt } from '../services/prompt.service';
import { upsertAudio } from '../services/audio.service';
import { closeLibraryEditor, uniqueId } from '../helpers/form.helper';
import { AudioRecorder } from './audioRecorder';
import { getLanguages } from "../store/selectors/project.selector";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd'
import DeleteIcon from '@mui/icons-material/Delete';
import { random } from "lodash";

export const LibraryEditor = ({
    selectedData,
    setSelectedData,
    editorOpen = null as any,
    setEditorOpen,
    type = null as any,
    project = null as any,
    editorState = null as any,
    toggleSegmentEditor,
    setDiscardChangeConfirmation
}: {
    selectedData?: any;
    setSelectedData?: React.Dispatch<React.SetStateAction<any>>;
    editorOpen?: boolean;
    setEditorOpen?: (value: boolean) => void;
    type?: string;
    project?: any;
    editorState?: any;
    toggleSegmentEditor?: (close?: boolean, open?: boolean) => void;
    setDiscardChangeConfirmation: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const { t } = useTranslation();

    const [tabType, setTabType] = useState(type);
    const [vName, setVname] = useState('');
    const [vList, setVlist] = useState<any[]>([]);
    const languages = useSelector(getLanguages);
    const dispatch = useAppDispatch();
    const formik = useFormik({

        initialValues: {
            name: '',
            description: '',
            textContent: '',
            // binaryContent: ''
            binaryContent: null as File | null, // Set the initial type,
            languageCodeId: "english"

        },

        onSubmit: async (values: any) => {

            interface BodyType {
                itemType: string;
                name: any;
                description: any;
                textContent: any;
                projectId: any;
                binaryContent: any;
                languageCodeId: string,
                lastModifiedTime: string;
                libraryID?: string; // Optional property
                textContents?:string[];
            }

            let libraryID;
            if (editorState === "create") {
                libraryID = '';
            } else {
                libraryID = selectedData.libraryID;
            }

            let body: BodyType = {
                itemType: tabType.toLowerCase() + 's',
                name: values.name,
                description: values.description,
                textContent: vList?.length ? vList.find(l=> l.default)?.title:values.textContent,
                projectId: project?.testProjectID,
                binaryContent: values.binaryContent,
                languageCodeId: values.languageCodeId,
                lastModifiedTime: new Date().toISOString().slice(0, -1),
                textContents: vList?.length ? vList.map(l=>l.title):[],
            };

            if (libraryID) {
                body.libraryID = libraryID;
            }

            let result;

            if (tabType == "Segment") {
                // result = await upsertGrammar(project?.testProjectID);
            }
            else if (tabType == "Grammar") {
                result = await upsertGrammar(project?.testProjectID, body, editorState);
                dispatch(reloadLibraries());
            } else if (tabType == "Variable") {
                result = await upsertVariable(project?.testProjectID, body, editorState);
                dispatch(reloadLibraries());
            } else if (tabType == "Prompt") {
                result = await upsertPrompt(project?.testProjectID, body, editorState);
                dispatch(reloadLibraries());
            } else if (tabType == "Audio") {
                result = await upsertAudio(project?.testProjectID, body, editorState);
                dispatch(reloadLibraries());
            }


            if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result.payload)) {

                dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))

            } else if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result.code)) {

                dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))

            } else {
                onDone();
            }

        }

    })




    const handleClear = () => {
        formik.setValues({
            name: '',
            description: '',
            textContent: '',
            // binaryContent: ''
            binaryContent: null as File | null, // Set the initial type,
            languageCodeId: "english"
        });

        setVlist([]);
    }

    const remove  = (id:number)=>(e:any)=> {
        let updated = vList.reduce((res:any[], curr:any)=>{
            if(+curr.id !== +id) {
                res.push(curr);
            } 
            return res;
        },[]);

        if(!updated.find(c=> c.default)) {
            updated = updated.reduce((res:any[], curr:any, index:number)=>{
                res.push({
                    ...curr,
                    default:index === 0,
                });
                return res;
            },[]);
        }

        setVlist(updated);
    }

    const reOrder = (item: any, targetIndex: number) => {
        if(item.index === undefined) {
            let result = [...vList];
            result.splice(targetIndex, 0, item);
            setVlist(result);
        }else if (item.index !== targetIndex) {
            let result = [...vList];
            const temp = vList[item.index];
            result.splice(item.index, 1);
            result.splice(targetIndex, 0, temp);

            setVlist(result);
        }
    }

    const setDefault = (id:number)=>(e:any)=> {
        setVlist(
            vList.reduce((res:any[], curr:any)=>{
                if(+curr.id === +id) {
                    res.push({
                        ...curr,
                        default:true,
                    });
                } else {
                    res.push({
                        ...curr,
                        default:false,
                    });
                }
                return res;
            },[]),
        )

    }

    const addVlist = ()=> {
        setVlist(
            vList.concat({id:new Date().getTime(), title:vName , default: !vList?.length})
        );

        setVname('');
    }


    const onDone = () => {
        closeLibraryEditor(dispatch);
        toggleSegmentEditor && toggleSegmentEditor(editorOpen)
        setEditorOpen && setEditorOpen(false)
        setSelectedData && setSelectedData('')  // Enable this if closing the editor upon saving
    }

    useEffect(() => {
        formik.setValues({
            name: selectedData?.name || '',
            description: selectedData?.description || '',
            textContent: selectedData?.textContent || '',
            binaryContent: selectedData?.binaryContent || '',
            languageCodeId: selectedData?.languageCodeId || 'english'
        });

        setVlist(selectedData?.textContents?.map((v:string)=>({ id:new Date().getTime()+random(10), title:v, default:v === selectedData?.textContent })) || []);
    }, [selectedData]);

    useEffect(() => {
    }, [formik.values?.binaryContent]); // This will run whenever binaryContent changes

    const audioReady = (file: any) => {
        formik.setFieldValue('binaryContent', file);
    }
    
    const handleCloseLibraryEditor = () => {
        closeLibraryEditor(dispatch);
        toggleSegmentEditor && toggleSegmentEditor(editorOpen)
        setEditorOpen && setEditorOpen(false)
        setSelectedData && setSelectedData('')
    }

    const handleSaveLibrary = async () => {
        await formik.submitForm();
    }

    const confirm = async () => {
        await formik.submitForm();
    }

    useEffect(() => {
        setDiscardChangeConfirmation({
            onConfrimAction: handleSaveLibrary,
            onDiscardAction: handleCloseLibraryEditor
        });
    },[]);

    
    return (
        <Card className="h-full">
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <div className="case-editor-header">
                        <Typography variant="subtitle1">{(editorState == "create") ? t(`Creating New ${tabType}`) : t(`Editing ${tabType} from Library`)}</Typography>
                        <HighlightOffIcon onClick={handleCloseLibraryEditor} />
                    </div>
                    <div className="form-container">
                        <Box className='segment-name-select'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="library-editor-name"></InputLabel>
                                <TextField
                                    id="name"
                                    value={formik.values?.name}
                                    label={t("Name")}
                                    name='name'
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Box>
                        <Box className='segment-name-select'>
                            <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="library-editor-language">{t('Language')}</InputLabel>
                                <Select
                                    labelId="library-editor-language"
                                    id="languageCodeId"
                                    name='languageCodeId'
                                    value={formik?.values?.languageCodeId}
                                    label={t("Language")}
                                    onChange={formik.handleChange}
                                >
                                    {languages.map((lng:any) => (
                            <MenuItem key={`opt_lng_${lng.id}`} value={lng.id}>{lng.name}</MenuItem>
                        ))}
                                </Select>
                            </FormControl>
                    </Box>
                        <Box className='segment-name-select'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="library-editor-description"></InputLabel>
                                <TextField
                                    id="description"
                                    value={formik.values?.description}
                                    label={t("Description")}
                                    name='description'
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Box>
                        
                        { !["Audio","Variable"].includes(tabType)   && (
                            <Box className='segment-name-select'>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="library-editor-text-content"></InputLabel>
                                    <TextField
                                        id="textContent"
                                        value={formik.values?.textContent}
                                        label={t("Text Content")}
                                        name='textContent'
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                            </Box>
                        )}

                        {tabType == "Variable" && (
                            <Box className='segment-name-select flex'>
                                <div className='dib w-80'>
                                   <TextField
                                        fullWidth
                                        value={vName}
                                        label={t("Text Content")}
                                        onChange={(e:any)=>setVname(e.target.value)}
                                    />
                                </div>
                                <div className='dib w-20 text-right'>
                                    <Button
                                        variant={'contained'}
                                        color={vName ? "secondary" : "primary"}
                                        disabled={!vName}
                                        onClick={addVlist}
                                        className={`${vName ? 'btn-primary':''} max-h-30 width-70 min-h-30`}
                                        >
                                        {t('Add')}
                                    </Button>
                                </div>
                            </Box>
                        )}
                        {tabType == "Audio" && (
                            <>
                                <Box className='segment-name-select'>
                                    <FormControl sx={{ width: "100%" }}>
                                        <input
                                            id="binaryContent"
                                            type="file" // Set the input type to file
                                            name='binaryContent'
                                            accept="audio/wav" // Specify accepted file types
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                formik.setFieldValue('binaryContent', event.target.files && event.target.files[0]);
                                            }}
                                            style={{ display: 'none' }} // Hide the default input element
                                        />
                                        <label htmlFor="binaryContent">
                                            <Button
                                                component="span"
                                                variant="outlined"
                                                color="primary"
                                                className="capitalize"
                                            >
                                                {t('Upload Audio File (WAV)')}
                                            </Button>
                                        </label>
                                        <AudioRecorder onDone={audioReady} />
                                        {formik.values?.binaryContent?.name && (
                                            <Typography variant="body2" color="textSecondary">
                                                <span style={{color:'#000'}}>{t('Recording Complete')}</span> - {formik.values?.binaryContent?.name}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Box>


                            </>
                        )}

                        {tabType == "Variable" && (
                            <div className='v-name-list'>
                                <Typography>Variables</Typography>
                                <Box className='segment-name-select schedule-editor-test-case-box'>
                                    {vList.map((item, index)=>(
                                        <VariableItem list={vList} data={{...item, index}} setDefault={setDefault} remove={remove} reOrder={reOrder}></VariableItem>
                                    ))}
                                </Box>
                            </div>
                         )}

                        <Box className="segment-editor-save-buttons">
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', paddingTop: '14px' }}>
                                <Button
                                    type="submit"
                                    variant={'contained'}
                                    color="primary"
                                    className='max-h-30'
                                // onClick={handleSave}
                                >
                                    {t('Save')}
                                </Button>
                                <Button
                                    type="button"
                                    variant={'contained'}
                                    color="primary"
                                    className='max-h-30'
                                    onClick={handleClear}
                                >
                                    {t('Clear')}
                                </Button>
                            </div>
                        </Box>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

const VariableItem = ({ ...props }) => {
    const [{ isOver }, drops] = useDrop(() => ({
        accept: 'VARIABLES-ROWS',
        drop: (item: any) => {
            const targetIndex = props.data.index;
            props.reOrder(item, targetIndex);
        },
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
    }),[props.list])

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'VARIABLES-ROWS',
        item: {
            ...props.data,
            order: true,
        },
        collect: (monitor: any) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))

    const ref = useRef<any>(null);
    drag(drops(ref))
    return (<div style={isOver ? { backgroundColor: '#CECECE' } : {}} className="flex-center" ref={ref}>
                <div className="testcase-info">
                    <FormControlLabel
                        control={
                            <Radio 
                                name='default-var'
                                value={props.data.id}
                                checked={props.data.default}
                                onChange={props.setDefault(props.data.id)}
                            />
                        }
                        label={<Typography>{props.data.title}</Typography>}
                    />
                </div>
                <div className="flex-end">
                        <DeleteIcon className='pointer' onClick={props.remove(props.data.id)} />
                </div>
            </div>);
}