import { FormLabel, FormControl, Select, MenuItem, Box} from "@mui/material"
import { FormikProps } from "formik";
import { pauseTimeOptions } from '../../helpers/form.helper';
import { useTranslation } from 'react-i18next';


export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}


export const Pause = ({ formik }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {/* Left Side of Prompt Library */}
                <Box className='prompt-library-left' sx={{ width: '50%' }}>
                    <Box>
                        <div className="prompt-library-dropdowns">
                            <FormControl sx={{ width: "75%" }}>
                                <FormLabel htmlFor="case-editor-pause-time">{t('Pause Time')}:</FormLabel>
                            </FormControl>
                            <FormControl sx={{ width: "25%" }}>
                                <Select
                                    labelId="case-editor-pause-time"
                                    id="pauseTimeout"
                                    name='pauseTimeout'
                                    value={values?.pauseTimeout}
                                    onChange={handleChange}
                                >
                                      {pauseTimeOptions.map(id=>(
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                </Box>

                {/* Right Side of Prompt Library */}
                <Box className='prompt-library-right' sx={{ width: '50%' }}>
                    {/* EMPTY */}

                </Box>
            </Box>
        </>
    );
};
