import * as React from 'react';
import { Button, Card, CardContent, Typography, InputLabel, FormControl, Select, MenuItem, Box, TextField } from "@mui/material"
import { useFormik } from "formik"
import { useEffect, useRef } from "react"
import { useAppDispatch } from "../store/hooks"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Listen, ListenAnything, Pause, Hangup, Speak, PlaybackAudioFile, RecordVoice } from '../ui/segmentEditor';
import { addNewSegment, removeSegment, removeStep, setEditSegment, updateSegment } from '../store/resuders/segment.reducer';
import { ISegment, ISegmentLanguage, ISegmentStep } from '../types/interfaces/segment.interface';
import { useSelector } from 'react-redux';
import { getEditSegment, getSelectedSegments } from '../store/selectors/segment.selector';
import { uniqueId } from '../helpers/form.helper';
import { max,uniqBy } from "lodash";
import { GoToStep, NumberOfRunsEquals } from '../ui/segmentEditor/branchCondition';
import { alphabet, upsertSegment } from '../services/segment.service';
import { globalNotification, reloadSegments, toggleAlert } from '../store/resuders/app.reducer';
import { getAllStepTypes, getCurrentProject, getCurrentTestCase, getGoToTypes, getLanguages } from '../store/selectors/project.selector';
import { addProjectSegments, setCurrentTestCase, updateProjectSegments } from '../store/resuders/project.reducer';
import { BRANCH_CONDITION, STEP_TYPE } from '../types/global.enum';
import { addSelectedPrompts, clearSelectedPrompts, setSelectedPrompts } from '../store/resuders/prompt.reducer';
import { clearSelectedAudios, setSelectedAudios } from '../store/resuders/audio.reducer';
import { TransferCallToAgent, ScreenShot, GetCallData, EnterScreenData, MouseAction, ScreenscrapeData } from '../ui/segmentEditor/vaTypes';
import { GrammarMapper } from '../ui/segmentEditor/grammar';
import { useTranslation } from 'react-i18next';
import { Steps, Hints } from 'intro.js-react';
import InfoIcon from '@mui/icons-material/Info';

export const SegmentEditor = ({
    data = null as any,
    onDone = null as any,
    toggleSegmentEditor,
    projectLibrary = false,
    setDiscardChangeConfirmation
}: {
    toggleSegmentEditor: (close?: boolean, open?: boolean) => void;
    data?: any;
    onDone?: any;
    projectLibrary?: boolean;
    setDiscardChangeConfirmation: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const { t } = useTranslation();
    const [clear, setClear] = React.useState(false);
    const [intro, setIntro] = React.useState<any>(null);
    const [submit, setSubmit] = React.useState<boolean>(false);
    const segments = useSelector(getSelectedSegments);
    const editSegment = useSelector(getEditSegment);
    const [segmentId, setSegmentId] = React.useState<string>('');
    const [stepOptions, setStepOptions] = React.useState<number[]>([1]);
    const project = useSelector(getCurrentProject);
    const languages = useSelector(getLanguages);
    const goToTypes = useSelector(getGoToTypes);
    const allStepTypes = useSelector(getAllStepTypes);
    const currentTestCase = useSelector(getCurrentTestCase);
    const preSub = useRef<string>(''); 

    const dispatch = useAppDispatch();
    
    const formik = useFormik({
        initialValues: {
            channelType: 'voice',
            name: '',
            segmentName: '',
            language: 'english',
            step: 1,
            type: '',
            sub: 'A',
            prompt: '',
            remark: '',
            branchCondition: '1',
            mode: false,
            predefinedActionNames: '',
            stepTimeout: 15,
            pauseTimeout: 1,
            promptLatencyMin: 0,
            promptLatencyMax: 2,
            incompleteTimeout: 3,
            completeTimeout: 0.2,
            promptConfidenceLevel: 0.4,
            voiceOrDTMF: false,
            audioTimeout: 15,
            finalSilence: 2,
            mouseControlActionModeSelector: 'basic',
            fillScreenDataActionModeSelector: 'basic',
            gotoValue: 2,
            screenDataActionModeSelector: 'basic',
            participantDataActionAttributeNames: [],
        } as any,
        onSubmit: async (values: any) => {
            const ignoreClear = values?.ignoreClear;

            setSubmit(true);
            if(!values.segmentName) return;
            let participantDataActionAttributeNames = {};

            if (values?.participantDataActionAttributeNames?.length) {
                values?.participantDataActionAttributeNames.forEach((attr: any) => {
                    participantDataActionAttributeNames = {
                        ...participantDataActionAttributeNames,
                        [attr.attributeName]: attr.expectValue,
                    }
                })
            } else if (values?.participantDataActionAttributeNames?.length === 0) {
                participantDataActionAttributeNames = {};
            } else {
                participantDataActionAttributeNames = values?.participantDataActionAttributeNames;
            }

            const stepValues = {
                noiseBackground: values?.noiseBackground,
                isGrammar: values?.grammar?.[0] === 'on',
                incompleteTimeout: values?.incompleteTimeout,
                promptConfidenceLevel: values?.promptConfidenceLevel,
                promptLatencyMax: values?.promptLatencyMax,
                promptLatencyMin: values?.promptLatencyMin,
                stepTimeout: [STEP_TYPE.LISTEN, STEP_TYPE.LISTEN_ANYTHING, STEP_TYPE.RECORD_VOICE].includes(values.type) ? values?.stepTimeout : null,
                voiceOrDTMF: values?.voiceOrDTMF,
                remark: values?.remark,
                messageToPlay: values?.type === STEP_TYPE.SPEAK ? values?.prompt : '',
                prompt: [STEP_TYPE.LISTEN, STEP_TYPE.LISTEN_ANYTHING].includes(values?.type) ? values?.prompt : '',
                stepType: values?.type,
                pauseTimeout: values?.pauseTimeout,
                stepSeq: values.step,
                completeTimeout: values?.completeTimeout,
                finalSilence: values?.finalSilence,
                recordingHint: values?.recordingHint,
                gotoStepNo: values.gotoStepNo,
                gotoType: +values?.branchCondition,
                grammarMapping: values?.grammarMapping,
                gotoElseStepNo: values?.elseGoToStep,
                agentXferActionActionTimeout: values?.agentXferActionActionTimeout,
                agentXferActionAgentID: values?.agentXferActionAgentID,
                agentXferActionAgentPwd: values?.agentXferActionAgentPwd,
                agentXferActionOrgName:values?.agentXferActionOrgName,
                agentXferActionQueueName: values?.agentXferActionQueueName,
                agentXferActionScreenshot: values?.agentXferActionScreenshot?.[0] === 'on' || values?.agentXferActionScreenshot,
                agentXferActionAutoPickup: values?.agentXferActionAutoPickup?.[0] === 'on' || values?.agentXferActionAutoPickup,

                fillScreenDataActionElementName: values?.fillScreenDataActionElementName,
                fillScreenDataActionModeSelector: values?.fillScreenDataActionModeSelector,
                fillScreenDataActionScreenshot: values?.fillScreenDataActionScreenshot?.[0] === 'on' || values?.fillScreenDataActionScreenshot,
                fillScreenDataActionValue: values?.fillScreenDataActionValue,
                fillScreenDataActionXpath: values?.fillScreenDataActionXpath,


                mouseControlActionElementName: values?.mouseControlActionElementName,
                mouseControlActionModeSelector: values?.mouseControlActionModeSelector,
                mouseControlActionMouseActionType: values?.mouseControlActionMouseActionType,
                mouseControlActionScreenshot: values?.mouseControlActionScreenshot?.[0] === 'on' || values?.mouseControlActionScreenshot,
                mouseControlActionXpath: values?.mouseControlActionXpath,


                screenDataActionElementName: values?.screenDataActionElementName,
                screenDataActionModeSelector: values?.screenDataActionModeSelector,
                screenDataActionScreenshot: values?.screenDataActionScreenshot?.[0] === 'on' || values?.screenDataActionScreenshot,
                screenDataActionXpath: values?.screenDataActionXpath,
                screenDataActionExpectedValue: values?.screenDataActionExpectedValue,

                participantDataActionAttributeNames,

                gotoValue: +values?.gotoValue,
                libraryItemIds: !values?.selectPromptLibrary && [STEP_TYPE.LISTEN].includes(values?.type) ? null : values?.libraryItemIds,
            };

            let segment = null as ISegment | null;

            if (segmentId) {
                segment = projectLibrary ? editSegment.segment : segments?.find(c => c.id === segmentId) || null;

                if (segment?.segmentLangs?.findIndex(c => c.languageCode?.id === values.language) === -1) {
                    segment = {
                        ...segment,
                        name: values.segmentName,
                        segmentLangs: segment.segmentLangs.concat({
                            languageCode: {
                                id: values.language,
                            },
                            steps: [
                                { ...stepValues },
                            ],
                        })
                    }
                } else if (segment) {
                    segment = {
                        ...segment,
                        name: values.segmentName,
                        segmentLangs: segment.segmentLangs.reduce((result: any, curr: ISegmentLanguage) => {
                            if (curr.languageCode.id === values.language) {

                                if (curr.steps?.findIndex(st => st.stepSeq === values.step) === -1) { // new step

                                    result.push({
                                        ...curr,
                                        steps: (curr.steps || []).concat({ ...stepValues }),
                                    });
                                } else if (curr.steps?.some(st => st.stepSeq === values.step && values.sub === 'A')) { // edit step
                                    result.push({
                                        ...curr,
                                        steps: (curr.steps || []).reduce((rSteps: ISegmentStep[], currStep: ISegmentStep) => {

                                            if (currStep.stepSeq === values.step) {
                                                rSteps.push({
                                                    ...currStep,
                                                    ...stepValues,
                                                    listenSubStep: stepValues.stepType === STEP_TYPE.LISTEN ? currStep.listenSubStep : [],
                                                });
                                            } else {
                                                rSteps.push(currStep);
                                            }
                                            return rSteps;
                                        }, []),
                                    });
                                } else if (curr.steps?.some(st => st.stepSeq === values.step && values.sub !== 'A' && st.listenSubStep?.length && st.listenSubStep?.findIndex(s => s.name === values.sub) !== -1)) { //edit sub

                                    result.push({
                                        ...curr,
                                        steps: (curr.steps || []).reduce((rSteps: ISegmentStep[], currStep: ISegmentStep) => {

                                            if (currStep.stepSeq === values.step) {
                                                rSteps.push({
                                                    ...currStep,
                                                    listenSubStep: (currStep.listenSubStep || []).reduce((rSubs: ISegmentStep[], currSub: ISegmentStep) => {
                                                        if (currSub.name === values.sub) {
                                                            rSubs.push({
                                                                ...currSub,
                                                                stepType: values.type,
                                                                prompt: values.prompt,
                                                                remark: values.remark,
                                                                gotoStepNo: values.gotoStepNo,
                                                                gotoType: +values?.branchCondition,
                                                                voiceOrDTMF: values?.voiceOrDTMF,
                                                                isGrammar: values?.grammar?.[0] === 'on',
                                                            });
                                                        } else {
                                                            rSubs.push(currSub);
                                                        }
                                                        return rSubs;
                                                    }, [])
                                                })
                                            } else {
                                                rSteps.push(currStep);
                                            }
                                            return rSteps;
                                        }, []),
                                    });

                                } else {//add new sub step
                                    result.push({
                                        ...curr,
                                        steps: (curr.steps || []).reduce((rSteps: ISegmentStep[], currStep: ISegmentStep) => {

                                            if (currStep.stepSeq === values.step) {
                                                rSteps.push({
                                                    ...currStep,
                                                    listenSubStep: (currStep.listenSubStep || []).concat({ name: values.sub, stepType: values.type, prompt: values.prompt, remark: values.remark }),
                                                })
                                            } else {
                                                rSteps.push(currStep);
                                            }
                                            return rSteps;
                                        }, []),
                                    });
                                }

                            } else {
                                result.push(curr);
                            }
                            return result;
                        }, []),
                    }
                }

                if (segment) {
                    const result = await upsertSegment(project?.testProjectID || 1, segment);

                    if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
                        dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))
                        return;
                    } else {
                        dispatch(updateProjectSegments(result.data.data));
                        dispatch(updateSegment({
                            ...result.data?.data,
                            id: `${result.data?.data?.testSegmentVoiceId}`,
                        }));

                        if(!ignoreClear){
                            handleClear();
                            dispatch(globalNotification('The segment is updated.'));
                        }

                    }

                }

            } else {

                const segId = uniqueId();
                segment = {
                    name: values.segmentName,
                    id: segId,
                    defaultLng: values.language,
                    segmentLangs: [
                        {
                            languageCode: {
                                id: values.language,
                            },
                            steps: [
                                { ...stepValues },
                            ],
                        },
                    ]
                }

                const result = await upsertSegment(project?.testProjectID || 1, segment);

                if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
                    dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')));
                    return;
                } else {
                    if(!ignoreClear) {
                        dispatch(globalNotification('The new segment is created.'))
                    }

                    dispatch(addProjectSegments(result.data?.data));

                    dispatch(addNewSegment({
                        ...result.data?.data,
                        id: `${result.data?.data?.testSegmentVoiceId}`,
                        defaultLng: result.data?.data?.segmentLangs?.[0]?.languageCode?.id,
                    }));

                }

            }

            dispatch(reloadSegments());
            
            if(!ignoreClear) {
                handleClear();
            }
        }
    })

    const disabledSave = React.useMemo(() => formik.values.type === STEP_TYPE.PAUSE && !formik.values.pauseTimeout, [formik.values]);

    const removeSubs = async () => {
        if (segmentId) {
            let seg = projectLibrary ? editSegment.segment : segments.find(c => c.testSegmentVoiceId === +segmentId);

            if (seg) {
                seg = {
                    ...seg,
                    segmentLangs: seg?.segmentLangs?.reduce((r: ISegmentLanguage[], curr: ISegmentLanguage) => {
                        if (curr.languageCode.id === formik.values.language) {
                            r.push({
                                ...curr,
                                steps: curr.steps?.reduce((steps: ISegmentStep[], st: ISegmentStep) => {
                                    if (st.stepSeq === +formik.values.step) {
                                        steps.push({
                                            ...st,
                                            listenSubStep: st.listenSubStep?.filter(l => l.name !== formik.values.sub),
                                        });
                                    } else {
                                        steps.push(st);
                                    }
                                    return steps;
                                }, []),
                            })
                        } else {
                            r.push(curr);
                        }
                        return r;
                    }, [])
                }

                if (currentTestCase) {
                    await upsertSegment(project?.testProjectID, seg);
                } else if (projectLibrary) {
                    dispatch(setEditSegment({ segment: seg }));
                    return;
                }
            }

            dispatch(removeStep({
                segmentId,
                stepId: +formik.values.step,
                lng: formik.values.language,
                sub: formik.values.sub,
            }));

            handleClear();

        }
    }

    const removeSteps = async () => {
        if (segmentId) {
            let seg = projectLibrary ? editSegment.segment : segments.find(c => c.testSegmentVoiceId === +segmentId);

            if (seg) {
                let index = 0;
                seg = {
                    ...seg,
                    segmentLangs: seg?.segmentLangs?.reduce((r: ISegmentLanguage[], curr: ISegmentLanguage) => {
                        if (curr.languageCode.id === formik.values.language) {
                            r.push({
                                ...curr,
                                steps: curr.steps?.reduce((res: any[], curr: any) => {
                                    if (curr.stepSeq !== +formik.values.step) {
                                        res.push({
                                            ...curr,
                                            stepSeq: ++index,
                                        });
                                    }
                                    return res;
                                }, [])
                            })
                        } else {
                            r.push(curr);
                        }
                        return r;
                    }, [])
                }

                if (currentTestCase) {
                    await upsertSegment(project?.testProjectID, seg);
                    dispatch(reloadSegments());
                } else if (editSegment) {
                    dispatch(setEditSegment({ segment: seg }));
                    return;
                }
            }

            dispatch(removeStep({
                segmentId,
                stepId: +formik.values.step,
                lng: formik.values.language,
                sub: '',
            }));


            handleClear();

        }
    }

    useEffect(() => {

        if (editSegment) {
            const { segment, lang, step, sub } = editSegment;
            setSegmentId(segment.id);

            const attrs = [] as any[];
            if (step && step.participantDataActionAttributeNames) {
                Object.keys(step.participantDataActionAttributeNames).forEach(key => {
                    attrs.push({
                        attributeName: key,
                        expectValue: step.participantDataActionAttributeNames[key],
                    })
                })
            }

            const stp = (step ? step : segment?.segmentLangs?.[0]?.steps?.[0]);
            if (stp?.libraryItemIds?.length) {
                if (stp.stepType === STEP_TYPE.PLAY_AUDIO) {
                    dispatch(setSelectedAudios(stp?.libraryItemIds))
                } else {
                    dispatch(setSelectedPrompts(stp?.libraryItemIds))
                }
            } else {
                dispatch(setSelectedPrompts([]))
                dispatch(setSelectedAudios([]))
            }


            formik.setValues({
                ...segment,
                name: segment.name,
                segmentName: segment.name,
                language: lang?.languageCode?.id || segment.segmentLangs?.[0]?.languageCode?.id,
                ...(step ? step : segment?.segmentLangs?.[0]?.steps?.[0]),
                type: step?.stepType || segment.segmentLangs?.[0]?.steps?.[0]?.stepType,
                step: step?.stepSeq || segment.segmentLangs?.[0]?.steps?.[0]?.stepSeq,
                grammar: step?.isGrammar || segment.segmentLangs?.[0]?.steps?.[0]?.isGrammar ? ['on'] : null,
                prompt: step?.messageToPlay || step?.prompt || segment.segmentLangs?.[0]?.steps?.[0]?.messageToPlay || segment.segmentLangs?.[0]?.steps?.[0]?.prompt,
                sub: sub || 'A',
                branchCondition: `${step?.gotoType || segment.segmentLangs?.[0]?.steps?.[0]?.gotoType}`,
                participantDataActionAttributeNames: attrs,
                elseGoToStep: step?.gotoElseStepNo || segment.segmentLangs?.[0]?.steps?.[0]?.gotoElseStepNo,
                selectPromptLibrary: stp?.libraryItemIds?.length > 0 && !stp.prompt,
            });

            toggleSegmentEditor(false, true);
        } else if (projectLibrary) {
            handleClear();
        }
    }, [editSegment])

    useEffect(() => {
        if (editSegment && formik.values.language) {
            const { segment } = editSegment;
            const steps: any = (segment as ISegment).segmentLangs.find(c => c.languageCode.id === formik.values.language)?.steps?.map(c => c.stepSeq) || [1];
            setStepOptions(steps);
        }
    }, [formik.values.language, editSegment]);

    useEffect(() => {
        const createNewSub = async ()=> {
            if (segmentId && formik.values.language && formik.values.step) {
                if(formik.values.new && preSub.current !== formik.values.sub && [STEP_TYPE.LISTEN, STEP_TYPE.LISTEN_ANYTHING].includes(formik.values?.type)) {
                    await formik.setFieldValue('ignoreClear', true);
                    await formik.setFieldValue('sub', preSub.current);
                    await formik.setFieldValue('prompt', formik.values.prompt);
                    await formik.submitForm();
                }

                let step = (projectLibrary ? editSegment?.segment : segments.find(c => c.id === segmentId))?.segmentLangs?.find((c: ISegmentLanguage) => c.languageCode.id === formik.values.language)?.steps?.find((c: ISegmentStep) => c.stepSeq === formik.values.step);
    
                let sub = null;
                if (formik.values.sub !== 'A') {
                    preSub.current = formik.values.sub;
                    sub = step?.listenSubStep?.find((d: ISegment) => d.name === formik.values.sub);
                    
                    step = {
                        ...step,
                        ...sub,
                        new:true,
                    } as any;
                }
                if (step) {
                    const attrs = [] as any[];
                    if (step && step.participantDataActionAttributeNames) {
                        Object.keys(step.participantDataActionAttributeNames).forEach(key => {
                            attrs.push({
                                attributeName: key,
                                expectValue: step.participantDataActionAttributeNames[key],
                            })
                        })
                    }
    
                    formik.setValues({
                        ...formik.values,
                        ...step,
                        type: step.stepType,
                        step: step.stepSeq,
                        grammar: step?.isGrammar ? ['on'] : null,
                        prompt: formik.values.sub !== 'A' && !sub ? '' : step?.messageToPlay || step?.prompt,
                        sub: formik.values.sub,
                        branchCondition: `${step?.gotoType}`,
                        elseGoToStep: step?.gotoElseStepNo,
                        participantDataActionAttributeNames: attrs,
                    })
                }
            }
        }

        createNewSub();
    }, [formik.values.language, segmentId, formik.values.step, formik.values.sub]);

    const confirm = async () => {
        await formik.submitForm();
    }

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
    }, [data]);

    const handleSaveSegment = async () => {
        await confirm();
    };

    const handleSaveSegmentAsNew = () => {
    };

    const handleClear = () => {
        setSubmit(false);
        setClear(!clear);
        setSegmentId('');
        setStepOptions([1]);
        preSub.current = '';

        formik.setValues({
            channelType: 'voice',
            name: '',
            segmentName: '',
            language: 'english',
            step: 1,
            type: '',
            sub: 'A',
            prompt: '',
            gotoValue: 2,
            remark: '',
            branchCondition: BRANCH_CONDITION.GO_To_NEXT,
            mouseControlActionModeSelector: 'basic',
            fillScreenDataActionModeSelector: 'basic',
            screenDataActionModeSelector: 'basic',
            stepTimeout: 15,
            pauseTimeout: 1,
            promptLatencyMin: 0,
            promptLatencyMax: 2,
            incompleteTimeout: 3,
            completeTimeout: 0.2,
            promptConfidenceLevel: 0.4,
            voiceOrDTMF: false,
            audioTimeout: 15,
            finalSilence: 2,
            participantDataActionAttributeNames: [],
        });

        dispatch(clearSelectedPrompts())
        dispatch(clearSelectedAudios())
        dispatch(setEditSegment(null))
    };

    useEffect(() => {

        const createNewStep = async ()=> {
            if (formik.values.step === 0) {
                const newId = (max(stepOptions) || 0) + 1;
                if(formik.values?.new) {
                    await formik.setFieldValue('ignoreClear', true);
                    await formik.setFieldValue('step', newId-1);
                    await formik.submitForm();
                }
                
                setStepOptions([...stepOptions, newId]);
    
                formik.setValues({
                    ...formik.values,
                    step: newId,
                    prompt: '',
                    remark: '',
                    type: '',
                    branchCondition: BRANCH_CONDITION.GO_To_NEXT,
                    sub: 'A',
                    grammar: false,
                    messageToPlay: '',
                    recordingHint: null,
                    pauseTimeout: 1,
                    stepTimeout: 15,
                    promptLatencyMin: 0,
                    promptLatencyMax: 2,
                    incompleteTimeout: 3,
                    completeTimeout: 0.2,
                    promptConfidenceLevel: 0.4,
                    voiceOrDTMF: false,
                    audioTimeout: 15,
                    finalSilence: 2,
                    gotoValue: 2,
                    participantDataActionAttributeNames: [],
                    new:true,
                })
                preSub.current = 'A';
                dispatch(clearSelectedPrompts());
                dispatch(clearSelectedAudios());
            }
        }
        
        createNewStep();
    }, [formik.values.step]);


    const closeEditor = () => {
        dispatch(setEditSegment(null));
        toggleSegmentEditor(projectLibrary)
    }

    useEffect(() => {
        setDiscardChangeConfirmation({
            onConfrimAction: handleSaveSegment,
            onDiscardAction: closeEditor
        });
    },[]);

    const steps = [
        {
            element: '#segmentName',
            intro: 'segment name goes here',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
        {
            element: '#type',
            intro: 'Type',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
        {
            element: '#branchCondition',
            intro: 'branch condition',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
    ];

    const showIntro = (el: string) => () => {
        setIntro({
            visible: true,
            step: steps.findIndex(c => c.element === `#${el}`),
        })
    }
    return (
        <Card className="h-100 min-h-80 sticky-top">
            <CardContent>
                <Steps
                    enabled={intro?.visible}
                    steps={steps}
                    initialStep={intro?.step}
                    onExit={() => setIntro(null)}
                />
                <form onSubmit={formik.handleSubmit}>
                    <div className="case-editor-header">
                        <Typography variant="subtitle1">{t(projectLibrary ? 'Editing a Segment from Library' : 'Segment Editor')}</Typography>
                        <HighlightOffIcon onClick={closeEditor} />
                    </div>
                    <div className="form-container">
                        <Box className='segment-name-select relative'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="case-editor-segment-name"></InputLabel>
                                <TextField
                                    id="segmentName"
                                    value={formik.values?.segmentName}
                                    label={t("Segment Name")}
                                    name='segmentName'
                                    onChange={formik.handleChange}
                                    error={!formik.values?.segmentName && submit}
                                    helperText={!formik.values?.segmentName && submit ? '* Required':''}
                                />
                            </FormControl>
                            <InfoIcon className='introl-icon' onClick={showIntro('segmentName')} />
                        </Box>
                        <Box className='type-language-select'>
                            <FormControl sx={{ width: "40%" }}>
                                <InputLabel id="case-editor-language">Language</InputLabel>
                                <Select
                                    labelId="case-editor-language"
                                    id="language"
                                    value={formik.values?.language}
                                    label={t("Language")}
                                    name="language"
                                    onChange={formik.handleChange}
                                >
                                    {languages.map((lng: any) => (
                                        <MenuItem key={`opt_lng_${lng.id}`} value={lng.id}>{lng.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: "15%" }}>
                                <InputLabel id="case-editor-step">Step</InputLabel>
                                <Select
                                    labelId="case-editor-step"
                                    id="step"
                                    value={formik.values?.step}
                                    label={t("Step")}
                                    name="step"
                                    disabled={!editSegment}
                                    onChange={formik.handleChange}
                                >
                                    {stepOptions.map(id => (
                                        <MenuItem value={id}>{id}</MenuItem>
                                    ))}
                                    <MenuItem value={0}>+</MenuItem>
                                </Select>
                            </FormControl>
                            <Button disabled={!editSegment} onClick={removeSteps} variant="contained" className='max-h-30' >{t('Remove')}</Button>
                        </Box>
                        <Box className="type-select ">
                            <FormControl sx={{ width: formik.values?.type === STEP_TYPE.LISTEN ? "40%":"100%" }} className="relative">
                                <InputLabel id="case-editor-type">Type</InputLabel>
                                <Select
                                    labelId="case-editor-type"
                                    id="type"
                                    value={formik.values?.type}
                                    label={t("Type")}
                                    name="type"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        dispatch(clearSelectedPrompts()); // Dispatch the action to clear prompts
                                        dispatch(clearSelectedAudios()); // Dispatch the action to clear audios
                                    }}
                                >
                                    {allStepTypes?.map((item: any) => (
                                        <MenuItem key={`step_types_${item.typeCode}`} value={item.typeCode}>{item.name}</MenuItem>

                                    ))}
                                </Select>
                                <InfoIcon className='introl-icon' onClick={showIntro('type')} />
                            </FormControl>
                            {formik.values?.type === STEP_TYPE.LISTEN && (
                                <>
                                    <FormControl sx={{ width: "15%" }}>
                                        <InputLabel id="case-editor-sub">Sub</InputLabel>
                                        <Select
                                            labelId="case-editor-sub"
                                            id="sub"
                                            value={formik.values?.sub}
                                            label={t("Sub")}
                                            name="sub"
                                            onChange={formik.handleChange}
                                        >
                                            {alphabet.map(a => (
                                                <MenuItem value={a} key={`sub_alpha_${a}`}>{a}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" onClick={removeSubs} className='max-h-30' >{t('Remove')}</Button>
                                </>
                            )}

                        </Box>
                        {/* Conditional Rendering Based on Type */}
                        {formik.values?.type === STEP_TYPE.LISTEN && (
                            <Listen formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.LISTEN_ANYTHING && (
                            <ListenAnything formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.PAUSE && (
                            <Pause formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.HANGUP && (
                            <Hangup formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.SPEAK && (
                            <Speak formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.PLAY_AUDIO && (
                            <PlaybackAudioFile formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.RECORD_VOICE && (
                            <RecordVoice formik={formik} />
                        )}
                        {/* VA TYPES */}
                        {formik.values?.type === STEP_TYPE.TRANSFER_CALL_TO_AGENT && (
                            <TransferCallToAgent formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.SCREEN_SHOT && (
                            <ScreenShot formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.GET_CALL_DATA && (
                            <GetCallData formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.ENTER_SCREEN_DATA && (
                            <EnterScreenData formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.MOUSE_ACTION && (
                            <MouseAction formik={formik} />
                        )}
                        {formik.values?.type === STEP_TYPE.SCREENSCRAPE_DATA && (
                            <ScreenscrapeData formik={formik} />
                        )}
                        {/*  */}
                        <Box className='segment-editor-remark'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="case-editor-remark"></InputLabel>
                                <TextField
                                    id="remark"
                                    name='remark'
                                    value={formik.values?.remark}
                                    label="Remark"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Box>
                        {formik.values?.type !== STEP_TYPE.HANGUP && (
                            <Box className='branch-condition relative'>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="case-editor-branch-condition">{t('Branch Condition')}</InputLabel>
                                    <Select
                                        labelId="case-editor-branch-condition"
                                        id="branchCondition"
                                        name='branchCondition'
                                        value={formik.values?.branchCondition}
                                        label={t("Branch Condition")}
                                        onChange={formik.handleChange}
                                    >
                                        {(goToTypes || []).map((type: any) => (
                                            <MenuItem key={`goto_${type.id}`} value={type.id}>{type.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <InfoIcon className='introl-icon' onClick={showIntro('branchCondition')} />
                            </Box>
                        )}
                        {/* Conditional Rendering Based on Type */}
                        {stepOptions?.length > 0 && formik.values?.branchCondition === BRANCH_CONDITION.GO_TO_STEP && (
                            <GoToStep formik={formik} steps={stepOptions} />
                        )}
                        {formik.values?.branchCondition === BRANCH_CONDITION.NUMBER_OF_RUNS && (
                            <NumberOfRunsEquals formik={formik} steps={stepOptions} />
                        )}

                        <GrammarMapper formik={formik} type={formik.values?.type} prompt={formik.values?.prompt} />

                        <Box className="segment-editor-save-buttons">
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', paddingTop: '14px' }}>
                                <Button
                                    variant={'contained'}
                                    color={disabledSave ? "secondary" : "primary"}
                                    disabled={disabledSave}
                                    className='max-h-30'
                                    onClick={handleSaveSegment}
                                >
                                    Save
                                </Button>
                                {/* <Button
                                    variant={'contained'}
                                    color="primary"
                                    className='max-h-30'
                                    onClick={handleSaveSegmentAsNew}
                                >
                                    ????
                                </Button> */}
                                <Button
                                    variant={'contained'}
                                    color="primary"
                                    className='max-h-30'
                                    onClick={handleClear}
                                >
                                    {t('Clear')}
                                </Button>
                            </div>
                        </Box>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}
