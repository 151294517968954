import { addNewSegment, setBatchSegments } from "../store/resuders/segment.reducer";
import { CHANNELS, DIGITAL_STEP_TYPE, LOCATOR_TYPES, PARAM_TYPES } from "../types/global.enum";
import { ISegment, ISegmentStep } from "../types/interfaces/segment.interface";
import { ITestCase } from "../types/interfaces/testCase.interface";
import apiService from "./api.service";
import { orderBy } from "lodash";
import digitalApiService from "./digitalApi.service";
import { getDigitalApi, getVoiceApi } from "./config.service";

export const downloadFile = async (id: any) => {

    const res = await apiService.get(`${getVoiceApi()}/results/${id}/content?contentType=audio/wav`, {
        responseType: "arraybuffer",
        headers: {
            'Content-Type': 'audio/wav'
        }
    }).catch(e => e);

    if (res?.code === "ERR_BAD_RESPONSE") return new Blob([], { type: 'audio/wav' });
    // new window.Blob([new Uint8Array(res.data)])
    const blob = new Blob([res.data], { type: 'audio/wav' });

    return blob;

}
export const getTestCaseList = async (projectId = 1, channel: CHANNELS = CHANNELS.VOICE, pageNo = 0, pageSize = 10, sort = '', filter = '') => {
    const { data } =
        channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`) :
            await apiService.get(`/projects/${projectId}/cases?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`);
    return data;
}


export const runMultipleVariableTestCase = async (projectId: number, testCases: any) => {
    await Promise.all(
        testCases.map(async (testCase: any) => {
            await apiService.post(`/projects/${projectId}/cases/${testCase?.testCaseId}/run?multiValue=${testCase.runAll ? 'all' : 'default'}`, {});
            return null;
        })
    ).catch(e => e)
}

export const deleteTestCases = async (projectID: number, caseId: number[], channel: CHANNELS) => {
    await Promise.all(
        caseId.map(async id => {
            if (id) {
                channel === CHANNELS.DIGITAL ? await digitalApiService.delete(`/projects/${projectID}/cases/${id}`) : await apiService.delete(`/projects/${projectID}/cases/${id}`);
            }
            return null;
        })
    ).catch(e => e)
}

export const deleteTestResults = async (projectID: number, resultId: number[]) => {
    await apiService.delete(`/projects/${projectID}/results`, { data: resultId }).catch(e => e);
}

export const checkMultiVariable = async (projectId: number, ids: string, channel: CHANNELS) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases/runcheck?caseId=${ids}`) : await apiService.get(`/projects/${projectId}/cases/runcheck?caseId=${ids}`)
        .catch(() => [] as any);

    return data?.data || [];
}

export const hasMultiVariable = async (projectId: number, id: number, channel: CHANNELS) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases/runcheck?caseId=${id}`) : await apiService.get(`/projects/${projectId}/cases/runcheck?caseId=${id}`)
        .catch(() => [] as any);

    return {
        iterationCount: data?.data?.[0]?.iterationCount || 1,
        numMultiValueVariables: data?.data?.[0]?.numMultiValueVariables || 0,
    }
}

export const populateDigitalSteps = (steps: any[]) => steps.map((step: any) => {
    let cr = {
        rowId: step.testStepId,
        stepSeq: step.stepSeq,
        testStepId: step.testStepId,
        actionType: step.stepType,
        remark: step.remark,
        timeOut: step.timeout,
        latencyTimeOut: step.latencyStep?.timeout,
        criteria2: (step.latencyStep?.itemLocators || []).map((loc: any) => {
            switch (loc.paramType) {
                case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: loc.testStepParamId, locatorType: loc.paramType, operation: loc.matchOp, displayText: loc.value };
                case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: loc.testStepParamId, attributeName: loc.name, attributeValue: loc.value, locatorType: loc.paramType, operation: loc.matchOp, };
                case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: loc.testStepParamId, elementName: loc.value, locatorType: loc.paramType };
            }
        }),
        criteria: step.itemLocators.map((loc: any) => {
            switch (loc.paramType) {
                case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: loc.testStepParamId, locatorType: loc.paramType, operation: loc.matchOp, displayText: loc.value };
                case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: loc.testStepParamId, attributeName: loc.name, attributeValue: loc.value, locatorType: loc.paramType, operation: loc.matchOp, };
                case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: loc.testStepParamId, elementName: loc.value, locatorType: loc.paramType };
            }
        }),
    } as any;

    switch (step.stepType) {
        case DIGITAL_STEP_TYPE.ENTER_TEXT: {
            cr = {
                ...cr,
                textToSend: step?.simpleParameters?.[0]?.value,
                dontNotSend: step?.simpleParameters?.[1]?.value === 'true' ? true : false,
            }
            break;
        }

        case DIGITAL_STEP_TYPE.LOAD_PAGE: {
            cr = {
                ...cr,
                url: step?.simpleParameters?.[0]?.value,
            }
            break;
        }

        case DIGITAL_STEP_TYPE.PAUSE: {
            cr = {
                ...cr,
                seconds: step?.simpleParameters?.[0]?.value,
            }
            break;
        }

    }

    return cr;

});

export const getTestCaseById = (projectId: number, id: number, channel: CHANNELS) => async (dispatch: any) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases/${id}`) : await apiService.get(`/projects/${projectId}/cases/${id}`);
    let segs = [] as any[];

    await Promise.all(
        orderBy(data?.data?.segments || [], 'segmentSeq').map(async (seg: any, index: number) => {
            const result = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/segments/${seg.testSegmentId}`) : await apiService.get(`/projects/${projectId}/segments/${seg.testSegmentVoiceID}`);

            if (channel === CHANNELS.DIGITAL) {
                segs.push(
                    {
                        ...result?.data?.data,
                        id: result?.data?.data?.testSegmentId,
                        defaultLng: seg.languageCode || 'en',
                        segmentSeq: seg.segmentSeq || index,
                        testCaseSegmentId: seg.testCaseSegmentId,
                        testSegmentVoiceId: seg.testSegmentId,
                        segmentLangs: [
                            {
                                steps: populateDigitalSteps(orderBy(result?.data?.data?.steps || [], 'stepSeq')),
                            },
                        ],
                    }
                );

            } else {
                segs.push(
                    {
                        ...result?.data?.data,

                        id: `${result?.data?.data?.testSegmentVoiceId}`,
                        defaultLng: seg.languageCode || 'en',
                        segmentSeq: seg.segmentSeq || index,
                        testCaseSegmentId: seg.testCaseSegmentId,
                    }
                );
            }

            return true;
        })
    )

    dispatch(setBatchSegments(orderBy(segs, 'segmentSeq')));

    return data;
}


export const upsertTestCase = async (projectId: number, formData: ITestCase) => {
    if (formData.id || formData.testCaseId) {
        return await apiService.put(`/projects/${projectId}/cases/${formData.id || formData.testCaseId}`, {
            ...formData,
        }).catch(e => e);
    } else
        return await apiService.post(`/projects/${projectId}/cases`, {
            ...formData,
        }).catch(e => e);
}

export const manualTestCaseRun = async (testCaseId: number, projectId: number, multiValue: 'default' | 'all' = 'default') => {
    return await apiService.post(`/projects/${projectId}/cases/${testCaseId}/run?multiValue=${multiValue}`, {})
        .catch(e => e);
}

export const runStatusStream = (requestId: string) => {
    const d = new EventSource(`${getVoiceApi()}/request/${requestId}/stream`);
    return d;
}

export const runStatusStreamDigital = (requestId: string) => {
    const d = new EventSource(`${getDigitalApi()}/request/${requestId}/stream`);
    return d;
}



export const transcription = async (segId: number, stepId: number, lng: string) => {
    return await apiService.get(`/results/${segId}/items/${stepId}/transcribe/${lng}`)
        .catch(e => e);
}



export const getTranscriptionFile = async (stepId: number) => {
    return await apiService.get(`/results/${stepId}/audio`)
        .catch(e => e);
}



export const getLastRunResult = async (projectId: number, caseId: number, channel: CHANNELS) => {
    return channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases/${caseId}/results/last`).catch(e => e) : await apiService.get(`/projects/${projectId}/cases/${caseId}/results/last`)
        .catch(e => e);
}

export const getTestCase = async (projectId: number, id: number, channel = CHANNELS.VOICE) => {
    return channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases/${id}`)
        : await apiService.get(`/projects/${projectId}/cases/${id}`).catch(e => e)
}

export const runDigitalTestCases = async (projectId: any, cases: any[], browsers: string) => {

    return await Promise.all(
        cases.map(async (id) => {
            return await digitalApiService.post(`/projects/${projectId}/cases/${id?.id || id?.testCaseId}/run?browsers=${browsers}&multiValue=${id.runAll ? `all` : `default`}`).catch(e => e);
        })
    );

}

export const upsertDigitalTestCase = async (segments: ISegment[], name: string, projectId: number, caseId = null, saveSegmentOnly = false, duplicate = false) => {


    const updatedSegments = await Promise.all(
        segments.map(async segment => {
            const model = segment.segmentLangs?.[0]?.steps?.map((item, index) => {

                const id = duplicate ? 0 : segment.testCaseSegmentId;

                if (item.itemLocators) return item;

                switch (item.actionType) {
                    case DIGITAL_STEP_TYPE.SCREENSHOT: return { testStepId: !id ? 0 : (item.testStepId || 0), stepSeq: index, stepType: item.actionType, remark: item.remark, simpleParameters: [], itemLocators: [] };

                    case DIGITAL_STEP_TYPE.PAUSE: return { testStepId: !id ? 0 : (item.testStepId || 0), stepSeq: index, stepType: item.actionType, remark: item.remark, itemLocators: [], simpleParameters: [{ value: item.seconds, name: PARAM_TYPES.SIMPLE_PAUSE, paramType: PARAM_TYPES.SIMPLE_NAME_VALUE }] };
                    case DIGITAL_STEP_TYPE.MOUSE_OVER: return { testStepId: !id ? 0 : (item.testStepId || 0), stepSeq: index, stepType: item.actionType, remark: item.remark, itemLocators: [], simpleParameters: [] };

                    case DIGITAL_STEP_TYPE.LOAD_PAGE:
                        return {
                            testStepId: !id ? 0 : (item.testStepId || 0),
                            stepSeq: index,
                            stepType: item.actionType,
                            remark: item.remark,
                            itemLocators: [],
                            simpleParameters: [{ name: PARAM_TYPES.SIMPLE_URL, value: item.url, paramType: PARAM_TYPES.SIMPLE_NAME_VALUE }],
                            latencyStep: {
                                testStepId: !id ? 0 : (item?.latencyStep?.testStepId || 0),
                                stepType: DIGITAL_STEP_TYPE.FIND_ITEM,
                                timeout: item.latencyTimeOut,
                                itemLocators: item.criteria2?.map((cr: any, _index: number) => {
                                    switch (cr.locatorType) {
                                        case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                        case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                        case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                    }
                                })
                            },
                        };

                    case DIGITAL_STEP_TYPE.SWITCH_FRAME:
                        return {
                            testStepId: !id ? 0 : (item.testStepId || 0),
                            stepSeq: index,
                            stepType: item.actionType,
                            remark: item.remark,
                            simpleParameters: [],
                            itemLocators: item.criteria?.map((cr: any, _index: number) => {
                                switch (cr.locatorType) {
                                    case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                }
                            })
                        };

                    case DIGITAL_STEP_TYPE.ENTER_TEXT:
                        return {
                            testStepId: !id ? 0 : (item.testStepId || 0),
                            stepSeq: index,
                            stepType: item.actionType,
                            remark: item.remark,
                            simpleParameters: [
                                { name: PARAM_TYPES.SIMPLE_TEXT, value: item.textToSend, paramType: PARAM_TYPES.SIMPLE_NAME_VALUE },
                                { name: PARAM_TYPES.SIMPLE_TEXT_LINE, value: item.dontNotSend, paramType: PARAM_TYPES.SIMPLE_NAME_VALUE }
                            ],
                            itemLocators: item.criteria?.map((cr: any, _index: number) => {
                                switch (cr.locatorType) {
                                    case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                }
                            }),
                            latencyStep: {
                                testStepId: !id ? 0 : (item?.latencyStep?.testStepId || 0),
                                stepType: DIGITAL_STEP_TYPE.FIND_ITEM,
                                timeout: item.latencyTimeOut,
                                itemLocators: item.criteria2?.map((cr: any, _index: number) => {
                                    switch (cr.locatorType) {
                                        case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                        case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                        case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                    }
                                })
                            },
                        };

                    case DIGITAL_STEP_TYPE.FIND_ITEM:
                        return {
                            testStepId: !id ? 0 : (item.testStepId || 0),
                            stepSeq: index,
                            stepType: item.actionType,
                            remark: item.remark,
                            timeout: item.timeOut,
                            simpleParameters: [],
                            itemLocators: item.criteria?.map((cr: any, _index: number) => {
                                switch (cr.locatorType) {
                                    case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                }
                            })
                        };

                    case DIGITAL_STEP_TYPE.CLICK:
                        return {
                            testStepId: !id ? 0 : (item.testStepId || 0),
                            stepSeq: index,
                            stepType: item.actionType,
                            remark: item.remark,
                            simpleParameters: [],
                            itemLocators: item.criteria?.map((cr: any, _index: number) => {
                                switch (cr.locatorType) {
                                    case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                    case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                }
                            }),
                            latencyStep: {
                                testStepId: !id ? 0 : (item?.latencyStep?.testStepId || 0),
                                stepType: DIGITAL_STEP_TYPE.FIND_ITEM,
                                timeout: item.latencyTimeOut,
                                itemLocators: item.criteria2?.map((cr: any, _index: number) => {
                                    switch (cr.locatorType) {
                                        case LOCATOR_TYPES.DISPLAY_TEXT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.displayText, matchOp: cr.operation, paramSeq: _index };
                                        case LOCATOR_TYPES.HTML_ELEMENT: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.elementName, paramSeq: _index };
                                        case LOCATOR_TYPES.HTML_ATTRIBUTE: return { testStepParamId: !id ? 0 : (cr.testStepParamId || 0), paramType: cr.locatorType, value: cr.attributeValue, matchOp: cr.operation, name: cr.attributeName, paramSeq: _index };

                                    }
                                })
                            },
                        };
                }
            });


            if (segment.testSegmentVoiceId) {
                const { data } = await digitalApiService.put(`/projects/${projectId}/segments/${segment.testSegmentVoiceId}`, {
                    steps: model,
                    testSegmentId: segment.testSegmentVoiceId,
                    name: segment.name,
                    projectId,
                    remark: segment.remark || '',
                }).catch(e => e);

                return {
                    ...data?.data,
                    testCaseSegmentId: duplicate ? 0 : segment.testCaseSegmentId,
                }
            }

            const { data } = await digitalApiService.post(`/projects/${projectId}/segments`, {
                steps: model,
                testSegmentId: 0,
                name: segment.name,
                projectId,
                remark: segment.remark || '',
            }).catch(e => e);

            return data?.data;
        })
    )


    if (saveSegmentOnly) {
        return updatedSegments;
    }

    if (caseId) {
        const { data } = await digitalApiService.put(`/projects/${projectId}/cases/${caseId}`, {
            segments: updatedSegments,
            name,
            remark: '',
            browser: '',
        });

        return data;
    } else {
        const { data } = await digitalApiService.post(`/projects/${projectId}/cases`, {
            segments: updatedSegments,
            name,
            remark: '',
            browser: '',
        });

        return data;
    }


}


export const handleScreenShotsUrl = (screenshot: string, isDigital: boolean) => {
    return `${screenshot.startsWith('/') ? new URL(isDigital ? getDigitalApi() : getVoiceApi())?.origin : ``}${screenshot}`;
}