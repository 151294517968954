import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../types";

const segmentSelectors = (state: IAppState) => state.segment;


export const getSelectedSegments = createSelector(
    [segmentSelectors],
    (app) => app?.segment || [],
);

export const allowUpsertLibrary = createSelector(
    [segmentSelectors],
    (app) => app?.upsertLibrary,
);

export const getEditSegment = createSelector(
    [segmentSelectors],
    (app) => app?.editSegment,
);
