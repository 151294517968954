import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { upsertVAUser } from "../services/user.service";
import { IVAUser } from "../types/interfaces/user.interface";
import React from "react";
import { toggleAlert } from "../store/resuders/app.reducer";
import { useAppDispatch } from "../store/hooks";
import { regions } from "../helpers/form.helper";
import { useTranslation } from "react-i18next";

export const AddVAUser = ({
  data = null as any,
  projectList = [] as any,
  onDone = null as any,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleProjectChange = (
    event: SelectChangeEvent<typeof selectedProjects>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedProjects(
      // On autofill we get a stringified value.
      value
    );
  };

  const validate = (values:any) => {
    const errors:any = {};
    const missingFields:string[] = [];

    if(!values.clientSecret) missingFields.push('Client Secret');

    if(missingFields.length > 0){
        errors.missingFields = missingFields.toString();
        dispatch(toggleAlert('Please fill-in the missing fields:'+errors.missingFields));
    }

    return errors;
};

  const formik = useFormik({
    validateOnChange:false,
    validateOnBlur:false,
    initialValues: {
      id: null,
      agentName: "",
      agentUsername: "",
      agentPassword: "",
      orgName: "",
      region: "",
      clientId: "",
      genRegion:'',
      genOrgName:'',
      clientSecret: "",
      platform:"Genesys",
      workspaceUrl:'',
      queueName:'',
      projects: [],
    },
    onSubmit: async (values) => {
      const formData = { ...values, 
        id: values?.id ? values?.id : undefined,
        projects: selectedProjects, 
      };
      const result = await upsertVAUser(formData);

      if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
        dispatch(
          toggleAlert(result?.response?.data?.data?.messages?.join(","))
        );
      } else {
        onDone && onDone();
      }
    },
    validate
  });

  useEffect(() => {
    if (data) {
      formik.setValues(data);
      setSelectedProjects(data?.projects);
    }
  }, [data]);

  const handleSave = async () => {
    await formik.submitForm();
  };

  return (
    <Card className="h-full">
      <CardContent>
        <form>
          <div className="form-header">
            <Typography variant="subtitle1">
              {formik.values?.id ? t("Edit Agent") : t("Create a new Agent")}
            </Typography>
          </div>
          <div className="pt-32 pl-40 pr-40">

            <Box className="user-input">
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="platform">{t("Desktop Platform")}</InputLabel>
                <Select
                  labelId="platform"
                  id="platform"
                  value={formik.values?.platform}
                  label={t("Desktop Platform")}
                  name="platform"
                  onChange={formik.handleChange}
                >
                    <MenuItem value={"Amazon"}>Amazon</MenuItem>
                    <MenuItem value={"Genesys"}>Genesys</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className="user-input">
              <FormControl sx={{ width: "50%" }}>
                <InputLabel htmlFor="agentId"></InputLabel>
                <TextField
                  type="text"
                  id="agentUsername"
                  name="agentUsername"
                  label={t("Agent Login Username")}
                  value={formik.values?.agentUsername}
                  onChange={formik.handleChange}
                />
              </FormControl>

                <FormControl sx={{ width: "50%" }}>
                  <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                  <TextField
                    id="agentPassword"
                    type={showPassword ? "text" : "password"}
                    label={t("Agent Login Password")}
                    name="agentPassword"
                    onChange={formik.handleChange}
                    value={formik.values?.agentPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
            </Box>

            {formik?.values?.platform === 'Genesys' ? (
              <>           
              {/* <Box className="user-input">
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel id="genRegion">{t("Genesys Org Region")}</InputLabel>
                  <Select
                    labelId="genRegion"
                    id="genRegion"
                    value={formik.values?.genRegion}
                    label={t("Genesys Org Region")}
                    name="genRegion"
                    onChange={formik.handleChange}
                  >
                      {regions.map((id) => (
                        <MenuItem value={id}>{id}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel htmlFor="genOrgName"></InputLabel>
                  <TextField
                    type="text"
                    id="genOrgName"
                    name="genOrgName"
                    label={t("Genesys Org Name")}
                    value={formik.values?.genOrgName}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box> */}

              <Box className="user-input">
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel htmlFor="queueName"></InputLabel>
                  <TextField
                    type="text"
                    id="queueName"
                    name="queueName"
                    label={t("Queue Name")}
                    value={formik.values?.queueName}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel htmlFor="clientId"></InputLabel>
                  <TextField
                    type="text"
                    id="clientId"
                    name="clientId"
                    label={t("Client ID")}
                    value={formik.values?.clientId}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>

              <Box className="user-input">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="clientSecret"></InputLabel>
                  <TextField
                    type="text"
                    id="clientSecret"
                    name="clientSecret"
                    label={t("Client Secret")}
                    value={formik.values?.clientSecret}
                    onChange={formik.handleChange}
                    required
                  />
                </FormControl>
              </Box>
              </>
             ):(
                <>
                
                  <Box className="user-input">
                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel htmlFor="url"></InputLabel>
                      <TextField
                        type="text"
                        id="workspaceUrl"
                        name="workspaceUrl"
                        label={t("URL for Agent Workspace")}
                        value={formik.values?.workspaceUrl}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel htmlFor="queueName"></InputLabel>
                      <TextField
                        type="text"
                        id="queueName"
                        name="queueName"
                        label={t("Queue Name")}
                        value={formik.values?.queueName}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                </Box>

                <Box className="user-input">
                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel htmlFor="accessKey"></InputLabel>
                      <TextField
                        type="text"
                        id="clientId"
                        name="clientId"
                        label={t("AWS Accesskey")}
                        value={formik.values?.clientId}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel htmlFor="secretKey"></InputLabel>
                      <TextField
                        type="text"
                        id="clientSecret"
                        name="clientSecret"
                        label={t("AWS Secretkey")}
                        value={formik.values?.clientSecret}
                        onChange={formik.handleChange}
                      />
                    </FormControl>
                </Box>
                </>
             )}

              <Box className="user-input">
                <div className="agent-divider">
                  Lens Settings
                </div>
              </Box>
           

            <Box className="user-input">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="agentName"></InputLabel>
                <TextField
                  type="text"
                  id="agentName"
                  name="agentName"
                  label={t("Agent Name in Lens")}
                  value={formik.values?.agentName}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Box>

            <Box className="user-input">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="organization"></InputLabel>
                <TextField
                  type="text"
                  id="orgName"
                  name="orgName"
                  label={t("Organization")}
                  value={formik.values?.orgName}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Box>
            {/* <Box className="user-input">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="clientId"></InputLabel>
                <TextField
                  type="text"
                  id="clientId"
                  name="clientId"
                  label={t("Client ID")}
                  value={formik.values?.clientId}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Box>
            <Box className="user-input">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="clientSecret"></InputLabel>
                <TextField
                  type="text"
                  id="clientSecret"
                  name="clientSecret"
                  label={t("Client Secret")}
                  value={formik.values?.clientSecret}
                  onChange={formik.handleChange}
                  required
                />
              </FormControl>
            </Box> */}
            <Box className="user-input">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="region">{t("Region")}</InputLabel>
                <Select
                  labelId="region"
                  id="region"
                  value={formik.values?.region}
                  label={t("Region")}
                  name="region"
                  onChange={formik.handleChange}
                >
                  {regions.map((id) => (
                    <MenuItem value={id}>{id}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="user-input">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="project">{t("Project")}</InputLabel>
                <Select
                  labelId="project"
                  id="project"
                  label={t("Project")}
                  name="projects"
                  multiple
                  value={selectedProjects || []}
                  onChange={handleProjectChange}
                  input={<OutlinedInput label="Projects" />}
                  renderValue={() => selectedProjects?.map((project: any) => project.name).join(", ")}
                >
                  {projectList.map((option: any) => (
                    <MenuItem key={option?.testProjectID} value={option}>
                      <Checkbox
                        checked={selectedProjects?.find((element: any) => element.testProjectID === option.testProjectID)}
                      />
                      <ListItemText primary={option?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="db center mt-54">
            <Button variant="contained" onClick={handleSave} color="primary">
            {t("Save")}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};
