import { AppBar, MenuItem, TextField, Toolbar, Typography } from "@mui/material"
import { lensLogo, tekLogo } from "../assets"
import { useTranslation, Trans } from 'react-i18next';

export const Header = () => {
    const { t, i18n } = useTranslation();

    const changeLng = (e:any)=> {
        i18n.changeLanguage(e.target.value);
    }
    return (
        <AppBar component="nav" color="transparent" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar >
                <div className="flex align-center">
                    <img src={tekLogo} alt="" className="lens-logo" />
                    <img src={lensLogo} alt="" className="lens-logo" />
                </div>
                <div className="language-selector">
                    <TextField onChange={changeLng} 
                        select value={i18n.language}
                        label={t("Language")}>
                        <MenuItem value={'en-US'}>English</MenuItem>
                        <MenuItem value={'fr'}>French</MenuItem>
                        {/* <MenuItem value={'es'}>Spanish</MenuItem> */}
                    </TextField>
                </div>
            </Toolbar>
        </AppBar >
    )
}