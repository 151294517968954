import { createAction, createReducer } from "@reduxjs/toolkit";
import { uniqueId } from "../../helpers/form.helper";
import { HELP_CENTER } from "../../types/global.enum";
export const reloadSegments = createAction('reloadSegments');
export const toggleSpinner = createAction<boolean>('APP-TOGGLE-SPINNER');
export const toggleAlert = createAction<any>('APP-TOGGLE-ALERT');
export const reloadLibraries = createAction('reloadLibraries');
export const serGrammaroptions = createAction<any[]>('serGrammaroptions');
export const globalNotification = createAction<string>('globalNotification');
export const loadHelpCenter = createAction<HELP_CENTER>('loadHelp');
export const showHelpDetails = createAction<any>('showHelpDetails');

export const appReducer = createReducer(null, (builder) => {

    builder.addCase(serGrammaroptions, (state: any, action) => {

        return {
            ...state,
            grammarOptions: action.payload,
        }
    }).addCase(showHelpDetails, (state: any, action) => {

        return {
            ...state,
            helpKey: action.payload,
        }
    }).addCase(loadHelpCenter, (state: any, action) => {

        return {
            ...state,
            helpCenter: action.payload,
        }
    }).addCase(globalNotification, (state: any, action) => {

        return {
            ...state,
            globalNotification: action.payload,
        }
    }).addCase(toggleSpinner, (state: any, action) => {

        return {
            ...state,
            showSpinner: action.payload,
        }
    }).addCase(toggleAlert, (state: any, action) => {

        return {
            ...state,
            globalAlert: { show: action.payload ? `${new Date().getTime()}` : '', message: action.payload },
        }
    }).addCase(reloadLibraries, (state: any, action) => {

        return {
            ...state,
            reloadLibraries: `${new Date().getTime()}`,
        }
    }).addCase(reloadSegments, (state: any, action) => {

        return {
            ...state,
            reloadSegments: uniqueId(),
        }
    })


})