import { createAction, createReducer } from "@reduxjs/toolkit";
import { IGrammar } from "../../types/interfaces/grammar.interface";

export const setCurrentGrammar = createAction<IGrammar[]>('setCurrentGrammar');
export const addGrammars = createAction<IGrammar[]>('addGrammars');
export const addSelectedGrammars = createAction<IGrammar[]>('addSelectedGrammars');
export const clearSelectedGrammars = createAction('clearSelectedGrammars');
export const removeGrammars = createAction<string[]>('removeGrammars');
export const updateGrammars = createAction<IGrammar[]>('updateGrammars');
export const upsertGrammar = createAction<string>('upsertGrammar');
export const setEditGrammar = createAction<any>('setEditGrammar');


export const grammarReducer = createReducer<IGrammar[] | null>([], (builder) => {

    builder.addCase(setCurrentGrammar, (state: any, action) => {
        return {
            ...state,
            grammar: action.payload,
        }
    }).addCase(addGrammars, (state: any, action) => {
        return {
            ...state,
            grammar: (state?.grammar || []).concat(action.payload),
        }
    }).addCase(addSelectedGrammars, (state: any, action) => {
        return {
            ...state,
            selectedGrammars: (state?.selectedGrammars || []).concat(action.payload),
        }
    }).addCase(clearSelectedGrammars, (state: any, action) => {

        return {
            ...state,
            selectedGrammars: [],
        }
    }).addCase(removeGrammars, (state: any, action) => {

        return {
            ...state,
            grammar: state?.grammar.filter((c: IGrammar) => !action.payload.includes(c.id)),
        }
    }).addCase(updateGrammars, (state: any, action: any) => {
        return {
            ...state,
            grammar: (state.grammar || []).reduce((result: any, curr: any) => {
                if (curr.grammarId === action.payload.grammarId) {
                    result.push({
                        ...curr,
                        ...action.payload,
                    });
                } else {
                    result.push(curr);
                }

                return result;
            }, [])
        }
    }).addCase(setEditGrammar, (state: any, action) => {

        return {
            ...state,
            editGrammar: action.payload,
        }
    }).addCase(upsertGrammar, (state: any, action) => {

        return {
            ...state,
            upsertGrammar: action.payload,
        }
    })
})

