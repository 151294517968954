import { useRef, useState } from 'react';
import { Button, Card, CardContent, Typography, InputLabel, FormControl, Select, MenuItem, Box, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useAppDispatch } from "../store/hooks"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import { getEditSegment, getSelectedSegments } from '../store/selectors/segment.selector';
import { getAllStepTypes, getCurrentProject, getCurrentTestCase, getGoToTypes, getLanguages } from '../store/selectors/project.selector';
import { deleteIcon } from '../assets';
import { ports, scheduleTypes, status, trigger } from '../helpers/form.helper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { PerpetualSchedule } from './perpetualSchedule';
import { FormikProps } from "formik";
import { RecurrenceEditor } from './recurrenceEditor';
import { ScheduleTestCaseEditor } from './scheduleTestCaseEditor';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getSelectedTestCases } from '../store/selectors/schedule.selector';
import { showHelpDetails, toggleAlert } from '../store/resuders/app.reducer';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd'
import { getTestCase } from '../services/testcase.service';
import { useParams } from 'react-router-dom';
import { getHelpCenter } from "../store/selectors/app.selector";
import InfoIcon from '@mui/icons-material/Info';

export const ScheduleEditor = ({
    data,
    formik,
    toggleScheduleEditor,
    scheduleEditorAction = null as any,
    setScheduleEditorAction
}: {
    data?: [],
    formik: FormikProps<any>;
    toggleScheduleEditor: () => void;
    scheduleEditorAction?: any;
    setScheduleEditorAction: (value: string) => void
}) => {
    const { t } = useTranslation();
    const [clear, setClear] = useState(false);
    const project = useSelector(getCurrentProject);
    const { projectIdParam } = useParams();
    const [addTestCaseOpen, setAddTestCaseOpen] = useState(false);
    const [hasTestCases, setHasTestCases] = useState(false);  // State to check if the schedule contain test cases
    const [testCaseInfoBoxOpen, setTestCaseInfoBoxOpen] = useState(false);  // Test Case Info Box
    const [testCasesOpen, setTestCasesOpen] = useState(false);  // Test Case Editor
    const [recurrenceOpen, setRecurrenceOpen] = useState(false);  // Perpetual Schedule Editor
    const [addRecurrenceOpen, setAddRecurrenceOpen] = useState(false);  // Recurrence Editor
    const [recurrenceEditorState, setRecurrenceEditorState] = useState("ADD");
    // const [testCasesFromEditor, setTestCasesFromEditor] = useState<any>([]);
    const [testCaseData, setTestCaseData] = useState<any>([]);  // Contains Test Cases Added From Editor
    const [selectedTestCases, setSelectedTestCases] = useState<any[]>([]);  // Store Selected Test Cases from Test Case Info Box
    const [paramToEdit, setParamToEdit] = useState<any>({});
    const [triggerType, setTriggerType] = useState<any>(formik?.values?.triggerTimeForRunOnce === 'Now' ? 'Now' : 'Future' );
    const helpCenter = useSelector(getHelpCenter);

    const dispatch = useAppDispatch();

    //Validate Recurrence Form Data
    const validate = (values:any) => {
        const errors:any = {};
        const missingFields:string[] = [];

        if(values.exclusion){
            if(values.dayOrDate.length === 0) missingFields.push('Select Date');
        }else {
            if(values.dayOrDate.length === 0) missingFields.push('Select Days');
        }

        if(missingFields.length > 0){
            errors.missingFields = missingFields.toString();
            dispatch(toggleAlert('Please fill-in the missing fields:'+errors.missingFields));
        }

        return errors;
    };

    const recurrenceFormik = useFormik({
        validateOnChange:false,
        validateOnBlur:false,
        initialValues: {
            dayOrDate: [],
            // fromTime: dayjs(), // Current time in 24-hour format
            // toTime: dayjs(),   // Current time in 24-hour format
            fromTime: dayjs().format('HH:mm:ss.SSS'),
            toTime: dayjs().format('HH:mm:ss.SSS'),
            runInterval: 1,
            runIntervalUnit: 'hours',
            enabled: false,
            exclusion: true
        } as any,
        onSubmit: async (values: any) => {

            formik.setFieldValue("scheduleTime", [...formik?.values?.scheduleTime, {
                ...recurrenceFormik?.values,
                fromTime: typeof recurrenceFormik?.values?.fromTime === 'object' ? recurrenceFormik?.values?.fromTime?.format('HH:mm:ss.SSS'):recurrenceFormik?.values?.fromTime,
                toTime: typeof recurrenceFormik?.values?.toTime === 'object' ? recurrenceFormik?.values?.toTime?.format('HH:mm:ss.SSS'):recurrenceFormik?.values?.toTime,
                dayOrDate: Array.isArray(recurrenceFormik?.values?.dayOrDate) ? recurrenceFormik?.values?.dayOrDate:[recurrenceFormik?.values?.dayOrDate]
            }]);

            recurrenceFormik.resetForm();
        },
        validate

    })


    const toggleAddTestCase = () => {
        setTestCasesOpen(!testCasesOpen)  // Open test case editor
        setAddTestCaseOpen(!addTestCaseOpen)  // Toggle state of Add Test Case button
        /* TODO: Add condition here to check if the schedule contains test cases to toggle the test case info box state */
        if (hasTestCases) {
            setTestCaseInfoBoxOpen(true)  // Open test case info box
        } else {
            setTestCaseInfoBoxOpen(false)  // Close test case info box
        }
        /* */
        setRecurrenceOpen(false)  //  Close Recurrence Editor
    }

    const toggleRecurrenceOpen = () => {
        setRecurrenceOpen(!recurrenceOpen)
        setAddTestCaseOpen(false)  // Toggle state of Add Test Case button
        setTestCasesOpen(false)  // Close Test Case Editor
    }


    const saveTestCases = async () => {
        // Save Button
        if (triggerType === 'Now') {
            formik.setFieldValue('triggerTimeForRunOnce', "Now");
        }
        await formik.submitForm();
    }

    const saveTestCasesAsNew = async () => {
        // Save As New Button
        if (triggerType === 'Now') {
            formik.setFieldValue('triggerTimeForRunOnce', "Now");
        }
        setScheduleEditorAction("ADD")
        formik.setFieldValue('testScheduleId', undefined);
        await formik.submitForm();
    }

    const confirm = async () => {
        await formik.submitForm();

    }

    const deletePerpetualSchedule = (itemToDelete: any) => {
        // Find the index of the item to delete
        const indexToDelete = formik.values.scheduleTime.findIndex((item: any) => {
            // Compare properties of the item to delete with each object in the array
            console.log("Item:", item)
            return (
                item.dayOrDate[0] === itemToDelete.dayOrDate[0] &&
                item.fromTime === itemToDelete.fromTime &&
                item.toTime === itemToDelete.toTime &&
                item.runInterval === itemToDelete.runInterval &&
                item.runIntervalUnit === itemToDelete.runIntervalUnit &&
                item.enabled === itemToDelete.enabled &&
                item.exclusion === itemToDelete.exclusion
            );
        });

        if (indexToDelete !== -1) {
            // Create a new array without the item to delete
            const updatedScheduleTime = [
                ...formik.values.scheduleTime.slice(0, indexToDelete),
                ...formik.values.scheduleTime.slice(indexToDelete + 1)
            ];

            // Update formik with the modified scheduleTime
            formik.setFieldValue('scheduleTime', updatedScheduleTime);
        }
    }

    const removeSelectedTestCases = () => {
        // Get the array of testCaseSequences  to be removed from selectedTestCases
        const testCaseSequencesToRemove = selectedTestCases.map((testCase) => testCase?.caseSeq);

        // Use Array.filter to create a new array excluding the test cases with the specified caseSeq
        const updatedTestCases = formik?.values?.testCases.filter(
            (testCase: any) => !testCaseSequencesToRemove.includes(testCase?.caseSeq)
        );

        formik.setFieldValue("testCases", updatedTestCases);

        setSelectedTestCases([]);
    };

    // Function to handle checkbox changes
    const handleCheckboxChange = (testCase: any) => {
        // Check if the testCase is already selected
        const isSelected = selectedTestCases.some((selected) => selected.caseSeq  === testCase.caseSeq );

        if (isSelected) {
            // If already selected, remove it from the selectedTestCases array
            setSelectedTestCases((prevSelected) =>
                prevSelected.filter((selected) => selected.caseSeq  !== testCase.caseSeq )
            );
        } else {
            // If not selected, add it to the selectedTestCases array
            setSelectedTestCases((prevSelected) => [...prevSelected, testCase]);
        }
    };

    const moveTestCaseUp = (index: any) => {
        const { testCases } = formik.values;
        if (index > 0) {
            const updatedTestCases = [...testCases];
            // Swap the test case with the one above it
            updatedTestCases[index].caseSeq = index;
            updatedTestCases[index-1].caseSeq = index+ 1;
            [updatedTestCases[index], updatedTestCases[index - 1]] = [updatedTestCases[index - 1], updatedTestCases[index]];
            
            formik.setFieldValue('testCases', updatedTestCases);
        }
    };

    const moveTestCaseDown = (index: any) => {
        const { testCases } = formik.values;
        if (index < testCases.length - 1) {
            const updatedTestCases = [...testCases];
            // Swap the test case with the one below it
            updatedTestCases[index].caseSeq = index+2;
            updatedTestCases[index+1].caseSeq = index+1;
            [updatedTestCases[index], updatedTestCases[index + 1]] = [updatedTestCases[index + 1], updatedTestCases[index]];
            formik.setFieldValue('testCases', updatedTestCases);
        }
    };

    const reorderTestCase = (item: any, targetIndex: number) => {
        if (item.index !== targetIndex) {
            const testcases = formik.values.testCases;
            let result = [...testcases];
            //Checking wheather testcase sequence is new or exsting
            if(item?.index !== undefined){
                const temp = testcases[item.index];
                result.splice(item.index, 1);
                result.splice(targetIndex, 0, temp);
                const reorderedTestCases = result.reduce((res: any[], curr: any, index: number) => {
                    curr.caseSeq = index + 1; //Adding 1 to case sequence becasue of casaSeq starts from 1
                    res.push({
                        ...curr
                    });
                    return res;
                }, []);
                formik.setFieldValue('testCases', reorderedTestCases);
            } else {
                testcases.splice(targetIndex, 0, item)
                const reorderedTestCases = testcases.reduce((res: any[], curr: any, index: number) => {
                    curr.caseSeq = index + 1; //Adding 1 to case sequence becasue of casaSeq starts from 1
                    res.push({
                        ...curr
                    });
                    return res;
                }, []);
                formik.setFieldValue('testCases', reorderedTestCases);
            }
            

            
            
        }
    }

    const useSelectedTestCases = () => {
        return useSelector(getSelectedTestCases);
    };

    const onTriggerChange = (event: any) => {
        // formik.handleChange(event);
        setTriggerType(event.target.value);
    }

    const onPortChange = (event: any) => {
        let voice = {
            port: event.target.value,
            
        }
        formik.setFieldValue('voice',voice);
    }

    useEffect(() => {
        if (formik?.values?.testCases) {
            setHasTestCases(true)
            setTestCaseInfoBoxOpen(true)
        } else {
            setHasTestCases(false)
            setTestCaseInfoBoxOpen(false)
        }
    }, [formik?.values]);


    let gridItemSize = 12;

    if (testCasesOpen) {
        gridItemSize = 6;
    }
    else if (recurrenceOpen && addRecurrenceOpen) {
        gridItemSize = 4;
    }
    else if (recurrenceOpen) {
        gridItemSize = 6;
    } else {
        gridItemSize = 12;
    }

    const TestCaseItem = ({ ...props }) => {
        const [{ isOver }, drops] = useDrop(() => ({
            accept: 'TESTCASE-ROWS',
            drop: (item: any) => {
                const targetIndex = props.data.index;
                reorderTestCase(item, targetIndex);
            },
            collect: (monitor: any) => ({
                isOver: !!monitor.isOver(),
            }),
        }))

        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'TESTCASE-ROWS',
            item: {
                ...props.data,
                order: true,
            },
            collect: (monitor: any) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }))

        const ref = useRef<any>(null);
        drag(drops(ref))
        return (<div style={isOver ? { backgroundColor: '#CECECE' } : {}} className="flex-center" ref={ref}>
                    <div className="testcase-info">
                        {/* Checkbox */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedTestCases.some((selected) => selected.caseSeq === props.data.testCase.caseSeq)}
                                    onChange={() => handleCheckboxChange(props.data.testCase)}
                                />
                            }
                            label={<Typography>{props.data.testCase.testCaseId}&nbsp;&nbsp;{props.data.testCase.name}</Typography>}
                        />
                    </div>
                    <div className="flex-end">
                        {props.data.index > 0 && (
                            <ArrowUpwardIcon
                                onClick={() => moveTestCaseUp(props.data.testCase.caseSeq - 1)}
                                color="primary"
                                aria-label="Move Up"
                            >
                            </ArrowUpwardIcon>
                        )}
                        {props.data.index < formik.values?.testCases.length - 1 && (
                            <ArrowDownwardIcon
                                onClick={() => moveTestCaseDown(props.data.testCase.caseSeq - 1)}
                                color="primary"
                                aria-label="Move Down"
                            >
                            </ArrowDownwardIcon>
                        )}
                    </div>
                </div>);
    }

    const addTestCaseToScheduler = async (testCaseDropped: any) => {
        const updatedTestCases = [...formik.values.testCases];
        const projectId = project ? project.testProjectID : +(projectIdParam || '0');
        const id = testCaseDropped.testCaseId;
        const response = await getTestCase(projectId, id);
        const testcase = response.data.data; // Entire response is the testcase
        testcase.caseSeq = updatedTestCases.length + 1
        updatedTestCases.push(testcase);

        // Update the Formik field value
        formik.setFieldValue('testCases', updatedTestCases);
    }

    const ref = useRef<any>(null);
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'TESTCASE-ROWS',
        drop: (data, monitor) => {
            addTestCaseToScheduler(data);
        },
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
        hover: (item, monitor) => {
            // console.log('hover', monitor)
        }
    }), [formik.values?.testCases])
    
    drop(ref);




    return (
        <Grid container>
            <Grid item xs={gridItemSize} paddingRight={'9px'} >
                <div>
                    {helpCenter && <div onClick={()=>dispatch(showHelpDetails('Schedule Editor'))} className="help-icon-float"><InfoIcon /></div>}
                    <Card className="h-100-p">
                        <CardContent className='schedule-editor-container'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="schedule-editor-header">
                                    <Typography variant="subtitle1">{scheduleEditorAction == 'ADD' ? t('Create a New Schedule') : t('Editing Schedule')}</Typography>
                                    {/* <HighlightOffIcon onClick={() => toggleScheduleEditor()} /> */}
                                </div>
                                <div className="scheduler-form-container">
                                    <Box className='schedule-primary-fields'>
                                        <FormControl sx={{ width: "60%" }}>
                                            <InputLabel id="case-editor-segment-name"></InputLabel>
                                            <TextField
                                                id="name"
                                                value={formik.values?.name}
                                                label={t('Schedule Name')}
                                                name='name'
                                                onChange={formik.handleChange}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: "10%" }}>
                                            <InputLabel id="case-editor-step">Ports</InputLabel>
                                            <Select
                                                labelId="case-editor-step"
                                                id="ports"
                                                value={formik.values?.voice?.port}
                                                label={t('Ports')}
                                                name="port"
                                                onChange={onPortChange}
                                            >
                                                {ports.map(id => (
                                                    <MenuItem value={id}>{id}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ width: "30%" }}>
                                            <InputLabel id="case-editor-language">{t('Schedule Type')}</InputLabel>
                                            <Select
                                                labelId="case-editor-language"
                                                id="type"
                                                value={formik.values?.type}
                                                label={t('Schedule Type')}
                                                name="type"
                                                onChange={formik.handleChange}
                                            >
                                                {scheduleTypes.map(id => (
                                                    <MenuItem value={id}>{id}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box >
                                    {formik.values?.type === "One Time" && (
                                        <Box className='schedule-secondary-fields'>
                                            <FormControl sx={{ width: "20%" }}>
                                                <InputLabel id="case-editor-step">{t('Status')}</InputLabel>
                                                <Select
                                                    labelId="case-editor-step"
                                                    id="status"
                                                    value={formik.values?.status}
                                                    label={t('Status')}
                                                    name="status"
                                                    onChange={formik.handleChange}
                                                >
                                                    {status.map(id => (
                                                        <MenuItem value={id}>{t(id)}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ width: "20%" }}>
                                                <InputLabel id="case-editor-step">{t('Trigger')}</InputLabel>
                                                <Select
                                                    labelId="case-editor-step"
                                                    id="trigger"
                                                    value={triggerType}
                                                    label={t('Trigger')}
                                                    name="trigger"
                                                    onChange={onTriggerChange}
                                                >
                                                    {trigger.map(id => (
                                                        <MenuItem value={id}>{id}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                            {triggerType !== 'Now' && (
                                                <FormControl sx={{ width: "30%" }}>
                                                    <DateTimePicker
                                                        label={t('Start Date and Time')}
                                                        format="YYYY/MM/DD HH:mm A"
                                                        value={formik?.values?.triggerTimeForRunOnce !== 'Now' ? dayjs(formik?.values?.triggerTimeForRunOnce) : dayjs()}
                                                        onChange={(date: any) => formik.setFieldValue('triggerTimeForRunOnce', date.format('YYYY-MM-DDTHH:mm:ss'))}
                                                    />
                                                </FormControl>
                                            )}

                                        </Box>
                                    )}
                                    {formik.values?.type == "Perpetual" && (
                                        <Box className='schedule-secondary-fields' style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                            <FormControl sx={{ width: "20%" }}>
                                                <InputLabel id="case-editor-step">{t('Status')}</InputLabel>
                                                <Select
                                                    labelId="case-editor-step"
                                                    id="status"
                                                    value={formik.values?.status}
                                                    label={t('Status')}
                                                    name="status"
                                                    onChange={formik.handleChange}
                                                >
                                                    {status.map(id => (
                                                        <MenuItem value={id}>{t(id)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button variant={recurrenceOpen ? 'outlined' : 'contained'} color="primary" onClick={toggleRecurrenceOpen}>
                                                {recurrenceOpen ? t('Cancel') : t('Recurrence')}
                                            </Button>
                                        </Box>
                                    )}
                                </div>
                                <div className={testCaseInfoBoxOpen ? 'schedule-editor-test-case-container' : 'schedule-editor-test-case-container-no-test-cases'}>
                                    <div className="schedule-editor-test-case-header">
                                            <Typography variant="subtitle1">{t('Test Case Info')}</Typography>
                                        </div>
                                    {testCaseInfoBoxOpen && (<a><img src={deleteIcon} className="schedule-editor-delete" alt="Remove" style={{ cursor: 'pointer !important' }} onClick={removeSelectedTestCases}/></a>)}
                                    {testCaseInfoBoxOpen ? (
                                            <Box className="schedule-editor-test-case-box">
                                                {/* ADD Existing Test Cases From Schedule*/}
                                                {formik.values?.testCases.map((testCase: any, index: number) => (
                                                    <TestCaseItem key ={testCase.caseSeq} data={{ testCase, index: index }}></TestCaseItem>
                                                ))}
                                                {/* ADD Test Cases From Editor */}
                                                <div className="schedule-editor-drag-field" ref={ref}></div>

                                            </Box>
                                        ) : (
                                            <div className="schedule-editor-test-case-body">
                                                <Typography variant="subtitle2">{t('Start by adding test cases to this schedule')}.</Typography>
                                            </div>
                                        )}
                                        <div className='schedule-editor-test-case-buttons'>
                                            <Button variant={addTestCaseOpen ? 'outlined' : 'contained'} color="primary" onClick={toggleAddTestCase}>
                                                {addTestCaseOpen ? t('Cancel') : t('Add Test Case') }
                                            </Button>
                                            {testCaseInfoBoxOpen && (
                                                <div style={{ display: 'flex', gap: '30px' }}>
                                                    <Button variant='contained' color="primary" onClick={saveTestCases}>
                                                        {t('Save')}
                                                    </Button>
                                                    <Button variant='contained' color="primary" onClick={saveTestCasesAsNew}>
                                                        {t('Save As New')}
                                                    </Button>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
                
            </Grid>
            {testCasesOpen && (
                <Grid item xs={gridItemSize} paddingRight={'9px'} >
                    <div>{helpCenter && <div onClick={()=>dispatch(showHelpDetails(''))} className="help-icon-float"><InfoIcon /></div>}
                    <ScheduleTestCaseEditor
                        formik={formik}
                        setAddRecurrenceOpen={setAddRecurrenceOpen}
                        addRecurrenceOpen={addRecurrenceOpen}
                        toggleRecurrenceOpen={toggleRecurrenceOpen}
                        setRecurrenceEditorState={setRecurrenceEditorState}
                        recurrenceEditorState={recurrenceEditorState}
                        testCaseData={testCaseData}
                        setTestCaseData={setTestCaseData}
                    />
                    </div>
                </Grid>
                
            )}


            {recurrenceOpen && (
                <Grid item xs={gridItemSize} paddingRight={'9px'} >
                    <PerpetualSchedule
                        formik={formik}
                        recurrenceFormik={recurrenceFormik}
                        setAddRecurrenceOpen={setAddRecurrenceOpen}
                        addRecurrenceOpen={addRecurrenceOpen}
                        toggleRecurrenceOpen={toggleRecurrenceOpen}
                        setRecurrenceEditorState={setRecurrenceEditorState}
                        recurrenceEditorState={recurrenceEditorState}
                        paramToEdit={paramToEdit}
                        setParamToEdit={setParamToEdit}
                        deletePerpetualSchedule={deletePerpetualSchedule}
                    />
                </Grid>
            )}
            {addRecurrenceOpen && (
                <Grid item xs={gridItemSize} paddingRight={'9px'} >
                    <RecurrenceEditor
                        formik={recurrenceFormik}
                        setAddRecurrenceOpen={setAddRecurrenceOpen}
                        addRecurrenceOpen={addRecurrenceOpen}
                        setRecurrenceEditorState={setRecurrenceEditorState}
                        recurrenceEditorState={recurrenceEditorState}
                        paramToEdit={paramToEdit}
                        setParamToEdit={setParamToEdit}
                        deletePerpetualSchedule={deletePerpetualSchedule}
                    />
                </Grid>
            )}
        </Grid>
    )
}
