import apiService from "./api.service";
import { IVariable } from "../types/interfaces/variable.interface";
import { CHANNELS } from "../types/global.enum";
import digitalApiService from "./digitalApi.service";

export const getTestResultList = async (projectId = 1, pageSize = 10, pageNo = 0, filter = '', startDate: any = '', stopDate: any = '', channel = CHANNELS.VOICE, sort = '') => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/results?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}&startDate=${startDate}&endDate=${stopDate}&sortBy=${sort}`)
        : await apiService.get(`/projects/${projectId}/results?pageNo=${pageNo}&pageSize=${pageSize}&filter=${filter}&startDate=${startDate}&endDate=${stopDate}&sortBy=${sort}`);
    return data;
}

export const getTestResultlistDetails = async (projectId = 0, resultId = 0, pageSize = 10, startDate: any = '', stopDate: any = '', channel = CHANNELS.VOICE) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/cases/${resultId}/results?pageSize=${pageSize}&startDate=${startDate}&endDate=${stopDate}`)
        : await apiService.get(`/projects/${projectId}/cases/${resultId}/results?pageSize=${pageSize}&startDate=${startDate}&endDate=${stopDate}`);
    return data;
}

export const getScheduledTestResultlistDetails = async (projectId = 0, archiveId = 0, channel = CHANNELS.VOICE) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/projects/${projectId}/schedules/${archiveId}/results`)
        : await apiService.get(`/projects/${projectId}/schedules/${archiveId}/results`);
    return data;
}

export const getTestResultDetails = async (resultId = 0, projectId = 0,channel = CHANNELS.VOICE) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.get(`/results/${resultId}`)
    : await apiService.get(`/results/${resultId}`);
    return data;
}

export const saveBatchComments = async (projectId = 0, archiveId = 0, batchComments = '', channel = CHANNELS.VOICE) => {
    const { data } = channel === CHANNELS.DIGITAL ? await digitalApiService.patch(`/projects/${projectId}/schedules/${archiveId}`, {remark: batchComments})
    : await apiService.patch(`/projects/${projectId}/schedules/${archiveId}`, {remark: batchComments});
    return data;
}
