
export const STORAGE_KEY = 'LENS_API_API';

export const getLensApi = () => {
    const data = localStorage.getItem(STORAGE_KEY);

    if (!data) {
        return null;
    };

    return JSON.parse(data);
}

export const getVoiceApi = () => getLensApi()?.voice || process.env.REACT_APP_ServerBaseURL;
export const getDigitalApi = () => getLensApi()?.digital || process.env.REACT_APP_Digital_ServerBaseURL;