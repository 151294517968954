import { createAction, createReducer } from "@reduxjs/toolkit";
import { IPrompt } from "../../types/interfaces/prompt.interface";

export const setCurrentPrompt = createAction<IPrompt[]>('setCurrentPrompt');
export const addPrompts = createAction<IPrompt[]>('addPrompts');
export const addSelectedPrompts = createAction<IPrompt[]>('addSelectedPrompts');
export const clearSelectedPrompts = createAction('clearSelectedPrompts');
export const removePrompts = createAction<string[]>('removePrompts');
export const updatePrompts = createAction<IPrompt[]>('updatePromptS');
export const upsertPrompt = createAction<string>('upsertPrompt');
export const setEditPrompt = createAction<any>('setEditPrompt');
export const setSelectedPrompts = createAction<IPrompt[]>('setSelectedPrompts');


export const promptReducer = createReducer<IPrompt[] | null>([], (builder) => {

    builder.addCase(setCurrentPrompt, (state: any, action) => {
        return {
            ...state,
            prompt: action.payload,
        }
    }).addCase(addPrompts, (state: any, action) => {
        return {
            ...state,
            prompt: (state?.prompt || []).concat(action.payload),
        }
    }).addCase(addSelectedPrompts, (state: any, action) => {
        return {
            ...state,
            selectedPrompts: (state?.selectedPrompts || []).concat(action.payload),
        }
    }).addCase(setSelectedPrompts, (state: any, action) => {
        return {
            ...state,
            selectedPrompts: action.payload,
        }
    }).addCase(clearSelectedPrompts, (state: any, action) => {

        return {
            ...state,
            selectedPrompts: [],
        }
    }).addCase(removePrompts, (state: any, action) => {

        return {
            ...state,
            prompt: state?.prompt.filter((c: IPrompt) => !action.payload.includes(c.id)),
        }
    }).addCase(updatePrompts, (state: any, action: any) => {
        return {
            ...state,
            prompt: (state.prompt || []).reduce((result: any, curr: any) => {
                if (curr.promptId === action.payload.promptId) {
                    result.push({
                        ...curr,
                        ...action.payload,
                    });
                } else {
                    result.push(curr);
                }

                return result;
            }, [])
        }
    }).addCase(setEditPrompt, (state: any, action) => {

        return {
            ...state,
            editPrompt: action.payload,
        }
    }).addCase(upsertPrompt, (state: any, action) => {

        return {
            ...state,
            upsertPrompt: action.payload,
        }
    })
})


