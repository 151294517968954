import axios from "axios";
import { toggleAlert } from "../store/resuders/app.reducer";
import { UserApi } from "./api.service";
import { IUser, IVAUser } from "../types/interfaces/user.interface";
import apiService from "./api.service";
import { getVoiceApi } from "./config.service";

export const getUSerDetails = async () => {
    const user = await UserApi.get(`/users/${getUserId()}`);

    return user?.data?.data;
}

export const updateUserProfile = async (formData: any, dispatch: any) => {

    const user = await UserApi.put(`/usersProfile`, { ...formData }).catch(e => e);

    if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST", "ERR_NETWORK"].includes(user?.code)) {
        dispatch(toggleAlert(user?.response?.data?.data?.messages?.join(',') || user?.message));
        return null;
    }
    return user?.data?.data;
}


export const changeUserPassword = async (formData: any, dispatch: any) => {

    // const result = await UserApi.patch(`/users/${getUserId()}/overridePassword`, { ...formData }).catch(e => e);
    const result = await UserApi.patch(`/users/changePassword`, { ...formData }).catch(e => e);

    if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST", "ERR_NETWORK"].includes(result?.code)) {
        dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',') || result?.message));
        return null;
    } else {
        return result?.data?.data;
    }    
}

export const doLogin = async (username: string, password: string, dispatch: any) => {
    const result: any = await axios.create({
        baseURL: getVoiceApi().replace('voice/v1', 'auth'),
        timeout: 30000,
    }).post(`/signin`, {
        username,
        password,
    }).catch(e => e);

    if (["ERR_BAD_RESPONSE", "ERR_BAD_REQUEST"].includes(result?.code)) {
        dispatch(toggleAlert(result?.response?.data?.data?.messages?.join(',')))
        return false;
    } else {
        setToken(result?.data?.data?.accessToken);
        setUserDetails(result?.data?.data);
        return true;
    }
}

export const isLoggedIn = () => localStorage.getItem('TOKEN');

export const doLogOut = () => {
    localStorage.removeItem('TOKEN');
}


const getUserId = () => {
    const data = localStorage.getItem('USER');
    if (data) {
        return JSON.parse(data)?.id;
    }
    return 0;
}

export const setUserDetails = (user: any) => {

    localStorage.setItem('USER', JSON.stringify(user));
}

export const setToken = (token: string) => {

    localStorage.setItem('TOKEN', token)
}

export const upsertUser = async (formData: IUser) => {
    if (formData.id) {
        return await axios.create({
            baseURL: getVoiceApi().replace('voice/v1', 'user/v1/'),
            timeout: 30000,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }).put(`/users/${formData.id}`, formData).catch(e => e);
    } else {
        return await axios.create({
            baseURL: getVoiceApi().replace('voice/v1', 'user/v1/'),
            timeout: 30000,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }).post(`/users`, formData).catch(e => e);
    }

}

export const deleteUsers = async (id: number[]) => {
    await Promise.all(
        id.map(async i => {

            await axios.create({
                baseURL: getVoiceApi().replace('voice/v1', 'user/v1/'),
                timeout: 30000,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
                }
            }).delete(`/users/${i}`).catch(e => e);

            return true;
        })
    )
}

export const resetUserPassword = async (id: any, newPassword: any) => {

    return await axios.create({
        baseURL: getVoiceApi().replace('voice/v1', 'user/v1/'),
        timeout: 30000,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
        }
    }).patch(`/users/${id}/overridePassword`, {
        'newPassword': newPassword
    }).catch(e => e);
}

export const getUserList = async (pageNo = 0, pageSize = 10, sort = '', filter = '') => {

    return await axios.create({
        baseURL: getVoiceApi().replace('voice/v1', 'user/v1/'),
        timeout: 30000,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
        }
    }).get(`/users?pageSize=${pageSize}&pageNo=${pageNo}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`).catch(e => e);
}

export const upsertVAUser = async (formData: IVAUser) => {
    if (formData.id) {
        return await apiService.put(`/virtualAgent/${formData.id}`, formData).catch(e => e);
    } else {
        return await apiService.post(`/virtualAgent`, formData).catch(e => e);
    }

}

export const getVAUserList = async (pageNo = 0, pageSize = 10, sort = '', filter = '') => {

   return await apiService.get(`/virtualAgent?pageSize=${pageSize}&pageNo=${pageNo}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`).catch(e => e);
}

export const deleteVAUsers = async (id: number[]) => {
    await Promise.all(
        id.map(async i => {

            await apiService.delete(`/virtualAgent/${i}`).catch(e => e);

            return true;
        })
    )
}