import apiService from "./api.service";
import { IPrompt } from "../types/interfaces/prompt.interface"

export const getPromptList = async (projectId = 1, pageSize = 10, pageNo = 0, sort = '', filter = '') => {
    const { data } = await apiService.get(`/projects/${projectId}/prompts?pageNo=${pageNo - 1}&pageSize=${pageSize}&filter=${filter}${sort ? '&sortBy=' + encodeURIComponent(sort) : ''}`);
    return data;
}

export const deletePrompt = async (projectId: number, promptIds: number[]) => {
    return await Promise.all(
        promptIds.map(async id => {
            if (id) {
                await apiService.delete(`projects/${projectId}/prompts/${id}`)
            }

            return null;
        })
    ).catch(e => e)

}

export const upsertPrompt = async (projectId: number, prompt: any, editorState: string) => {
    if (editorState == "create") {
        return await apiService.post(`/projects/${projectId}/prompts`, { ...prompt })
            .catch(e => e);
    } else {
        return await apiService.put(`/projects/${projectId}/prompts/${prompt.libraryID}`, { ...prompt })
            .catch(e => e);
    }
}