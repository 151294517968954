import * as React from 'react';
import { InputLabel, FormLabel, FormControl, Select, SelectChangeEvent, MenuItem, Box, TextField } from "@mui/material"
import { FormikProps } from "formik";
import { useTranslation } from 'react-i18next';


export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    steps: number[];
    language?: string;
}


export const NumberOfRunsEquals = ({ formik, steps = [1] }: IProps) => {
    // Access the formik values and helpers as needed
    const { values, handleChange } = formik;
    const { t } = useTranslation();

    return (
        <>
            <Box className='num-of-runs-equals'>
                <FormControl sx={{ width: "20%" }}>
                    <InputLabel id="case-editor-num-of-runs"></InputLabel>
                    <TextField
                        id="gotoValue"
                        name='gotoValue'
                        value={values?.gotoValue}
                        defaultValue={2}
                        label={t("# of Runs")}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl sx={{ width: "40%" }}>
                    <InputLabel id="case-editor-go-to-step">{t('Go to Step')} #</InputLabel>
                    <Select
                        labelId="case-editor-go-to-step"
                        id="gotoStepNo"
                        name='gotoStepNo'
                        value={values?.gotoStepNo}
                        label={t("Go to Step #")}
                        onChange={handleChange}
                    >
                        <MenuItem value={"First"}>First</MenuItem>
                        <MenuItem value={"Last"}>Last</MenuItem>
                        <MenuItem value={"Previous"}>Previous</MenuItem>
                        {steps.map(i => (
                            <MenuItem value={i} key={`step_${i}`}>{i}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ width: "40%" }}>
                    <InputLabel id="case-editor-else-go-to-step">{t('Else Go to Step')} #</InputLabel>
                    <Select
                        labelId="case-editor-else-go-to-step"
                        id="elseGoToStep"
                        name='elseGoToStep'
                        value={values?.elseGoToStep}
                        label={t("Else Go to Step #")}
                        onChange={handleChange}
                    >
                        <MenuItem value={"First"}>{t('First')}</MenuItem>
                        <MenuItem value={"Last"}>{t('Last')}</MenuItem>
                        <MenuItem value={"Previous"}>{t('Previous')}</MenuItem>
                        {steps.map(i => (
                            <MenuItem value={i} key={`else_step_${i}`}>{i}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </>
    );
};
