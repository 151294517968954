import { styled, Theme, CSSObject } from '@mui/material/styles';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import { useEffect, useMemo, useState } from "react";
import { arrowIcon, drawerCloseIcon, drawerIcon, globalIcon, logoutIcon, projectIcon, runnerIcon, globalLibraryIcon, help, helpcenter, helpsummary, userIcon, virtualAgentIcon, profile } from '../assets';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentProject } from '../store/selectors/project.selector';
import { useTranslation } from 'react-i18next';
import { doLogOut } from '../services/user.service';
import { useAppDispatch } from '../store/hooks';
import { loadHelpCenter, showHelpDetails } from '../store/resuders/app.reducer';
import { HELP_CENTER } from '../types/global.enum';
import { getHelpCenter, getHelpCenterByKey } from '../store/selectors/app.selector';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const drawerWidth = 329;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `61px`,
    [theme.breakpoints.up('sm')]: {
        width: `61px`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const LeftBar = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [showSublist, setShowSublist] = useState(false);
    const [showAdminSublist, setShowAdminSublist] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showVirtualAgent, setShowVirtualAgent] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const project = useSelector(getCurrentProject);
    const [currentPage, setCurrentPage] = useState('');
    const dispatch = useAppDispatch();
    const helpCenterSelector = useSelector(getHelpCenter);
    const helpCenterDetails = useSelector(getHelpCenterByKey);
    
    const openLink = (_open: boolean, link: string) => () => {
        if(link === 'users') setShowUser(true);        
        if (!open && _open) {
            setOpen(_open)
        } else {
            history(link);
            setOpen(false);
            window.location.reload(); // Refresh the page
        }
    }

    const isActive = (path: string) => {
        return location.pathname === path;
    };


    useEffect(() => {
        setShowSublist((project?.testProjectID || 0) >0);
        setCurrentPage(location.pathname);
    }, [project]);

    // useEffect(()=>{
    //     // setCurrentPage(location.pathname);
    // },[]);



    const handleDrawerMouseLeave = () => {
        setOpen(false);
    };

    const logout = () => {
        doLogOut();
        document.location.href = "/login";
    }

    const showHelp = ()=> {
        dispatch(showHelpDetails(''));
        handleDrawerMouseLeave();
        dispatch(loadHelpCenter(HELP_CENTER.PROJECT));
    }

    const closeHelp = (e:any)=> {
        e.preventDefault();
        e.stopPropagation();
        dispatch(loadHelpCenter(HELP_CENTER.NONE));
        dispatch(showHelpDetails(''));
    }

    const isHome = useMemo(()=>helpCenterSelector && document.location.pathname.endsWith('projects') ,[helpCenterSelector])
    const isTestCase = useMemo(()=>helpCenterSelector && document.location.pathname.endsWith('testcases'), [helpCenterSelector]);
    const isScheduler = useMemo(()=>helpCenterSelector && document.location.pathname.endsWith('scheduler'), [helpCenterSelector]);
    const isTestResult = useMemo(()=>helpCenterSelector && document.location.pathname.endsWith('testresults'), [helpCenterSelector]);

    return (
        <>
            <div className={!open ? 'floating-menu' : 'floating-menu-open'}>

                {open ? (
                    <img src={drawerCloseIcon} alt="" className='close-drawer' />
                ) : (
                    <>
                        <img src={drawerIcon} alt="" />
                        <div className='arrow-icon'>
                            <img src={arrowIcon} alt="" />
                        </div>
                    </>
                )}
            </div>

            <Drawer variant="permanent" open={open} onMouseLeave={handleDrawerMouseLeave} onMouseEnter={() => {
                if(!helpCenterSelector) {
                    setOpen(!open);
                }
            }}> {isHome && <div onClick={()=>dispatch(showHelpDetails('Navigation Panel'))} className="menu-helper help-icon"><InfoIcon /></div>}
                {isTestCase && <div onClick={()=>dispatch(showHelpDetails('Navigation Panel'))} className="menu-helper help-icon"><InfoIcon /></div>}
                {isScheduler && <div onClick={()=>dispatch(showHelpDetails('Navigation Panel'))} className="menu-helper help-icon"><InfoIcon /></div>}
                {isTestResult && <div onClick={()=>dispatch(showHelpDetails('Navigation Panel'))} className="menu-helper help-icon"><InfoIcon /></div>}

                <List sx={{ mt: 2, position: 'relative', height: '86%' }}>
                    <ListItem disablePadding sx={{ display: 'block' }} className={["/", "/projects"].includes(location.pathname) ? "active" : ""}>
                        <ListItemButton
                            onClick={openLink(true, '/projects')}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={projectIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText primary={t('Project')} sx={{ opacity: open ? 1 : 0 }} />
                            {open && (<KeyboardArrowRightIcon />)}
                        </ListItemButton>
                        {showSublist && (
                            <>
                                <ListItem disablePadding sx={{ display: 'block', }} className={["/projects/test-cases", "/testcase"].includes(location.pathname) ? "active" : ""}>
                                    <ListItemButton
                                        // onClick={openLink(false, '/projects/test-cases')}
                                        onClick={openLink(false, `/projects/${project?.testProjectID}/testcases`)}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: currentPage.includes(`/projects/${project?.testProjectID}/testcases`) ? '#006A68' : '#E9EFEE',
                                        }}

                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                paddingLeft: open ? "20px" : "0px"
                                            }}
                                        >
                                            <ScienceOutlinedIcon style={{ color: currentPage.includes(`/projects/${project?.testProjectID}/testcases`) ? 'white' : undefined }} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Test Cases')} style={{ color: currentPage.includes(`/projects/${project?.testProjectID}/testcases`) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                                        {open && showSublist && (<KeyboardArrowRightIcon />)}
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'block' }} className="">
                                    <ListItemButton
                                        onClick={openLink(false, `/projects/${project?.testProjectID}/scheduler`)}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: currentPage.includes(`/projects/${project?.testProjectID}/scheduler`) ? '#006A68' : '#E9EFEE',
                                        }}

                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                paddingLeft: open ? "20px" : "0px"
                                            }}
                                        >
                                            <AlarmOutlinedIcon style={{ color: currentPage.includes(`/projects/${project?.testProjectID}/scheduler`) ? 'white' : undefined }} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Scheduler')} style={{ color: currentPage.includes(`/projects/${project?.testProjectID}/scheduler`) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                                        {open && showSublist && (<KeyboardArrowRightIcon />)}
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: [`/projects/${project?.testProjectID}/testresults`, `/projects/${project?.testProjectID}/scheduled/testresults`].find((data:any) => currentPage.startsWith(data)) ? '#006A68' : '#E9EFEE',
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                paddingLeft: open ? "20px" : "0px"
                                            }}
                                        >
                                            <InventoryOutlinedIcon style={{ color: [`/projects/${project?.testProjectID}/testresults`, `/projects/${project?.testProjectID}/scheduled/testresults`].find((data:any) => currentPage.startsWith(data)) ? 'white' : undefined }}/>
                                        </ListItemIcon>
                                        <ListItemText primary={t('Test Results')} style={{ color: [`/projects/${project?.testProjectID}/testresults`, `/projects/${project?.testProjectID}/scheduled/testresults`].find((data:any) => currentPage.startsWith(data)) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                                        {open && showSublist && (<KeyboardArrowRightIcon />)}
                                    </ListItemButton>
                                    {/* Test Result SubMenu */}
                                    <ListItem disablePadding sx={{ display: 'block' }} className="">
                                        <ListItemButton
                                            onClick={openLink(false, `/projects/${project?.testProjectID}/scheduled/testresults`)}
                                            sx={{
                                                minHeight: 48,
                                                px: 2.5,
                                                paddingLeft: open ? "80px" : "0px"
                                            }}
                                        >
                                            <ListItemText primary={t("Scheduled Test Runs")} sx={{ opacity: open ? 1 : 0 }}/>
                                            {open && (<KeyboardArrowRightIcon />)}
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding sx={{ display: 'block' }} className="">
                                        <ListItemButton
                                            onClick={openLink(false, `/projects/${project?.testProjectID}/testresults`)}
                                            sx={{
                                                minHeight: 48,
                                                px: 2.5,
                                                paddingLeft: open ? "80px" : "0px"
                                            }}
                                        >
                                            <ListItemText primary={t("Manual Test Runs")} sx={{ opacity: open ? 1 : 0 }}/>
                                            {open && (<KeyboardArrowRightIcon />)}
                                        </ListItemButton>
                                    </ListItem>
                                </ListItem>
                                {/* <ListItem disablePadding sx={{ display: 'block' }} className="">
                                    <ListItemButton
                                        onClick={openLink(false, `/projects/${project?.testProjectID}/testresults`)}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: currentPage.includes(String(`/projects/${project?.testProjectID}/testresults`)) ? '#006A68' : '#E9EFEE',
                                        }}

                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                paddingLeft: open ? "20px" : "0px"
                                            }}
                                        >
                                            <InventoryOutlinedIcon style={{ color: currentPage.includes(String(`/projects/${project?.testProjectID}/testresults`)) ? 'white' : undefined }} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Test Results')} style={{ color: currentPage.includes(String(`/projects/${project?.testProjectID}/testresults`)) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                                        {open && showSublist && (<KeyboardArrowRightIcon />)}
                                    </ListItemButton>
                                </ListItem> */}
                            </>
                        )}
                                                
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={openLink(false, '/users')}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: currentPage.includes(`/users`) ? '#006A68' : '#E9EFEE'
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={userIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText primary="Admin" style={{ color: currentPage.includes(`/users`) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                            {open && showUser && (<KeyboardArrowRightIcon />)}

                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={openLink(false, '/VAUsers')}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: currentPage.includes(`/VAUsers`) ? '#006A68' : '#E9EFEE'
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={virtualAgentIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText primary="Virtual Agent" style={{ color: currentPage.includes(`/VAUsers`) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                            {open && showVirtualAgent && (<KeyboardArrowRightIcon />)}

                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={openLink(false, '/profile')}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: currentPage.includes(`/profile`) ? '#006A68' : '#E9EFEE'
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={profile} alt="" />
                            </ListItemIcon>
                            <ListItemText primary="Profile" style={{ color: currentPage.includes(`/profile`) ? 'white' : undefined }} sx={{ opacity: open ? 1 : 0 }} />
                            {open && showVirtualAgent && (<KeyboardArrowRightIcon />)}

                        </ListItemButton>
                    </ListItem>
                    
                    {/* <ListItem disablePadding sx={{ display: 'block', mt: 2 }} className="">
                        <ListItemButton onClick={() => setOpen(true)}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={globalLibraryIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText primary={t('Global Library')} sx={{ opacity: open ? 1 : 0 }} />
                            {open && (<KeyboardArrowRightIcon />)}
                        </ListItemButton>
                    </ListItem> */}
                    <div className="leftbar-settings-bar">
                        <div className={`pointer ${helpCenterSelector ? 'help-center-icon':''}`} onClick={showHelp}>
                            <img src={helpCenterSelector ? helpcenter:help} alt="help"/>

                            {!helpCenterDetails && helpCenterSelector && <div className='help-center-summary'>
                                    <div className='tip-header'>Hi! I am the Tips and Helps bot from TekVision! <div onClick={closeHelp}  className='close-help-center'><CloseIcon /></div></div>
                                    <div className="tip-details">You are now at the Project List page. This is the landing page help you start a new project or selecting an existing project</div>
                                </div>}
                        </div>
                        {/* <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={() => setOpen(true)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SettingsOutlinedIcon style={{ fontSize: '30px' }} />
                                </ListItemIcon>
                                <ListItemText primary={t('Settings')} sx={{ opacity: open ? 1 : 0 }} />
                                {open && (<KeyboardArrowRightIcon />)}
                            </ListItemButton>
                        </ListItem> */}
                        {/* <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={() => setOpen(true)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SupportOutlinedIcon style={{ fontSize: '30px' }} />
                                </ListItemIcon>
                                <ListItemText primary={t('Help & Getting Started')} sx={{ opacity: open ? 1 : 0 }} />
                                {open && (<KeyboardArrowRightIcon />)}
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={logout}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={logoutIcon} alt="" />
                                </ListItemIcon>
                                <ListItemText primary={t('Log Out')} sx={{ opacity: open ? 1 : 0 }} />
                                {open && (<KeyboardArrowRightIcon />)}
                            </ListItemButton>
                        </ListItem>
                    </div>
                </List>
            </Drawer>
        </>
    )
}