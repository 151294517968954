import { Button, Card, CardContent, Grid, IconButton, Link, Typography } from "@mui/material";
import { GLOBAL_ACTION } from "../types/global.enum";
import { useEffect, useState } from "react";
import { deleteIcon, editFilledIcon, users } from "../assets";
import { Modal, SearchInput } from "../ui";
import { useTranslation } from "react-i18next";
import { deleteVAUsers, getVAUserList } from "../services/user.service";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AddVAUser } from "./addVAuser";
import { CollapseDataGrid } from "../ui/collapse.table.ui";
import { globalNotification, toggleAlert } from "../store/resuders/app.reducer";
import { useAppDispatch } from "../store/hooks";
import { getAllProjectList } from "../services/project.service";

export const VirtualUserManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [action, setAction] = useState<GLOBAL_ACTION>(GLOBAL_ACTION.NONE);
  const [edit, setEdit] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [selectedVAUser, setSelectedVAUser] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<string>('');
  const [projectList, setProjectList] = useState<any>([]);

  const [criteria, setCriteria] = useState<any>({
    sort: "-agentName",
    pageNo: 0,
    pageSize: 10,
    filter: "",
  });

  const columns: any[] = [
    {
      caption: t("Agent Name"),
      width: "20%",
      fieldName: "agentName",
      sortable: true,
      redner: (params: any) => (
        <div className="flex-center">
          <Link onClick={editUser(params)} className="a-link-black">
            {params.agentName}
          </Link>
        </div>
      ),
    },
    {
      caption: t("Agent ID"),
      width: "60%",
      fieldName: "agentUsername",
      sortable: true,
      redner: (params: any) => (
          <div className="flex-center">
              {params.agentUsername}
          </div>
        ),
    },
    {
      caption: t("Extend"),
      sortable: true,
      width: "15%",
    },
  ];

  const sortChanged = (column: string, direction: string) => {
    setCriteria({
      ...criteria,
      pageNo: 0,
      sort: `${direction === "ASC" ? "+" : "-"}${column}`,
    });
  };

  const pageChanged = (page: number) => {
    setCriteria({
      ...criteria,
      pageNo: page - 1,
    });
  };

  const onFilterChanged = (filter: string) => {
    setCriteria({
      ...criteria,
      pageNo: 0,
      filter,
    });
  };

  const pageSizeChanged = (size: number) => {
    setCriteria({
      ...criteria,
      pageSize: size,
    });
  };
  const loadData = async () => {
    const { data } = await getVAUserList(criteria.pageNo, criteria.pageSize, criteria.sort, criteria.filter);
    setData(data?.data);
    const projectData = await getAllProjectList();
    setProjectList(projectData?.data || []);
  };

  useEffect(() => {
    loadData();
  }, [criteria]);

  const updateAction = () => {
    switch (action) {
      case GLOBAL_ACTION.NONE: {
        setAction(GLOBAL_ACTION.ADD);
        break;
      }

      default: {
        setAction(GLOBAL_ACTION.NONE);
        setEdit(null);
      }
    }
  };

  const selectionChanged = (id: any) => {
    setSelectedVAUser(id);
  };

  const editUser = (data: any) => () => {
    setEdit(data);
    setAction(GLOBAL_ACTION.EDIT);
  };

  const onDone = async () => {
    dispatch(globalNotification(edit ? t('Virtual Agent is updated') :t('Virtual Agent is Created')));
    setAction(GLOBAL_ACTION.NONE);
    setEdit(null);
    await loadData();
  };

  const deleteSelected = async () => {
    if (selectedVAUser?.length) {
      setIsOpen(`${new Date().getTime()}`);
    }
  };

  const deleteVAUser = async () => {
    try {
        await deleteVAUsers(selectedVAUser);
        dispatch(globalNotification(t('Virtual Agent is deleted')));
        await loadData();
        setSelectedVAUser([]);
    } catch (error) {
        dispatch(toggleAlert("Users deletion failed")); // Dispatch toggleAlert action with an error alert
    }
  }

  const editSelected = () => {
    if(selectedVAUser?.length < 1) {
      dispatch(toggleAlert(t('Select Virtual Agent first')));
    } else if(selectedVAUser?.length > 1) {
      dispatch(toggleAlert(t('Select one Virtual Agent to edit')));
    } else {
      const value = data?.content?.find((d: any) => d.id === selectedVAUser[0]);
      setEdit(value);
      setAction(GLOBAL_ACTION.EDIT);
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container>
        <Grid
          item
          xs={12}
          className="flex justify-end align-center gap-8"
          display='flex'
          justifyContent='space-between'
          marginBottom={"16px"}
        >
          <div className="flex-center mb-16 pl-20 mt-16">
            <img src={users} alt="" /> 
            <Typography variant="subtitle2">{t('Admin')} </Typography>
            <Typography variant="subtitle2">{'>'}</Typography>
            <Typography variant="subtitle2">{t('Virtual Agent')}</Typography>
          </div>
          <Button
            variant={action !== GLOBAL_ACTION.NONE ? "outlined" : "contained"}
            color="primary"
            onClick={updateAction}
          >
            {t(action !== GLOBAL_ACTION.NONE ? t("Close") : t("Add Agent"))}
          </Button>
        </Grid>

        <Grid item xs={action !== GLOBAL_ACTION.NONE ? 6 : 12}>
          <Card className="h-full">
            <CardContent>
              <div className="action-header">
                {/* <img src={lockIcon} alt="" /> */}
                <img src={editFilledIcon} alt="Edit" onClick={editSelected} />
                <img src={deleteIcon} alt="Remove" onClick={deleteSelected} />
                {/* <img src={downloadIcon} alt="" /> */}
                <div className="flex-end">
                  <SearchInput onFilter={onFilterChanged} />
                </div>
              </div>

              <CollapseDataGrid
                pageChanged={pageChanged}
                projectList={projectList}
                totalPages={data?.totalPages || 0}
                sortChanged={sortChanged}
                columns={columns}
                data={data?.content || []}
                selection={true}
                sortBy={criteria.sort}
                selectionChanged={selectionChanged}
                pageSizeChanged={pageSizeChanged}
              />
            </CardContent>
          </Card>
        </Grid>

        {action !== GLOBAL_ACTION.NONE && (
          <Grid item xs={6} paddingLeft={1}>
            <AddVAUser data={edit} projectList={projectList} onDone={onDone} />
          </Grid>
        )}

          <Modal
            isOpen={isOpen}
            title="Delete Virtual Agent"
            small={true}
            deleteConfirmation={true}
            ButtonDelete={deleteVAUser}
          />
      </Grid>
    </DndProvider>
  );
};
