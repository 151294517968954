import { useEffect } from "react"
import { ssoApi } from "../../services/api.service"; 
import { setToken, setUserDetails } from "../../services/user.service";

export const CallBack = ()=> {
    const getToken = async (id:string)=> {
        if(!id) {
            document.location.href = "/login";
        } else {
            const result = await ssoApi.get(`/auth/getToken?id=${id}`);
            setToken(result?.data?.data?.accessToken);
            setUserDetails(result?.data?.data);
            document.location.href = "/";
        }
    }

    useEffect(()=> {
        const id = window.location.search.replace('?id=','');
        getToken(id);
    },[])

    return null;
}