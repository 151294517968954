import { createAction, createReducer } from "@reduxjs/toolkit";
import { ISchedule } from "../../types/interfaces/schedule.interface";

export const setCurrentSchedule = createAction<ISchedule[]>('setCurrentSchedule');
export const addSchedules = createAction<ISchedule[]>('addSchedules');
export const addSelectedSchedules = createAction<ISchedule[]>('addSelectedSchedules');
export const clearSelectedSchedules = createAction('clearSelectedSchedules');
export const removeSchedules = createAction<string[]>('removeSchedules');
export const updateSchedules = createAction<ISchedule[]>('updateSchedules');
export const upsertSchedule = createAction<string>('upsertSchedule');
export const setEditSchedule = createAction<any>('setEditSchedule');
export const addTestCasesToSchedule = createAction<any>('addTestCasesToSchedule');
export const clearScheduleTestCases = createAction('clearScheduleTestCases');
export const setScheduleTime = createAction<any>('setScheduleTime');
export const addScheduleTime = createAction('addScheduleTime');

export const scheduleReducer = createReducer<ISchedule[] | null>([], (builder) => {

    builder.addCase(setCurrentSchedule, (state: any, action) => {
        return {
            ...state,
            schedule: action.payload,
        }
    }).addCase(addSchedules, (state: any, action) => {
        return {
            ...state,
            schedule: (state?.schedule || []).concat(action.payload),
        }
    }).addCase(addSelectedSchedules, (state: any, action) => {
        return {
            ...state,
            selectedSchedules: (state?.selectedSchedules || []).concat(action.payload),
        }
    }).addCase(clearSelectedSchedules, (state: any, action) => {

        return {
            ...state,
            selectedSchedules: [],
        }
    }).addCase(removeSchedules, (state: any, action) => {

        return {
            ...state,
            schedule: state?.schedule.filter((c: ISchedule) => !action.payload.includes(String(c.testScheduleId))),
        }
    }).addCase(updateSchedules, (state: any, action: any) => {
        return {
            ...state,
            schedule: (state.schedule || []).reduce((result: any, curr: any) => {
                if (curr.scheduleId === action.payload.scheduleId) {
                    result.push({
                        ...curr,
                        ...action.payload,
                    });
                } else {
                    result.push(curr);
                }

                return result;
            }, [])
        }
    }).addCase(setEditSchedule, (state: any, action) => {

        return {
            ...state,
            editSchedule: action.payload,
        }
    }).addCase(upsertSchedule, (state: any, action) => {

        return {
            ...state,
            upsertSchedule: action.payload,
        }
    }).addCase(addTestCasesToSchedule, (state: any, action) => {

        return {
            ...state,
            scheduleTestCases: (state?.scheduleTestCases || []).concat(action.payload)
        }
    }).addCase(clearScheduleTestCases, (state: any, action) => {

        return {
            ...state,
            scheduleTestCases: [],
        }
    }).addCase(setScheduleTime, (state: any, action) => {

        return {
            ...state,
            scheduleTime: action.payload,
        }
    }).addCase(addScheduleTime, (state: any, action) => {

        return {
            ...state,
            scheduleTime: (state?.scheduleTime || []).concat(action.payload)
        }
    })
})

