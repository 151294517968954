import React, { useMemo } from "react";
import Chart from "react-apexcharts";

export const ChartComponent = ({
    type = 'pie' as "line"
    | "area"
    | "bar"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "boxPlot"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | "rangeArea"
    | "treemap",
    data = [] as {value:number,label:string,color?:string} [],
    dataLabel = false,
    legend = false,
    stacked = false as boolean,
    height = '',
    width='500',
})=> {
    const options = useMemo(()=> {
        let option = {
            dataLabels: {
                enabled: dataLabel,
            },
            legend: {
                show: legend,
            },
        } as any;

        if(data.every(c=>c.color)) {
            option = {
                ...option,
                colors: data.map(d=>d.color),
            }
        }
        if(type === 'bar' && stacked) {
            return  { 
                ...option,
                chart: {
                    stacked:stacked,
                    id: "basic-bar",
                    type:type,
                    toolbar: {
                        show: false,
                      },
                },
                plotOptions: {
                    bar: {
                      horizontal: true
                    }
                  },
                  grid: {
                    show: false,
                  },
                  xaxis: {
                    show: false,
                    labels: {
                      show: false
                    },
                    axisBorder: {
                      show: false
                    },
                    axisTicks: {
                      show: false
                    }
                  },
                  yaxis: {
                    show: false,
                    labels: {
                      show: false,
                    },
                    axisBorder: {
                        show: false,
                      },
                      axisTicks: {
                        show: false,
                      },
                      crosshairs: {
                        show: false,
                      },
                  },
            }
        } else if(type === 'pie') {
                return {
                    ...option,
                    labels: data.map(d=>d.label) || [],
                }
        } else {
            return  { 
                ...option,
                chart: {
                    stacked:stacked,
                    id: "basic-bar"
                },
                xaxis: {
                    categories: data.map(d=>d.label) || [],
                },
                
            }
        }

    },[type, data, stacked]);

    const series = useMemo(()=> {
        if(type === 'bar' && stacked) {
            return data.map(d=>({
                name:d.label,
                data:[d.value],
            }));
        } else if(type === 'pie') {
            return  data.map(d=>d.value) || [];
        } else {
            return  [
                {
                  name: "series-1",
                  data: data.map(d=>d.value), 
                }
              ] as any;
        }

    },[type, data, stacked]);

    return (
            <Chart
              options={options}
              series={series}
              type={type}
              width={width}
              height={height}
            />
    )
}