import axios from "axios";
import { getDigitalApi } from "./config.service";
import { getToken } from "./api.service";


export default axios.create({
    baseURL: getDigitalApi(),
    //withCredentials: true,
    timeout: 30000,
    ...getToken(),
});
