import { Grid, Card, CardContent, Typography, Box, FormControl, InputLabel, TextField, Button, Divider, Link } from "@mui/material";
import { TestCaseList } from "./testCaseList";
import CloseIcon from '@mui/icons-material/Close';
import { ChartComponent } from "../charts/chart";
import { formatTimestamp } from "../../helpers/date.helper";
import { useEffect, useState } from "react";
import { saveBatchComments } from "../../services/testresults.service";
import { useSelector } from "react-redux";
import { getCurrentProject } from "../../store/selectors/project.selector";
import { globalNotification } from "../../store/resuders/app.reducer";
import { useAppDispatch } from "../../store/hooks";
import { useTranslation } from "react-i18next";
export interface IProps {
    showCloseButton?: boolean
    closeBatchDetails?: () => void;
    projectId : any;
    testcaseId : any;
    selectedBatch?: any;
    openTestResultsDetailsView: () => void;
    reloadBatchList: () => void;
}

export const BatchDetails = ({
    showCloseButton = false,
    closeBatchDetails = () => {},
    projectId,
    testcaseId,
    selectedBatch = {},
    openTestResultsDetailsView,
    reloadBatchList
}: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const project = useSelector(getCurrentProject);
    const [batchComments, setBatchComments] = useState<any>('');

    useEffect(()=>{
        setBatchComments(selectedBatch?.remarks || '');
    },[]);

    const chartData = [
        {
            value: selectedBatch?.success || 0,
            label: "",
            color: "#58A148"
        },
        {
            value: selectedBatch?.warning || 0,
            label: "",
            color: "#F9C950"
        },
        {
            value: selectedBatch?.failed || 0,
            label: "",
            color: "#FD5900"
        },
    ];

    const calculateDuration = (startDate: any, endDate: any) => {
        const startTime = new Date(startDate);
        const endTime = new Date(endDate);
        const durationMilliseconds = endTime.getTime() - startTime.getTime();
        const durationInSeconds = durationMilliseconds / 1000;
        if (durationInSeconds < 0) {
            return `0 secs`;
        } else {
            return `${durationInSeconds.toFixed(2)} secs`;
        }
    }

    const handleBatchCommentChange = (event: any) => {
        setBatchComments(event.target.value);
    }

    const handleBatchCommentSave = async () => {
        await saveBatchComments(projectId, selectedBatch?.archiveId, batchComments, project?.data?.channelType);
        reloadBatchList();
        dispatch(globalNotification('Comments Updated.'))
    }


    return (
        <Card className="h-100 sticky-top">
            <CardContent>                            
                <div className="form-header">
                    <Typography variant="subtitle1">{selectedBatch?.scheduleName}</Typography>
                    { showCloseButton && (
                        <Box position='absolute' right={0} marginRight='5px'>
                            <Link onClick={closeBatchDetails}>
                                < CloseIcon style={{color : 'white'}} />
                            </Link>
                        </Box>
                    )}                                            
                </div>
                <div style={{ maxHeight: 'calc(100vh - 188px)', display: "flex", flexDirection: 'column', alignItems: 'stretch', backgroundColor: "#F4F4F4", paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '20px', overflow: 'auto'}}>
                    <Grid container gap={2} style={{}}>
                        <Grid item xs={12}>
                            <Typography fontWeight={500} fontSize='20px' lineHeight='19.5px' color="#006A68">SUMMARY</Typography>
                        </Grid>
                        <Grid item xs={12} padding='10px'>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize='16px' lineHeight='16px' color='black' fontWeight={500} padding={'5px'}>{t("Schedule Name")}: {selectedBatch?.scheduleName}</Typography>
                                    <Typography fontSize='16px' lineHeight='16px' color='black' fontWeight={500} padding={'5px'}>{t("Start Time")}: {formatTimestamp(selectedBatch?.runStartDate)}</Typography>
                                    <Typography fontSize='16px' lineHeight='16px' color='black' fontWeight={500} padding={'5px'}>{t("Duration")}: {calculateDuration(selectedBatch?.runStartDate, selectedBatch?.runEndDate)}</Typography>
                                </Grid>
                                <Grid item xs={6}> 
                                    <Typography fontSize='16px' lineHeight='16px' color='black' fontWeight={500} padding={'5px'}>{t("Number Ports")}: {selectedBatch?.voicePort}</Typography>
                                    <Typography fontSize='16px' lineHeight='16px' color='black' fontWeight={500} padding={'5px'}>{t("Stop Time")}: {formatTimestamp(selectedBatch?.runEndDate)}</Typography>
                                    <Typography fontSize='16px' lineHeight='16px' color='black' fontWeight={500} padding={'5px'}>{t("Type")}: {selectedBatch?.scheduleType}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} padding='10px'>
                            <Grid container>
                                <div style={{ width: '100%', display: 'flex', gap: '20px', alignItems: 'center' }}>
                                    <FormControl sx={{ width: "80%", backgroundColor: 'white' }}>
                                        <InputLabel id="case-editor-segment-name"></InputLabel>
                                        <TextField
                                            id="comments"
                                            label={t("Comments")}
                                            name='comments'
                                            value={batchComments}
                                            onChange={handleBatchCommentChange}
                                        />
                                    </FormControl>
                                    <Button style={{width: "20%"}} variant={'contained'} color="primary" onClick={handleBatchCommentSave}>
                                        {t("Save")}
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <div style={{height: '220px', borderRadius: '8px', backgroundColor: 'white', margin: '10px 70px 10px 70px'}}>
                                <Grid container style={{height: '100%'}}>
                                    <Grid item xs={6}>
                                        <div style={{height: '100%', display: 'flex', justifyContent: 'center',  flexDirection: 'column', marginLeft: '50px', marginRight: '50px'}}>
                                            <div style={{display: 'flex',  flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}>
                                                <div style={{display: 'flex'}}>
                                                    <div className="last-run-status-filled-circle last-run-status-filled-green-circle"></div>
                                                    <Typography style={{marginLeft: '30px'}} color='#58A148'>{t("PASS")}</Typography>
                                                </div>
                                                <div><Typography>{selectedBatch?.success || 0}</Typography></div>                                                                                                        
                                            </div>
                                            <div style={{display: 'flex',  flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}>
                                                <div style={{display: 'flex'}}>
                                                    <div className="last-run-status-filled-circle last-run-status-filled-yellow-circle"></div>
                                                    <Typography  style={{marginLeft: '30px'}} color='#F9C950'>{t("WARNING")}</Typography>
                                                </div>                                                                                    
                                                <Typography>{selectedBatch?.warning || 0}</Typography>
                                            </div>
                                            <div style={{ display: 'flex',   flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}>
                                                <div style={{display: 'flex'}}>
                                                    <div className="last-run-status-filled-circle last-run-status-filled-red-circle"></div>
                                                    <Typography style={{marginLeft: '30px'}} color='#FD5900'>{t("FAIL")}</Typography>
                                                </div>                                                      
                                                <Typography>{selectedBatch?.failed || 0}</Typography>
                                            </div>
                                            <div style={{display: 'flex',   flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}> 
                                                <div style={{display: 'flex'}}>
                                                    <div className="last-run-status-filled-circle"></div>
                                                    <Typography style={{marginLeft: '30px'}}>{t("Remaining")}</Typography>
                                                </div>                                                                                            
                                                <Typography>{selectedBatch?.total - (selectedBatch?.success + selectedBatch?.warning + selectedBatch?.failed) || 0}</Typography>
                                            </div>
                                            <div style={{display: 'flex',   flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px'}}> 
                                                <div style={{display: 'flex'}}>
                                                    <div className="last-run-status-filled-circle"></div>
                                                    <Typography style={{marginLeft: '30px'}}>{t("All")}</Typography>
                                                </div>                                                                                            
                                                <Typography>{selectedBatch?.total || 0}</Typography>
                                            </div>
                                        </div>                                    
                                    </Grid>
                                    <Grid item xs={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <ChartComponent data={chartData} height="200px" width="200px"/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Divider  style={{ width: '100%', height: '1.5px', backgroundColor: "#006A68"}}/>
                        <Grid item xs={12}>
                            <TestCaseList projectId={projectId} testcaseId={selectedBatch?.archiveId} openTestResultsDetailsView={openTestResultsDetailsView}/>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>

    );
}