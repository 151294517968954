import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import {
    InputLabel,
    FormControl,
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';

export interface IProps {
    formik: FormikProps<any>;
    segmentName?: string;
    type?: string;
    language?: string;
}

interface Attribute {
    attributeName: string;
    expectValue: string;
}

export const GetCallData = ({ formik }: IProps) => {
    const [attributes, setAttributes] = useState<Attribute[]>(!formik.values?.participantDataActionAttributeNames?.length ? [] : formik.values?.participantDataActionAttributeNames);
    const { values, handleChange, setFieldValue } = formik;
    const { t } = useTranslation();

    // Add Attribute/Expect Value to the attributes array
    const handleAddAttribute = () => {
        setAttributes([...attributes, { attributeName: '', expectValue: '' }]);
    };

    // Remove Attribute/Expect Value to the attributes array
    const handleRemoveAttribute = (index: number) => {
        const updatedAttributes = attributes.filter((_, i) => i !== index);
        setAttributes(updatedAttributes);

        // Clear the values of the removed attribute from the formik values
        setFieldValue(`attributeName-${index}`, ''); // Clear attribute name value
        setFieldValue(`expectValue-${index}`, '');   // Clear expect value
    };

    const handleChanges = (key: string, index: number) => (e: any) => {
        setAttributes(
            attributes.reduce((res: any[], curr: any, i: number) => {
                if (i === index) {
                    res.push({
                        ...curr,
                        [key]: e.target.value,
                    })
                } else {
                    res.push(curr);
                }
                return res;
            }, []),
        )
    }

    useEffect(() => {
        let res = {} as any;
        attributes.forEach(a => {
            if (a.attributeName && a.expectValue) {
                res = {
                    ...res,
                    [a.attributeName]: a.expectValue,
                };
            }
        });

        formik.setFieldValue('participantDataActionAttributeNames', res);

    }, [attributes]);

    return (
        <>
            {attributes?.map((attribute, index) => (
                <Box className='segment-editor-attribute-fields' key={index}>
                    <FormControl sx={{ width: '40%' }}>
                        <TextField
                            value={attribute?.attributeName}
                            label={t('Attribute Name')}
                            onChange={handleChanges('attributeName', index)}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '40%' }}>
                        <TextField
                            value={attribute?.expectValue}
                            label={t('Expect Value')}
                            onChange={handleChanges('expectValue', index)}
                        />
                    </FormControl>
                    <HighlightOffIcon style={{ fill: '#006A68', fontSize: '25px' }} onClick={() => handleRemoveAttribute(index)} />
                </Box>
            ))}

            <Box className='segment-editor-remark'>
                <div className='add-attribute' onClick={handleAddAttribute}>
                    <div>
                        <AddCircleOutlineIcon
                            style={{ fill: '#006A68', fontSize: '25px' }}
                        />
                    </div>
                    <div >
                        <Typography variant='caption' style={{ fontSize: '15px' }}>{t('Add Attribute')}</Typography>
                    </div>
                </div>
            </Box>


        </>
    );
};
