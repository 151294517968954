import { Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { deleteIcon } from "../../assets";
import { formatTimestamp } from "../../helpers/date.helper";
import { SearchInput } from "../searchInput.ui";
import { TestResultStatusGrid } from "../testResultStatusTable.ui";
import { useEffect, useMemo, useState } from "react";
import { CHANNELS } from "../../types/global.enum";
import { useSelector } from "react-redux";
import { getCurrentProject, getCurrentTestCase } from "../../store/selectors/project.selector";
import { getScheduledTestResultlistDetails, getTestResultDetails, getTestResultlistDetails } from "../../services/testresults.service";
import { setCurrentTestCase, setCurrentTestResultDetails } from "../../store/resuders/project.reducer";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

export interface IProps {
    projectId : any;
    testcaseId : any;
    openTestResultsDetailsView: () => void;
}

export const TestCaseList = ({
    projectId,
    testcaseId,
    openTestResultsDetailsView
}: IProps) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const project = useSelector(getCurrentProject);
    const [data, setData] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [criteria, setCriteria] = useState<any>({
        sort: '-scheduleStartTime',
        pageNo: 0,
        pageSize: 10,
        filter: ''
    });

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        var direction = criteria.sort.charAt(0) === '+'? "ASC" : "DESC";
        setFilteredData((previousData: any) => previousData?.sort((a: any, b: any) => {
            if (direction && criteria.sort) {
                const aValue = a[criteria.sort.substring(1)]?.toString() || ''; 
                const bValue = b[criteria.sort.substring(1)]?.toString() || '';
                if (direction === 'ASC') {
                    return aValue.localeCompare(bValue);
                } else {
                    return bValue.localeCompare(aValue);
                }
            }
            return 0;
        }));
    }, [criteria])

    const loadData = async () => {
        const { data } = await getScheduledTestResultlistDetails(projectId ,testcaseId, project.data.channelType);
        setData(data);
        setFilteredData(data);
    }

    const gotoTestCaseDetailsPage = (testResult: any) => async () => {
        const { data } = await getTestResultDetails(testResult?.resultId, +(project?.testProjectID || projectId || 1), project.data.channelType);
        dispatch(setCurrentTestResultDetails(data));
        history(`/projects/${project?.testProjectID || projectId}/scheduled/testresults/${testResult.scheduleId}/${testResult?.resultId}`);
        openTestResultsDetailsView();
    }

    // Columns for Test Results Status Page
    const testStatusColumns: any[] = [
        { caption: 'ID', width: '10%', fieldName: 'resultId', sortable: true, key: true },
        {
            fieldName: 'testCaseName', caption: t('TEST CASE NAME'), width: '30%', sortable: true,
            redner: (params: any) => <div className="flex-center">
                <Link onClick={gotoTestCaseDetailsPage(params)} className="a-link-black">{params.testCaseName}</Link></div>
        },
        {
            fieldName: 'startTime', caption: t('DATE TIME'), width: '15%', sortable: true,
            redner: (params: any) => {
                const startTime = new Date(params.startTime);
                return (formatTimestamp(startTime))
            }
        },
        {
            fieldName: 'status', caption: t('STATUS'), width: '15%', sortable: true,
            redner: (params: any) => (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {/* {params?.status === 'FAILED' ? <div className="last-run-status-filled-circle last-run-status-filled-red-circle"></div> : 
                params?.status === 'RUNNING' ? <div className="last-run-status-filled-circle last-run-status-filled-yellow-circle"></div> :
                <div className="last-run-status-filled-circle last-run-status-filled-green-circle"></div>
                } */}
                
                <span style={{ color: params?.status === 'FAILED' ? 'red' : params?.status === 'RUNNING' ? 'black' : '#58A148' }}>
                    {params?.status}
                </span>
            </div>
                
            )
        },
    ];

    const sortChanged = (column: string, direction: string) => {
        setCriteria({
            ...criteria,
            pageNo: 0,
            sort: `${direction === 'ASC' ? '+' : '-'}${column}`,
        });
    };

    const onFilterChanged = (filter: string) => {
        if(filter) {
            filter = filter.toLowerCase();
            setFilteredData(data?.filter((d : any) => d.testCaseName.toLowerCase().includes(filter)));
        } else {
            setFilteredData(data);
        }
        
        // console.log(filter);
        // setCriteria({
        //     ...criteria,
        //     pageNo: 0,
        //     filter
        // });
    }

    return (
        <Grid item xs={12} paddingRight={'9px'} style={{overflowY: 'auto'}}>
            <Card>
                <CardContent>
                    <div className='test-result-header'>
                        <div className="action-header">                                                                            
                        </div>
                        <div className="test-results-filters">
                            <SearchInput onFilter={onFilterChanged} />
                        </div>
                    </div>
                    <TestResultStatusGrid
                        totalPages={-1}
                        sortChanged={sortChanged}
                        columns={testStatusColumns}
                        data={
                            filteredData || []
                        }
                        sortBy={criteria.sort}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
}
